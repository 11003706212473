import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const RedirectToHome = () => {
  const navigate = useNavigate()
  const location = useLocation() // Get the current location

  // Check if the current URL includes '/account/dashboard'
  const isAccountBonusPage = location.pathname.includes('/account/dashboard')

  return (
    !isAccountBonusPage && ( // Conditionally render the button
      <button
        style={{
          position: 'absolute',
          top: '16px',
          right: '0'
        }}
        type='button'
        onClick={() => navigate('/')}
        className='close-btn modal-close filters-selected-false btn btn-danger'
      >
        <i className='fa-solid fa-xmark' />
      </button>
    )
  )
}

export default RedirectToHome
