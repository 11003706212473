import React from 'react'
import './button.scss'

const ButtonHOC = (type) => {
  return ({ isDisabled, isLoading, onClick, className, children, ...props }) => {
    const buttonClass = `btn__${type} ${className || ''} ${isDisabled ? 'btn__disabled' : ''}`
    return (
      <button
        className={buttonClass}
        onClick={onClick}
        disabled={isDisabled || isLoading}
        {...props}
      >
        {isLoading ? <span className='loader' /> : children}
      </button>
    )
  }
}

export const PrimaryButton = ButtonHOC('primary')
export const SecondaryButton = ButtonHOC('secondary')

export default { PrimaryButton, SecondaryButton }
