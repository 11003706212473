import React from 'react'
import { UserDetailContextProvider } from './useContext'
import AccountInfo from '../../components/AccountInfo'

const NewAccountInfoPage = () => {
  return (
    <UserDetailContextProvider>
      <AccountInfo />{' '}
    </UserDetailContextProvider>
  )
}

export default NewAccountInfoPage
