import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCountriesStart, getRegistrationFieldsStart, getTenantDetailsStart, userSignUpStart } from '../../../store/redux-slices/login'
import { Buffer } from 'buffer'
import { formatDateMDY } from '../../../utils/dateFormatter'
import { useNavigate, useParams } from 'react-router-dom'
import { decryptCredentials, getCookie, getItem, getLoginToken } from '../../../utils/storageUtils'
import { Routes } from '../../../utils/routes'
import { browserFingerPrint } from '../../../utils/browserFingerprint'
import { getLanguageDataStart, getLanguagesStart, setSelectedLanguagesStart } from '../../../store/redux-slices/language'
import { toast } from '../../../components/Toast'

const useSignUp = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { languageCode } = useParams()
  const { regFields, tenantDetails, allCountries, loading } = useSelector((state) => state.login)
  const { languages, languageData, selectedLanguageCode, userIPCountry } = useSelector((state) => state.language)
  const [toggleViewPassword, setToggleViewPassword] = useState({
    password: false,
    confirmPassword: false
  })
  const [isPassValid, setIsPassValid] = useState(false)
  const [search, setSearch] = useState('')
  const [language, setLanguage] = useState(languageCode || selectedLanguageCode?.toLowerCase() || getItem('language')?.toLowerCase())
  // For Privacy and Policy
  useEffect(() => {
    const langCode = language?.toLowerCase()
    const anchor = document?.getElementById('privacy-and-policy')?.querySelector('a')
    const href = anchor?.getAttribute('href')
    const newHref = href?.replace(/\/[a-z][a-z]\//, `/${langCode}/`)
    if (anchor) anchor.href = newHref
  }, [language, languageData])

  // For Terms and Conditions
  useEffect(() => {
    const langCode = language?.toLowerCase()
    const anchor = document?.getElementById('terms-and-conditions')?.querySelector('a')
    const href = anchor?.getAttribute('href')
    const newHref = href?.replace(/\/[a-z][a-z]\//, `/${langCode}/`)
    if (anchor) anchor.href = newHref
  }, [language, languageData])

  // Taking only the true registration fields
  const arr =
    regFields &&
    Object.keys(regFields)
      .map((keyName, i) => regFields[keyName] !== false && keyName)
      .filter((ele) => ele !== false && ele)

  // Converting registration fields array into object with empty values
  const initialState =
    arr &&
    arr.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = ''
      return accumulator
    }, {})

  const handleInitialState = (formValues) => {
    for (const k in initialState) {
      if (k === 'newsLetter' || k === 'sms' || k === 'newsletterAndSms') {
        initialState[k] = formValues[k] ? 'true' : 'false'
      } else {
        initialState[k] = formValues[k]
      }
    }
    if (regFields.phone) {
      initialState.phoneCode = formValues.phoneCode
    }
    if (regFields.address) {
      initialState.city = formValues.city.trim()
      initialState.zipCode = formValues.zipCode.trim()
    }
    for (const k in initialState) {
      if (regFields[k] === 0 ||
       initialState[k] === '' || initialState[k] === null) {
        delete initialState[k] // Deleting the fields which are empty/null
        if (k === 'newsLetter') initialState.newsLetter = 'false'
        if (k === 'sms') initialState.sms = 'false'
      }
    }
    const encryptedPass = Buffer.from(initialState.password).toString('base64')
    initialState.password = encryptedPass
    initialState.confirmPassword && (initialState.confirmPassword = encryptedPass)
    initialState.dateOfBirth && (initialState.dateOfBirth = formatDateMDY(initialState.dateOfBirth))
    initialState.uniqueKey = browserFingerPrint()
    const refToken = decryptCredentials(getCookie('refToken'))
    if (refToken) initialState.token = refToken
    if (regFields.phone === 2 && !isPassValid) {
      toast(languageData?.enterValidPhone, 'error')
    } else {
      userSignUp(initialState)
    }
  }
  useEffect(() => {
    language && dispatch(setSelectedLanguagesStart({ selectedLanguageCode: language?.toLowerCase(), navigate }))
  }, [language])

  useEffect(() => {
    if (languages?.allowedLanguages?.includes(languageCode?.toUpperCase())) {
      dispatch(setSelectedLanguagesStart({
        selectedLanguageCode: languageCode
      }))
    } else {
      dispatch(setSelectedLanguagesStart({
        selectedLanguageCode: languages?.allowedLanguages?.[0]
      }))
    }
  }, [languages])

  useEffect(() => {
    if (getLoginToken()) {
      navigate(Routes.homepage)
    }
    if (!languageData) {
      dispatch(getLanguageDataStart())
    }
  }, [])

  useEffect(() => {
    if (!languages) dispatch(getLanguagesStart())
  }, [languages])

  useEffect(() => {
    dispatch(getRegistrationFieldsStart())
    dispatch(getTenantDetailsStart())
  }, [])

  useEffect(() => {
    dispatch(getAllCountriesStart())
  }, [selectedLanguageCode])

  const userSignUp = (initialState) => dispatch(userSignUpStart({ initialState, navigate, returnTo: window.locationPath }))

  // For required * sign
  const showStar = (fieldName) =>
    fieldName === 2 &&
      <span className='text-danger'>&nbsp;*&nbsp;</span>

  return {
    loading,
    regFields,
    toggleViewPassword,
    tenantDetails,
    allCountries,
    languageData,
    language,
    languages,
    setLanguage,
    navigate,
    setIsPassValid,
    setToggleViewPassword,
    handleInitialState,
    showStar,
    search,
    setSearch,
    countryCode: userIPCountry
  }
}

export default useSignUp
