import React, { useContext } from 'react'
import { SignUpContext } from '../../../../../../pages/NewSignup/useContext'

const MobileSignupImage = () => {
  const { languageData } = useContext(SignUpContext)

  return (
    <div className='mobile-signup-img-box' style={{ backgroundImage: `url('${languageData?.signupBackground}')` }}>
      <img
        loading='lazy'
        src={languageData?.signupBannerMobile}
        className='w-100'
        alt='login-img'
      />
      <div className='signup-text-mobile'>
        <div className='signup-welcome-heading'>
          <span className='amazing'>
            {languageData?.signupGetAnAmazing}
          </span>
          <div className='signup-welcome-text'>
            <p>{languageData?.signupBannerDesc}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileSignupImage
