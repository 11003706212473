import React from 'react'
const CustomDropdownToggle = React.forwardRef(({ children, onClick, style, onClickCapture, onKeyDown }, ref) => (
  <button
    className='custom-select-box-container'
    type='button'
    ref={ref}
    onClick={onClick}
    style={style}
    onKeyDown={onKeyDown}
    onClickCapture={onClickCapture}
  >
    {children}
  </button>
))
export default CustomDropdownToggle
