import React, { useRef, useEffect, useState } from 'react'

const DashboardSectionHeading = ({ headingText }) => {
  const headingRef = useRef(null)
  const [textWidth, setTextWidth] = useState(0)

  useEffect(() => {
    if (headingRef.current) {
      setTextWidth(headingRef.current.getBoundingClientRect().width)
    }
  }, [headingText])

  return (
    <div className='heading-section'>
      <div className='heading-text'>
        <span ref={headingRef}>
          {headingText}
        </span>
      </div>
      <div className='bottom-line' style={{ width: `${textWidth}px` }} />
    </div>
  )
}

export default DashboardSectionHeading
