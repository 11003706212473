import React from 'react'
import GameCard from './GameCard'

const InfiniteScrollData = ({
  games,
  toggleFavorite,
  selectedLanguageCode,
  favoriteGamesList
}) => {
  return (
    <>
      <div className='all-game-listing'>
        {games?.rows &&
          games?.rows?.map((game, index) => (
            <GameCard
              game={game}
              index={index}
              toggleFavorite={toggleFavorite}
              selectedLanguageCode={selectedLanguageCode}
              lobby={false}
              key={index}
              favoriteGamesList={favoriteGamesList}
            />
          ))}
      </div>
    </>
  )
}

export default InfiniteScrollData
