import React from 'react'
import { Col, Dropdown, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { statusType } from './constants'
import NoDataAvailable from '../../components/NoDataAvailable'
import { errorMessages } from '../../constants/errorMessages'
import { getDateTime } from '../../utils/dateFormatter'
import PaginationComponent from '../../components/Pagination'
import Trigger from '../../components/OverlayTrigger'
import Loader from '../../components/Loader'
import { currencyCodeList } from '../../constants/currencyCodeList'
import CustomDateRange from '../../components/CustomDateRange'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import CustomDropdownToggle from '../../components/custom/CustomDropdownToggle'
import useWithdrawList from './hooks/useWithdrawList'
import { CancelModal } from '../../components/ConfirmationModal'

const WithdrawList = () => {
  const {
    withdrawalHistory,
    loading,
    userDetails,
    handleCancel,
    page,
    setPage,
    limit,
    setLimit,
    status,
    setStatus,
    search,
    setSearch,
    state,
    setState,
    languageData,
    totalPages,
    show,
    setShow,
    handleYes,
    fetchLatestChanges
  } = useWithdrawList()

  return (
    <>
      <Row className='withdraw-page'>
        <Col className='tab-title-wrapper d-flex justify-content-center'>
          <h2 className='mb-0 text-center'>
            {languageData?.pendingWithdrawal}
          </h2>
          {!loading && (
            <button
              className='d-flex justify-content-end bg-transparent border-0 btn-refresh'
              size='sm'
              onClick={fetchLatestChanges}
            >
              <img
                loading='lazy'
                src={`${process.env.REACT_APP_STATIC_IMAGE}/reload.svg`}
                alt='reload'
                className='reloading-btn'
              />
            </button>
          )}
        </Col>
      </Row>
      <div className='history-container user-account-block'>
        <div className='table-layouts'>
          <div className='heading-filter'>
            <Row>
              <Col className='column-search'>
                <div className='transaction-history'>
                  <div className='form-group icon_include search-icon'>
                    <input
                      name='search'
                      className='custom-input'
                      type='text'
                      value={search}
                      onChange={e =>
                        setSearch(e.target.value, '')}
                      placeholder={languageData?.quickSearch}
                      aria-describedby='emailHelp'
                    />
                    <span className='icon'>
                      <i className='fa-solid fa-magnifying-glass mt-1' style={{ color: '#fff' }} />
                    </span>
                  </div>
                </div>
              </Col>
              <Col className='date-range column-date'>
                <CustomDateRange
                  state={state}
                  setState={setState}
                  onChange={item => setState([item.selection])}
                />
              </Col>
              <Col className='column-dropdown'>
                <div className='form-group w-100'>
                  <Dropdown className='icon-right'>
                    <Dropdown.Toggle id='dropdown-basic' as={CustomDropdownToggle}>
                      <span className='text-capitalize'>{languageData[statusType?.find(item => (item?.values === status))?.label] || null}</span>
                      <img src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`} alt='Dropdown' />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {statusType.map(({ label, values }) =>
                        <Dropdown.Item
                          key={label}
                          onClick={() => setStatus(values)}
                        >{languageData[label]}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>
          <div className='gaming-transaction-table bg'>
            {loading
              ? <Loader top='11rem' position='unset' />
              : withdrawalHistory && withdrawalHistory?.count !== 0 && (
                <Table width='100%' responsive>
                  <thead>
                    <tr className='text-center'>
                      <th scope='col'> <Trigger val={languageData?.action} width='90px' /></th>
                      <th scope='col'> <Trigger val={languageData?.amount} width='90px' /></th>
                      <th scope='col'> <Trigger val={languageData?.status} width='90px' /></th>
                      <th scope='col'> <Trigger val={languageData?.casinoProviders} width='90px' /></th>
                      <th scope='col'> <Trigger val={languageData?.transactionId} width='90px' /></th>
                      <th scope='col'> <Trigger val={languageData?.dateTime} width='90px' /></th>
                      <th scope='col'> <Trigger val={languageData?.processedOn} width='90px' /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawalHistory?.rows.map(({
                      status,
                      amount,
                      withdrawRequestId,
                      updatedAt,
                      createdAt,
                      transactionId,
                      paymentProvider,
                      moreDetails
                    }, i) => {
                      return (
                        <tr key={`transaction-history ${i}`}>
                          <td className='align-baseline'>
                            <div className='d-flex justify-content-center accounts-btn'>

                              {!status
                                ? (
                                  <button
                                    className='cancel-btn pending-btn'
                                    value='Cancel'
                                    onClick={() => handleCancel(withdrawRequestId)}
                                  >
                                    <img src={`${process.env.REACT_APP_STATIC_IMAGE}/icon-pending.svg`} alt='Pending' />
                                  </button>)
                                : <img src={`${process.env.REACT_APP_STATIC_IMAGE}/icon-denied.svg`} alt='Denied' height={15} />}
                            </div>
                          </td>

                          <td className='align-middle d-flex justify-content-center'>
                            <div className='cell-values text-center'>
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {amount}
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <p
                                  style={{ cursor: 'pointer', maxWidth: '80px' }}
                                  className='text-truncate'
                                >
                                  {currencyCodeList[userDetails?.userWallet?.currencyCode || 'EUR']}{' '}{amount}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='d-flex justify-content-center text-center'>
                              <p>{languageData[statusType.find(val => parseInt(val.values) === status)?.label]}</p>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='d-flex justify-content-center'>
                              <p>{paymentProvider}</p>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='d-flex justify-content-center'>
                              <p>{moreDetails?.withdrawId || transactionId}</p>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='d-flex justify-content-center'>
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {getDateTime(createdAt)}
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className='text-truncate transaction-name text-center'
                                >
                                  {getDateTime(createdAt)}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='d-flex justify-content-center'>
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {getDateTime(updatedAt)}
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className='text-truncate transaction-name text-center'
                                >
                                  {getDateTime(updatedAt)}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>

                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              )}

            {withdrawalHistory && withdrawalHistory.count === 0 && !loading &&
              <NoDataAvailable errorMessage={languageData[errorMessages.withdrawalHistory]} />}

          </div>
          {withdrawalHistory && withdrawalHistory.count !== 0 && !loading && (
            <PaginationComponent
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>

      {show && (
        <CancelModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
        />
      )}

    </>
  )
}

export default WithdrawList
