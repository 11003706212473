import { React } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Icon from 'components/Icon'
import { formatTags, getGameUrl, isNewGame } from 'utils/helpers'
import { useSelector } from 'react-redux'
import { getItem } from 'utils/storageUtils'
import './styles/index.scss'

const GameCard = ({
  game,
  index,
  toggleFavorite,
  selectedLanguageCode,
  favoriteGamesList,
  playGameCallback
}) => {
  const { brandLogo } = useSelector(state => state.language)
  const loggedIn = JSON.parse(getItem('loggedIn'))
  const { languageData } = useSelector((state) => state.language)
  const location = useLocation()

  const CardDetails = ({ langKey, value, icon }) => (
    <div className='lobby-game-otherinfo'>
      <div className='lobby-game-otherinfo-label'>
        <i className={`fa-solid fa-${icon} mr-1`} />
        {langKey}
      </div>
      <div className='lobby-game-otherinfo-value'>{value}</div>
    </div>
  )

  return (
    <div key={`list-menu-item-${index}`} className='lobby-game-card'>
      <div className='game-container'>
        <div className='hover-area'>
          <div className='hover-content'>
            <div
              className='fav-icon'
              onClick={(e) => {
                e.preventDefault()
                toggleFavorite({
                  isFavorite: favoriteGamesList?.rows?.some(
                    (el) => el.categoryGameId === game?.categoryGameId
                  ),
                  tenantGameSubCategoryId: game?.tenantGameSubCategoryId,
                  categoryGameId: game?.categoryGameId
                })
              }}
            >
              <Icon
                name='favorite'
                fill={
                  favoriteGamesList?.rows?.some(
                    (el) => el.categoryGameId === game?.categoryGameId
                  )
                    ? '#ffffff'
                    : ''
                }
              />
            </div>
            <br />
            <span className='hover-content-buttons'>
              <Link
                className='real-play'
                to={getGameUrl(selectedLanguageCode?.toLowerCase(), game?.identifier)}
                onClick={() => {
                  if (location?.pathname) {
                    window.previousScreen = location.pathname
                  }
                  if (playGameCallback) playGameCallback()
                }}
              >
                Play
                <i className='fa-solid fa-play' />
              </Link>
              {game?.demo && window.innerWidth > 1024 && !loggedIn && (
                <Link
                  className='demo-play'
                  to={`/${selectedLanguageCode?.toLowerCase()}/demo/${game?.identifier
                    }`}
                  onClick={() => {
                    if (location?.pathname) {
                      window.previousScreen = location.pathname
                    }
                    if (playGameCallback) playGameCallback()
                  }}
                >
                  {languageData?.tryForFree}
                </Link>
              )}
            </span>
          </div>
          <img
            loading='lazy'
            src={game?.thumbnailUrl || languageData?.gameCardPlaceholderImage || '/images/placeholder.svg'}
            onError={(e) => {
              e.target.onerror = null
              e.target.src = '/images/placeholder.svg'
            }}
            className='w-100 game-img'
            alt='game'
          />
        </div>
        {isNewGame(game?.moreDetails?.released_at) && (
          <div className='game-tag-wrapper'>
            <div className='game-tag new'>
              <i className='fa-solid fa-sparkles' />
            </div>
          </div>
        )}
        <div className='lobby-game-info'>
          <div className='lobby-game-name'>{game?.name}</div>
          <div className='lobby-game-provider'>
            <img
              src={game?.providerLogo || brandLogo || '/images/brand-logo.svg'}
              onError={(e) => {
                e.target.onerror = null
                e.target.src = brandLogo || '/images/brand-logo.svg'
              }}
              className='lobby-game-provider-logo'
              alt='provider'
            />
            <span className='lobby-game-provider-name'>
              {game?.providerName}
            </span>
          </div>
          {game?.volatilityRating
            ? (
              <CardDetails
                langKey={languageData?.volatility}
                value={game?.volatilityRating}
                icon='chart-line-up-down'
              />
              )
            : (formatTags(game?.theme) === '-' && game?.returnToPlayer)
                ? (
                  <CardDetails
                    langKey={languageData?.returnToPlayer}
                    value={game?.returnToPlayer + '%'}
                    icon='coins'
                  />
                  )
                : (
                  <CardDetails
                    langKey={languageData?.theme}
                    value={formatTags(game?.theme)}
                    icon='palette'
                  />
                  )}
        </div>
      </div>
    </div>
  )
}

export default GameCard
