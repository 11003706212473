import React, { useContext } from 'react'
import { UserDetailContext } from '../../../../../pages/NewAccountInfoPage/useContext'
import FormFieldControl from '../../../../FormFieldControl'
import DateBirth from '../../../../FormFieldControl/DateBirth'
import UserInfoPhoneField from '../../../../FormFieldControl/UserInfoPhoneField'
import LanguageField from '../../../../FormFieldControl/LanguageField'
const PrivateData = ({ formik }) => {
  const { regFields, languageData, showStar } =
    useContext(UserDetailContext)

  const commonProps = {
    containerClassName: 'custom-form-group',
    fieldClassName: 'form-control custom-input',
    contextValue: UserDetailContext
  }

  const PreferredLanguageLabel = () => (
    <label htmlFor='currencyCode' className='custom-label'>
      {languageData?.signupLanguage || 'Preferred Language'}
      {regFields?.preferredLanguage === 2
        ? showStar(regFields.preferredLanguage)
        : ''}
    </label>
  )
  return (
    <>
      {regFields && (
        <div className=''>
          <div className='signup-form-detail'>
            <FormFieldControl
              type='text'
              name='firstName'
              control='input'
              label='signupFirstName'
              placeholder={languageData?.firstNamePlaceholder}
              {...commonProps}
              disabled
            />

            <FormFieldControl
              type='text'
              name='lastName'
              control='input'
              label='signupLastName'
              placeholder={languageData?.lastNamePlaceholder}
              {...commonProps}
              disabled
            />

            <FormFieldControl
              type='text'
              name='username'
              control='input'
              label='signupUserName'
              placeholder={languageData?.userNamePlaceholder}
              {...commonProps}
              disabled
            />

            <FormFieldControl
              name='phone'
              label='signupPhoneNo'
              {...commonProps}
              disabled
            >
              <UserInfoPhoneField
                disabled
                formik={formik}
                contextValue={UserDetailContext}
              />
            </FormFieldControl>

            <FormFieldControl
              name='dateOfBirth'
              label='signupDob'
              {...commonProps}
              disabled
            >
              <DateBirth disabled formik={formik} contextValue={UserDetailContext} />
            </FormFieldControl>

            <FormFieldControl
              name='preferredLanguage'
              label='signupLanguage'
              defaultLabel={<PreferredLanguageLabel />}
              {...commonProps}
              disabled
            >
              <LanguageField disabled formik={formik} contextValue={UserDetailContext} />
            </FormFieldControl>
          </div>
        </div>
      )}
    </>
  )
}

export default PrivateData
