import { React } from 'react'
import { useSelector } from 'react-redux'

const FreeSpinGameCard = ({
  game,
  setIdentifier,
  identifier
}) => {
  const { brandLogo, languageData } = useSelector(state => state.language)
  return (
    <div xs={2} md={4} lg={4} className={`free-spin-game-container ${game.identifier === identifier ? 'selected' : ''}`} onClick={() => setIdentifier(game.identifier)}>
      <div className='hover-area'>
        <img
          loading='lazy'
          src={game?.thumbnailUrl || languageData?.gameCardPlaceholderImage || '/images/placeholder.svg'}
          onError={(e) => {
            e.target.onerror = null
            e.target.src = '/images/placeholder.svg'
          }}
          className='w-100 game-img'
          alt='game'
        />
      </div>
      <div className='lobby-game-info'>
        <div className='lobby-game-name'>{game?.name}</div>
        <div className='lobby-game-provider'>
          <img
            src={game?.providerLogo || brandLogo || '/images/brand-logo.svg'}
            onError={(e) => {
              e.target.onerror = null
              e.target.src = brandLogo || '/images/brand-logo.svg'
            }}
            className='lobby-game-provider-logo'
            alt='provider'
          />
          <span className='lobby-game-provider-name'>
            {game?.providerName}
          </span>
        </div>
      </div>
    </div>
  )
}

export default FreeSpinGameCard
