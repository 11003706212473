import React from 'react'
import { Modal } from 'react-bootstrap'

const SkipModal = ({
  showModal,
  setShowModal,
  skipBonusConfirmHandler,
  languageData
}) => {
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='generic-modal-layout'
        data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-hidden='true'
      >
        <div className='topdiv mx-auto'>
          <Modal.Body>
            <div>
              {languageData?.bonusSkipContent || 'Are you sure you want to skip Bonus.'}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              className='btn-modal'
              onClick={() => {
                setShowModal(false)
                skipBonusConfirmHandler()
              }}
            > {languageData?.signupConfirm}
            </button>
            <button
              className='btn-modal' onClick={() => {
                setShowModal(false)
              }}
            > {languageData?.cancel}
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export default SkipModal
