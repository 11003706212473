import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getPaymentProvidersStart,
    getPaymentProvidersSuccess,
    getPaymentProvidersFailure,
    verifyPaymentStart,
    verifyPaymentComplete,
    getPaymentStatusStart,
    getPaymentStatusSuccess,
    getPaymentStatusFailure,
    resetPaymentData,
    getAllPaymentProvidersSuccess,
    verifyPaymentFailed,
    setMerchantId,
    getUtorgCurrenciesStart,
    getUtorgCurrenciesSuccess,
    getUtorgCurrenciesFailed
  },
  reducer
} = createSlice({
  name: 'payment',
  initialState: {
    loading: false,
    merchantId: null,
    paymentData: null,
    paymentStatus: null,
    paymentErrCode: null,
    paymentProviders: null,
    allPaymentProviders: null,
    verifyPaymentLoading: false,
    utorgCurrencies: []
  },
  reducers: {
    getPaymentProvidersStart: (state) => ({
      ...state,
      loading: true,
      paymentProviders: null
    }),
    getPaymentProvidersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      paymentProviders: payload
    }),
    getAllPaymentProvidersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allPaymentProviders: payload
    }),
    getPaymentProvidersFailure: (state) => ({
      ...state,
      loading: false
    }),
    verifyPaymentStart: (state) => ({
      ...state,
      verifyPaymentLoading: true,
      paymentData: null,
      paymentStatus: null
    }),
    verifyPaymentComplete: (state, { payload }) => ({
      ...state,
      verifyPaymentLoading: false,
      paymentData: payload,
      paymentStatus: null
    }),
    verifyPaymentFailed: (state, { payload }) => ({
      ...state,
      paymentErrCode: payload
    }),
    getPaymentStatusStart: (state) => ({
      ...state,
      loading: true,
      paymentStatus: null
    }),
    getPaymentStatusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      paymentStatus: payload
    }),
    getPaymentStatusFailure: (state) => ({
      ...state,
      loading: false
    }),
    resetPaymentData: (state) => ({
      ...state,
      paymentProviders: [],
      paymentData: null
    }),
    setMerchantId: (state, { payload }) => ({
      ...state,
      merchantId: payload
    }),
    getUtorgCurrenciesStart: (state) => ({
      ...state,
      utorgCurrencies: []
    }),
    getUtorgCurrenciesSuccess: (state, { payload }) => ({
      ...state,
      utorgCurrencies: payload
    }),
    getUtorgCurrenciesFailed: (state, { payload }) => ({
      ...state,
      utorgCurrencies: []
    })
  }
})

export default reducer

export {
  getPaymentProvidersStart,
  getPaymentProvidersSuccess,
  getPaymentProvidersFailure,
  verifyPaymentStart,
  verifyPaymentComplete,
  getPaymentStatusStart,
  getPaymentStatusSuccess,
  getPaymentStatusFailure,
  resetPaymentData,
  getAllPaymentProvidersSuccess,
  verifyPaymentFailed,
  setMerchantId,
  getUtorgCurrenciesStart,
  getUtorgCurrenciesSuccess,
  getUtorgCurrenciesFailed
}
