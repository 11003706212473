import React from 'react'
import { useSelector } from 'react-redux'
import './styles/index.scss'
import { getItem } from 'utils/storageUtils'
import Loader from 'components/Loader'

const PaymentFailed = ({
  paymentStatus,
  navigate,
  setShow,
  deposit
}) => {
  const { languageData } = useSelector((state) => state.language)
  return (
    <div className='deposit-receipt-content'>
      <div className='text-center status_animation_wrapper'>
        <svg className='cross__svg' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
          <circle className='cross__circle' cx='26' cy='26' r='25' fill='none' />
          <path className='cross__path cross__path--right' fill='none' d='M16,16 l20,20' />
          <path className='cross__path cross__path--right' fill='none' d='M16,36 l20,-20' />
        </svg>
      </div>
      <div className='deposit-receipt-detail' />
      <div className='deposit-receipt-list'>
        {paymentStatus?.messages?.length
          ? paymentStatus.messages.map((val, indx) => {
            return val.value && (
              <div className='info-list-item' key={indx}>
                <span>
                  {val.label}
                </span>
                <span>
                  {val.value}
                </span>
              </div>
            )
          })
          : languageData?.somethingWentWrong}
        <div className='info-list-item'>
          <span>
            {deposit
              ? (
                  !paymentStatus?.error
                    ? paymentStatus?.errors?.[0]?.msg || languageData?.somethingWentWrong
                    : (paymentStatus?.error || paymentStatus?.statusCode)?.split('_')?.join(' ')
                )
              : (
                  (paymentStatus?.statusCode)?.split('_')?.join(' ')
                )}
          </span>
        </div>
      </div>
      <div className='continue-site-btn'>
        <button
          type='button' className='text-center' onClick={() => {
            setShow(false)
            navigate(`/${getItem('language').toLowerCase()}/account/${deposit ? 'deposit' : 'withdraw'}`)
          }}
        >
          {languageData?.continueToSite}
        </button>
      </div>
    </div>
  )
}

const PaymentSuccess = ({
  paymentStatus,
  navigate,
  setShow,
  deposit,
  fetchAmount,
  setShowReceipt
}) => {
  const { languageData } = useSelector((state) => state.language)
  return (
    <>
      {deposit
        ? (
          <div className='deposit-receipt-content'>
            <div className='text-center status_animation_wrapper'>
              <div className='icon icon--order-status svg'>
                <svg xmlns='http://www.w3.org/2000/svg' className='success'>
                  <g fill='none' stroke='#22AE73' stroke-width='2'>
                    <circle cx='77' cy='77' r='72' />
                    <circle id='colored' fill='#22AE73' cx='77' cy='77' r='72' />
                    <polyline className='st0' stroke='#fff' stroke-width='10' points='43.5,77.8 63.7,97.9 112.2,49.4 ' />
                  </g>
                </svg>
              </div>
            </div>
            <div className='deposit-receipt-detail'>
              <h4>
                {paymentStatus?.messages?.find(({ label }) => label === 'Amount deposited to player account')?.value}
              </h4>
              <p className='text-center'>
                {new Date().toDateString()}, {new Date().toLocaleTimeString()}
              </p>
            </div>
            <div className='deposit-receipt-list'>
              {paymentStatus?.messages && paymentStatus.messages?.map((val, indx) => {
                return val.value && (
                  <div className='info-list-item' key={indx}>
                    <span>
                      {val.label}
                    </span>
                    <span>
                      {val.value}
                    </span>
                  </div>
                )
              })}
              <div className='info-list-item'>
                <span>
                  {languageData?.receiptOfYour} {paymentStatus?.pspService || paymentStatus?.psp} {languageData?.transaction}
                </span>
              </div>
            </div>
            <div className='continue-site-btn'>
              <button
                type='button' className='text-center' onClick={() => {
                  setShow(false)
                  navigate(`/${getItem('language').toLowerCase()}/account/transaction`)
                }}
              >
                {languageData?.continueToSite}
              </button>
            </div>
          </div>)
        : (
          <div className='deposit-receipt-content'>
            <div className='text-center status_animation_wrapper'>
              <div className='icon icon--order-status svg'>
                <svg xmlns='http://www.w3.org/2000/svg' className='success'>
                  <g fill='none' stroke='#22AE73' stroke-width='2'>
                    <circle cx='77' cy='77' r='72' />
                    <circle id='colored' fill='#22AE73' cx='77' cy='77' r='72' />
                    <polyline className='st0' stroke='#fff' stroke-width='10' points='43.5,77.8 63.7,97.9 112.2,49.4 ' />
                  </g>
                </svg>
              </div>
            </div>
            <div className='deposit-receipt-detail'>
              <h4>
                {fetchAmount &&
              (fetchAmount.substr(0, 1) === '-'
                ? fetchAmount.substr(1)
                : fetchAmount)}
              </h4>
              <p className='text-center'>
                {new Date().toLocaleDateString()},{' '}
                {new Date().toLocaleTimeString()}
              </p>
            </div>
            <div className='deposit-receipt-list'>
              {paymentStatus?.messages && paymentStatus.messages?.map((val, indx) => {
                return val.value && (
                  <div className='info-list-item' key={indx}>
                    <span>
                      {val.label}
                    </span>
                    <span>
                      {val.value}
                    </span>
                  </div>
                )
              })}
              <div className='info-list-item'>
                <span>
                  {languageData?.receiptOfYour}{' '}
                  {paymentStatus?.pspService || paymentStatus?.psp}{' '}
                  {languageData?.withdrawal}.
                  <br />
                  <br />
                  {languageData?.withdrawRequestCreated}.
                </span>
              </div>
            </div>
            <div className='continue-site-btn'>
              <button
                type='button' className='text-center' onClick={() => {
                  setShowReceipt(false)
                  navigate(`/${getItem('language').toLowerCase()}/account/withdraw`)
                }}
              >
                {languageData?.continueToSite}
              </button>
            </div>
          </div>)}
    </>
  )
}

export const PaymentStatusInfo = ({
  paymentStatus,
  status,
  setShow,
  navigate,
  deposit
}) => {
  const { languageData } = useSelector(state => state.language)
  const { paymentErrCode } = useSelector(state => state.casinoMenu)
  const { paymentData } = useSelector((state) => state.payment)

  return (
    <div className='deposit-receipt-content'>
      <div className='text-center status_animation_wrapper'>
        <div className='icon icon--order-status svg'>
          <i class='fa-solid fa-circle-info' style={{ color: '#ffa827', fontSize: '155px' }} />
        </div>
      </div>
      <div className='deposit-receipt-list text-center'>
        <p>{languageData?.transactionInfo}</p>
        <div className='deposit-receipt-detail' />
      </div>
      <div className='deposit-receipt-list'>
        {!paymentStatus?.messages
          ? (
            <p>
              {paymentErrCode === 3086
                ? `${languageData?.depositLimitReached}.`
                : (paymentStatus?.errors?.[0]?.msg
                    ? paymentStatus?.errors?.[0]?.msg?.split('_')?.join(' ')
                    : paymentData?.error?.[0]?.msg
                      ? paymentData?.error?.[0]?.msg?.replaceAll('_', ' ')
                      : paymentData?.description || languageData?.somethingWentWrong)}
            </p>
            )
          : (paymentStatus?.messages && paymentStatus.messages?.map((val, indx) => {
              return val.value && (
                <div className='info-list-item' key={indx}>
                  <span>
                    {val.label}
                  </span>
                  <span>
                    {val.value}
                  </span>
                </div>
              )
            })
            )}
        <div className='info-list-item'>
          <span>
            {deposit
              ? (
                  !paymentStatus?.error
                    ? paymentStatus?.errors?.[0]?.msg || languageData?.somethingWentWrong
                    : (paymentStatus?.error || paymentStatus?.statusCode)?.split('_')?.join(' ')
                )
              : (
                  (paymentStatus?.statusCode)?.split('_')?.join(' ')
                )}
          </span>
        </div>
      </div>
      <div className='continue-site-btn text-center'>
        <button
          type='button' onClick={() => {
            setShow(false)
            navigate(`/${getItem('language').toLowerCase()}/account/${deposit ? 'deposit' : 'withdraw'}`)
          }}
        >
          {languageData?.continueToSite}
        </button>
      </div>
    </div>
  )
}

const PaymentReceipt = ({
  paymentStatus,
  navigate,
  deposit = false,
  setShow,
  fetchAmount,
  setShowReceipt,
  loading = false
}) => {
  if (loading) {
    return <Loader top='6%' />
  }
  return (
    <>
      {['FAILED', 'INCONSISTENT', 'CANCELLED'].includes(paymentStatus?.txState)
        ? <PaymentFailed deposit={deposit} paymentStatus={paymentStatus} navigate={navigate} setShow={setShow} />
        : ((paymentStatus?.txState === 'SUCCESSFUL')
            ? <PaymentSuccess setShowReceipt={setShowReceipt} fetchAmount={fetchAmount} deposit={deposit} paymentStatus={paymentStatus} navigate={navigate} setShow={setShow} />
            : <PaymentStatusInfo deposit={deposit} paymentStatus={paymentStatus} status={paymentStatus?.txState} setShow={setShow} navigate={navigate} />
          )}
    </>
  )
}

export default PaymentReceipt
