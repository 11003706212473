import React from 'react'
import 'react-date-range/dist/styles.css'
import Loader from '../../components/Loader'
import 'react-date-range/dist/theme/default.css'
import useBetHistory from './hooks/useBetHistory'
import Pagination from '../../components/Pagination'
import { getDateTime } from '../../utils/dateFormatter'
import CustomDateRange from '../../components/CustomDateRange'
import NoDataAvailable from '../../components/NoDataAvailable'
import { currencyCodeList } from '../../constants/currencyCodeList'
import { internalTransactionType, transactionType } from './constants'
import CustomDropdownToggle from '../../components/custom/CustomDropdownToggle'
import { Col, Dropdown, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'

const Transactions = () => {
  const {
    loading,
    betHistory,
    limit,
    page,
    search,
    selectedAction,
    totalPages,
    userDetails,
    languageData,
    setSelectedAction,
    setSearch,
    setLimit,
    setPage,
    fetchLatestChanges,
    state,
    setState
  } = useBetHistory()

  const getStatusColor = (actionType) => {
    return actionType === 'win'
      ? 'status-complete'
      : actionType === 'bet'
        ? 'status-requested'
        : actionType === 'freespins'
          ? 'status-rollback'
          : 'status-light'
  }

  return (
    <>
      <div className='tab-title-wrapper d-flex justify-content-center align-items-center'>
        <h2>{languageData?.headerBetHistory}</h2>
        <button
          className='d-flex justify-content-end bg-transparent border-0 btn-refresh'
          size='sm'
          onClick={fetchLatestChanges}
        >
          {/* <OverlayTrigger placement='right' overlay={popover}> */}
          <img loading='lazy' src={`${process.env.REACT_APP_STATIC_IMAGE}/reload.svg`} alt='reload' className='reloading-btn' />
          {/* </OverlayTrigger> */}
        </button>
      </div>

      <div className='user-account-block history-container'>
        <div className='filer-and-table-container table-layouts'>
          <div className='heading-filter'>
            <Row>
              <Col className='column-search'>
                <div className='bet-history'>
                  <div className='form-group icon_include search-icon'>
                    <input
                      name='search'
                      className='custom-input form-control'
                      type='text'
                      value={search}
                      onChange={e =>
                        setSearch(e.target.value, '')}
                      placeholder={languageData?.quickSearch}
                      aria-describedby='emailHelp'
                    />
                    <span className='icon'>
                      <i className='fa-solid fa-magnifying-glass' style={{ color: '#fff' }} />
                    </span>
                  </div>
                </div>
              </Col>

              <Col className='date-range column-date'>
                <CustomDateRange
                  state={state}
                  setState={setState}
                  onChange={item => setState([item.selection])}
                />
              </Col>

              <Col className='column-dropdown'>
                <div className='form-group w-100'>
                  <Dropdown className='icon-right'>
                    <Dropdown.Toggle id='dropdown-basic' as={CustomDropdownToggle}>
                      <span>{languageData[selectedAction]}</span>
                      <img src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`} alt='Dropdown' />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item disabled>{languageData?.actionType}</Dropdown.Item>
                      {transactionType?.map(
                        ({ label, value }) =>
                          <Dropdown.Item
                            key={label}
                            onClick={() => setSelectedAction(value)}
                          >{languageData[label]}
                          </Dropdown.Item>
                      )}
                      {userDetails?.isInternalUser && internalTransactionType?.map(
                        ({ label, value }) =>
                          <Dropdown.Item
                            key={label}
                            onClick={() => setSelectedAction(value)}
                          >{languageData[label]}
                          </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>

          <div className='gaming-transaction-table'>
            {loading
              ? <Loader top='2rem' position='unset' />
              : betHistory && betHistory?.count !== 0 && (
                <Table responsive striped width='100%'>
                  <thead>
                    <tr style={{ color: 'white' }} className='text-center'>
                      <th scope='col'>{languageData?.userDetail}</th>
                      <th scope='col'>{languageData?.gameIdentifier}</th>
                      <th scope='col'>{languageData?.actionType}</th>
                      <th scope='col'>{languageData?.betType}</th>
                      <th scope='col'>{languageData?.amount}</th>
                      <th scope='col'>{languageData?.beforeBalance}</th>
                      <th scope='col'>{languageData?.afterBalance}</th>
                      <th scope='col'>{languageData?.jackpotWin}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betHistory?.rows.map((item, i) => {
                      return (
                        <tr key={`bet-history ${i}`}>
                          <td className='align-middle'>
                            <div className='d-flex flex-column transaction-section'>
                              <p className='transaction-name'>
                                {item?.gameIdentifier && item?.gameIdentifier.split(':')[1]}
                              </p>
                              <small className='transaction-date'>
                                {getDateTime(item?.updatedAt)}
                              </small>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='cell-values'>
                              <p className='mb-0 text-center text-capitalize'>
                                {item?.gameIdentifier && item?.gameIdentifier.split(':')[0]}
                              </p>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className={`cell-values text-center ${getStatusColor(item.actionType)}`}>
                              {item?.actionType === 'win'
                                ? (
                                  <span className='text-capitalize'>{languageData[item?.actionType]}</span>
                                  )
                                : (
                                  <span className='text-capitalize'>{languageData[item?.actionType]}
                                  </span>
                                  )}
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='cell-values text-center'>
                              <p className='mb-0'>
                                {(() => {
                                  switch (item?.amountType) {
                                    case 0: return languageData?.cash
                                    case 1: return languageData?.headerBonus
                                    case 2: return `${languageData?.cashBonus}`
                                    default: return ''
                                  }
                                })()}
                              </p>
                            </div>
                          </td>

                          <td>
                            <div className='cell-values text-center'>
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {currencyCodeList[item?.currencyCode || 'EUR']} {item?.amountType === 0
                                      ? item?.amount
                                      : item?.amountType === 2
                                        ? item?.amount + item?.nonCashAmount
                                        : item?.nonCashAmount}
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className='text-truncate'
                                >
                                  {currencyCodeList[item?.currencyCode || 'EUR']} {item?.amountType === 0
                                    ? item?.amount
                                    : item?.amountType === 2
                                      ? item?.amount + item?.nonCashAmount
                                      : item?.nonCashAmount}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='cell-values text-center'>
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {currencyCodeList[item?.currencyCode || 'EUR']} {item?.beforeBalance}
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className='text-truncate mb-0'
                                >
                                  {currencyCodeList[item?.currencyCode || 'EUR']} {item?.beforeBalance}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='cell-values text-center'>
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {currencyCodeList[item?.currencyCode || 'EUR']} {item?.afterBalance}
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className='text-truncate mb-0'
                                >
                                  {currencyCodeList[item?.currencyCode || 'EUR']} {item?.afterBalance}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='cell-values text-center'>
                              <p className='mb-0'>
                                {['win', 'winInternal'].includes(item?.actionType) ? `${currencyCodeList[item?.currencyCode || 'EUR']} ${item?.moreDetails?.jackpotAmount || 0}` : '-'}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              )}

            {betHistory && betHistory.count === 0 && !loading && <NoDataAvailable errorMessage={languageData?.noBetHistory} />}

          </div>
          {betHistory && betHistory.count !== 0 && !loading && (
            <Pagination
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Transactions
