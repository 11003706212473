import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getCmsPageStart,
    getCmsPageSuccess,
    getCmsPageFailure
  },
  reducer
} = createSlice({
  name: 'cms',
  initialState: {
    loading: false,
    cmsDetails: null
  },
  reducers: {
    getCmsPageStart: (state) => ({
      ...state,
      loading: true
    }),
    getCmsPageSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      cmsDetails: payload
    }),
    getCmsPageFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getCmsPageStart,
  getCmsPageSuccess,
  getCmsPageFailure
}
