import React from 'react'

const PassIcons = ({ toggleViewPassword, setToggleViewPassword }) => {
  return (
    <span
      className='show-password-icon'
      onClick={() =>
        setToggleViewPassword((toggleViewPassword) => !toggleViewPassword)}
    >
      {toggleViewPassword
        ? (
          <img
            loading='lazy'
            className='show-icon'
            src={`${process.env.REACT_APP_STATIC_IMAGE}/show-icon.svg`}
            alt='open-eye'
          />
          )
        : (
          <img
            loading='lazy'
            src={`${process.env.REACT_APP_STATIC_IMAGE}/hide-icon.svg`}
            alt='close-eye'
          />
          )}
    </span>
  )
}

export default PassIcons
