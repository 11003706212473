import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import './styles/index.scss'
const LoyaltyProgressBar = ({
  now,
  label,
  progressClass = '',
  leftStyle = ''
}) => {
  return (
    <>
      <ProgressBar now={now} label={now > 30 ? label : ''} />
      {now <= 30 && (
        <div
          className={progressClass}
          style={{
            left: leftStyle
          }}
        >
          {`${now}%`}
        </div>
      )}
    </>
  )
}

export default LoyaltyProgressBar
