import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const ForfietModal = ({
  showModal,
  setShowModal,
  forfeitHandler,
  selectedBonus,
  selectedLanguageCode
}) => {
  const { languageData } = useSelector((state) => state.language)
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='generic-modal-layout'
        data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-hidden='true'
      >
        <div className=''>
          <Modal.Header>{languageData?.areYouSure} ?</Modal.Header>
          <Modal.Body>
            <div className='modal-body'>
              <p>{languageData?.forfeitMessageFirst}</p>
              <span className='text-capitalize highlighted-content'>
                {` ${selectedBonus?.bonus?.promotionTitle?.[selectedLanguageCode] || selectedBonus?.bonus?.promotionTitle?.EN} `}
              </span>
              <p>{languageData?.forfeitMessageSecond}</p>
            </div>
          </Modal.Body>

          <Modal.Footer className='d-flex justify-content-around'>
            <button
              className='btn-modal btn-cancel' onClick={() => {
                forfeitHandler(selectedBonus?.userBonusId, selectedBonus?.status)
              }}
            > {languageData?.forfeitButton}
            </button>
            <button
              className='btn-modal' onClick={() => {
                setShowModal(false)
              }}
            > {languageData?.cancel}
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export default ForfietModal
