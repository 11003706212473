import { takeLatest, put, select } from 'redux-saga/effects'

import {
  getUserBetHistoryStart,
  getUserBetHistorySuccess,
  getUserBetHistoryFailure,
  getUserIdAndSessionIdStart,
  getUserIdAndSessionIdSuccess,
  getUserIdAndSessionIdFailure,
  getWithdrawalHistoryStart,
  getWithdrawalHistorySuccess,
  getWithdrawalHistoryFailure,
  cancelWithdrawRequestStart,
  cancelWithdrawRequestComplete,
  getUserTransactionsStart,
  getUserTransactionsSuccess,
  getUserTransactionsFailure
} from '../redux-slices/transactions'

import {
  getUserBetHistory,
  getUserCredential,
  getWithdrawalHistory,
  cancelWithdrawRequest,
  getUserTransactions
} from '../../utils/apiCalls'
import { toast } from '../../components/Toast'
import { setItem } from '../../utils/storageUtils'
import { getDateDaysAgo } from '../../utils/dateFormatter'
import { verifyPaymentStart } from '../redux-slices/payment'

const selectedLangCode = state => state?.language

export default function * transactionsWatcher () {
  yield takeLatest(getUserBetHistoryStart, getUserBetHistoryWorker)
  yield takeLatest(getUserIdAndSessionIdStart, getUserIdAndSessionIdStartWorker)
  yield takeLatest(getWithdrawalHistoryStart, getWithdrawalHistoryWorker)
  yield takeLatest(cancelWithdrawRequestStart, cancelWithdrawRequestWorker)
  yield takeLatest(getUserTransactionsStart, getUserTransactionsWorker)
}

function * getUserBetHistoryWorker (action) {
  try {
    const { limit, pageNo, search, startDate, endDate, actionType } = action && action.payload

    const { data } = yield getUserBetHistory({ limit, pageNo, search, startDate, endDate, actionType })

    yield put(getUserBetHistorySuccess(data?.data?.casinoTransaction))
  } catch (e) {
    yield put(getUserBetHistoryFailure())
  }
}

function * getUserIdAndSessionIdStartWorker (action) {
  try {
    const { type, payments = false, myData = null, navigate } = action && action.payload

    const { data } = yield getUserCredential(type)

    setItem('sessionId', data?.data?.sessionId)
    yield put(getUserIdAndSessionIdSuccess(data?.data))
    if (payments) yield put(verifyPaymentStart({ data: { ...myData, sessionId: data?.data?.sessionId }, navigate }))
  } catch (e) {
    const { selectedLanguageCode } = yield select(selectedLangCode)
    const { navigate } = action && action.payload

    yield put(getUserIdAndSessionIdFailure())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    if (e?.response?.data?.errors[0]?.errorCode === 3054) {
      navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/dashboard`, { state: { tab: 'dashboard' } })
    } else {
      navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/account-verify`, { state: { tab: 'account-verify' } })
    }
  }
}

function * getWithdrawalHistoryWorker (action) {
  try {
    const { limit, pageNo, status, startDate, endDate, search } = action && action.payload

    const { data } = yield getWithdrawalHistory({ limit, pageNo, status, startDate, endDate, search })

    yield put(getWithdrawalHistorySuccess(data?.data?.withdrawRequest))
  } catch (e) {
    yield put(getWithdrawalHistoryFailure())
  }
}

function * cancelWithdrawRequestWorker (action) {
  try {
    const { data, limit, pageNo, status, search, isTransactions = false, startDate, endDate, transactionType } = action && action.payload

    yield cancelWithdrawRequest(data)

    yield put(cancelWithdrawRequestComplete())

    if (isTransactions) {
      yield put(getUserTransactionsStart({ limit, pageNo, status, startDate, endDate, transactionType, search }))
    } else {
      yield put(getWithdrawalHistoryStart({ limit, pageNo, status, startDate: getDateDaysAgo(10), endDate: new Date(), search }))
    }
  } catch (e) {
    yield put(cancelWithdrawRequestComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getUserTransactionsWorker (action) {
  try {
    const { limit, pageNo, startDate, endDate, transactionType, search } = action && action.payload

    const { data } = yield getUserTransactions({ limit, pageNo, startDate, endDate, transactionType, search })

    yield put(getUserTransactionsSuccess(data?.data?.transactions))
  } catch (e) {
    yield put(getUserTransactionsFailure())
  }
}
