import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { countryFlag } from '../../constants/countryFlag'
import { languageCodeList } from '../../constants/languageList'
import './styles/index.scss'
import { setItem } from '../../utils/storageUtils'
import { setSelectedLanguagesStart } from '../../store/redux-slices/language'
import { useNavigate } from 'react-router-dom'

const LanguageSelect = ({ setLanguage }) => {
  const { selectedLanguageCode, languages } = useSelector(
    (state) => state.language
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const inputLanguage = window?.location?.pathname?.split('/')?.[1]
    if (
      selectedLanguageCode?.toLowerCase() !== inputLanguage?.toLowerCase() &&
      window?.location?.pathname !== '/'
    ) {
      setLanguage(inputLanguage?.toLowerCase())
      dispatch(
        setSelectedLanguagesStart({
          selectedLanguageCode: inputLanguage
        })
      )
    }
  }, [selectedLanguageCode])

  return (
    <div className='dropdown languages show'>
      <button
        type='button'
        className='dropdown-toggle'
        data-toggle='dropdown'
        aria-expanded='false'
        style={{
          fontSize: '14px',
          fontWeight: 'normal'
        }}
      >
        <span>
          {countryFlag?.filter(
            (ele) => ele?.languageCode === selectedLanguageCode
          )?.[0]?.flag && (
            <img
              width='25px'
              className='me-1'
              src={`${
                countryFlag?.filter(
                  (ele) => ele?.languageCode === selectedLanguageCode
                )?.[0]?.flag
              }`}
              alt='country-img'
            />
          )}
        </span>
        {selectedLanguageCode} |
      </button>

      <div className='dropdown-menu dropdown-menu-right'>
        {languages?.allowedLanguages?.map(
          (item, i) =>
            selectedLanguageCode !== item && (
              <a
                key={i}
                className='dropdown-item user'
                onClick={() => {
                  setLanguage(item.toLowerCase())
                  setItem('language', item.toUpperCase())
                  dispatch(
                    setSelectedLanguagesStart({
                      selectedLanguageCode: item,
                      navigate
                    })
                  )
                }}
              >
                {countryFlag?.filter((ele) => ele?.languageCode === item)?.[0]
                  ?.flag && (
                    <img
                      width='25px'
                      src={`${
                      countryFlag?.filter(
                        (ele) => ele?.languageCode === item
                      )?.[0]?.flag
                    }`}
                      className='me-2'
                      alt='country-img'
                    />
                )}
                {languageCodeList?.[item]?.toUpperCase()}
              </a>
            )
        )}
      </div>
    </div>
  )
}

const LangDropdown = ({
  selectedLanguageCode,
  languages,
  setLanguage,
  dispatch,
  navigate,
  isSidebar
}) => (
  <div className='language-name'>
    {!isSidebar && (
      <div className='name selected'>
        {countryFlag?.filter(
          (ele) => ele?.languageCode === selectedLanguageCode
        )?.[0]?.flag && (
          <img
            width='25px'
            src={`${
              countryFlag?.filter(
                (ele) => ele?.languageCode === selectedLanguageCode
              )?.[0]?.flag
            }`}
            alt='country-img'
          />
        )}
        {languageCodeList?.[selectedLanguageCode?.toUpperCase()]?.toUpperCase()}
      </div>
    )}
    {languages?.allowedLanguages?.map(
      (item, i) =>
        item !== selectedLanguageCode && (
          <div
            className={`name ${
              selectedLanguageCode === languageCodeList?.[item]
                ? 'selected'
                : ''
            }`}
            key={i}
            onClick={(e) => {
              setLanguage(item.toLowerCase())
              setItem('language', item.toUpperCase())
              dispatch(
                setSelectedLanguagesStart({
                  selectedLanguageCode: item,
                  navigate
                })
              )
            }}
          >
            {countryFlag?.filter((ele) => ele?.languageCode === item)?.[0]
              ?.flag && (
                <img
                  width='25px'
                  src={`${
                  countryFlag?.filter((ele) => ele?.languageCode === item)?.[0]
                    ?.flag
                }`}
                  alt='country-img'
                />
            )}
            {languageCodeList?.[item?.toUpperCase()]?.toUpperCase()}
          </div>
        )
    )}
  </div>
)

export const BottomLanguageDropdown = ({
  language,
  setLanguage,
  isFooter,
  isSidebar = false
}) => {
  const { languages, selectedLanguageCode } = useSelector(
    (state) => state.language
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <div
      className={`dropdown language-dropdown-bottom  ${isFooter && 'footer'} `}
    >
      <button
        className='language-btn text-left dropdown-toggle text-center name'
        id='dropdownLanguageButtonFooter'
        data-toggle='dropdown'
        aria-expanded='false'
      >
        {countryFlag?.filter(
          (ele) => ele?.languageCode === selectedLanguageCode
        )?.[0]?.flag && (
          <img
            width='25px'
            src={`${
              countryFlag?.filter(
                (ele) => ele?.languageCode === selectedLanguageCode
              )?.[0]?.flag
            }`}
            alt='country-img'
            className={`${isSidebar && 'sidebar-img'}`}
          />
        )}
        {languageCodeList?.[selectedLanguageCode?.toUpperCase()]?.toUpperCase()}
      </button>
      <div
        aria-labelledby='dropdownLanguageButtonFooter'
        className={`dropdown-menu  ${
          isSidebar ? 'sidebar-language' : 'language-selector-hide'
        }`}
        id='dropdownLanguageButtonFooter'
      >
        <LangDropdown
          selectedLanguageCode={selectedLanguageCode}
          languages={languages}
          setLanguage={setLanguage}
          dispatch={dispatch}
          language={language}
          navigate={navigate}
          isSidebar={isSidebar}
        />
      </div>
    </div>

  )
}

export default LanguageSelect
