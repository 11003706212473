import React from 'react'
import withModal from '../../wrapper/withModal' // Import the withModal HOC

// Define the CustomModal component
const CustomModal = ({ children }) => {
  return <>{children}</> // Render the children passed to the modal
}

// Export the CustomModal component wrapped with the withModal HOC as ModalComponent
export const ModalComponent = withModal(CustomModal)
