import { getDeviceType } from '../../../utils/getDeviceType'

export const deviceType = getDeviceType()

export const overlayOption = {
  trigger: 'focus',
  placement: 'left',
  overlayType: 'popover',
  overlayId: 'popover-contained',
  overlayClassName: 'dashboard-loyalty-popover'
}
