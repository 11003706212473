import React from 'react'
import useDashboard from './hooks/useDashboard'
import BalanceSection from '../../components/DashboardSections/BalanceSection'
import LoyaltySection from '../../components/DashboardSections/LoyaltySection'
import ActiveBonuses from '../../components/DashboardSections/ActiveBonuses'
import ClaimableBonuses from '../../components/DashboardSections/ClaimableBonuses'
import { dashboardContext } from '../../context'
import './styles/index.scss'
import { Col, Row } from 'react-bootstrap'

const Dashboard = (props) => {
  const dashboardProps = useDashboard(props)

  return (
    <div>
      <h2>{dashboardProps.languageData?.dashboard}</h2>
      <div className='dashboard-main-section'>
        <BalanceSection Context={dashboardContext} {...dashboardProps} />
        <LoyaltySection Context={dashboardContext} {...dashboardProps} />
        <Row>
          <Col className='mb-2' xs={12} sm={6}>
            <ActiveBonuses Context={dashboardContext} {...dashboardProps} />
          </Col>
          <Col xs={12} sm={6}>
            <ClaimableBonuses Context={dashboardContext} {...dashboardProps} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Dashboard
