import { getItem } from './storageUtils'
import { useLocation } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'
import CustomOverlayTrigger from '../components/CustomOverlayTrigger'
import { gameOverlayHover } from '../constants/overlayTrigger'

export const capitalizeMyText = (name, delemiter) => {
  return (name && name?.length > 0 && name.split(delemiter).map(ele =>
    ele.substr(0, 1).toUpperCase().concat(ele.substr(1))
  ).join(' ')) || name
}

export const countDown = (startMinutes = 1, msg, setValue, windowPopup) => {
  let time = startMinutes * 60 - 1 // Start From 59th second

  const t = setInterval(() => {
    const minutes = Math.floor(time / 60)
    const seconds = time % 60
    const result = `${msg} ${String(minutes)?.length === 1 ? `0${minutes}` : minutes}:${String(seconds)?.length === 1 ? `0${seconds}` : seconds}`
    setValue(result) // Update value using ref
    time--
    if (minutes === 0 && seconds === 0) {
      clearInterval(t)
      windowPopup && windowPopup?.close()
    }
  }, 1000)
}

export const ScrollToTop = () => {
  const { pathname } = useLocation()

  const actualPath = pathname.replaceAll(`/${getItem('language')?.toLowerCase()}/`, ' ')

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
  }, [actualPath])

  useEffect(() => {
    // if (process.env.DEBUGGING_ENABLED !== true || process.env.DEBUGGING_ENABLED !== 'true') {
    //   console.log = () => {}
    //   console.warn = () => {}
    //   console.error = () => {}
    // }

    // console.log = () => {}
    console.warn = () => {}
    console.error = () => {}
  }, [])

  return null
}

export const isNewGame = (date) => {
  if (date) {
    const currentDate = new Date()
    const gameUpdatedAt = new Date(date)
    const diff = parseInt((currentDate - gameUpdatedAt) / (1000 * 60 * 60 * 24), 10)
    if (diff <= 30 && diff >= 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const killAllFrames = (payment) => {
  payment && window._PaymentIQCashierReset()
  const iframes = document.querySelectorAll('iframe')
  for (let i = 0; i < iframes.length; i++) {
    if (iframes[i]?.id !== 'intercom-frame') iframes[i].parentNode.removeChild(iframes[i])
  }
}

export const getTimeLeft = (date, formatted) => {
  const newDate = new Date(date).getTime() - new Date().getTime()
  const dd = Math.floor(newDate / (1000 * 60 * 60 * 24))
  const hh = Math.floor((newDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const mm = Math.floor((newDate % (1000 * 60 * 60)) / (1000 * 60))
  const ss = Math.floor((newDate % (1000 * 60)) / 1000)
  return formatted
    ? `${dd < 10 ? '0' + dd : dd}d ${hh < 10 ? '0' + hh : hh}h:${mm < 10 ? '0' + mm : mm}m:${ss < 10 ? '0' + ss : ss}s`
    : { dd: dd < 10 ? '0' + dd : dd, hh: hh < 10 ? '0' + hh : hh, mm: mm < 10 ? '0' + mm : mm, ss: ss < 10 ? '0' + ss : ss }
}

export const isMobileOrTablet = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

  // Adjust these values based on your specific criteria
  const mobileMaxWidth = 767
  const tabletMaxWidth = 991

  if (screenWidth <= mobileMaxWidth) {
    // Mobile
    return true
  } else if (screenWidth <= tabletMaxWidth) {
    // Tablet
    return true
  } else {
    // Laptop or Desktop
    return false
  }
}

export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || window.opera
  return /android|iPad|iPhone|iPod/i.test(userAgent) || window.innerWidth <= 1024
}

export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
  const menuRef = useRef(null)
  const toggleRef = useRef(null)

  const showComponent = () => {
    setIsComponentVisible(!isComponentVisible)
  }

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && !toggleRef.current.contains(event.target)) {
      setIsComponentVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return { toggleRef, menuRef, isComponentVisible, setIsComponentVisible, showComponent }
}

//  Capitalizes the first letter of a given string.
function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// Truncate function for strings with overlay
function truncateTextWithOverlay (text, maxLength = 15) {
  if (text.length > maxLength) {
    return (
      <CustomOverlayTrigger overlayContent={text} {...gameOverlayHover}>
        <div className='loyalty-info-i'>{text.substring(0, maxLength)}...</div>
      </CustomOverlayTrigger>
    )
  }
  return text
}

// Truncates a string if its length exceeds maxLength and appends '...'.
function truncateText (text, maxLength = 12) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }
  return text + '...'
}

// Formats inputString as tags, handling JSON-encoded arrays and normal strings.
export function formatTags (inputString) {
  // Check if inputString is a string
  if (typeof inputString !== 'string') {
    // If not a string, return input as is
    return inputString === undefined ? '-' : inputString
  }

  if (inputString === '[]') return '-'

  // Attempt to parse the string as JSON
  try {
    const tagsArray = JSON.parse(inputString)

    // Check if parsed result is an array
    if (Array.isArray(tagsArray)) {
      // Capitalize the first letter of each tag
      const capitalizedTags = tagsArray.map(capitalizeFirstLetter)

      // If array length is more than one, return formatted content
      if (capitalizedTags.length > 1) {
        return (
          <CustomOverlayTrigger overlayContent={capitalizedTags.join(', ')} {...gameOverlayHover}>
            <div className='loyalty-info-i'>{truncateText(capitalizedTags[0])}</div>
          </CustomOverlayTrigger>
        )
      } else if (capitalizedTags.length === 1) {
        // If array length is one, return the single tag
        return truncateTextWithOverlay(capitalizedTags[0])
      }
    }
  } catch (error) {
    // If JSON parsing fails or input is not a valid JSON array string, capitalize the first letter of the input string
    return truncateTextWithOverlay(capitalizeFirstLetter(inputString))
  }

  // If inputString is not a valid JSON array or cannot be parsed, capitalize the first letter of the input string
  return truncateTextWithOverlay(capitalizeFirstLetter(inputString))
}

export function formatPriceWithCommas (price) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * @param {String}  languageCode - Selected language code
 * @param {String}  gameIdentifier - Unique game identifier used in game URL
 */

/**
 * @returns {String} - Game URL as per the device
 */

export const getGameUrl = (languageCode, gameIdentifier) => {
  return window.innerWidth <= 1024
    ? `/${languageCode}/play-mobile/${gameIdentifier}`
    : `/${languageCode}/play/${gameIdentifier}`
}
