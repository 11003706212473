import { combineReducers } from '@reduxjs/toolkit'
import { removeLoginToken } from '../../utils/storageUtils'

import loginReducer from './login'
import casinoMenuReducer from './casinoMenu'
import transactions from './transactions'
import bonusReducer from './bonus'
import limitsReducer from './limits'
import cmsReducer from './cms'
import languageReducer from './language'
import loaderReducer from './loader'
import userReducer from './user'
import paymentReducer from './payment'
import tournamentReducer from './tournament'
import configReducer from './config'

export const resetState = () => ({
  type: 'resetState'
})

const appReducer = combineReducers({
  login: loginReducer,
  user: userReducer,
  casinoMenu: casinoMenuReducer,
  bonus: bonusReducer,
  limits: limitsReducer,
  cms: cmsReducer,
  language: languageReducer,
  transactions,
  loader: loaderReducer,
  payment: paymentReducer,
  tournament: tournamentReducer,
  config: configReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'resetState') {
    removeLoginToken()
    return appReducer({ ...state, user: { ...state?.user, userDetails: null } }, action)
  }

  return appReducer(state, action)
}

export default rootReducer
