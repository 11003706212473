import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { InputGroup, Table, Form } from 'react-bootstrap'
import { getDateTime } from '../../../utils/dateFormatter'
import { depositLimit } from '../schema'
import { currencyCodeList } from '../../../constants/currencyCodeList'
import { useSelector } from 'react-redux'

const DepositLimit = ({
  userDetails,
  handleDepositLimit,
  showCheckbox,
  showInput,
  setShowCheckbox,
  setShowInput
}) => {
  const { languageData } = useSelector(state => state.language)
  const { licenseDetail } = useSelector((state) => state.language)

  return (
    <>
      <Formik
        initialValues={{
          dailyDepositLimit: userDetails?.userLimit?.dailyDepositLimit || '',
          weeklyDepositLimit: userDetails?.userLimit?.weeklyDepositLimit || '',
          monthlyDepositLimit: userDetails?.userLimit?.monthlyDepositLimit || '',
          dailyDepositReset: false,
          weeklyDepositReset: false,
          monthlyDepositReset: false
        }}
        validationSchema={depositLimit({ userLimit: userDetails?.userLimit, languageData, licenseDetail })}
        onSubmit={(formValues, { resetForm }) => {
          handleDepositLimit(formValues)
          resetForm()
        }}
      >
        {({ values, resetForm, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <Form>
            <div className='limits-block table-layouts'>
              <div className='heading'>
                <span style={{ whiteSpace: 'nowrap' }}>{languageData?.depositLimit}</span>
                <div className='btn-container '>
                  {!(userDetails?.userLimit?.dailyDepositLimit &&
                          userDetails?.userLimit?.weeklyDepositLimit &&
                          userDetails?.userLimit?.monthlyDepositLimit) &&
                          (values?.dailyDepositLimit === '' &&
                          values?.weeklyDepositLimit === '' &&
                          values?.monthlyDepositLimit === '') &&
                          !showInput?.depositInput
                    ? (
                      <button
                        type='button'
                        className='btn btn-success ml-2'
                        onClick={() => {
                          setShowInput(showInput => ({ ...showInput, depositInput: true }))
                          setShowCheckbox(showCheckbox => ({ ...showCheckbox, depositCheckbox: false }))
                        }}
                      >
                        <span>{languageData?.btnSetLimit}</span>
                      </button>)
                    : (
                      <button
                        type='button'
                        className='btn btn-success ml-2'
                        onClick={() => {
                          setShowInput(showInput => ({ ...showInput, depositInput: false }))
                          setShowCheckbox(showCheckbox => ({ ...showCheckbox, depositCheckbox: false }))
                          resetForm()
                        }}
                      >
                        <span>{languageData?.cancel}</span>
                      </button>)}

                  {(userDetails?.userLimit?.dailyDepositLimit !== null ||
                          userDetails?.userLimit?.weeklyDepositLimit !== null ||
                          userDetails?.userLimit?.monthlyDepositLimit !== null) &&
                          !showInput?.depositInput &&
                            <button
                              type='button'
                              className='btn btn-success ml-2'
                              onClick={() => {
                                setShowInput(showInput => ({ ...showInput, depositInput: true }))
                                setShowCheckbox(showCheckbox => ({ ...showCheckbox, depositCheckbox: false }))
                                resetForm()
                              }}
                            >
                              <span>{languageData?.editLimit}</span>
                            </button>}

                  {(userDetails?.userLimit?.dailyDepositLimit !== values?.dailyDepositLimit ||
                          userDetails?.userLimit?.weeklyDepositLimit !== values?.weeklyDepositLimit ||
                          userDetails?.userLimit?.monthlyDepositLimit !== values?.monthlyDepositLimit) &&
                          (values?.dailyDepositLimit !== '' ||
                          values?.weeklyDepositLimit !== '' ||
                          values?.monthlyDepositLimit !== '') &&
                          showInput?.depositInput &&
                            <button
                              type='button'
                              className='btn btn-success ml-2'
                              disabled={(!values?.dailyDepositLimit && !values?.weeklyDepositLimit && !values?.monthlyDepositLimit) ||
                                (values?.dailyDepositLimit === (userDetails?.userLimit?.dailyDepositLimit || '') &&
                                  values?.weeklyDepositLimit === (userDetails?.userLimit?.weeklyDepositLimit || '') &&
                                  values?.monthlyDepositLimit === (userDetails?.userLimit?.monthlyDepositLimit || ''))}
                              onClick={() => {
                                handleSubmit()
                              }}
                            >{languageData?.save}
                            </button>}

                  <button
                    type='button' className='btn btn-danger ml-2'
                    disabled={!(userDetails?.userLimit?.dailyDepositLimit ||
                          userDetails?.userLimit?.weeklyDepositLimit ||
                          userDetails?.userLimit?.monthlyDepositLimit) ||
                          (showCheckbox?.depositCheckbox && !(values?.dailyDepositReset ||
                          values?.weeklyDepositReset ||
                          values?.monthlyDepositReset))}
                    onClick={() => {
                      setShowCheckbox(showCheckbox => ({ ...showCheckbox, depositCheckbox: true }))
                      setShowInput(showInput => ({ ...showInput, depositInput: false }))
                      setFieldValue('dailyDepositLimit', userDetails?.userLimit?.dailyDepositLimit || '')
                      setFieldValue('weeklyDepositLimit', userDetails?.userLimit?.weeklyDepositLimit || '')
                      setFieldValue('monthlyDepositLimit', userDetails?.userLimit?.monthlyDepositLimit || '')
                      if (showCheckbox?.depositCheckbox) handleSubmit()
                    }}
                  ><span>{languageData?.removeLimit}</span>
                  </button>
                </div>
              </div>
              <Table responsive striped>
                <thead className='text-center'>
                  <tr>
                    {showCheckbox?.depositCheckbox && <th />}
                    <th>{languageData?.type}</th>
                    <th>{languageData?.usedLimit}</th>
                    <th>{languageData?.setLimit}</th>
                    <th>{languageData?.updatedAt}</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  <tr>
                    {showCheckbox?.depositCheckbox &&
                      <td className='table-error-message'>
                        <input
                          type='checkbox'
                          name='dailyDepositReset'
                          value={values?.dailyDepositReset}
                          checked={values?.dailyDepositReset}
                          disabled={new Date(userDetails?.userLimit?.dailyDepositExpiry) > new Date() || userDetails?.userLimit?.dailyDepositExpiry === null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {userDetails?.userLimit?.dailyDepositExpiry && new Date(userDetails?.userLimit?.dailyDepositExpiry) > new Date() &&
                          <small className='d-block text-danger'>{languageData?.cannotBeRemoved} {getDateTime(userDetails?.userLimit?.dailyDepositExpiry)}</small>}
                      </td>}
                    <td>{languageData?.daily}</td>
                    <td className='white-color'>{userDetails?.userLimit?.dailyDepositUsedLimit}
                    </td>
                    <td className='white-color'>{showInput?.depositInput
                      ? (
                        <div>
                          <InputGroup className='input-container ml-0'>
                            <InputGroup.Text>{currencyCodeList[userDetails?.currencyCode || 'EUR']}</InputGroup.Text>
                            <Form.Control
                              name='dailyDepositLimit'
                              placeholder={languageData?.enterAmount}
                              value={values?.dailyDepositLimit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                          <ErrorMessage
                            component='div'
                            name='dailyDepositLimit'
                            className='text-danger small'
                          />
                        </div>
                        )
                      : <span>{userDetails?.userLimit?.dailyDepositLimit ? userDetails?.userLimit?.dailyDepositLimit : <span>{languageData?.dailyDepositLimitNotSet}</span>}</span>}

                    </td>
                    <td className='white-color'>{userDetails?.userLimit?.dailyDepositLimit !== null ? getDateTime(userDetails?.userLimit?.dailyDepositUpdatedAt) : <span>-</span>}</td>
                  </tr>
                  <tr>
                    {showCheckbox?.depositCheckbox &&
                      <td className='table-error-message'>
                        <input
                          type='checkbox'
                          name='weeklyDepositReset'
                          value={values?.weeklyDepositReset}
                          checked={values?.weeklyDepositReset}
                          disabled={new Date(userDetails?.userLimit?.weeklyDepositExpiry) > new Date() || userDetails?.userLimit?.weeklyDepositExpiry === null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {userDetails?.userLimit?.weeklyDepositExpiry && new Date(userDetails?.userLimit?.weeklyDepositExpiry) > new Date() &&
                          <small className='d-block text-danger'>{languageData?.cannotBeRemoved} {getDateTime(userDetails?.userLimit?.weeklyDepositExpiry)}</small>}
                      </td>}
                    <td>{languageData?.weekly}</td>
                    <td className='white-color'>{userDetails?.userLimit?.weeklyDepositUsedLimit}
                    </td>
                    <td className='white-color'>{showInput?.depositInput
                      ? (
                        <div>
                          <InputGroup className='input-container ml-0'>
                            <InputGroup.Text>{currencyCodeList[userDetails?.currencyCode || 'EUR']}</InputGroup.Text>
                            <Form.Control
                              name='weeklyDepositLimit'
                              placeholder={languageData?.enterAmount}
                              value={values?.weeklyDepositLimit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                          <ErrorMessage
                            component='div'
                            name='weeklyDepositLimit'
                            className='text-danger small'
                          />
                        </div>
                        )
                      : <span>{userDetails?.userLimit?.weeklyDepositLimit ? userDetails?.userLimit?.weeklyDepositLimit : <span>{languageData?.weeklyDepositLimitNotSet}</span>} </span>}
                    </td>
                    <td className='white-color'>{userDetails?.userLimit?.weeklyDepositLimit !== null ? getDateTime(userDetails?.userLimit?.weeklyDepositUpdatedAt) : <span>-</span>}</td>
                  </tr>
                  <tr>
                    {showCheckbox?.depositCheckbox &&
                      <td className='table-error-message'>
                        <input
                          type='checkbox'
                          name='monthlyDepositReset'
                          value={values?.monthlyDepositReset}
                          checked={values?.monthlyDepositReset}
                          disabled={new Date(userDetails?.userLimit?.monthlyDepositExpiry) > new Date() || userDetails?.userLimit?.monthlyDepositExpiry === null}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {userDetails?.userLimit?.monthlyDepositExpiry && new Date(userDetails?.userLimit?.monthlyDepositExpiry) > new Date() &&
                          <small className='d-block text-danger'>{languageData?.cannotBeRemoved} {getDateTime(userDetails?.userLimit?.monthlyDepositExpiry)}</small>}
                      </td>}
                    <td>{languageData?.monthly}</td>
                    <td className='white-color'>{userDetails?.userLimit?.monthlyDepositUsedLimit}
                    </td>
                    <td className='white-color'>{showInput?.depositInput
                      ? (
                        <div>
                          <InputGroup className='input-container ml-0'>
                            <InputGroup.Text>{currencyCodeList[userDetails?.currencyCode || 'EUR']}</InputGroup.Text>
                            <Form.Control
                              name='monthlyDepositLimit'
                              placeholder={languageData?.enterAmount}
                              value={values?.monthlyDepositLimit}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </InputGroup>
                          <ErrorMessage
                            component='div'
                            name='monthlyDepositLimit'
                            className='text-danger small'
                          />
                        </div>
                        )
                      : <span>{userDetails?.userLimit?.monthlyDepositLimit ? userDetails?.userLimit?.monthlyDepositLimit : <span>{languageData?.monthlyDepositLimitNotSet}</span>}</span>}
                    </td>
                    <td className='white-color'>{userDetails?.userLimit?.monthlyDepositLimit !== null ? getDateTime(userDetails?.userLimit?.monthlyDepositUpdatedAt) : <span>-</span>}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default DepositLimit
