import React from 'react'
import useUserDetailsInfo from '../hooks/useUserDetailsInfo'

const UserDetailContext = React.createContext()

const UserDetailContextProvider = ({ children }) => {
  const userSignUpData = useUserDetailsInfo()
  return (
    <UserDetailContext.Provider value={userSignUpData}>
      {children}
    </UserDetailContext.Provider>
  )
}

export { UserDetailContextProvider, UserDetailContext }
