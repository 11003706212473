import * as Yup from 'yup'
import { getDateTime } from '../../utils/dateFormatter'

export const wagerLimit = ({ userLimit, languageData }) => Yup.object().shape({
  dailyBetLimit: Yup.string()
    .matches(/^[0-9]*$/, languageData?.onlyNumbers)
    .test('Greater than 0', languageData?.amountGTZero, e => {
      if (e && parseInt(e) <= 0) {
        return false
      } else {
        return true
      }
    })
    .test('Daily Bet Limit', languageData?.amountEqualOrLess, (value, context) => {
      if (context.parent.weeklyBetLimit &&
        context.parent.weeklyBetLimit !== (userLimit?.weeklyBetLimit || '') &&
        parseInt(value) > parseInt(context.parent.weeklyBetLimit)) return false

      if (context.parent.monthlyBetLimit &&
        context.parent.monthlyBetLimit !== (userLimit?.monthlyBetLimit || '') &&
        parseInt(value) > parseInt(context.parent.monthlyBetLimit)) return false
      return true
    })
    .test('expiry', `${languageData?.cannotBeIncreased} ${getDateTime(userLimit?.dailyBetExpiry)}`, (value) => {
      if (userLimit?.dailyBetExpiry && value && userLimit?.dailyBetLimit) {
        if (parseInt(value) > parseInt(userLimit?.dailyBetLimit) && new Date(userLimit?.dailyBetExpiry) > new Date()) {
          return false
        }
      }
      return true
    }),
  weeklyBetLimit: Yup.string()
    .matches(/^[0-9]*$/, languageData?.onlyNumbers)
    .test('Greater than 0', languageData?.amountGTZero, e => {
      if (e && parseInt(e) <= 0) {
        return false
      } else {
        return true
      }
    })
    .test('Weekly Bet Limit', languageData?.amountInBetween, (value, context) => {
      if (context.parent.dailyBetLimit &&
        context.parent.dailyBetLimit !== (userLimit?.dailyBetLimit || '') &&
        parseInt(context.parent.dailyBetLimit) > parseInt(value)) return false

      if (context.parent.monthlyBetLimit &&
        context.parent.monthlyBetLimit !== (userLimit?.monthlyBetLimit || '') &&
        parseInt(value) > parseInt(context.parent.monthlyBetLimit)) return false
      return true
    }),
  monthlyBetLimit: Yup.string()
    .matches(/^[0-9]*$/, languageData?.onlyNumbers)
    .test('Greater than 0', languageData?.amountGTZero, e => {
      if (e && parseInt(e) <= 0) {
        return false
      } else {
        return true
      }
    })
    .test('Monthly Bet Limit', languageData?.amountEqualOrGreater, (value, context) => {
      if (context.parent.dailyBetLimit &&
        context.parent.dailyBetLimit !== (userLimit?.dailyBetLimit || '') &&
        parseInt(value) < parseInt(context.parent.dailyBetLimit)) return false

      if (context.parent.weeklyBetLimit &&
        context.parent.weeklyBetLimit !== (userLimit?.weeklyBetLimit || '') &&
        parseInt(value) < parseInt(context.parent.weeklyBetLimit)) return false
      return true
    })
})

export const lossLimit = ({ userLimit, languageData }) =>
  Yup.object().shape({
    dailyLossLimit: Yup.string()
      .matches(/^[0-9]*$/, languageData?.onlyNumbers)
      .test('Greater than 0', languageData?.amountGTZero, e => {
        if (e && parseInt(e) <= 0) {
          return false
        } else {
          return true
        }
      })
      .test('Daily Loss Limit', languageData?.amountEqualOrLess, (value, context) => {
        if (context.parent.weeklyLossLimit &&
          context.parent.weeklyLossLimit !== (userLimit?.weeklyLossLimit || '') &&
          parseInt(value) > parseInt(context.parent.weeklyLossLimit)) return false

        if (context.parent.monthlyLossLimit &&
          context.parent.monthlyLossLimit !== (userLimit?.monthlyLossLimit || '') &&
          parseInt(value) > parseInt(context.parent.monthlyLossLimit)) return false
        return true
      })
      .test('expiry', `${languageData?.cannotBeIncreased} ${getDateTime(userLimit?.dailyLossExpiry)}`, (value) => {
        if (userLimit?.dailyLossExpiry && value && userLimit?.dailyLossLimit) {
          if (parseInt(value) > parseInt(userLimit?.dailyLossLimit) && new Date(userLimit?.dailyLossExpiry) > new Date()) {
            return false
          }
        }
        return true
      }),
    weeklyLossLimit: Yup.string()
      .matches(/^[0-9]*$/, languageData?.onlyNumbers)
      .test('Greater than 0', languageData?.amountGTZero, e => {
        if (e && parseInt(e) <= 0) {
          return false
        } else {
          return true
        }
      })
      .test('Weekly Loss Limit', languageData?.amountInBetween, (value, context) => {
        if (context.parent.dailyLossLimit &&
          context.parent.dailyLossLimit !== (userLimit?.dailyLossLimit || '') &&
          parseInt(context.parent.dailyLossLimit) > parseInt(value)) return false

        if (context.parent.monthlyLossLimit &&
          context.parent.monthlyLossLimit !== (userLimit?.monthlyLossLimit || '') &&
          parseInt(value) > parseInt(context.parent.monthlyLossLimit)) return false
        return true
      }),
    monthlyLossLimit: Yup.string()
      .matches(/^[0-9]*$/, languageData?.onlyNumbers)
      .test('Greater than 0', languageData?.amountGTZero, e => {
        if (e && parseInt(e) <= 0) {
          return false
        } else {
          return true
        }
      })
      .test('Monthly Loss Limit', languageData?.amountEqualOrGreater, (value, context) => {
        if (context.parent.dailyLossLimit &&
          context.parent.dailyLossLimit !== (userLimit?.dailyLossLimit || '') &&
          parseInt(value) < parseInt(context.parent.dailyLossLimit)) return false

        if (context.parent.weeklyLossLimit &&
          context.parent.weeklyLossLimit !== (userLimit?.weeklyLossLimit || '') &&
          parseInt(value) < parseInt(context.parent.weeklyLossLimit)) return false
        return true
      })
  })

export const depositLimit = ({ userLimit, languageData, licenseDetail }) =>
  Yup.object().shape({
    dailyDepositLimit: Yup.string()
      .matches(/^[0-9]*$/, languageData?.onlyNumbers)
      .test('Greater than 0', languageData?.amountGTZero, e => {
        if ((e && parseInt(e) <= 0 && licenseDetail?.License?.depositLimitExclusion === false)) {
          return false
        } else {
          return true
        }
      })
      .test('Daily Deposit Limit', languageData?.amountEqualOrLess, (value, context) => {
        if (context.parent.weeklyDepositLimit &&
            context.parent.weeklyDepositLimit !== (userLimit?.weeklyDepositLimit || '') &&
            parseInt(value) > parseInt(context.parent.weeklyDepositLimit)) return false

        if (context.parent.monthlyDepositLimit &&
            context.parent.monthlyDepositLimit !== (userLimit?.monthlyDepositLimit || '') &&
            parseInt(value) > parseInt(context.parent.monthlyDepositLimit)) return false
        return true
      })
      .test('expiry', `${languageData?.cannotBeIncreased} ${getDateTime(userLimit?.dailyDepositExpiry)}`, (value) => {
        if (userLimit?.dailyDepositExpiry && value && userLimit?.dailyDepositLimit) {
          if (parseInt(value) > parseInt(userLimit?.dailyDepositLimit) && new Date(userLimit?.dailyDepositExpiry) > new Date()) {
            return false
          }
        }
        return true
      }),
    weeklyDepositLimit: Yup.string()
      .matches(/^[0-9]*$/, languageData?.onlyNumbers)
      .test('Greater than 0', languageData?.amountGTZero, e => {
        if (e && parseInt(e) <= 0 && licenseDetail?.License?.depositLimitExclusion === false) {
          return false
        } else {
          return true
        }
      })
      .test('Weekly Deposit Limit', languageData?.amountInBetween, (value, context) => {
        if (context.parent.dailyDepositLimit &&
            context.parent.dailyDepositLimit !== (userLimit?.dailyDepositLimit || '') &&
            parseInt(context.parent.dailyDepositLimit) > parseInt(value)) return false

        if (context.parent.monthlyDepositLimit &&
            context.parent.monthlyDepositLimit !== (userLimit?.monthlyDepositLimit || '') &&
            parseInt(value) > parseInt(context.parent.monthlyDepositLimit)) return false
        return true
      }),
    monthlyDepositLimit: Yup.string()
      .matches(/^[0-9]*$/, languageData?.onlyNumbers)
      .test('Greater than 0', languageData?.amountGTZero, e => {
        if (e && parseInt(e) <= 0 && licenseDetail?.License?.depositLimitExclusion === false) {
          return false
        } else {
          return true
        }
      })
      .test('Monthly Deposit Limit', languageData?.amountEqualOrGreater, (value, context) => {
        if (context.parent.dailyDepositLimit &&
            context.parent.dailyDepositLimit !== (userLimit?.dailyDepositLimit || '') &&
            parseInt(value) < parseInt(context.parent.dailyDepositLimit)) return false

        if (context.parent.weeklyDepositLimit &&
            context.parent.weeklyDepositLimit !== (userLimit?.weeklyDepositLimit || '') &&
            parseInt(value) < parseInt(context.parent.weeklyDepositLimit)) return false
        return true
      })
  })

export const takeABreak = (languageData) =>
  Yup.object().shape({
    takeABreak: Yup.string()
      .matches(/^[0-9]*$/, languageData?.onlyNumbers)
      .required(languageData?.timePeriodRequired)
  })

export const sessionLimit = (languageData) =>
  Yup.object().shape({
    timePeriod: Yup.string().required(languageData?.timePeriodRequired)
  })

export const selfExclusion = (languageData) =>
  Yup.object().shape({
    timePeriod: Yup.string()
      .required(languageData?.timePeriodRequired)
  })

export const gamePlayLimit = (languageData) =>
  Yup.object().shape({
    timePeriod: Yup.string()
      .matches(/^[0-9]*$/, languageData?.onlyNumbers)
      .required(languageData?.timePeriodRequired)
  })
