import React from 'react'
import { capitalizeMyText } from '../../utils/helpers'
import { getItem } from '../../utils/storageUtils'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

const GameCategories = ({
  selectedFilter,
  setSelectedFilter,
  setSelectedMenu,
  selectedMenu,
  gameSubCategory,
  selectedLanguageCode,
  navigate,
  casinoCategory,
  navTabSubCategory
}) => {
  const { brandLogo, languageData } = useSelector(state => state.language)
  return (
    <>
      <Swiper
        slidesPerView='auto'
        slidesPerGroup={1}
        spaceBetween={20}
        className='mySwiper '
        direction='horizontal'
        loop={false}
      >
        <SwiperSlide>
          <a
            className={selectedFilter?.category?.length ? 'game-type' : 'game-type active'}
            onClick={() => {
              setSelectedFilter(selectedFilter => (
                {
                  ...selectedFilter,
                  category: [],
                  themes: [],
                  provider: [],
                  rating: []
                }))
              setSelectedMenu(0)
              navigate(`/${getItem('language')?.toLowerCase()}/${casinoCategory}/all`)
            }}
          >
            <i className='fa-solid fa-grid-2' />
            <span className='text'>
              {capitalizeMyText(languageData?.all, '_')}
            </span>
          </a>
        </SwiperSlide>
        {gameSubCategory && gameSubCategory?.rows?.map((subCategory, index) => {
          return (
            <SwiperSlide key={index}>
              <a
                className={selectedFilter?.category?.includes(subCategory?.name?.[selectedLanguageCode] || subCategory?.name?.EN) || subCategory?.tenantGameSubCategoryId === selectedMenu ? 'game-type active' : 'game-type'}
                key={index}
                onClick={() => {
                  setSelectedFilter(selectedFilter => ({ ...selectedFilter, category: [subCategory?.tenantGameSubCategoryId] }))
                  setSelectedMenu(subCategory?.tenantGameSubCategoryId)
                  window?.scrollTo(0, (navTabSubCategory?.current?.parentElement?.offsetTop - 200))
                }}
              >
                {subCategory?.imageUrl
                  ? (
                    <img
                      src={subCategory?.imageUrl || brandLogo || '/images/brand-logo.svg'}
                      onError={(e) => {
                        e.target.onerror = null
                        e.target.src = brandLogo || '/images/brand-logo.svg'
                      }}
                      alt='subCategoryImage'
                    />
                    )
                  : (
                    <i className='fa-solid fa-dice' />
                    )}

                <span className='text'>
                  {capitalizeMyText((subCategory?.name?.[selectedLanguageCode] || subCategory?.name?.EN), '_')}
                </span>
              </a>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

export default GameCategories
