import { takeLatest, put, select, delay } from 'redux-saga/effects'
import {
  getGameCategory,
  getGameSubCategory,
  getCasinoGames,
  getProviders,
  addFavoriteGame,
  removeFavoriteGame,
  getFavoriteGame,
  getAllGames,
  getTopGames,
  getTopWinners,
  getCurrentWinners,
  getCasinoProvider,
  getRecentGames
} from '../../utils/apiCalls'
import {
  getGameSubCategoryStart,
  getGameSubCategorySuccess,
  getGameSubCategoryFailure,
  getGameStart,
  getGameSearch,
  getGameSuccess,
  getGameFailure,
  getFavGameStart,
  getFavGameSuccess,
  getFavGameFailure,
  getGameCategoryStart,
  getGameCategorySuccess,
  getGameCategoryFailure,
  getHomeDataStart,
  getHomeDataSuccess,
  getHomeDataFailure,
  getProvidersStart,
  getProvidersSuccess,
  getProvidersFailure,
  toggleFavoriteStart,
  toggleFavoriteSuccess,
  toggleFavoriteFailure,
  getFilteredGamesStart,
  getFilteredGamesSuccess,
  getFilteredGamesFailure,
  removeFavoriteStart,
  removeFavoriteSuccess,
  removeFavoriteFailure,
  getTopGamesStart,
  getTopGamesSuccess,
  getTopGamesFailure,
  getTopWinnersStart,
  getTopWinnersSuccess,
  getTopWinnersFailure,
  getCurrentWinnersStart,
  getCurrentWinnersSuccess,
  getCurrentWinnersFailure,
  updateGameSubCategorySuccess,
  updateRowsFetched,
  getCategoryProviderListStart,
  getCategoryProviderListSuccess,
  getCategoryProviderListFailure,
  getRecentGamesStart,
  getRecentGamesComplete,
  getProvidersByOrderStart,
  getProvidersByOrderFailure,
  getProvidersByOrderSuccess,
  resetGameSearch
} from '../redux-slices/casinoMenu'
import { getLanguageDataStart } from '../redux-slices/language'
import { showFooter, showLeagueList, showRealPlayer } from '../redux-slices/loader'
import { setIsUserBlock } from '../redux-slices/user'
import { cmsReorder } from '../../utils/constants'
const getGames = (state) => state.casinoMenu.games
const getSubCategory = (state) => state.casinoMenu.gameSubCategory
const getFavGames = (state) => state.casinoMenu.favoriteGamesList
const filteredGames = (state) => state.casinoMenu?.filteredGames
const gameSearch = (state) => state.casinoMenu?.gameSearch
const gameCount = (state) => state.casinoMenu?.rowsFetchedCount

export default function * casinoMenuWatcher () {
  yield takeLatest(getGameCategoryStart.type, getGameCategoryWorker)
  yield takeLatest(getGameSubCategoryStart.type, getGameSubCategoryWorker)
  yield takeLatest(getGameStart.type, getGameWorker)
  yield takeLatest(getFavGameStart.type, getFavGameWorker)
  yield takeLatest(getHomeDataStart.type, getHomeDataWorker)
  yield takeLatest(getProvidersStart.type, getProvidersWorker)
  yield takeLatest(getProvidersByOrderStart.type, getProvidersOrderByWorker)
  yield takeLatest(toggleFavoriteStart.type, toggleFavoriteWorker)
  yield takeLatest(removeFavoriteStart.type, removeFavoriteWorker)
  yield takeLatest(getFilteredGamesStart.type, getFilteredGamesWorker)
  yield takeLatest(getTopGamesStart.type, getTopGamesWorker)
  yield takeLatest(getTopWinnersStart.type, getTopWinnersWorker)
  yield takeLatest(getCurrentWinnersStart.type, getCurrentWinnersWorker)
  yield takeLatest(getCategoryProviderListStart.type, getCasinoProviderWorker)
  yield takeLatest(getRecentGamesStart.type, getRecentGamesWorker)
}

function * getGameCategoryWorker () {
  try {
    const { data } = yield getGameCategory()

    yield put(getGameCategorySuccess(data?.data?.casinoCategories?.rows))
  } catch (e) {
    yield put(getGameCategoryFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getGameSubCategoryWorker (action) {
  try {
    const { categoryId } = action && action.payload
    const { data } = yield getGameSubCategory({ categoryId })

    yield put(getGameSubCategorySuccess(data?.data?.response))
    yield put(showLeagueList())
    yield put(showRealPlayer())
  } catch (e) {
    yield put(getGameSubCategoryFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getGameWorker (action) {
  try {
    const game = yield select(getGames)
    const { subCategoryId, page, limit, search, provider, fetchMore = true } = action && action.payload
    yield delay(10)
    const { data } = yield getCasinoGames({
      subCategoryId, page, limit, search, provider
    })
    if (game?.tenantGameSubCategoryId && game?.tenantGameSubCategoryId === data?.data?.categoryGames.tenantGameSubCategoryId) {
      const newData = {
        count: data?.data?.categoryGames.count,
        tenantGameSubCategoryId: data?.data?.categoryGames.tenantGameSubCategoryId,
        rows: fetchMore ? [...game.rows, ...data?.data?.categoryGames.rows] : [...data?.data?.categoryGames.rows]
      }
      yield put(getGameSuccess(newData))
    } else {
      yield put(getGameSuccess(data?.data?.categoryGames))
    }
  } catch (e) {
    yield put(getGameFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getFavGameWorker (action) {
  try {
    const game = yield select(getFavGames)
    const { page, limit } = action && action.payload
    const { data } = yield getFavoriteGame({ limit, page })
    if (page === 1) {
      yield put(getFavGameSuccess(data?.data?.favoriteGames))
    } else {
      const newData = {
        count: data?.data?.favoriteGames.count,
        rows: [...game?.rows || [], ...data?.data?.favoriteGames.rows]
      }
      yield put(getFavGameSuccess(newData))
    }
  } catch (e) {
    yield put(getFavGameFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getHomeDataWorker (action) {
  try {
    const { getLang = true, navigate } = action && action.payload
    if (getLang) yield put(getLanguageDataStart({ navigate }))

    const { data: gameCategory } = yield getGameCategory()
    let orderedCMS = []
    if (gameCategory?.data?.cmsList?.rows?.length) {
      orderedCMS = gameCategory?.data?.cmsList?.rows?.sort((a, b) => {
      // Extract names from objects
        const nameA = a.slug
        const nameB = b.slug

        // Get values from mapping object, or use defaultValue if name is not found
        const valueA = cmsReorder[nameA] !== undefined ? cmsReorder[nameA] : Infinity
        const valueB = cmsReorder[nameB] !== undefined ? cmsReorder[nameB] : Infinity

        // Compare values
        return valueA - valueB
      })
    }
    yield put(getHomeDataSuccess({
      gameCategory: {
        cmsList:
        {
          ...gameCategory?.data?.cmsList,
          rows: orderedCMS
        },
        ...gameCategory?.data
      }
    }))

    if (gameCategory?.data?.countryBlocked) {
      yield put(setIsUserBlock(true))
    }
  } catch (e) {
    // const { navigate } = action?.payload
    // console.log('e?.response')
    // if (e?.response?.data?.errors[0]?.errorCode === 3000) {
    //   navigate && navigate(`/${getItem('language').toLowerCase() || 'en'}/600`)
    // }

    yield put(getHomeDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getProvidersWorker () {
  try {
    const { data } = yield getProviders()
    yield put(getProvidersSuccess(data?.data?.providers))
  } catch (e) {
    yield put(getProvidersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getProvidersOrderByWorker (action) {
  try {
    const { data } = yield getProviders(action?.payload?.orderBy)
    yield put(getProvidersByOrderSuccess(data?.data?.providers))
  } catch (e) {
    yield put(getProvidersByOrderFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * toggleFavoriteWorker (action) {
  try {
    const { isFavorite, categoryGameId, tenantGameSubCategoryId, searchGamesFilter = false, play = false, setGameData, gameData } = action && action.payload
    let updatedGames = yield select(getGames)
    let updatedGameRows = updatedGames?.rows

    const gameSubCategory = yield select(getSubCategory)

    if (isFavorite) {
      yield removeFavoriteGame({ categoryGameId })
    } else {
      yield addFavoriteGame({ categoryGameId })
    }

    if (!play) {
      if (tenantGameSubCategoryId) {
        const games = gameSubCategory?.rows?.map((item) => item.tenantGameSubCategoryId === tenantGameSubCategoryId
          ? { ...item, CategoryGames: item?.CategoryGames?.map((game) => game.categoryGameId === categoryGameId ? { ...game, isFavorite: !isFavorite } : game) }
          : item)

        const updatedSubCat = { count: gameSubCategory?.count, rows: games }

        yield put(updateGameSubCategorySuccess(updatedSubCat))
      } else if (searchGamesFilter) {
        const myGames = yield select(filteredGames)

        const updatedFilteredGames = myGames?.rows?.map((game) => game.categoryGameId === categoryGameId ? { ...game, isFavorite: !isFavorite } : game)

        yield put(getFilteredGamesSuccess({ count: myGames?.count, rows: updatedFilteredGames }))
      } else {
        updatedGameRows = yield updatedGameRows?.map(obj =>
          obj.categoryGameId === categoryGameId ? { ...obj, isFavorite: !isFavorite } : obj
        )

        updatedGames = yield { ...updatedGames, rows: updatedGameRows }
        yield put(toggleFavoriteSuccess(updatedGames))
      }

      yield put(getFavGameStart({ page: 1, limit: 20 }))
    } else {
      setGameData({ ...gameData, isFavorite: !isFavorite })
    }
  } catch (e) {
    yield put(toggleFavoriteFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * removeFavoriteWorker (action) {
  try {
    const { isFavorite, categoryGameId } = action && action.payload
    let updatedGames = yield select(getFavGames)
    let updatedGameRows = updatedGames?.rows

    if (isFavorite) {
      yield removeFavoriteGame({ categoryGameId })
      updatedGameRows = yield updatedGameRows.filter((game) => game.categoryGameId !== categoryGameId)
      updatedGames = yield { ...updatedGames, rows: updatedGameRows }
    }
    yield put(removeFavoriteSuccess(updatedGames))
  } catch (e) {
    yield put(removeFavoriteFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getFilteredGamesWorker (action) {
  try {
    const {
      limit = '',
      pageNo = '',
      category = '[]',
      search = '',
      rating = '[]',
      themes = '[]',
      provider = '[]',
      fetchMore = false,
      gameInputSearch = false
    } = action && action.payload

    if (gameInputSearch && pageNo === 1) {
      yield put(resetGameSearch())
    }

    const res = yield getAllGames({
      limit,
      pageNo,
      category,
      search,
      rating,
      themes,
      provider
    })

    const gameList = res?.data?.data?.categoryGames?.rows
    const totalCount = res?.data?.data?.categoryGames?.count

    const uniqueGames = { rows: [], count: 0 }

    if (gameList.length > 0) {
      const games = [...new Map(gameList.map((g) => [g.identifier, g])).values()]
      uniqueGames.rows = games
      uniqueGames.count = res?.data?.data?.categoryGames?.count
    }

    const allFilteredGames = yield select(filteredGames)
    const allGameSearch = yield select(gameSearch)
    const prevGamesCount = yield select(gameCount)

    if (fetchMore && !gameInputSearch) {
      yield put(updateRowsFetched(gameList?.length + prevGamesCount))
      yield put(getFilteredGamesSuccess({
        count: allFilteredGames?.count,
        rows: [...new Map([...allFilteredGames?.rows, ...gameList].map((g) => [g.identifier, g])).values()]
      }))
    } else if (gameInputSearch) {
      /**
       * A filtered array of unique game objects derived from the categoryGames data.
       *
       * The `rows` variable is constructed by taking rows of data from the categoryGames property in the API response,
       * removing duplicate entries based on their `identifier` property, and preserving the first occurrence of each unique identifier.
       *
       * @type {Array<Object>} An array of unique game objects with each object representing data from categoryGames rows.
       */
      const rows = [...new Map([...res?.data?.data?.categoryGames?.rows].map((g) => [g.identifier, g])).values()]
      /**
       * The total count of combined items.
       *
       * This variable calculates the sum of the length of rows
       * and the `count` property of the `allGameSearch` object if it exists.
       * If `allGameSearch` or its `count` property is not defined,
       * it defaults to 0.
       */
      const count = rows.length + (allGameSearch?.count || 0)
      if (fetchMore) {
        yield put(getGameSearch({ count, totalCount, rows: [...allGameSearch?.rows, ...rows] }))
      } else {
        yield put(getGameSearch({ count, totalCount, rows }))
      }
    } else {
      yield put(updateRowsFetched(gameList.length))
      yield put(getFilteredGamesSuccess(uniqueGames))
    }
  } catch (e) {
    yield put(getFilteredGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTopGamesWorker (action) {
  try {
    const { limit } = action && action.payload

    const { data: res } = yield getTopGames({ limit })

    yield put(getTopGamesSuccess(res?.data?.gameReport))

    yield put(getTopWinnersStart({ limit: 3 }))

    yield put(getCurrentWinnersStart({ limit: 20 }))

    yield put(showFooter(true))
  } catch (e) {
    yield put(getTopGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTopWinnersWorker (action) {
  try {
    const { limit } = action && action.payload

    const { data: res } = yield getTopWinners({ limit })

    yield put(getTopWinnersSuccess(res?.data?.topWinners))
  } catch (e) {
    yield put(getTopWinnersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCurrentWinnersWorker (action) {
  try {
    const { limit } = action && action.payload

    const { data: res } = yield getCurrentWinners({ limit })

    yield put(getCurrentWinnersSuccess(res?.data?.currentWinners))
  } catch (e) {
    yield put(getCurrentWinnersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCasinoProviderWorker (action) {
  try {
    const { category, search } = action && action.payload

    const { data: res } = yield getCasinoProvider({ category, search })

    yield put(getCategoryProviderListSuccess(res?.data?.providerList))
  } catch (e) {
    yield put(getCategoryProviderListFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getRecentGamesWorker (action) {
  try {
    const { limit } = action && action.payload

    const { data } = yield getRecentGames({ limit })

    yield put(getRecentGamesComplete(data?.data?.gamesPlayed))
  } catch (e) {
    yield put(getRecentGamesComplete())
  }
}
