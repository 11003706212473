import './styles/index.scss'

/**
 * The purpose of this component is just to apply CSS classes to its children.
 * It is created as part of CSS refactoring task.
 * The CategorisedGamesGrid.scss file is used in multiple pages and its was not possible to move it
 * inside the styles directory of a specific component.
 *
 * So, we have create this component to use it wherever that file is imported
 *
 * TODO: We need to remove this as part of future refactorings
 *
 * @param {{ children: ReactDOM }} props
 */
const GameSliderContainer = ({ children }) => {
  return (
    <>
      {children}
    </>
  )
}

export default GameSliderContainer
