import * as Yup from 'yup'

// Schema for EN with Extra Validations
export const userSigninSchemaEN = (regFields, languageData) =>
  regFields &&
  Yup.object().shape({
    email: regFields.email &&
      Yup.string().email(languageData?.invalidEmail).max(50, languageData?.max50Characters).required(languageData?.emailRequired),

    password: regFields.password &&
      Yup.string().min(6, languageData?.min6Chars).max(50, languageData?.max50Characters).required(languageData?.passwordRequired),

    confirmPassword: regFields.confirmPassword &&
      Yup.string()
        .max(50, languageData?.max50Characters)
        .oneOf([Yup.ref('password'), null], languageData?.matchPassword)
        .required(languageData?.confirmPasswordRequired),

    firstName: regFields.firstName &&
      Yup.string()
        .min(3, languageData?.fName3Chars)
        .matches(/^([^0-9]*)$/, languageData?.notNumbers)
        .max(50, languageData?.max50Characters)
        .required(languageData?.firstNameRequired),

    lastName: regFields.lastName === 2
      ? Yup.string()
        .matches(/^([^0-9]*)$/, languageData?.notNumbers)
        .min(3, languageData?.lName3Chars).max(50, languageData?.max50Characters).required(languageData?.lastNameRequired)
      : Yup.string()
        .matches(/^([^0-9]*)$/, languageData?.notNumbers)
        .min(3, languageData?.lName3Chars).max(50, languageData?.max50Characters).nullable(),

    phone: regFields.phone === 2
      ? Yup.string().required(languageData?.phoneRequired).nullable()
      : regFields.phone !== 0 && Yup.string().nullable(),

    dateOfBirth: regFields.dateOfBirth === 2
      ? Yup.date()
        .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), languageData?.mustBe18)
        .typeError(languageData?.validDOB)
        .required(languageData?.dateOfBirthRequired)
      : regFields.dateOfBirth !== 0 && Yup.date()
        .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), languageData?.mustBe18)
        .typeError(languageData?.validDOB).nullable(),

    gender: regFields.gender === 2
      ? Yup.string().required(languageData?.genderRequired)
      : regFields.gender !== 0 && Yup.string().nullable(),

    username: regFields.username === 2
      ? Yup.string().max(20, languageData?.max20Characters)
        .min(3, languageData?.min3Characters)
        .matches(/^[A-Za-z][^\s]*$/, languageData?.userNameAllowed)
        .required(languageData?.userNameRequired)
      : regFields.username !== 0 && Yup.string().max(20, languageData?.max20Characters)
        .min(3, languageData?.min3Characters)
        .matches(/^[A-Za-z][^\s]*$/, languageData?.userNameAllowed)
        .nullable(),

    address: regFields.address === 2
      ? Yup.string().max(100, languageData?.max100Characters)
        .required(languageData?.addressRequired)
        .nullable()
      : regFields.address !== 0 && Yup.string().max(100, languageData?.max100Characters)
        .nullable(),

    city: regFields.address === 2
      ? Yup.string().max(50, languageData?.max50Characters)
        .required(languageData?.cityRequired).nullable()
      : regFields.address !== 0 && Yup.string()
        .max(50, languageData?.max50Characters).nullable(),

    zipCode: regFields.address === 2
      ? Yup.string()
        .required(languageData?.postCodeRequired).nullable()
      : regFields.address !== 0 && Yup.string()
        .nullable(),

    currencyCode: regFields.currencyCode &&
      Yup.string().required(languageData?.currencyCodeRequired),

    countryCode: regFields.countryCode === 2
      ? Yup.string().required(languageData?.countryRequired)
      : regFields.countryCode !== 0 && Yup.string().nullable(),

    preferredLanguage: regFields.preferredLanguage === 2
      ? Yup.string().required(languageData?.preferredLanguageRequired)
      : regFields.preferredLanguage !== 0 && Yup.string().nullable()
  })

// Schema for Other Languages
export const userSigninSchema = (regFields, languageData) =>
  regFields &&
  Yup.object().shape({
    email: regFields.email &&
      Yup.string().email(languageData?.invalidEmail).max(50).required(languageData?.emailRequired),

    password: regFields.password &&
      Yup.string().max(50).required(languageData?.passwordRequired),

    confirmPassword: regFields.confirmPassword &&
      Yup.string()
        .max(50)
        .oneOf([Yup.ref('password'), null], languageData?.matchPassword)
        .required(languageData?.confirmPasswordRequired),

    firstName: regFields.firstName &&
      Yup.string()
        .min(3, languageData?.fName3Chars).matches(/^([^0-9]*)$/, languageData?.notNumbers)
        .max(50, languageData?.max50Characters).required(languageData?.firstNameRequired),

    lastName: regFields.lastName === 2
      ? Yup.string()
        .min(3, languageData?.lName3Chars).max(50, languageData?.max50Characters).matches(/^([^0-9]*)$/, languageData?.notNumbers).required(languageData?.lastNameRequired)
      : Yup.string()
        .min(3, languageData?.lName3Chars).max(50, languageData?.max50Characters).matches(/^([^0-9]*)$/, languageData?.notNumbers).nullable(),

    phone: regFields.phone === 2
      ? Yup.string().required(languageData?.phoneRequired).nullable()
      : regFields.phone !== 0 && Yup.string().nullable(),

    dateOfBirth: regFields.dateOfBirth === 2
      ? Yup.date()
        .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), languageData?.mustBe18)
        .typeError(languageData?.validDOB)
        .required(languageData?.dateOfBirthRequired)
      : regFields.dateOfBirth !== 0 && Yup.date()
        .max(new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate() - 1), languageData?.mustBe18)
        .typeError(languageData?.validDOB).nullable(),

    gender: regFields.gender === 2
      ? Yup.string().required(languageData?.genderRequired)
      : regFields.gender !== 0 && Yup.string().nullable(),

    username: regFields.username === 2
      ? Yup.string().max(20, languageData?.max20Characters)
        .min(3, languageData?.min3Characters)
        .matches(/^[A-Za-z][^\s]*$/, languageData?.userNameAllowed)
        .required(languageData?.userNameRequired)
      : regFields.username !== 0 && Yup.string().max(20, languageData?.max20Characters)
        .min(3, languageData?.min3Characters)
        .matches(/^[A-Za-z][^\s]*$/, languageData?.userNameAllowed)
        .nullable(),

    address: regFields.address === 2
      ? Yup.string().max(100, languageData?.max100Characters)
        .required(languageData?.addressRequired)
        .nullable()
      : regFields.address !== 0 && Yup.string().max(100, languageData?.max100Characters)
        .nullable(),

    city: regFields.address === 2
      ? Yup.string().max(50, languageData?.max50Characters)
        .required(languageData?.cityRequired).nullable()
      : regFields.address !== 0 && Yup.string()
        .max(50, languageData?.max50Characters).nullable(),

    zipCode: regFields.address === 2
      ? Yup.string()
        .required(languageData?.postCodeRequired).nullable()
      : regFields.address !== 0 && Yup.string()
        .nullable(),

    currencyCode: regFields.currencyCode &&
      Yup.string().required(languageData?.currencyCodeRequired),

    countryCode: regFields.countryCode === 2
      ? Yup.string().required(languageData?.countryRequired)
      : regFields.countryCode !== 0 && Yup.string().nullable(),

    preferredLanguage: regFields.preferredLanguage === 2
      ? Yup.string().required(languageData?.preferredLanguageRequired)
      : regFields.preferredLanguage !== 0 && Yup.string().nullable()
  })
