import { takeLatest, put, takeEvery, select, delay } from 'redux-saga/effects'
import { toast } from '../../components/Toast'
import { getPaymentProviders, getPaymentStatus, getUtorgCurrencies, verifyPayment } from '../../utils/apiCalls'
import { removeItem, setItem } from '../../utils/storageUtils'
import {
  getPaymentProvidersStart,
  getPaymentProvidersSuccess,
  getPaymentProvidersFailure,
  verifyPaymentStart,
  verifyPaymentComplete,
  getPaymentStatusStart,
  getPaymentStatusSuccess,
  getPaymentStatusFailure,
  getAllPaymentProvidersSuccess,
  verifyPaymentFailed,
  getUtorgCurrenciesStart,
  getUtorgCurrenciesSuccess,
  getUtorgCurrenciesFailed
} from '../redux-slices/payment'
import { getUserIdAndSessionIdStart } from '../redux-slices/transactions'

const getLanguageData = state => state.language.selectedLanguageCode

export default function * paymentWatcher () {
  yield takeEvery(getPaymentProvidersStart, getPaymentProvidersWorker)
  yield takeLatest(verifyPaymentStart, verifyPaymentWorker)
  yield takeLatest(getPaymentStatusStart, getPaymentStatusWorker)
  yield takeLatest(getUtorgCurrenciesStart, getUtorgCurrenciesWorker)
}

function * getPaymentProvidersWorker (action) {
  try {
    const { paymentType, bonusId } = action && action.payload

    const { data } = yield getPaymentProviders({ paymentType, bonusId })

    paymentType === 'both'
      ? yield put(getAllPaymentProvidersSuccess(data?.data?.providerDetails))
      : yield put(getPaymentProvidersSuccess(data?.data?.paymentCategory))
  } catch (e) {
    yield put(getPaymentProvidersFailure())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * verifyPaymentWorker (action) {
  try {
    const { data: myData, navigate } = action && action.payload

    // Check if sessionId exists then proceed else fetch sessionId first
    if (myData?.sessionId) {
      yield put(verifyPaymentFailed(null))

      const { data } = yield verifyPayment(myData)

      if (data?.data?.response?.txId || data?.data?.response?.id) setItem('txId', String(data?.data?.response?.txId || data?.data?.response?.id))
      else yield setItem('txId', '')
      yield setItem('aggregator', String(myData.aggregator))
      yield data?.data?.response?.session ? setItem('orderId', String(data?.data?.response?.session?.order_id)) : setItem('orderId', '')
      yield put(verifyPaymentComplete(data?.data?.response))
    } else {
      yield put(getUserIdAndSessionIdStart({ myData, payments: true, navigate, type: myData?.paymentType }))
    }
  } catch (e) {
    const { setData, setAmt } = action && action.payload
    if (setData) {
      setData({})
    }
    if (setAmt) {
      setAmt('Other')
    }
    if (e?.response?.data?.errors[0]?.errorCode === 3087) {
      const { navigate } = action && action.payload
      const selectedLanguageCode = yield select(getLanguageData)

      navigate && navigate(`/${selectedLanguageCode?.toLowerCase()}/account/account-verify`)
    } else {
      const { resetPay, setShowBonusNext } = action && action.payload
      yield resetPay()
      setShowBonusNext && setShowBonusNext(true)
    }
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(verifyPaymentFailed(e?.response?.data?.errors[0]?.errorCode || e?.response?.data))
  }
}

function * getPaymentStatusWorker (action) {
  try {
    const { transactionId, sessionId, aggregator, orderId, setShowBonusListing } = action && action.payload
    yield delay(3000)

    const { data } = yield getPaymentStatus({ transactionId, sessionId, aggregator, orderId })

    yield put(getPaymentStatusSuccess(data?.data?.response))
    if (setShowBonusListing) {
      yield setShowBonusListing(false)
    }
    removeItem('txId')
    removeItem('sessionId')
    removeItem('aggregator')
    removeItem('order-id')
  } catch (e) {
    yield put(getPaymentStatusFailure())
  }
}

function * getUtorgCurrenciesWorker () {
  try {
    const { data } = yield getUtorgCurrencies()

    const currencyData = data?.data?.currencies?.map(item => ({
      name: item.currency,
      value: item.currency
    }))

    yield put(getUtorgCurrenciesSuccess(currencyData))
  } catch (e) {
    yield put(getUtorgCurrenciesFailed([]))
  }
}
