import React from 'react'
import { ListGroup, Form, Dropdown } from 'react-bootstrap'
import './styles/index.scss'
import useOutsideClick from '../../utils/useOutsideClick'
import { useSelector } from 'react-redux'

const PaginationItem = ({ active, onClick, label, disabled }) => {
  return (
    <li
      className={`page-list-item ${active}`}
      onClick={() => {
        if (disabled) {
          return
        }
        onClick()
      }}
    >
      {label}
    </li>
  )
}

const PaginationComponent = ({ page, totalPages, setPage, limit, setLimit }) => {
  const AllOptions = [10, 20, 50, 100]
  const newOptions = []
  AllOptions.flatMap((o) => o !== limit && newOptions.push(o))
  const { languageData } = useSelector((state) => state.language)
  const OPTIONS_ARR = [limit, ...newOptions]
  const items = []

  if (totalPages > 5) {
    const showFirstPages = page < 3
    const showLastPages = page > totalPages - 2

    if (!showFirstPages) {
      items.push(
        <PaginationItem
          key={1}
          active={page === 1}
          onClick={() => setPage(1)}
          label={1}
        />
      )

      items.push(<PaginationItem key='ellipsis1' label='...' />)
    } else {
      for (let i = 1; i <= 2; i++) {
        items.push(
          <PaginationItem
            key={i}
            active={i === page}
            onClick={() => setPage(i)}
            label={i}
          />
        )
      }
    }

    if (showLastPages) {
      for (let i = totalPages - 2; i <= totalPages; i++) {
        items.push(
          <PaginationItem
            key={i}
            active={i === page}
            onClick={() => setPage(i)}
            label={i}
          />
        )
      }
    }

    if (!showFirstPages && !showLastPages) {
      items.push(
        <PaginationItem
          key={page - 1}
          active={page === page - 1}
          onClick={() => setPage(page - 1)}
          label={page - 1}
        />
      )

      items.push(
        <PaginationItem
          key={page}
          active
          onClick={() => setPage(page)}
          label={page}
        />
      )

      items.push(
        <PaginationItem
          key={page + 1}
          active={page === page + 1}
          onClick={() => setPage(page + 1)}
          label={page + 1}
        />
      )
    }

    if (!showLastPages) {
      items.push(<PaginationItem key='ellipsis2' label='...' />)

      items.push(
        <PaginationItem
          key={totalPages}
          active={page === totalPages}
          onClick={() => setPage(totalPages)}
          label={totalPages}
        />
      )
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      items.push(
        <PaginationItem
          key={i}
          active={i === page}
          onClick={() => setPage(i)}
          label={i}
        />
      )
    }
  }

  const { ref, isVisible, setIsVisible } = useOutsideClick(false)

  return (
    <ListGroup.Item className='pagination-wrapper'>
      <div className='pagination-wrapper-flex'>
        <div className='dropdown-group d-flex align-items-center justify-content-center flex-sm-row flex-column'>
          <h6 className='mb-0 row-per-page'>{languageData?.rowsPerPage} :</h6>
          <Form.Group>
            <Dropdown className='dropdownBtn icon-right row-dropdown' ref={ref}>
              {!isVisible &&
                <Dropdown.Toggle id='dropdown-basic' className='show' onClick={() => setIsVisible(true)}>
                  <span className='text-capitalize'>{limit}</span>
                  <img src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`} alt='Dropdown' />
                </Dropdown.Toggle>}

              {isVisible &&
                <div className='dropdown-menu show'>
                  {OPTIONS_ARR.map(o =>
                    <Dropdown.Item
                      key={o}
                      className={limit === o ? 'selected' : ''}
                      onClick={() => {
                        setLimit(o)
                        setPage(1)
                      }}
                    >{o}
                      {limit === o && <img src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`} alt='Dropdown' />}
                    </Dropdown.Item>
                  )}
                </div>}
            </Dropdown>
          </Form.Group>
        </div>

        <div className='pages-group'>
          <ul className='pages-list'>
            <span className='pages-list-go-prev'>
              <span
                disabled={page === 1}
                onClick={() => page !== 1 && setPage(page - 1)}
              >
                <svg
                  width='9.618px'
                  height='16.359px'
                  viewBox='0 0 9.618 16.359'
                  enableBackground='new 0 0 9.618 16.359'
                >
                  <path
                    fill='#FFFFFF'
                    d='M7.795,16.357c-0.699-0.341-1.152-0.966-1.688-1.491c-1.853-1.825-3.678-3.679-5.512-5.521
                      c-0.783-0.785-0.798-1.499-0.019-2.282C2.753,4.875,4.936,2.698,7.12,0.52c0.654-0.653,1.488-0.69,2.064-0.112
                      c0.592,0.595,0.58,1.396-0.082,2.061c-1.871,1.879-3.76,3.743-5.641,5.614c-0.035,0.035-0.061,0.081-0.05,0.067
                      c1.86,1.885,3.708,3.76,5.562,5.63c0.965,0.976,0.844,1.898-0.351,2.579C8.348,16.357,8.072,16.357,7.795,16.357z'
                  />
                </svg>
              </span>
            </span>

            {items}
            <span className='pages-list-go-next'>
              <span
                disabled={page >= totalPages}
                onClick={() => page < totalPages && setPage(page + 1)}
              >
                <svg
                  width='9.618px'
                  height='16.359px'
                  viewBox='0 0 9.618 16.359'
                  enableBackground='new 0 0 9.618 16.359'
                >
                  <path
                    fill='#FFFFFF'
                    d='M1.823,0.001C2.522,0.342,2.975,0.967,3.51,1.492c1.853,1.825,3.678,3.679,5.512,5.521
                      C9.805,7.799,9.82,8.513,9.041,9.296c-2.176,2.188-4.358,4.365-6.542,6.543c-0.654,0.653-1.488,0.69-2.064,0.112
                      c-0.592-0.595-0.58-1.396,0.082-2.061c1.871-1.879,3.76-3.743,5.641-5.614c0.035-0.035,0.061-0.081,0.05-0.067
                      c-1.86-1.885-3.708-3.76-5.562-5.63C-0.32,1.604-0.199,0.681,0.996,0C1.27,0.001,1.546,0.001,1.823,0.001z'
                  />
                </svg>
              </span>
            </span>
          </ul>
        </div>
      </div>
    </ListGroup.Item>

  )
}

export default PaginationComponent
