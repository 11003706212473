const storage = {}

export const setDelay = (item, time) => {
  storage[item] = true
  setTimeout(() => {
    storage[item] = false
  }, time)
}

export const checkDelay = (item) => {
  if (storage[item]) {
    return false
  } else {
    return true
  }
}
