import React, { useContext } from 'react'
import { UserDetailContext } from '../../../pages/NewAccountInfoPage/useContext'
import { Formik, Form } from 'formik'
import { userSigninSchemaEN, userSigninSchema } from './schema'
import { getItem } from '../../../utils/storageUtils'
import UserInfoHeader from './UserInfoHeader'
import UserInfoFooter from './UserInfoFooter'
import UserInfoTabs from './UserInfoTabs'

const UserInfoForm = () => {
  const { regFields, userDetails, isChecked, handleInitialState, languageData } = useContext(UserDetailContext)
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...userDetails,
        password: '',
        confirmPassword: '',
        preferredLanguage: userDetails?.locale
      }}
      validationSchema={
        getItem('language')?.toUpperCase() === 'EN'
          ? userSigninSchemaEN(regFields, userDetails, isChecked, languageData)
          : userSigninSchema(regFields, userDetails, isChecked, languageData)
      }
      onSubmit={(formValues) => {
        handleInitialState(formValues)
      }}
      validateOnBlur
      validateOnChange
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <UserInfoHeader />
            <div className='account-info-tabs user-account-block'>
              <UserInfoTabs formik={formik} />
              <UserInfoFooter formik={formik} />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default UserInfoForm
