import { takeLatest, put, select } from 'redux-saga/effects'
import { toast } from '../../components/Toast'
import { getLimitTable, setDailyLimit, setDepositLimit, setDisableUntil, setLossLimit, setSessionTime, setGamePlayLimit } from '../../utils/apiCalls'
import { getItem, setLoginToken } from '../../utils/storageUtils'
import { resetState } from '../redux-slices'

import {
  setDailyLimitStart,
  setDailyLimitFailure,
  setLossLimitStart,
  setLossLimitFailure,
  setDepositLimitStart,
  setDepositLimitFailure,
  setSessionTimeStart,
  setSessionTimeFailure,
  setDisableUntilStart,
  setDisableUntilComplete,
  updatePasswordStatus,
  setGamePlayLimitStart,
  setGamePlayLimitComplete
} from '../redux-slices/limits'

import {
  getLimitTableFailure,
  getLimitTableStart,
  getLimitTableSuccess,
  getUserDetailsStart,
  setDailyLimitSuccess,
  setDepositLimitSuccess,
  setLossLimitSuccess,
  setSessionTimeSuccess
} from '../redux-slices/user'
import { logoutStart } from '../redux-slices/login'

const getLanguageData = state => state.language.languageData

export default function * limitsWatcher () {
  yield takeLatest(setDailyLimitStart.type, setDailyLimitWorker)
  yield takeLatest(setLossLimitStart.type, setLossLimitWorker)
  yield takeLatest(setDepositLimitStart.type, setDepositLimitWorker)
  yield takeLatest(setSessionTimeStart.type, setSessionTimeWorker)
  yield takeLatest(setDisableUntilStart.type, setDisableUntilWorker)
  yield takeLatest(getLimitTableStart.type, getLimitTableWorker)
  yield takeLatest(setGamePlayLimitStart.type, setGamePlayLimitWorker)
}

function * setDailyLimitWorker (action) {
  try {
    const languageData = yield select(getLanguageData)
    const { data } = action && action.payload
    const { data: response } = yield setDailyLimit({
      data:
      {
        daily: data?.dailyBetLimit,
        weekly: data?.weeklyBetLimit,
        monthly: data?.monthlyBetLimit,
        reset: data?.reset,
        password: data?.password
      }
    })
    if (response?.data?.code === 455) {
      yield put(updatePasswordStatus(false))
      return
    }
    yield put(setDailyLimitSuccess(response?.data?.limit))

    yield put(updatePasswordStatus(true))

    yield put(getLimitTableStart({ userId: getItem('userId') }))

    yield toast(`${languageData?.wager} ${languageData?.limitSetSuccess}`, 'success')
  } catch (e) {
    yield put(setDailyLimitFailure())

    yield put(getUserDetailsStart())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * setLossLimitWorker (action) {
  try {
    const { data } = action && action.payload
    const languageData = yield select(getLanguageData)
    const { data: response } = yield setLossLimit({
      data:
      {
        daily: data?.dailyLossLimit,
        weekly: data?.weeklyLossLimit,
        monthly: data?.monthlyLossLimit,
        reset: data?.reset,
        password: data?.password
      }
    })
    if (response?.data?.code === 455) {
      yield put(updatePasswordStatus(false))
      return
    }
    yield put(setLossLimitSuccess(response?.data?.limit))

    yield put(updatePasswordStatus(true))

    yield put(getLimitTableStart({ userId: getItem('userId') }))

    yield toast(`${languageData?.loss} ${languageData?.limitSetSuccess}`, 'success')
  } catch (e) {
    yield put(setLossLimitFailure())

    yield put(getUserDetailsStart())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * setDepositLimitWorker (action) {
  try {
    const { data, navigate } = action && action.payload
    const languageData = yield select(getLanguageData)

    const { data: response } = yield setDepositLimit({
      data:
      {
        daily: data?.dailyDepositLimit,
        weekly: data?.weeklyDepositLimit,
        monthly: data?.monthlyDepositLimit,
        reset: data?.reset,
        password: data?.password
      }
    })
    if (response?.data?.code === 455) {
      yield put(updatePasswordStatus(false))
      return
    }
    yield put(setDepositLimitSuccess(response?.data?.limit))

    yield put(updatePasswordStatus(true))

    yield put(getLimitTableStart({ userId: getItem('userId') }))

    yield toast(`${languageData?.headerDeposit} ${languageData?.limitSetSuccess}`, 'success')
    if (data?.dailyDepositLimit === '0' || data?.weeklyDepositLimit === '0' || data?.monthlyDepositLimit === '0') {
      yield put(logoutStart({ navigate }))
    }
  } catch (e) {
    yield put(setDepositLimitFailure())

    yield put(getUserDetailsStart())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * setSessionTimeWorker (action) {
  try {
    const { data } = action && action.payload

    const { data: response } = yield setSessionTime(data)
    const languageData = yield select(getLanguageData)

    if (response?.data?.code === 455) {
      yield put(updatePasswordStatus(false))
      return
    }

    yield put(updatePasswordStatus(true))

    yield put(setSessionTimeSuccess(response?.data?.limit))

    yield put(getLimitTableStart({ userId: getItem('userId') }))

    yield setLoginToken(response?.data?.accessToken)

    yield toast(`${languageData?.session} ${languageData?.limitSetSuccess}`, 'success')
  } catch (e) {
    yield put(setSessionTimeFailure())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * setDisableUntilWorker (action) {
  try {
    const { data } = action && action.payload
    const languageData = yield select(getLanguageData)

    yield setDisableUntil(data)

    yield put(resetState())

    yield toast(`${languageData?.accountDisabled}`, 'success')
  } catch (e) {
    if (e?.response?.data?.errors[0]?.errorCode === 3055 || e?.response?.data?.errors[0]?.errorCode === 3056 || e?.response?.data?.errors[0]?.errorCode === 3057) {
      yield put(updatePasswordStatus(false))
      return
    } else {
      yield put(setDisableUntilComplete(true))
    }

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getLimitTableWorker (action) {
  try {
    const { userId } = action && action.payload

    const { data: response } = yield getLimitTable({ userId })

    yield put(getLimitTableSuccess(response?.data?.limitTable))
  } catch (e) {
    yield put(getLimitTableFailure())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * setGamePlayLimitWorker (action) {
  try {
    const { data } = action && action.payload
    const languageData = yield select(getLanguageData)

    const response = yield setGamePlayLimit(data)
    if (response?.data?.code === 455) {
      yield put(updatePasswordStatus(false))
      return
    }
    yield put(setGamePlayLimitComplete(response?.data?.limit))

    yield put(updatePasswordStatus(true))

    yield put(getLimitTableStart({ userId: getItem('userId') }))

    yield toast(`${languageData?.games} ${languageData?.limitSetSuccess}`, 'success')
  } catch (e) {
    yield put(setGamePlayLimitComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}
