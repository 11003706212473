import axios from 'axios'
import store from '../store'
import { resetState } from '../store/redux-slices'
import { getCookie, getItem, getLoginToken } from './storageUtils'
import { history } from './history'
import { setIsUserBlock } from '../store/redux-slices/user'

const axiosInstance = axios.create()

export const setupInterceptors = () => {
  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response.status === 403) {
        window.cioLogoutUser()
        store.dispatch(resetState())
      } else if (error.response.status === 406) {
        window.location.href = '/tenant-inactive'
      } else if (error.response.status === 600) {
        if (history) {
          // history.navigate('/en/600')
          store.dispatch(setIsUserBlock(true))
        }
      }

      return Promise.reject(error)
    }
  )
}

const METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE'
}

const makeRequest = async (url, method, data = {}) => {
  const headers = {
    'Content-Type': 'application/json'
  }

  if (getLoginToken()) {
    headers.Authorization = `Bearer ${getLoginToken()}`
  }

  headers.language = getItem('language') || 'EN'

  // Set countryCode as a header if it exists in the cookie
  if (getCookie('countryCode')) {
    headers['Country-Code-Id'] = getCookie('countryCode') || ''
  }

  return axiosInstance({
    url,
    method,
    data,
    headers
  })
}

const getRequest = (url) => makeRequest(url, METHODS.get)

const postRequest = (url, data) => makeRequest(url, METHODS.post, data)

const putRequest = (url, data) => makeRequest(url, METHODS.put, data)

const deleteRequest = (url, data) => makeRequest(url, METHODS.delete, data)

export { getRequest, postRequest, putRequest, deleteRequest }
