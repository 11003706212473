import { takeLatest, put, select } from 'redux-saga/effects'
import { getLanguageData, getLanguages } from '../../utils/apiCalls'
import { setItem } from '../../utils/storageUtils'
import {
  getLanguageDataFailure,
  getLanguageDataStart,
  getLanguageDataSuccess,
  getLanguagesFailure,
  getLanguagesStart,
  getLanguagesSuccess,
  getLicenseDetailSuccess,
  setBrandLogo,
  setSelectedLanguagesComplete,
  setSelectedLanguagesStart,
  setUserCountry
} from '../redux-slices/language'
import { showBanner, showChildren, showHeader } from '../redux-slices/loader'
import { setMerchantId } from '../redux-slices/payment'
import { setIsUserBlock } from '../redux-slices/user'

export default function * languageWatcher () {
  yield takeLatest(getLanguagesStart.type, getLanguagesWorker)
  yield takeLatest(setSelectedLanguagesStart.type, setSelectedLanguageWorker)
  yield takeLatest(getLanguageDataStart, getLanguageDataWorker)
}

const getselectedLanguageCode = state => state.language.selectedLanguageCode
const getLang = state => state.language.languages

function * getLanguagesWorker (action) {
  try {
    const { data: response } = yield getLanguages()

    yield put(getLanguagesSuccess(response?.data?.languages))
    yield put(setMerchantId(response?.data?.merchantId))
    yield put(setUserCountry(response?.data?.countryCode || 'SE'))
    if (response?.data?.countryBlocked) {
      yield put(setIsUserBlock(true))
    }

    const siteId = response?.data?.siteId
    if (siteId) {
      window.cioEnableScript(siteId)
    }
  } catch (e) {
    //  const { navigate } = action?.payload
    // if (e?.response?.data?.errors[0]?.errorCode === 3000) {
    //    navigate && navigate(`/${getItem('language').toLowerCase() || 'en'}/600`)
    // }
    yield put(getLanguagesFailure())
  }
}

function * setSelectedLanguageWorker (action) {
  try {
    const { selectedLanguageCode, navigate } = action && action.payload
    const languages = yield select(getLang)
    const myLanguage = languages?.allowedLanguages?.includes(selectedLanguageCode?.toUpperCase()) ? selectedLanguageCode?.toUpperCase() : 'EN'

    yield setItem('language', myLanguage)
    const newPath = window?.location?.pathname?.replace(`/${window?.location?.pathname?.split('/')?.[1]?.toLowerCase()}/`, `/${selectedLanguageCode?.toLowerCase()}/`)
    if (navigate) yield navigate(newPath)
    yield put(setSelectedLanguagesComplete({
      selectedLanguageCode: myLanguage
    }))

    yield put(getLanguageDataStart())
  } catch (e) {
    yield put(setSelectedLanguagesComplete())
  }
}

function * getLanguageDataWorker (action) {
  try {
    const data = action && action.payload
    const selectedLanguageCode = yield select(getselectedLanguageCode)
    const path = window.location.pathname?.split('/')?.[1]?.toUpperCase()
    if (window.location.pathname === '/' || selectedLanguageCode?.toUpperCase() === path) {
      const res = yield getLanguageData({ manual: !data })
      yield put(getLanguageDataSuccess(res?.data?.data?.languageData))
      yield put(getLicenseDetailSuccess(res?.data?.data?.licenseTags))
      yield put(setBrandLogo(res?.data?.data?.logoUrl))
      if (document.title !== res?.data?.data?.tenantName) { document.title = res?.data?.data?.tenantName }

      if (res?.data?.countryBlocked) {
        yield put(setIsUserBlock(true))
      }
    }

    yield put(showHeader(true))

    yield put(showChildren(true))

    yield put(showBanner(true))
  } catch (e) {
    // const { navigate } = action?.payload
    // if (e?.response?.data?.errors[0]?.errorCode === 3000) {
    //   navigate && navigate(`/${getItem('language').toLowerCase() || 'en'}/600`)
    // }
    yield put(getLanguageDataFailure())
    yield put(setIsUserBlock(true))
  }
}
