import { useEffect, useState } from 'react'
import { getCookie, getItem, setCookie, setItem } from '../../utils/storageUtils'
import Loader from '../Loader'
import { fetchRedirectionUrl } from '../../utils/apiCalls'

/**
 * PreAppLoader is a React component that handles the initial loading state
 * of the application. It checks for `token` and `userId` query parameters
 * in the URL, stores them in local storage, and removes them from the URL
 * to prevent exposure after the initial load. If the parameters are not
 * available, it fetches a redirection URL and redirects the user to that URL
 * if necessary. The loader is displayed until the redirection check or
 * parameters are processed.
 *
 * Example real-world scenario:
Currently, if a user tries to access shinywild from Germany, they will be redirected to the mirror site if defined in the back office.
However, if they register with the India country code, they should be redirected to the country mirror site after registration, if defined for that country.
The user and token are retrieved from the registration response and passed to the appropriate URL (shinywild or the corresponding URL for that country).

 *    `https://yourapp.com/?token=abc123&userId=456`
 * 2. The `PreAppLoader` component detects the token and userId from the URL, stores them, and removes them from the URL.
 * 3. If needed, the component will also redirect the user to a new URL, potentially including the token and userId. and countryCode
 * 4  We are setting the country code in cookies. So, if a user returns and is not logged in, we will redirect the user to the last logged-in country code
 *
 * @param {Object} props - The props for this component.
 * @param {React.ReactNode} props.children - The children components to render once loading is complete.
 * @returns {React.ReactNode} The loader or children depending on the loading state.
 */

const PreAppLoader = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)

  /**
   * Checks if the URL contains `token` and `userId` query parameters,
   * saves them to localStorage, and removes them from the URL.
   * @returns {boolean} Whether both `token` and `userId` were found in the URL.
   */
  const hasQueryParams = () => {
    // Extract token and userId from query parameters
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get('redirectionToken')
    const userId = urlParams.get('userId')
    const countryCode = urlParams.get('countryCode')

    // If token and userId are in the query params, save them to localStorage
    if (token) setItem('access-token', token)
    if (userId) setItem('userId', userId)
    if (countryCode) setCookie('countryCode', countryCode, 365)
    if (userId && token) setItem('loggedIn', String(true))

    // Remove token and userId from the URL
    if (token || userId || countryCode) {
      urlParams.delete('token')
      urlParams.delete('userId')
      urlParams.delete('countryCode')

      // Only update the URL if there are remaining parameters
      if (urlParams?.toString()) {
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`
        window.history.replaceState(null, '', newUrl)
      } else {
        // If no parameters remain, clear the query string
        const newUrl = `${window.location.pathname}`
        window.history.replaceState(null, '', newUrl)
      }
    }

    // Return whether query params exist
    return token && userId
  }

  /**
   * Fetches the redirection URL and performs the redirection if necessary.
   */
  const fetchRedirectionURL = async () => {
    try {
      const accessToken = getItem('access-token')
      const userId = getItem('userId')
      const countryCode = getCookie('countryCode') || ''
      const redirectionUrl = await fetchRedirectionUrl({ accessToken, countryCode })
      const currentUrl = window.location.origin

      if (redirectionUrl && currentUrl !== redirectionUrl) {
        const redirectionUrlWithParams = `${redirectionUrl}?redirectionToken=${encodeURIComponent(accessToken)}&userId=${encodeURIComponent(userId)}`
        if (accessToken) {
          window.location.href = redirectionUrlWithParams
        } else {
          window.location.href = `${redirectionUrl}?isRedirectUrl=true`
        }
      } else {
        setIsLoading(false) // No redirection needed
      }
    } catch (error) {
      console.error('Error fetching redirection URL:', error)
      setIsLoading(false) // Proceed with the app even if there's an error
    }
  }

  useEffect(() => {
    const hasParams = hasQueryParams()
    const urlParams = new URLSearchParams(window.location.search)
    const isRedirectUrl = urlParams.get('isRedirectUrl') === 'true'

    if (hasParams || isRedirectUrl) {
      // If token and userId or isRedirectUrl exist in query params, skip API call
      setIsLoading(false)
    } else {
      // Otherwise, fetch redirection URL
      fetchRedirectionURL()
    }
  }, [])

  if (isLoading) {
    return <Loader top='40vh' />
  }

  return <>{children}</>
}

export default PreAppLoader
