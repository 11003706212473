import React, { useContext } from 'react'
import { UserDetailContext } from '../../../../../pages/NewAccountInfoPage/useContext'
import FormFieldControl from '../../../../FormFieldControl'
import CountryField from '../../../../FormFieldControl/CountryField'
const Address = ({ formik }) => {
  const { languageData } = useContext(UserDetailContext)

  const commonProps = {
    containerClassName: 'custom-form-group',
    fieldClassName: 'form-control custom-input',
    contextValue: UserDetailContext
  }

  return (
    <>
      <div className='signup-form-detail'>
        <FormFieldControl
          name='countryCode'
          label='signupCounty'
          {...commonProps}
        >
          <CountryField disabled formik={formik} contextValue={UserDetailContext} />
        </FormFieldControl>

        <FormFieldControl
          type='text'
          name='currencyCode'
          control='input'
          label='signupCurrency'
          placeholder={languageData?.currencyPlaceholder}
          disabled
          {...commonProps}
        />

        <FormFieldControl
          type='text'
          name='address'
          control='input'
          label='signupAddress'
          placeholder={languageData?.addressPlaceholder}
          {...commonProps}
          disabled
        />

        <FormFieldControl
          type='text'
          name='city'
          control='input'
          label='signupCity'
          placeholder={languageData?.cityPlaceholder}
          {...commonProps}
          // disabled={userDetails?.kycStatus === 'APPROVED'}
          disabled
        />

        <FormFieldControl
          type='text'
          name='zipCode'
          control='input'
          label='signupPostcode'
          placeholder={languageData?.postCodePlaceholder}
          {...commonProps}
          disabled
        />
      </div>
    </>
  )
}

export default Address
