import { createSlice } from '@reduxjs/toolkit'
import { languageCodeList } from '../../constants/languageList'
import { getItem } from '../../utils/storageUtils'

const userLang = navigator.language.toUpperCase().substring(0, 2) || navigator.userLanguage.toUpperCase().substring(0, 2)

let selectedLanguageCode = getItem('language') ? getItem('language')?.toUpperCase() : (window?.location?.pathname?.split('/')?.[1]?.toUpperCase() || languageCodeList?.[userLang] ? userLang?.toUpperCase() : process.env.REACT_APP_DEFAULT_LANGUAGE)

const {
  actions: {
    getLanguagesStart,
    getLanguagesSuccess,
    getLanguagesFailure,
    setSelectedLanguagesStart,
    setSelectedLanguagesComplete,
    getLanguageDataStart,
    getLanguageDataSuccess,
    getLicenseDetailSuccess,
    getLanguageDataFailure,
    updateUserLangSuccess,
    setUserCountry,
    setBrandLogo
  },
  reducer
} = createSlice({
  name: 'language',
  initialState: {
    loading: false,
    selectedLanguageCode,
    showUserLang: true,
    userIPCountry: null,
    brandLogo: null,
    licenseDetail: {}
  },
  reducers: {
    getLanguagesStart: (state) => ({
      ...state,
      loading: true
    }),
    getLanguagesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      languages: payload
    }),
    getLanguagesFailure: (state) => ({
      ...state,
      loading: false
    }),
    setSelectedLanguagesStart: (state) => ({
      ...state,
      loading: true,
      languageChanged: true
    }),
    setSelectedLanguagesComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      selectedLanguageCode: payload?.selectedLanguageCode,
      languageChanged: false
    }),
    getLanguageDataStart: (state) => ({
      ...state,
      loading: true
    }),
    getLanguageDataSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      languageData: payload
    }),
    getLicenseDetailSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      licenseDetail: payload
    }),
    getLanguageDataFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateUserLangSuccess: (state) => ({
      ...state,
      showUserLang: false
    }),
    setUserCountry: (state, { payload }) => ({
      ...state,
      userIPCountry: payload
    }),
    setBrandLogo: (state, { payload }) => ({
      ...state,
      brandLogo: payload
    })
  }
})

export default reducer

export {
  getLanguagesStart,
  getLanguagesSuccess,
  getLanguagesFailure,
  setSelectedLanguagesStart,
  setSelectedLanguagesComplete,
  getLanguageDataStart,
  getLanguageDataSuccess,
  getLicenseDetailSuccess,
  getLanguageDataFailure,
  updateUserLangSuccess,
  setUserCountry,
  setBrandLogo
}
