import { takeLatest, put, select } from 'redux-saga/effects'
import { toast } from '../../components/Toast'
import { resetState } from '../redux-slices'
import { setSelectedLanguagesStart } from '../redux-slices/language'
import { serialize } from 'object-to-formdata'

import {
  getDocuments,
  getDocumentsLabel,
  getUserDetails,
  removeProfileImage,
  updateDocuments,
  updateUserDetails,
  uploadProfileImage,
  getSumSubAccessToken,
  getAllReviews,
  getCashbackBalance,
  getWalletAmount,
  submitOTP,
  resendPhoneOTP,
  changePhoneNumber
} from '../../utils/apiCalls'

import {
  getDocumentsFailure,
  getDocumentsLabelFailure,
  getDocumentsLabelStart,
  getDocumentsLabelSuccess,
  getDocumentsStart,
  getDocumentsSuccess,
  getUserDetailsFailure,
  getUserDetailsStart,
  getUserDetailsSuccess,
  removeProfileImageComplete,
  removeProfileImageStart,
  updateDocumentsComplete,
  updateDocumentsStart,
  uploadProfileImageComplete,
  uploadProfileImageStart,
  userUpdateInfoComplete,
  userUpdateInfoStart,
  getSumSubAccessTokenStart,
  getSumSubAccessTokenSuccess,
  getSumSubAccessTokenFailure,
  getAllReviewFailure,
  getAllReviewSuccess,
  getAllReviewStart,
  getCashbackBalanceFailure,
  getCashbackBalanceSuccess,
  getCashbackBalanceStart,
  getWalletAmountStart,
  getWalletAmountSuccess,
  getWalletAmountFailure,
  submitOTPStart,
  submitOTPComplete,
  changePhoneNumberStart,
  changePhoneNumberComplete,
  resendPhoneOTPStart,
  resendPhoneOTPComplete
} from '../redux-slices/user'

const getLanguageData = state => state.language.languageData

export default function * userWatcher () {
  yield takeLatest(getUserDetailsStart.type, getUserDetailWorker)
  yield takeLatest(userUpdateInfoStart.type, updateUserInfo)
  yield takeLatest(uploadProfileImageStart.type, uploadProfileImageWorker)
  yield takeLatest(removeProfileImageStart.type, removeProfileImageWorker)
  yield takeLatest(getDocumentsLabelStart.type, getDocumentsLabelWorker)
  yield takeLatest(getDocumentsStart.type, getDocumentsWorker)
  yield takeLatest(updateDocumentsStart.type, updateDocumentsWorker)
  yield takeLatest(getSumSubAccessTokenStart.type, getSumSubAccessTokenWorker)
  yield takeLatest(getAllReviewStart.type, getAllReviewWorker)
  yield takeLatest(getCashbackBalanceStart.type, getCashbackBalanceWorker)
  yield takeLatest(getWalletAmountStart.type, getWalletAmountWorker)
  yield takeLatest(submitOTPStart.type, submitOTPWorker)
  yield takeLatest(changePhoneNumberStart.type, changePhoneNumberWorker)
  yield takeLatest(resendPhoneOTPStart.type, resendPhoneOTPWorker)
}

function * getUserDetailWorker () {
  try {
    const { data } = yield getUserDetails()

    yield put(getUserDetailsSuccess(data?.data?.getUser))
  } catch (e) {
    yield put(getUserDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateUserInfo (action) {
  try {
    const { initialState, navigate } = action.payload
    const languageData = yield select(getLanguageData)

    yield updateUserDetails(initialState)
    yield put(userUpdateInfoComplete())
    yield toast(`${languageData?.account} ${languageData?.updatedSuccess}`, 'success')

    if (initialState?.password) {
      yield put(resetState())
      yield toast(`${languageData?.loginPassword} ${languageData?.updatedSuccess}`, 'success')
    }

    if (initialState?.preferredLanguage) {
      yield put(setSelectedLanguagesStart({
        selectedLanguageCode: initialState?.preferredLanguage,
        navigate
      }))
    }
    yield put(getUserDetailsStart())
  } catch (e) {
    yield put(userUpdateInfoComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * uploadProfileImageWorker (action) {
  try {
    const { image } = action.payload
    const languageData = yield select(getLanguageData)
    yield uploadProfileImage({ data: serialize({ profileImage: image }) })
    yield put(uploadProfileImageComplete())

    yield toast(`${languageData?.profileImage} ${languageData?.updatedSuccess}`, 'success')

    yield put(getUserDetailsStart())
  } catch (e) {
    yield put(uploadProfileImageComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * removeProfileImageWorker () {
  try {
    const languageData = yield select(getLanguageData)
    yield removeProfileImage()
    yield put(removeProfileImageComplete())
    yield toast(`${languageData?.profileImageRemoved}`, 'success')

    yield put(getUserDetailsStart())
  } catch (e) {
    yield put(removeProfileImageComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getDocumentsLabelWorker () {
  try {
    const { data } = yield getDocumentsLabel()
    yield put(getDocumentsLabelSuccess(data))
  } catch (e) {
    yield put(getDocumentsLabelFailure())
  }
}

function * getDocumentsWorker () {
  try {
    const { data } = yield getDocuments()

    yield put(getDocumentsSuccess(data.data.userDocument))
  } catch (e) {
    yield put(getDocumentsFailure())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * updateDocumentsWorker (action) {
  try {
    const { data } = action && action.payload

    const languageData = yield select(getLanguageData)

    yield updateDocuments({ data: serialize(data) })

    yield put(updateDocumentsComplete())

    yield put(getDocumentsStart())

    yield put(getUserDetailsStart())

    yield toast(`${languageData?.docs} ${languageData?.updatedSuccess}`, 'success')
  } catch (e) {
    yield put(updateDocumentsComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getSumSubAccessTokenWorker (action) {
  try {
    const { data } = yield getSumSubAccessToken()

    yield put(getSumSubAccessTokenSuccess(data?.data?.token))
  } catch (e) {
    yield put(getSumSubAccessTokenFailure())

    yield toast(e?.response?.data?.message, 'error')
  }
}

function * getAllReviewWorker () {
  try {
    const { data } = yield getAllReviews()

    yield put(getAllReviewSuccess(data?.data?.reviews))
  } catch (e) {
    yield put(getAllReviewFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getCashbackBalanceWorker () {
  try {
    const { data } = yield getCashbackBalance()

    yield put(getCashbackBalanceSuccess(data?.data?.cashback))
  } catch (e) {
    yield put(getCashbackBalanceFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * getWalletAmountWorker () {
  try {
    const { data } = yield getWalletAmount()

    yield put(getWalletAmountSuccess(data?.data?.amount?.amount))
  } catch (e) {
    yield put(getWalletAmountFailure())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * submitOTPWorker (action) {
  try {
    const languageData = yield select(getLanguageData)

    const { data, setPhoneDisable, setShow } = action.payload
    yield submitOTP(data)

    yield put(submitOTPComplete())
    yield setPhoneDisable(true)
    yield toast('OTP ' + languageData?.verificationCompletedSuccessfully, 'success')
    yield put(getUserDetailsStart())
    yield setShow(false)
  } catch (e) {
    yield put(submitOTPComplete())

    yield toast(e?.response?.data?.errors[0].description, 'error')
  }
}

function * changePhoneNumberWorker (action) {
  try {
    const { data, setPhoneDisable } = action.payload

    yield changePhoneNumber(data)
    yield put(changePhoneNumberComplete())
    yield setPhoneDisable(true)
  } catch (e) {
    yield put(changePhoneNumberComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * resendPhoneOTPWorker () {
  try {
    const languageData = yield select(getLanguageData)

    yield resendPhoneOTP({})
    yield put(resendPhoneOTPComplete())

    yield toast('OTP ' + languageData?.sentSuccessfully, 'success')
  } catch (e) {
    yield put(resendPhoneOTPComplete())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}
