import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getTopGamesStart } from '../../../store/redux-slices/casinoMenu'
import { getPaymentProvidersStart } from '../../../store/redux-slices/payment'
// import { getAllReviewStart } from '../../../store/redux-slices/user'
import { checkDelay, setDelay } from '../../../utils/apiDelayHelper'

const useCasinoMenuList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, selectedLanguageCode } = useSelector((state) => state.language)
  const { languageData } = useSelector((state) => state.language)
  const { allPaymentProviders, loading: paymentLoading } = useSelector(state => state.payment)
  // const { reviewList } = useSelector(state => state.user)
  const { topGames, topWinners, currentWinners } = useSelector((state) => state.casinoMenu)
  const { showBanner, showLeagueList, showRealPlayer } = useSelector(state => state?.loader)
  const getMaskCount = (name) => name.length === 3 ? 2 : name.length === 4 ? 3 : 4

  useEffect(() => {
    window.scrollTo(0, 0)
    if (showLeagueList) {
      if (checkDelay('getTopGamesStart')) {
        dispatch(getTopGamesStart({ limit: 3 }))
        setDelay('getTopGamesStart', 10000)
      }
      // if (checkDelay('getAllReviewStart')) {
      //   dispatch(getAllReviewStart())
      //   setDelay('getAllReviewStart', 300000)
      // }
    }
    !allPaymentProviders && !paymentLoading && dispatch(getPaymentProvidersStart({ paymentType: 'both', bonusId: '' }))
  }, [showLeagueList])

  return {
    loading,
    languageData,
    topWinners,
    currentWinners,
    topGames,
    selectedLanguageCode,
    showBanner,
    showLeagueList,
    showRealPlayer,
    navigate,
    allPaymentProviders,
    // reviewList,
    getMaskCount
  }
}

export default useCasinoMenuList
