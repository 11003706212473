import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getUserBetHistoryStart,
    getUserBetHistorySuccess,
    getUserBetHistoryFailure,
    getUserIdAndSessionIdStart,
    getUserIdAndSessionIdSuccess,
    getUserIdAndSessionIdFailure,
    clearUserIdAndSessionId,
    getWithdrawalHistoryStart,
    getWithdrawalHistorySuccess,
    getWithdrawalHistoryFailure,
    cancelWithdrawRequestStart,
    cancelWithdrawRequestComplete,
    getUserTransactionsStart,
    getUserTransactionsSuccess,
    getUserTransactionsFailure
  }, reducer
} = createSlice({
  name: 'transactions',
  initialState: {
    txLoading: false,
    betLoading: false,
    loading: false,
    betHistory: null,
    withdrawalHistory: null
  },
  reducers: {
    getUserBetHistoryStart: (state) => ({
      ...state,
      betLoading: true
    }),
    getUserBetHistorySuccess: (state, { payload }) => ({
      ...state,
      betLoading: false,
      betHistory: payload
    }),
    getUserBetHistoryFailure: (state) => ({
      ...state,
      betLoading: false
    }),
    getUserIdAndSessionIdStart: (state) => ({
      ...state,
      loading: true
    }),
    getUserIdAndSessionIdSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userPaymentCredential: payload
    }),
    getUserIdAndSessionIdFailure: (state) => ({
      ...state,
      loading: false
    }),
    clearUserIdAndSessionId: (state) => ({
      ...state,
      userPaymentCredential: null
    }),
    getWithdrawalHistoryStart: (state) => ({
      ...state,
      loading: true,
      withdrawalHistory: null
    }),
    getWithdrawalHistorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      withdrawalHistory: payload
    }),
    getWithdrawalHistoryFailure: (state) => ({
      ...state,
      loading: false
    }),
    cancelWithdrawRequestStart: (state) => ({
      ...state,
      loading: true
    }),
    cancelWithdrawRequestComplete: (state) => ({
      ...state,
      loading: false
    }),
    getUserTransactionsStart: (state) => ({
      ...state,
      txLoading: true
    }),
    getUserTransactionsSuccess: (state, { payload }) => ({
      ...state,
      txLoading: false,
      userTransactions: payload
    }),
    getUserTransactionsFailure: (state) => ({
      ...state,
      txLoading: false
    })
  }
})

export default reducer

export {
  getUserBetHistoryStart,
  getUserBetHistorySuccess,
  getUserBetHistoryFailure,
  getUserIdAndSessionIdStart,
  getUserIdAndSessionIdSuccess,
  getUserIdAndSessionIdFailure,
  clearUserIdAndSessionId,
  getWithdrawalHistoryStart,
  getWithdrawalHistorySuccess,
  getWithdrawalHistoryFailure,
  cancelWithdrawRequestStart,
  cancelWithdrawRequestComplete,
  getUserTransactionsStart,
  getUserTransactionsSuccess,
  getUserTransactionsFailure
}
