import React from 'react'
import './styles/index.scss'
import useCashbackBonus from './hooks/useCashbackBonus'
import Banner from '../../components/Banner'

const CashbackBonus = () => {
  const {
    cashbackTable,
    languageData,
    showMoreLevels,
    setShowMoreLevels,
    CASHBACK_DATA
  } = useCashbackBonus()

  return (
    <>
      <section className='wrapper-content'>
        <Banner pageType='cashback' anchorTagFilterType='cashbackBannerBtn' />
        <section className='reward-section container'>
          <div className='inner-heading cashback'>
            <h2 className='text-uppercase'>
              <span className='text-data-underline'>{languageData?.cashbackHeadingOne}</span>
            </h2>
            <div className='mt-5'>
              <div className='reward-card text-start'>
                <p className='pera-text'>{languageData?.cashbackHeadingOneDesc} </p>
              </div>
            </div>
            {CASHBACK_DATA.map((el) => (
              <div className='mt-5' key={el.imgSlug}>
                <div className='reward-card '>
                  <div className='reward-icon mb-4 text-center'>
                    <img loading='lazy' src={`${process.env.REACT_APP_STATIC_IMAGE}/${el.imgSlug}`} alt='Reward Icon' />
                  </div>
                  <h2 className='container text-uppercase text-center'>
                    <span className='text-data-underline'>
                      {el.headingText}
                    </span>
                  </h2>
                  <p className='pera-text mt-5'>{el.descText}. </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>

      <section className='testimonial-wrapper'>
        <div className='testomonal-section lolayty-text'>
          {/* <div className='wrapper'> */}
          <div className='container-fluid' style={{ maxWidth: '1400px' }}>
            <div className='row d-md-flex align-items-start'>
              <div className='col-lg-6'>
                <div className='testomonal-left d-md-flex align-items-lg-start align-items-sm-center'>
                  <div className='cashback-img mt-lg-0 pt-lg-0 mt-sm-5 pt-sm-5 pr-2'>
                    <img className='inner-image' loading='lazy' src={`${process.env.REACT_APP_STATIC_IMAGE}/cashback-leval.png`} alt='Heart' />
                  </div>
                  <div className='promotion-testimonials-heading'>
                    <h2 className='testimonals-title-text m-0 pl-2'>{languageData?.cashbackTestimonialHeading}</h2>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <p className='promotion-testimonials-text'>{languageData?.cashbackTestimonialDesc}</p>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className='cashback-img'>
          <img loading='lazy' className='outside-img' src={`${process.env.REACT_APP_STATIC_IMAGE}/cashback-leval.png`} alt='Heart' />
        </div>
      </section>
      <section className='wrapper-content bonuspage'>
        <section className='table-section progression-section-cashback bonuspage'>
          <div className='container'>
            <div className='reward-card text-start'>
              <p className='pera-text'>{languageData?.cashbackTableDesc} </p>
            </div>
            {cashbackTable &&
              <div className='table-wrap mt-5 ml-lg-3 mr-lg-3'>
                <div className='table-heading'>
                  <h4 className='text-center m-0'>{languageData?.cashbackTableHeading}</h4>
                </div>
                <div className='theme-card new-design'>
                  <div className='table-responsive'>
                    <table className='table loyalty-table'>
                      <thead>
                        <tr>
                          <th scope='col' className='text-center text-capitalize'>{languageData?.cashbackTableHeaderOne}</th>
                          <th scope='col' className='text-center'>{languageData?.cashbackTableHeaderThree}</th>
                          <th scope='col' className='text-center'>{languageData?.cashbackTableHeaderTwo}</th>
                          {languageData?.cashbackTableHeaderFour && <th scope='col' className='text-center'>{languageData?.loyaltyCashback} {languageData?.cashbackTableHeaderFour}</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {cashbackTable?.map((item, i) => {
                          return (i < 5 || showMoreLevels) &&
                            (
                              <tr key={`loyalty-level-listing ${i}`}>
                                <td className='text-center'>{item?.level}</td>
                                <td className='text-center'>{item?.cashback_multiplier ? `${item.cashback_multiplier} X` : 'N/A'}</td>
                                <td className='text-center'>{item?.bonusPercentage === 0 ? 'N/A' : `${Math.floor(item?.bonusPercentage * 100)} %`} </td>
                                {languageData?.cashbackTableHeaderFour && <td className='text-center'>NA</td>}
                              </tr>
                            )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {cashbackTable?.length > 5 &&
                    <div className='table-btn-wrap text-center'>
                      <a
                        href={() => false} className='btn table-btn text-uppercase'
                        onClick={(e) => {
                          e?.preventDefault()
                          setShowMoreLevels(prev => !showMoreLevels)
                        }}
                      >
                        {showMoreLevels ? languageData?.showLess : languageData?.promReadMore}
                      </a>
                    </div>}
                </div>
              </div>}
            <div className='mt-5'>
              <div className='reward-card text-start'>
                <p className='pera-text'>{languageData?.cashbackFooterDesc} </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default CashbackBonus
