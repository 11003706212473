import { PrimaryButton } from 'components/Common/Button'
import React from 'react'
import Loader from 'components/Loader'

const RestrictWithdraw = (props) => {
  if (props?.loading) {
    return (
      <Loader />
    )
  }

  return (
    <>
      <div className='user-account-block'>
        <div className='payment-methods-tab'>
          <div className='d-flex accounts-btn justify-content-center align-items-center flex-sm-row flex-column w-100'>
            <p className='info-text-bonus'>
              <i className='fa-solid fa-credit-card mr-2' /> {`${props.languageData?.warning || 'Warning!'}`}
            </p>
          </div>
          <div className='p-4'>
            <div className='d-flex justify-content-center align-items-center mb-4'>
              <i className='fa-solid fa-siren-on fa-shake fa-5x text-danger' />
            </div>
            <div className='mb-4 text-white d-flex'>
              <p>
                {props.languageData?.activeBonusWarning || 'You have active bonuses, If you proceed, Your all the active bonuses will be forfeited. Are you sure, you want to proceed with the withdrawal?'}
              </p>
            </div>
            <div>
              <div className='search-game-listing__buttons d-flex justify-content-center align-items-center gap-4'>
                <PrimaryButton
                  className='mt-4 button'
                  onClick={props.onClickForfeit}
                >
                  {props.languageData?.yesProceed || 'Yes, Proceed'}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RestrictWithdraw
