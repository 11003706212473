import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import { languageCodeList } from '../../../constants/languageList'
import CustomDropdownToggle from '../../custom/CustomDropdownToggle'

const LanguageField = ({ formik, contextValue, disabled = false }) => {
  const { languages, languageData } = useContext(contextValue)
  const { values, setFieldValue, validateField } = formik
  return (
    <Dropdown
      className={`icon-right small-height ${disabled ? 'pe-none' : ''}`}
      id='preferredLanguage'
    >
      <Dropdown.Toggle id='dropdown-basic2' as={CustomDropdownToggle}>
        <span>
          {languageCodeList?.[values?.preferredLanguage] ||
            languageData?.selectPreferredLanguage}
        </span>
        <img
          src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`}
          alt='Dropdown'
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className='scrollDropdown'>
          <Dropdown.Item disabled>
            {languageData?.selectPreferredLanguage}
          </Dropdown.Item>
          {languages?.allowedLanguages?.map((lang) => (
            <Dropdown.Item
              key={lang}
              onClick={async () => {
                await setFieldValue('preferredLanguage', lang)
                validateField('preferredLanguage')
              }}
            >
              {languageCodeList[lang]}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageField
