import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import _PaymentIQCashier, { IPiqCashierConfig, IPiqCashierApiMethods } from 'paymentiq-cashier-bootstrapper'

const usePIQ = ({ loadPIQ, paymentData, userDetails, sessionId, method = 'deposit' }) => {
  const { languageCode } = useParams()

  useEffect(() => {
    if (loadPIQ && paymentData?.amount) {
      //PIQ iframe changes
      const config: IPiqCashierConfig = {
        merchantId: paymentData?.merchantId || '100506999',
        userId: userDetails?.userId,
        sessionId: sessionId,
        method,
        locale: languageCode?.toLowerCase() || 'en',
        font: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
        singlePageFlow: 'true',
        containerWidth: '100%',
        containerHeight: '100%',
        providerType: paymentData?.routeProvider || paymentData?.service,
        environment: process.env.REACT_APP_ENVIRONMENT,
        showAmount: false,
        amount: paymentData?.amount,
        fixedProviderType: true,
        country: userDetails?.countryCode,
        attributes: { hostUri: window.location.origin, bootstrapVersion: '1.3.24', ...paymentData },
        showHeader: false,
        showFooter: false,
        fetchConfig: true
      }
      userDetails?.userId && sessionId && new _PaymentIQCashier('#cashier',
        {
          ...config,
          "theme": {
            "background": {
              "color": "transparent"
            },
            "headerbackground": {
              "color": "rgba(20, 20, 50, 0.3)"
            },
            "buttons": {
              "color": "#57d08a"
            },
            "input": {
              "color": "#605f9c",
              "borderRadius": "5px"
            },
            "inputbackground": {
              "color": "#ffffff"
            },
            "cashierbackground": {
              "color": "rgba(20, 20, 50, 0.3)"
            },
            "cardbackground": {
              "color": "transparent"
            },
            "labels": {
              "fontSize": "15px",
              "color": "#fff",
            },
            "border": {
              "radius": "5px"
            }
          }
        },
        (api: IPiqCashierApiMethods) => {
          // console.log('Cashier intialized and ready to take down the empire')

          // register callbacks
          // api.on({
          // })
          // api.set({
          // })
          api.css(`
            :root {
                --border-color: 442bb7!important;
                --headings-color: #ffffff!important;
              }
            * {
                font-weight: bold;
                border: #442bb7 !important;
              }
              .input:focus {
                background: #ffffff;
              }
              #app-container {
                border-radius: 15px;
              }
              .single-page-flow-container {
                .input-label{
                  display: none;
                }
              }
              .payment-method-details {
                margin-top: 10px;
              }
            `)
        }
      )
    }
  }, [loadPIQ, paymentData])
}

export default usePIQ
