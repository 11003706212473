import React from 'react'
import useSignUp from '../hooks/useSignup'

const SignUpContext = React.createContext()

const SignUpContextProvider = ({ children }) => {
  const userSignUpData = useSignUp()
  return (
    <SignUpContext.Provider value={userSignUpData}>
      {children}
    </SignUpContext.Provider>
  )
}

export { SignUpContextProvider, SignUpContext }
