import { spawn } from 'redux-saga/effects'

import loginWatcher from './login'
import casinoMenuWatcher from './casinoMenu'
import transactionsWatcher from './transactions'
import bonusWatcher from './bonus'
import limitsWatcher from './limits'
import cmsWatcher from './cms'
import languageWatcher from './language'
import userWatcher from './user'
import paymentWatcher from './payment'
import tournamentWatcher from './tournament'
import configWatcher from './config'

export default function * rootSaga () {
  yield spawn(loginWatcher)
  yield spawn(userWatcher)
  yield spawn(casinoMenuWatcher)
  yield spawn(transactionsWatcher)
  yield spawn(bonusWatcher)
  yield spawn(limitsWatcher)
  yield spawn(cmsWatcher)
  yield spawn(languageWatcher)
  yield spawn(paymentWatcher)
  yield spawn(tournamentWatcher)
  yield spawn(configWatcher)
}
