import React from 'react'
import { useNavigate } from 'react-router-dom'
import LanguageSelect from '../LanguageSelect'
import { useSelector } from 'react-redux'
import './styles/index.scss'

const LoginHeading = ({ language, setLanguage, langKey, anotherKey }) => {
  const navigate = useNavigate()
  const { languageData } = useSelector(state => state.language)
  return (
    <div className='login-heading'>
      <button type='button' onClick={() => navigate('/')} className='close-button-login-signup'>
        <i className='fa-solid fa-xmark img-fluid d-block mx-auto' />
      </button>
      <div className='logo-text'>
        <span className='signup-title'>{languageData?.[langKey]} {anotherKey ? languageData?.[anotherKey] : ''}</span>
      </div>

      <div className='dropdown-btn'>
        <LanguageSelect language={language} setLanguage={setLanguage} login />
      </div>
    </div>
  )
}

export default LoginHeading
