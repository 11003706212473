import React from 'react'
import {
  Col,
  OverlayTrigger,
  Popover,
  ProgressBar,
  Row
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useLoyalty from './hooks/useLoyalty'
import Loader from '../../components/Loader'
import './styles/index.scss'

const Index = () => {
  const { loading, loyaltyDetails, languageData, selectedLanguageCode } =
    useLoyalty()

  const popover = (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      className='loyalty-popover'
    >
      <Popover.Body>
        {languageData?.loyaltyPopover} &nbsp;
        <Link to={`/${selectedLanguageCode?.toLowerCase()}/loyalty-bonus`}>
          {languageData?.loyaltyPage}
        </Link>
        &nbsp;.
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      <h2 className='tab-title'>{languageData?.headerLoyalty}</h2>
      {loading
        ? (
          <Loader />
          )
        : (
          <div>
            <div className='heading-container'>
              <Row>
                <Col xs={6}>
                  <div
                    className='coin-box'
                    style={{ paddingBottom: '0 !important' }}
                  >
                    <div>
                      <div className='d-flex justify-content-start align-items-center'>
                        <p
                          className='subtitle-text mb-2'
                          style={{ lineHeight: '18px' }}
                        >
                          {languageData?.loyaltyTableHeaderTwo}
                        </p>
                        <OverlayTrigger
                          trigger='focus'
                          placement='right'
                          overlay={popover}
                        >
                          <button className='loyalty-info'>i</button>
                        </OverlayTrigger>
                      </div>
                      <h6 className='title-text'>
                        {loyaltyDetails?.currentPoint &&
                        loyaltyDetails?.currentPoint?.toFixed(2)}{' '}
                        {languageData?.loyaltyPoints}
                      </h6>
                    </div>
                  </div>
                </Col>

                <Col xs={6}>
                  <div className='level-box'>
                    <p
                      className='subtitle-text mb-2'
                      style={{ lineHeight: '18px' }}
                    >
                      {languageData?.loyaltyPerCurrency}:{' '}
                      <span className='text-lowercase'>
                        x{loyaltyDetails?.pointPerUnit}
                      </span>
                    </p>
                    <div>
                      <h6 className='title-text'>
                        {languageData?.cashbackTableHeaderOne}:{' '}
                        {loyaltyDetails?.level}
                      </h6>
                      {loyaltyDetails?.maxLevel && (
                        <small
                          className='d-block text-right'
                          style={{ color: 'var(--green)' }}
                        >
                          {languageData?.maxLevelReached}
                        </small>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {loyaltyDetails?.endPoint !== 0 && !loyaltyDetails?.maxLevel && (
              <div className='progress-bar-container'>
                <div className='level-img'>
                  <img
                    loading='lazy'
                    src={`${process.env.REACT_APP_STATIC_IMAGE}/level-img.png`}
                    className='level-img'
                    alt='level-img'
                  />
                  <p className='level-number'>{loyaltyDetails?.level}</p>
                </div>

                <p className='level-number-text'>
                  {languageData?.headerLevel} {loyaltyDetails?.level}
                </p>

                {loyaltyDetails?.currentPoint !== undefined &&
                loyaltyDetails?.currentPoint !== null &&
                loyaltyDetails?.startPoint !== undefined &&
                loyaltyDetails?.startPoint !== null &&
                loyaltyDetails?.endPoint && (
                  <>
                    <div className='progress-parent'>
                      <ProgressBar
                        now={parseFloat(
                          ((loyaltyDetails?.currentPoint -
                            loyaltyDetails?.startPoint) /
                            (loyaltyDetails?.endPoint -
                              loyaltyDetails?.startPoint)) *
                            100
                        )?.toFixed(2)}
                      />
                      <div
                        className='progress-number'
                        style={{
                          left: `${parseFloat(
                            ((loyaltyDetails?.currentPoint -
                              loyaltyDetails?.startPoint) /
                              (loyaltyDetails?.endPoint -
                                loyaltyDetails?.startPoint)) *
                              100
                          )?.toFixed(2)}%`
                        }}
                      >
                        {loyaltyDetails?.level}
                      </div>
                    </div>
                    <div className='d-flex justify-content-between mt-3'>
                      <p
                        className='level-text'
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        <span className='text-white'>
                          {parseFloat(
                            ((loyaltyDetails?.currentPoint -
                              loyaltyDetails?.startPoint) /
                              (loyaltyDetails?.endPoint -
                                loyaltyDetails?.startPoint)) *
                              100
                          )?.toFixed(2)}
                          %
                        </span>{' '}
                        ({loyaltyDetails?.currentPoint?.toFixed(2)}{' '}
                        {languageData?.loyaltyPoints})
                      </p>
                      <p
                        className='level-text text-sm-right'
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        (
                        {(
                          loyaltyDetails?.endPoint -
                          loyaltyDetails?.currentPoint
                        )?.toFixed(2)}{' '}
                        {languageData?.pointsToReach}){' '}
                        <span className='text-white'>
                          {' '}
                          {languageData?.headerLevel}{' '}
                          {loyaltyDetails?.level + 1}
                        </span>
                      </p>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          )}
    </>
  )
}

export default Index
