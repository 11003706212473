import React from 'react'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import Loader from '../../components/Loader'
import PaymentForm from '../../components/PaymentForm'
import useWithdraw from './hooks/useWithdraw'
import PaymentReceipt from '../../components/Receipts/PaymentReceipt'
import NoDataAvailable from '../../components/NoDataAvailable'
import { currencyCodeList } from '../../constants/currencyCodeList'
import usePIQ from '../Deposit/hooks/usePIQ'
import IframeModal from '../Deposit/IframeModal'
import RestrictWithdraw from './RestrictWithdraw'

const Withdraw = () => {
  const {
    languageData,
    showPaymentProviders,
    showWithdrawal,
    providerData,
    // handleChangeClick,
    handleProviderClick,
    paymentProviders,
    loading,
    amt,
    setAmt,
    currencyCode,
    type,
    setType,
    handleSubmit,
    initValues,
    wallet,
    showIFrame,
    paymentData,
    showWithdrawList,
    userDetails,
    showReceipt,
    paymentStatus,
    navigate,
    fetchAmount,
    setShowReceipt,
    getWalletAmount,
    walletLoading,
    amtOptions,
    resendVerificationLink,
    remainingTime,
    isTimeOver,
    data,
    loadPIQ,
    sessionId,
    setShowIFrame,
    handleLoadPIQ,
    withdrawalFormRef,
    verifyPaymentLoading,
    utorgCurrencies,
    activeBonusLoading,
    restrictWithdraw,
    cancelBonusLoading,
    onForfeitBonus
  } = useWithdraw()

  usePIQ({ loadPIQ, paymentData, userDetails, sessionId, method: 'withdrawal' })

  const minWithdraw =
    typeof providerData?.settings?.minWithdraw === 'object'
      ? Math.max(1, providerData?.settings?.minWithdraw[currencyCode || 'EUR'] || 0)
      : Math.max(1, providerData?.settings?.minWithdraw || 0)

  let maxWithdraw = providerData?.settings?.maxWithdraw
    ? typeof providerData?.settings?.maxWithdraw === 'object'
      ? Number(providerData?.settings?.maxWithdraw[currencyCode || 'EUR'])
      : Number(providerData?.settings?.maxWithdraw) || Number(userDetails?.userWallet?.amount)
    : Number(userDetails?.userWallet?.amount)

  maxWithdraw = maxWithdraw < minWithdraw ? minWithdraw : maxWithdraw

  return (
    <>
      <Row className='withdraw-page'>
        <Col className={`tab-title-wrapper d-flex ${!showPaymentProviders
          ? 'justify-content-center'
          : 'justify-content-center'
          }`}
        >
          {!showWithdrawList && (
            <h2 className={`mb-0 text-center withdraw-header ${!showPaymentProviders && 'pos-relative'}`}>
              {languageData?.headerWithdrawFunds}
            </h2>
          )}
        </Col>
      </Row>
      {
        (userDetails?.isEmailVerified && restrictWithdraw)
          ? (
            <RestrictWithdraw
              languageData={languageData}
              loading={activeBonusLoading || cancelBonusLoading}
              onClickForfeit={onForfeitBonus}
            />
            )
          : (
            <>
              {!showWithdrawList &&
                <>
                  <div className='user-account-block'>
                    <div className='payment-methods-tab'>
                      <div className='d-flex accounts-btn justify-content-center align-items-center flex-sm-row flex-column w-100'>
                        <p className='info-text-bonus'>
                          <i className='fa-solid fa-credit-card mr-2' /> {`${languageData?.chooseWithdrawalMethod}`}
                        </p>
                      </div>
                      <div className='payment-providers-wrapper'>
                        {showPaymentProviders && loading
                          ? <Loader top='20px' bottom='20px' />
                          : ((showPaymentProviders || showWithdrawal) && !loading && userDetails?.isEmailVerified && (
                              paymentProviders && Object.keys(paymentProviders)?.length
                                ? (Object.keys(paymentProviders).map((providerType) => {
                                    return (
                                      paymentProviders?.[providerType]?.map((provider, index) => (
                                        <div
                                          className={`payment-provider ${!data?.paymentProviderId ? 'none-selected' : ''}${data?.paymentProviderId === provider?.paymentProviderId ? 'selected' : ''}`}
                                          key={index}
                                          onClick={() =>
                                            handleProviderClick(provider)}
                                        >
                                          {provider?.recommendedWithdraw &&
                                            <div className='recommended-tag-wrapper'>
                                              <div className='recommended-tag'>
                                                <i className='fa-solid fa-fire' />
                                              </div>
                                            </div>}
                                          <div className='payment-provider-content'>
                                            <OverlayTrigger
                                              overlay={(props) => (
                                                <Tooltip {...props}>
                                                  {provider?.displayName || provider?.name}
                                                </Tooltip>
                                              )}
                                              placement='bottom'
                                            >
                                              <img loading='lazy' src={provider?.settings?.withdrawImageUrl || `${process.env.REACT_APP_STATIC_IMAGE}/defaultpayment.svg`} alt='Not Found' />
                                            </OverlayTrigger>
                                          </div>
                                          <span className='category'>{provider?.category || languageData?.other}</span>
                                        </div>
                                      ))
                                    )
                                  })
                                  )
                                : (
                                  <NoDataAvailable errorMessage={languageData?.noProvidersFound} />
                                  )
                            )
                            )}
                      </div>
                    </div>
                  </div>

                  <div ref={withdrawalFormRef} className={`user-account-block default-state-fx show-deposit-form-${showWithdrawal}`}>
                    <div className='payment-tab '>
                      <div className='d-flex accounts-btn justify-content-center align-items-center flex-sm-row flex-column bonus-border-bottom w-100'>
                        <p className='info-text-bonus'>
                          <i className='fa-solid fa-money-check-pen' />&nbsp;{languageData?.withdrawalWith}
                        </p>
                      </div>

                      {showWithdrawal && (
                        <>
                          <div className='change-btn-with-logo d-flex align-items-center justify-content-center'>
                            {/* <button onClick={() => handleChangeClick()} className='change-btn'>
                      <i className='fa-solid fa-arrows-repeat' />
                      {languageData?.restart}
                    </button> */}
                            <OverlayTrigger
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {providerData?.displayName || providerData?.name}
                                </Tooltip>
                              )}
                              placement='bottom'
                            >
                              <a className='btn amount-type-btn'>
                                <img
                                  loading='lazy'
                                  src={data?.settings?.withdrawImageUrl || `${process.env.REACT_APP_STATIC_IMAGE}/defaultpayment.svg`}
                                  alt='logo'
                                  className='transaction-type w-100'
                                />
                              </a>
                            </OverlayTrigger>
                          </div>

                          <div className='balance-preview'>
                            <div className='d-flex align-items-center justify-content-center w-100 gap-2'>
                              <div className='withdraw-amount text-center d-flex flex-column'>
                                <span className='fw-light'>
                                  {languageData?.withdrawableBalance}
                                </span>
                                <span className='fw-bold'>
                                  {currencyCodeList[currencyCode || 'EUR']}{' '}
                                  {wallet?.amount || 0}
                                </span>
                              </div>
                              <div className='reload-btn' onClick={() => getWalletAmount()}>
                                <i className={`fa-solid fa-rotate cursor-pointer ${walletLoading ? 'rotate' : ''}`} />
                              </div>
                            </div>
                          </div>

                          <PaymentForm
                            initValues={initValues}
                            data={providerData}
                            currencyCode={currencyCode}
                            handleSubmit={handleSubmit}
                            amt={amt}
                            setAmt={setAmt}
                            type={type}
                            setType={setType}
                            walletAmount={userDetails?.userWallet?.amount}
                            languageData={languageData}
                            amtOptions={amtOptions}
                            minWithdraw={minWithdraw}
                            maxWithdraw={maxWithdraw}
                            utorgCurrencies={utorgCurrencies}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>}

              {/* USER VERIFICATION EMAIL */}
              {!loading && !userDetails?.isEmailVerified && !showWithdrawList && (
                <div className='user-account-email-verification'>
                  <div className='d-flex flex-sm-row justify-content-sm-start justify-content-center align-items-center text-center'>
                    <div className='w-100'>
                      <p>
                        <br />
                        {isTimeOver
                          ? (
                            <p>
                              {languageData?.pleaseVerifyFirstText} &nbsp;{' '}
                              <span onClick={resendVerificationLink}>
                                {languageData?.clickHere}
                              </span>{' '}
                              {languageData?.pleaseVerifyLastText}{' '}
                            </p>
                            )
                          : (
                            <p>
                              {languageData?.pleaseWait} &nbsp;{' '}
                              <span>
                                {remainingTime.minutes || '00'}m:{remainingTime?.seconds}s
                              </span>{' '}
                              {languageData?.toResend}
                            </p>
                            )}
                      </p>

                    </div>
                  </div>
                </div>
              )}

              {/* RESPONSE FROM VALIDATE PAYMENT */}
              {showIFrame &&
            (data?.settings?.redirection === 'cashier_iframe' && data?.aggregator === 'paymentiq'
              ? (
                <>
                  {!loadPIQ && handleLoadPIQ()}
                  <IframeModal
                    showIFrame={showIFrame}
                    setShowIFrame={setShowIFrame}
                  />
                </>
                )
              : paymentData?.redirectOutput || paymentData?.redirect_url
                ? (
                  <IframeModal
                    showIFrame={showIFrame}
                    setShowIFrame={setShowIFrame}
                    url={paymentData?.redirectOutput?.url || paymentData?.redirect_url}
                    deposit={false}
                  />
                  )
                : ((paymentData?.status >= 400 || !paymentData || paymentData?.txState === 'FAILED') && !loading && (
                  <div className='deposit-receipt user-account-block zzzz'>
                    <div className='deposit-receipt-width'>
                      <PaymentReceipt
                        paymentStatus={paymentData}
                        navigate={navigate}
                        setShow={setShowReceipt}
                        deposit={false}
                        loading={verifyPaymentLoading}
                      />
                    </div>
                  </div>
                  )
                  ))}

              {showReceipt &&
            (loading
              ? <Loader />
              : (paymentStatus?.status >= 400 || !paymentStatus || paymentStatus?.txState === 'FAILED')
                  ? (
                    <div className='deposit-receipt user-account-block'>
                      <div className='deposit-receipt-width'>
                        <PaymentReceipt
                          paymentStatus={paymentStatus}
                          navigate={navigate}
                          setShow={setShowReceipt}
                          deposit={false}
                        />
                      </div>
                    </div>
                    )
                  : (
                    <div className='deposit-receipt user-account-block zzzz'>
                      <div className='deposit-receipt-width'>
                        <PaymentReceipt
                          paymentStatus={paymentStatus}
                          navigate={navigate}
                          setShow={setShowReceipt}
                          deposit={false}
                          fetchAmount={fetchAmount}
                        />
                      </div>
                    </div>
                    )
            )}
            </>
            )
}
    </>
  )
}

export default Withdraw
