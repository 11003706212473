import React, { useState } from 'react'
import FreeSpinGameCard from './FreeSpinGameCard'
import { Modal } from 'react-bootstrap'
import Loader from '../../../../components/Loader'
import { useSelector } from 'react-redux'
const GameModal = ({ loading, showFreeSpinGameModal, games, selectedBonusDetail, activateBonus, setShowFreeSpinGameModal }) => {
  const [identifier, setIdentifier] = useState(true)
  const { languageData } = useSelector((state) => state.language)
  return (
    <Modal show={showFreeSpinGameModal} className='free-spin-modal-container' size='lg'>
      <Modal.Header>
        <Modal.Title>{languageData?.availFreeSpinHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body className=''>
        {
        !loading
          ? (
            <div className='games-container'>
              {games && games.length > 0
                ? (
                    games.map((game, index) => (
                      <FreeSpinGameCard
                        key={index}
                        game={game}
                        identifier={identifier}
                        setIdentifier={setIdentifier}
                      />
                    ))
                  )
                : (
                  <span className='text-danger ml-5'>
                    {languageData?.casinoNoGamesFound}
                  </span>
                  )}
            </div>
            )
          : (
            <Loader top='10%' />
            )
      }

      </Modal.Body>
      <Modal.Footer className='justify-content-end '>
        <button
          className='btn-modal font-16' onClick={() => {
            setShowFreeSpinGameModal(false)
          }}
        >{languageData?.cancel}
        </button>
        <button className='btn-modal font-16' disabled={!identifier.length > 0} onClick={() => identifier.length > 0 && activateBonus(selectedBonusDetail, identifier)}>
          {languageData?.bonusActivate}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default GameModal
