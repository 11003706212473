import React from 'react'
import FormikControl from '../FormikControl'
import CustomError from './CustomError'
import CustomLabel from './CustomLabel'
import { ErrorMessage } from 'formik'
const FormFieldControl = ({
  type,
  name,
  label,
  control,
  fieldClassName,
  placeholder,
  defaultLabel,
  children,
  containerClassName,
  disabled,
  showStar,
  regFields,
  extraLabel = '',
  showLabel = true
}) => {
  return (
    <>
      {(regFields ? regFields[name] !== 0 : true) && (
        <div className={containerClassName}>
          {showLabel &&
            <CustomLabel
              name={name}
              label={label}
              defaultLabel={defaultLabel}
              regFields={regFields}
              showStar={showStar}
              className='custom-label'
              extraLabel={extraLabel}
            />}

          {/* field */}
          {children || (
            <FormikControl
              type={type}
              control={control}
              name={name}
              className={fieldClassName}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}

          <ErrorMessage name={name} component={CustomError} />
        </div>
      )}
    </>
  )
}

export default FormFieldControl
