import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { validateDepositSchema } from './schema'
import Icon from '../Icon'
import { getItem } from '../../utils/storageUtils'
import { currencyCodeList } from '../../constants/currencyCodeList'
import HtmlParser from 'react-html-parser'
import { useSelector } from 'react-redux'

const PaymentForm = ({
  initValues,
  data,
  currencyCode,
  handleSubmit,
  amt,
  setAmt,
  type,
  setType,
  selectedBonus,
  walletAmount,
  deposit = false,
  languageData,
  amtOptions,
  minWithdraw = '',
  maxWithdraw = '',
  identifier,
  utorgCurrencies
}) => {
  const { selectedLanguageCode } = useSelector(state => state.language)
  const showAmount = data?.settings?.requiredFields?.find(({ name }) => name === 'amount')?.name

  let minDeposit = deposit
    ? (data?.settings?.minDeposit
        ? (selectedBonus && Object.keys(selectedBonus)?.length
            ? Math.max(Number(typeof (data?.settings?.minDeposit) === 'object' ? data?.settings?.minDeposit[currencyCode || 'EUR'] : data?.settings?.minDeposit), Number(selectedBonus?.bonus?.currency?.[currencyCode || 'EUR']?.minDeposit) || 1)
            : Number(typeof (data?.settings?.minDeposit) === 'object' ? data?.settings?.minDeposit[currencyCode || 'EUR'] : data.settings.minDeposit))
        : (selectedBonus && Object.keys(selectedBonus)?.length
            ? Math.max(1, Number(selectedBonus?.bonus?.currency?.[currencyCode]?.minDeposit || 0))
            : 1))
    : typeof (data?.settings?.minWithdraw) === 'object' ? Number(data?.settings?.minWithdraw[currencyCode || 'EUR']) : Number(data?.settings?.minWithdraw) || Number(walletAmount)

  const maxDeposit = deposit
    ? (Number(typeof (data.settings.maxDeposit) === 'object' ? Number(data.settings.maxDeposit?.[currencyCode || 'EUR']) : Number(data.settings.maxDeposit)) || 100000)
    : typeof (data?.settings?.maxWithdraw) === 'object' ? Number(data?.settings?.maxWithdraw?.[currencyCode || 'EUR']) : Number(data?.settings?.maxWithdraw) || Number(walletAmount)

  minDeposit = deposit ? minDeposit : Math.min(maxDeposit, minDeposit)

  document.addEventListener('wheel', function (event) {
    if (document?.activeElement?.type === 'number' &&
      document?.activeElement?.classList?.contains('noscroll')) {
      document?.activeElement?.blur()
    }
  })

  const ErrorRender = ({ name }) => {
    return (
      <div
        style={{
          minHeight: '18px',
          position: 'relative'
        }}
      >
        <ErrorMessage
          component='div'
          name={name}
          className='error-message'
        />
      </div>
    )
  }

  const getFreeSpinsQty = (selectedBonus, currencyCode, identifier) => {
    if (selectedBonus?.bonusType === 'freespins' && selectedBonus?.bonus?.other?.aggregatorId === '2') {
      const selectedBonusDetail = selectedBonus?.bonus?.other?.hub88PrepaidDetails?.filter(
        (bonusDetail) => bonusDetail.currency === currencyCode && bonusDetail.game_code === identifier
      )

      // Check if selectedBonusDetail exists and has at least one element
      return selectedBonusDetail?.[0]?.bet_count ?? 0
    }

    // If conditions are not met, return freeSpinsQty
    return selectedBonus?.freeSpinsQty ?? 0
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ ...initValues }}
        validationSchema={
          validateDepositSchema({
            initValues,
            data,
            minDeposit: deposit ? minDeposit : minWithdraw,
            maxDeposit: deposit ? maxDeposit : maxWithdraw,
            currencyCode,
            type: deposit ? 'deposit' : 'withdraw',
            selectedBonus: deposit ? selectedBonus : walletAmount,
            languageData,
            walletAmount
          })
        }
        onSubmit={(formValues) => {
          handleSubmit({ ...formValues, amount: parseFloat(formValues.amount) })
          // window.scrollTo(0, 0)
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, errors, touched }) => (
          <Form>

            {(showAmount
              ? (
                <p className='min-max-amt'>
                  {data?.settings?.description?.[selectedLanguageCode] ? HtmlParser(data?.settings?.description?.[selectedLanguageCode]) : ''}
                </p>)
              : (
                <p className='min-max-amt'>
                  {data?.settings?.description?.[selectedLanguageCode] ? HtmlParser(data?.settings?.description?.[selectedLanguageCode]) : data?.settings?.description?.EN ? HtmlParser(data?.settings?.description?.EN) : ''}
                </p>
                )
              )}
            {showAmount && (
              <>
                <div className='deposit-amt-text'>
                  <p>
                    {deposit ? languageData?.depositAmount : languageData?.withdrawAmount}
                  </p>
                  <span>
                    {deposit ? languageData?.minDeposit : languageData?.minWithdrawal} {currencyCodeList[currencyCode || 'EUR']} {minDeposit} | {deposit ? languageData?.maxDeposit : languageData?.maxWithdrawal} {currencyCodeList[currencyCode || 'EUR']} {maxDeposit}
                  </span>
                </div>

                <div className='amout-show-btn'>
                  <input
                    name='amount'
                    type='number'
                    min={0}
                    step='any'
                    disabled={data?.name === 'Siru'}
                    className='noscroll deposit-amount-input'
                    placeholder={deposit ? languageData?.enterDepositAmount : languageData?.enterWithdrawAmount}
                    value={values.amount || ''}
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
            <div className='deposit-amt-btn-group'>
              {amtOptions && showAmount && Object.keys(amtOptions).map(amount =>
                (data?.name === 'Siru' ? amtOptions[amount] !== 'Other' : true) && ![0, '0'].includes(amtOptions[amount]) &&
                  <div className='btn-group-wrapper' key={amount}>
                    <button
                      type='button'
                      className={amt === amtOptions[amount] ? 'other-amount' : ''}
                      onClick={() => {
                        setAmt(amtOptions[amount])
                        setFieldValue('amount', amtOptions[amount] !== 'Other' ? amtOptions[amount] : '')
                      }}
                    >
                      {amtOptions[amount] === 'Other' ? languageData[amtOptions[amount]] : `${currencyCodeList[currencyCode || 'EUR']} ${amtOptions[amount]}`}
                    </button>
                  </div>
              )}
            </div>
            {errors?.amount && touched?.amount ? <ErrorRender name='amount' /> : null}
            {data?.settings?.requiredFields?.map(({ name, values: selectValue, dataType }, indx) => {
              let options = []
              if (selectValue) {
                options = selectValue
              }
              if (name === 'currency' && data?.aggregator === 'utorg') {
                options = utorgCurrencies
              }

              return name !== 'amount' && initValues[name] !== undefined && (
                <div key={indx}>
                  <div className='deposit-amt-text'>
                    <p>
                      {name === 'expirymmyyyy'
                        ? languageData?.capExpiry
                        : languageData?.[name] || name.split(/(?=[A-Z])/).filter(str => str !== 'enc').map(str => str.toUpperCase()).join(' ')}
                    </p>
                  </div>
                  {(selectValue || name === 'currency')
                    ? (
                  // If values are there means its must be select
                      <div className='amout-show-btn'>
                        <select
                          name={name}
                          value={values?.[name] || ''}
                          onChange={handleChange}
                        >
                          <option key='' value='' disabled>{languageData?.['select' + name?.charAt(0).toUpperCase() + name?.slice(1)] || `${languageData?.select} ${name?.charAt(0).toUpperCase()}${name?.slice(1)}`}</option>
                          {(typeof options === 'string' ? JSON.parse(options) : options).map((val) => {
                            return (
                              val?.value
                                ? <option key={val.value} value={val.value}>{val.name}</option>
                                : <option key={val} value={val}>{val}</option>
                            )
                          }
                          )}
                        </select>
                        {errors?.[name] && touched?.[name] ? <ErrorRender name={name} /> : null}
                      </div>
                      )
                    : (name?.length > 8 && (name.substr(name?.length - 8) === 'Password'))
                        ? (
                      // For Password
                          <div className='amout-show-btn'>
                            <div className='d-flex'>
                              <input
                                name={name}
                                type={type}
                                placeholder='*******'
                                value={values?.[name] || ''}
                                onChange={handleChange}
                              />
                              <span
                                style={{ marginLeft: '-30px', cursor: 'pointer' }}
                                onClick={() => setType(type === 'password' ? 'text' : 'password')}
                              >
                                {type === 'password'
                                  ? <Icon name='eye-password-visible' />
                                  : <Icon name='eye-password-invisible' />}
                              </span>
                            </div>
                            {errors?.[name] && touched?.[name] ? <ErrorRender name={name} /> : null}
                          </div>
                          )
                        : (dataType === 'date'
                            ? (
                          // For Expiry
                              <div className='amout-show-btn'>
                                <input
                                  name={name}
                                  type='text'
                                  placeholder='MM/YYYY'
                                  value={values?.expirymmyyyy || ''}
                                  onChange={(e) => {
                                    let expiry = e.target.value
                                    const checkNumber = e.target.value
                                    if (!isNaN(checkNumber?.replace(/[/]/, '')) && expiry.length < 8) {
                                      if (expiry.length > 2 && !expiry?.includes('/')) {
                                        const firstTwoDigits = expiry.substring(0, 2)
                                        const remainingDigits = expiry.substring(2)?.replaceAll('/', '')
                                        expiry = `${firstTwoDigits}/${remainingDigits}`
                                      }
                                      setFieldValue('expirymmyyyy', expiry)
                                    }
                                  }}
                                />
                                {errors?.[name] && touched?.[name] ? <ErrorRender name={name} /> : null}
                              </div>
                              )
                            : (
                          // For TextField
                              <div className='amout-show-btn'>
                                <input
                                  name={name}
                                  type={(['string', 'text'].includes(dataType) || name === 'encCreditcardNumber')
                                    ? 'text'
                                    : name === 'encCvv'
                                      ? 'password'
                                      : 'number'}
                                  placeholder={languageData?.[`${name}Placeholder`] || `${languageData?.loginEnter} ${name.split(/(?=[A-Z])/).filter(str => str !== 'enc').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')}`}
                                  value={values?.[name] || ''}
                                  onChange={(e) => {
                                    if (name === 'encCvv') {
                                      if (e.target.value?.length < 5 && !isNaN(e.target.value)) {
                                        handleChange(e)
                                      }
                                    } else if (name === 'encCreditcardNumber') {
                                      const ccNumber = e.target.value
                                      const checkNumber = e.target.value
                                      if (e.target.value?.length < 20 && !isNaN(checkNumber.replaceAll(/ /g, ''))) {
                                        setFieldValue('encCreditcardNumber', ccNumber)
                                      }
                                    } else {
                                      handleChange(e)
                                    }
                                  }}
                                />
                                {errors?.[name] && touched?.[name] ? <ErrorRender name={name} /> : null}
                              </div>
                              )
                          )}
                </div>
              )
            })}

            <div className='make-deposit-btn text-center w-100'>
              {deposit && selectedBonus?.bonus &&
                <div className='deposit-amt-text mt-4'>
                  <p>
                    {languageData?.bonusSelected}: {selectedBonus?.bonus?.promotionTitle?.[getItem('language')?.toUpperCase()] || selectedBonus?.bonus?.promotionTitle?.EN || selectedBonus?.bonus?.promotionTitle}
                  </p>
                </div>}
              <button
                type='submit'
                className={`${deposit && selectedBonus?.bonus ? '' : 'mt-4'}`}
                onClick={handleSubmit}
              >
                {deposit ? languageData?.[!showAmount ? 'headerDeposit' : 'headerDepositOf'] : languageData?.[!showAmount ? 'withdrawal' : 'withdrawalOf']} {showAmount ? currencyCodeList[currencyCode || 'EUR'] : ''} {showAmount ? values.amount || 0 : ''}
                {deposit && values?.amount && selectedBonus?.bonus?.currency?.[currencyCode]?.maxBonusThreshold &&
                  selectedBonus?.bonus?.depositBonusPercent &&
                  ` + ${!selectedBonus?.freeSpinsQty ? currencyCodeList[currencyCode || 'EUR'] : ''} ${selectedBonus?.freeSpinsQty ? getFreeSpinsQty(selectedBonus, currencyCode, identifier) : Math.min(((values?.amount * selectedBonus?.bonus?.depositBonusPercent) / 100)?.toFixed(2), selectedBonus?.bonus?.currency?.[currencyCode]?.maxBonusThreshold)} ${selectedBonus?.freeSpinsQty ? 'Freespin(s)' : 'Bonus'}`}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default React.memo(PaymentForm)
