import React from 'react'
import '../styles/index.scss'
import { formatPriceWithCommas } from '../../../utils/helpers'

const LoyaltyCard = ({ loyaltyLevelDetail, languageData }) => {
  return (
    <div className='loyalty-level-card'>
      <div className='loyalty-image-container'>
        <div className='loyalty-image'>
          <img src={languageData?.LoyaltyCardURL} alt='Loyalty Card' />
        </div>
        <span className='loyalty-image-label'>{loyaltyLevelDetail.level}</span>
      </div>

      <div className='loyalty-info-container'>
        <div className='loyalty-card-details'>
          {loyaltyLevelDetail?.freespinQuantity &&
            <>
              <div className='loyalty-reward-item'>
                <p className='loyalty-reward-value'>{Math.floor(loyaltyLevelDetail?.freespinQuantity)}</p>
                <p className='loyalty-reward-label'>{languageData?.freeSpins}</p>
              </div>
              <div className='loyalty-card-separator-add'>+</div>
            </>}
          <div className='loyalty-reward-item'>
            <p className='loyalty-reward-value'>{Math.floor(loyaltyLevelDetail?.cashback_multiplier * 100)}%</p>
            <p className='loyalty-reward-label'>{languageData?.headerCashback}</p>
          </div>
        </div>

        <div className='section-line' />
        <div className='points-info'>
          {languageData?.from} {formatPriceWithCommas(loyaltyLevelDetail?.startPoint)} {languageData?.pts}
        </div>
      </div>
    </div>
  )
}

export default LoyaltyCard
