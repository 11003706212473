import React from 'react'
import Slider from 'react-slick'
import HtmlParser from 'react-html-parser'
import useCasinoMenuList from './../../pages/HomePage/hooks/useCasinoMenuList'
import { currencyCodeList } from '../../constants/currencyCodeList'
import './styles/index.scss'
import TopPerformanceCard from './TopPerformanceCard'
import { getGameUrl } from '../../utils/helpers'

const TopPerformance = () => {
  const {
    topGames,
    navigate,
    topWinners,
    getMaskCount,
    languageData,
    currentWinners,
    selectedLanguageCode
  } = useCasinoMenuList()

  const winnersSettings = {
    speed: 3000,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true
  }

  return (
    <>
      <div className='top-performance-wrapper'>
        <div className='wrapper-block'>
          <div className='winner-card-content'>
            <h4 className='text-center mb-3' title={languageData?.homeCurrentWinners}><i className='fa-solid fa-star-sharp' /> &nbsp;&nbsp;{languageData?.homeCurrentWinners && HtmlParser(languageData?.homeCurrentWinners)}</h4>
            <div className='auto-scroll-wrapper'>
              {currentWinners?.length > 0 &&
                <Slider {...winnersSettings}>
                  {currentWinners?.map((item, i) => {
                    return (
                      <TopPerformanceCard
                        key={`top-performance__current-winners__${i}`}
                        type='current-winners'
                        index={i}
                        imageUrl={item?.thumbnailUrl}
                        title={`${item?.firstName} ${item?.lastName ? item?.lastName?.charAt(0) : null}`}
                        subtitle={item?.gameName}
                        infoText={`Win: ${currencyCodeList?.[item?.currencyCode]} ${item?.win}`}
                        onClick={() => {
                          navigate(getGameUrl(selectedLanguageCode?.toLowerCase(), item?.identifier))
                        }}
                      />
                    )
                  })}
                </Slider>}
              {(currentWinners?.length === 0 || currentWinners === undefined) && <h4 className='text-center'>{languageData?.noDataFound}</h4>}
            </div>
          </div>
        </div>

        <div className='wrapper-block flex-grow-2'>
          <div className='winner-card-content'>
            <h4 className='text-center mb-3' title={languageData?.homeTopWinners}><i className='fa-solid fa-star-sharp' />&nbsp;&nbsp;{languageData?.homeTopWinners && HtmlParser(languageData?.homeTopWinners)}</h4>
            <div className='winner-listing-wrap'>
              {topWinners?.map((item, i) => {
                const imageUrls = [
                  languageData?.topWinnersRank1,
                  languageData?.topWinnersRank2,
                  languageData?.topWinnersRank3
                ]
                return (
                  <TopPerformanceCard
                    key={`top-performance__top-winners__${i}`}
                    type='top-winners'
                    index={i}
                    imageUrl={imageUrls[i]}
                    title={`${item?.username.slice(0, -getMaskCount(item?.username)) + '*'.repeat(getMaskCount(item?.username))}`}
                    subtitle={item?.gameName}
                    infoText={`Win: ${currencyCodeList?.[item?.currencyCode]} ${item?.amount}`}
                  />
                )
              })}
              {(topWinners?.length === 0 || topWinners === undefined) && <h4 className='text-center'>{languageData?.noDataFound}</h4>}
            </div>
          </div>
        </div>

        <div className='wrapper-block'>
          <div className='winner-card-content'>
            <h4 className='text-center mb-3' title={languageData?.homeTopGames}><i className='fa-solid fa-star-sharp' />&nbsp;&nbsp;{languageData?.homeTopGames && HtmlParser(languageData?.homeTopGames)}</h4>
            <div className=''>
              {topGames?.map((item, i) => {
                return i < 3 && item.payout !== 'Infinity %' && (
                  <TopPerformanceCard
                    key={`top-performance__top-games__${item?.identifier}__${i}`}
                    type='top-games'
                    index={i}
                    imageUrl={item?.thumbnailUrl}
                    title={item?.gameName}
                    subtitle={item?.provider}
                    infoText={`${languageData?.payout || ''}: ${item?.payout}`}
                    onClick={() => {
                      navigate(getGameUrl(selectedLanguageCode?.toLowerCase(), item?.identifier))
                    }}
                  />
                )
              }
              )}
              {(topGames?.length === 0 || topGames === undefined) && <h4 className='text-center'>{languageData?.noDataFound}</h4>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopPerformance
