export const anchorTagFilter = (anchorString) => {
  let value = ''
  let href = ''
  let isUsed = false
  if (anchorString && anchorString.includes('</a>')) {
    href = anchorString.split('>')[0].split('href=')[1].replaceAll('"', '').replaceAll("'", '')
    value = anchorString.split('>')[1].replaceAll('</a', '')
    isUsed = true
  } else {
    value = anchorString
  }

  return { href, value, isUsed }
}
