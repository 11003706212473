import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const withModal = (WrappedComponent) => {
  return ({
    showModal,
    handleSubmit,
    handleClose,
    buttonText = 'Open Modal',
    buttonClass = '',
    mainBodyClass = '',
    headerClass = '',
    headerTitleClass = '',
    modalTitle = 'Modal Title',
    bodyClass = '',
    submitBtnClass = '',
    submitBtnText = 'Submit',
    closeBtnClass = '',
    closeBtnText = 'Close',
    footerClass = '',
    position = 'center',
    showHeader = true,
    showSubmitBtn = true,
    showFooter = true,
    showTitle = true,
    backdrop = false,
    size = '',
    ...props
  }) => {
    const { languageData } = useSelector((state) => state.language)
    // Fallback value if modalTitle is not available in languageData
    const translatedTitle = languageData[modalTitle] || modalTitle

    return (
      <>
        {showModal && (
          <Modal
            size={size}
            show={showModal}
            onHide={handleClose}
            backdrop={backdrop}
            centered
            className={mainBodyClass}
          >
            {showHeader && (
              <Modal.Header className={headerClass}>
                {showTitle
                  ? (
                    <Modal.Title className={headerTitleClass}>
                      {translatedTitle}
                    </Modal.Title>
                    )
                  : (
                      translatedTitle
                    )}
                {modalTitle === 'areYouSure' && ' ?'}
              </Modal.Header>
            )}

            <Modal.Body className={bodyClass}>
              <WrappedComponent {...props} />
            </Modal.Body>
            {showFooter && (
              <Modal.Footer className={footerClass}>
                <button className={closeBtnClass} onClick={handleClose}>
                  {languageData[closeBtnText] || closeBtnText}
                </button>

                {showSubmitBtn && (
                  <button className={submitBtnClass} onClick={handleSubmit}>
                    {languageData[submitBtnText] || submitBtnText}
                  </button>
                )}
              </Modal.Footer>
            )}
          </Modal>
        )}
      </>
    )
  }
}

export default withModal
