import getBrowserFingerprint from 'get-browser-fingerprint'
import { addBrowserId } from './apiCalls'
import { getItem, setItem } from './storageUtils'

export const browserFingerPrint = () => {
  const fingerprint = getBrowserFingerprint()
  const encodedValue = encodeURIComponent(`浏览器 ${fingerprint} ?`)
  if (!getItem('browser-key')) {
    setItem('browser-key', encodedValue)
    const data = {
      uniqueKey: encodedValue
    }
    addBrowserId(data)
  }
  return encodedValue
}
