import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import './styles/index.scss'
import PhoneInput from 'react-phone-input-2'
import { Modal, Row, Form as BForm } from 'react-bootstrap'
import OTPInput, { ResendOTP } from 'otp-input-react'
import { useDispatch, useSelector } from 'react-redux'
import { logoutStart } from '../../store/redux-slices/login'
import { changePhoneNumberStart, resendPhoneOTPStart, submitOTPStart } from '../../store/redux-slices/user'
import { toast } from '../Toast'
import { useNavigate } from 'react-router-dom'

export const CancelModal = ({ show, setShow, handleYes }) => {
  const { languageData } = useSelector((state) => state.language)
  return (
    <Modal
      show={show} onHide={() => setShow(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='generic-modal-layout'
      data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-hidden='true'
    >
      <div className='topdiv mx-auto'>
        <Modal.Header className='d-flex'>
          <Modal.Title>{languageData?.areYouSure} ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>{languageData?.cancelWithdrawRequest} ?</Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <button className='btn-modal' onClick={() => setShow(false)}>{languageData?.no}</button>
          <button
            className='btn-modal' onClick={handleYes}
          >{languageData?.yes}
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export const PhoneVerification = ({ show, setShow }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [OTP, setOTP] = useState('')
  const [isPassValid, setIsPassValid] = useState('')
  const [phoneDisable, setPhoneDisable] = useState(true)
  const [timerComplete, setTimerComplete] = useState(false)
  const { userDetails } = useSelector(state => state.user)
  const { languageData } = useSelector(state => state.language)

  const handleResendCodeClick = () => {
    dispatch(resendPhoneOTPStart())
    setTimerComplete(false)
  }

  const handleTimerComplete = () => {
    setTimerComplete(true)
  }

  const EditIcon = () => timerComplete && (
    <i
      className={`fa-solid fa-${!phoneDisable ? 'circle-arrow-left' : 'edit'} cursor-pointer img-fluid ml-3 d-block fs-5`}
      onClick={() => {
        setOTP('')
        !phoneDisable && setTimerComplete(false)
        setPhoneDisable(!phoneDisable)
      }}
    />
  )

  return (
    <Modal
      show={show}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='generic-modal-layout'
      data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-hidden='true'
    >
      <div className='topdiv'>
        <Modal.Header className='d-flex justify-content-center'>
          <Modal.Title>{languageData?.phoneVerification}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ phone: userDetails?.phone, phoneCode: userDetails?.phoneCode }}
          onSubmit={(formValues) => {
            if (userDetails?.phone === formValues?.phone && userDetails?.phoneCode === formValues?.phoneCode) {
              toast('Same Phone Number', 'error')
            } else {
              dispatch(changePhoneNumberStart({ data: formValues, setPhoneDisable }))
            }
          }}
        >
          {({ values, touched, handleSubmit, setFieldValue }) => (
            <Form className='login-form w-100'>
              <Modal.Body>
                <BForm.Label className='mb-3 fs-5'>{languageData?.phoneVerificationText}</BForm.Label>

                <div>
                  {!phoneDisable
                    ? (
                      <>
                        <div className='d-flex align-items-center'>
                          <PhoneInput
                            type='text'
                            name='phone'
                            disabled={phoneDisable}
                            value={values?.phoneCode + values?.phone}
                            containerClass='phoneInputContainer'
                            inputClass='phoneInputClass'
                            aria-describedby='emailHelp'
                            alwaysDefaultMask={false}
                            enableSearch
                            placeholder={languageData?.phonePlaceholder}
                            enableLongNumbers
                            country={userDetails?.countryCode?.toLowerCase() || 'us'}
                            onChange={(phone, code) => {
                              code = String(code?.dialCode)
                              const newPhone = phone.substring(code.length)
                              let isPhoneNotValid = true
                              const newCode = '+' + code
                              setFieldValue('phone', newPhone)
                              setFieldValue('phoneCode', newCode)
                              if (!(newPhone?.length > 15 || newPhone?.length < 5)) {
                                isPhoneNotValid = false
                              }
                              if (newPhone?.charAt(0) === '0' || isPhoneNotValid) {
                                setIsPassValid(false)
                              } else {
                                setIsPassValid(true)
                              }
                            }}
                            buttonStyle={{ backgroundColor: '#22214b' }}
                            inputStyle={{ width: '100%' }}
                          />

                          <EditIcon />
                        </div>
                        {touched && values?.phone
                          ? (!isPassValid
                              ? <p className='text-danger'>{languageData?.enterValidPhone}</p>
                              : null)
                          : touched && <p className='text-danger'>{languageData?.phoneRequired}</p>}
                      </>
                      )
                    : (
                      <div className='d-flex align-items-center fs-6'>
                        Phone:  {values?.phoneCode + ' ' + values?.phone}
                        <EditIcon />
                      </div>
                      )}
                </div>

                {phoneDisable &&
                  <Row className='mt-3'>
                    <OTPInput
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={6}
                      otpType='number'
                      disabled={false}
                      inputClassName='form-control custom-input'
                    />
                    <ResendOTP
                      className='mt-2'
                      renderButton={(buttonProps) => {
                        return <button {...buttonProps}>{languageData?.resend} OTP</button>
                      }}
                      onResendClick={handleResendCodeClick}
                      onTimerComplete={handleTimerComplete}
                      maxTime={60}
                    />
                  </Row>}

              </Modal.Body>

              <Modal.Footer className='d-flex justify-content-between mt-2'>
                <button
                  className='btn-modal min-w-fit'
                  onClick={() => {
                    dispatch(logoutStart({ navigate }))
                    setShow(false)
                  }}
                >{languageData?.headerLogout}
                </button>

                {(!phoneDisable ? true : OTP?.length === 6) &&
                  <button
                    type='button'
                    className='btn-modal'
                    onClick={() => {
                      if (!phoneDisable) handleSubmit()
                      else dispatch(submitOTPStart({ data: { otp: OTP }, setPhoneDisable, setShow }))
                    }}
                  >{languageData?.submit || 'submit'}
                  </button>}
              </Modal.Footer>
            </Form>

          )}
        </Formik>

      </div>
    </Modal>
  )
}
