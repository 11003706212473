import { useSelector } from 'react-redux'
import './styles/index.scss'

const Loader = ({ top, position = 'relative', zIndex, bottom }) => {
  const { brandLogo } = useSelector(state => state.language)
  return (
    <div
      className='parent'
      style={{ marginTop: top || '', position, zIndex, marginBottom: bottom || '' }}
    >
      <img
        className='image1'
        src={brandLogo || `${process.env.REACT_APP_STATIC_IMAGE}/brand-logo.svg` || '/images/brand-logo.svg'} alt='logo'
      />
      <img
        className='image2'
        src={`${process.env.REACT_APP_STATIC_IMAGE}/roundloader.png` || '/images/roundloader.png'} alt='bg-loader'
      />
    </div>

  )
}

export default Loader
