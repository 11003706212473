import React, { useContext } from 'react'
import { UserDetailContext } from '../../../../pages/NewAccountInfoPage/useContext'
import Icon from '../../../Icon'

const UserInfoHeader = () => {
  const {
    userDetails,
    languageData,
    removeImage,
    handleFileChange
  } = useContext(UserDetailContext)

  return (
    <div className='user-account-block'>
      <div className='user-account-block-wrapper'>
        <div className='user-img'>

          {userDetails?.profileImage
            ? <img className='user-image' loading='lazy' src={userDetails?.profileImage} alt='DP' key={userDetails?.profileImage} />
            : <img className='user-image' loading='lazy' src={`${process.env.REACT_APP_STATIC_IMAGE}/user-img.png`} alt='DP' />}

          <div className='dropdown'>
            <button type='button' className='profile-upload-btn' data-toggle='dropdown'>
              <img loading='lazy' src={`${process.env.REACT_APP_STATIC_IMAGE}/camera-icon.svg`} alt='camera-icon' />
            </button>

            {/* Upload Image */}
            <div className='dropdown-menu'>
              <a
                className='dropdown-item cursor-pointer'
                href={() => false}
              >
                <Icon name='upload-image' />
                <input
                  name='myProfile'
                  type='file'
                  id='file'
                  onInput={(e) => handleFileChange(e.target.files[0])}
                  hidden
                />
                <label htmlFor='file'>
                  <span className='ml-3 cursor-pointer'>{languageData?.uploadImage}</span>
                </label>
              </a>

              {/* Remove Image */}
              <a
                href={() => false}
                className='dropdown-item cursor-pointer'
                onClick={() => removeImage()}
              >
                <Icon
                  name='remove-image'
                />
                <span className='ml-3 cursor-pointer'>{languageData?.removeImage}</span>
              </a>

            </div>
          </div>
        </div>
        <div className='username-email'>
          <p>
            <span>{languageData?.loginEmail}: </span> {userDetails?.email ? userDetails.email : 'N/A'}
          </p>
          <p>
            <span>{languageData?.loginUsername}: </span> {userDetails?.username ? userDetails.username : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  )
}

export default UserInfoHeader
