import React from 'react'
import Slider from 'react-slick'
import { capitalizeMyText } from '../../utils/helpers'
import { getDeviceType } from '../../utils/getDeviceType'
import GameCard from './GameCard'
import useFilterGames from './hooks/useFilterGames'
import TopPerformance from '../TopPerformance'
import GameSliderContainer from 'components/GameSliderContainer'

const CategorisedGamesGrid = ({
  isHomePage,
  selectedFilter,
  setSelectedFilter
}) => {
  const {
    navigate,
    userDetails,
    recentGames,
    gameCategory,
    languageData,
    changeMenuTab,
    toggleFavorite,
    gameSubCategory,
    favoriteGamesList,
    selectedLanguageCode
  } = useFilterGames({ category: true, selectedFilter, setSelectedFilter })

  const settings = {
    initialSlide: 0,
    slidesToShow: 6.3,
    slidesToScroll: 4,
    speed: 500,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 2920,
        settings: {
          slidesToShow: 6.3,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5.1,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4.1,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 2.1,
          slidesToScroll: 2
        }
      }
    ]
  }

  const GetSliderGames = ({
    games,
    name,
    imageUrl,
    tenantGameSubCategoryId,
    recent = false
  }) => {
    return (
      games?.length > 0 && (
        <div className='game-slider-container' key='list-container'>
          <div className='game-slider-container-heading-content  mb-2'>
            <div className='d-flex align-items-center gap-2'>
              {recent
                ? (
                  <i className='fa-solid fa-clock-rotate-left type-icon' />
                )
                : (
                  <img className='type-icon' src={imageUrl} />
                )}
              <p className='heading-text m-0'>
                {capitalizeMyText(
                  name?.[selectedLanguageCode] || name?.EN || name,
                  '_'
                )}
              </p>
            </div>
            {games?.length > 0 && !recent && (
              <a
                href={() => false}
                className='view-all-btn'
                onClick={() => {
                  navigate(
                    `/${selectedLanguageCode?.toLowerCase()}/${gameCategory?.casinoCategories?.rows?.[0]?.name?.EN?.toLowerCase()}/${name?.EN?.toLowerCase()}`,
                    {
                      state: {
                        from: gameCategory?.casinoCategories?.rows?.[0]
                          ?.tenantGameCategoryId,
                        selectedMenu: tenantGameSubCategoryId
                      }
                    }
                  )

                  changeMenuTab(
                    tenantGameSubCategoryId,
                    tenantGameSubCategoryId
                  )
                }}
              >
                {languageData?.casinoGameViewAllBtn}
              </a>
            )}
          </div>
          {getDeviceType() === 'mobile'
            ? (
              <div className='game-slider-container-grid'>
                {games && games.length > 0
                  ? (games.slice(0, 12).map((game, index) => (
                    <GameCard
                      lobby
                      key={index}
                      game={game}
                      index={index}
                      toggleFavorite={toggleFavorite}
                      favoriteGamesList={favoriteGamesList}
                      selectedLanguageCode={selectedLanguageCode}
                    />
                  )))
                  : (
                    <span className='text-danger ml-5'>
                      {languageData?.casinoNoGamesFound}
                    </span>
                  )}
              </div>
            )
            : (
              <Slider {...settings}>
                {games && games.length > 0
                  ? (
                    games
                      ?.slice(0, 12)
                      .map((game, index) => (
                        <GameCard
                          lobby
                          key={index}
                          game={game}
                          index={index}
                          toggleFavorite={toggleFavorite}
                          favoriteGamesList={favoriteGamesList}
                          selectedLanguageCode={selectedLanguageCode}
                        />
                      ))
                  )
                  : (
                    <span className='text-danger ml-5'>
                      {languageData?.casinoNoGamesFound}
                    </span>
                  )}
              </Slider>
            )}
        </div>
      )
    )
  }

  return (
    <GameSliderContainer>
      {!!recentGames?.length && userDetails?.userId && (
        <GetSliderGames
          games={recentGames}
          name={languageData?.recentGames || 'Recent Games'}
          recent
        />
      )}
      {isHomePage && (
        <section className='top-performance-section'>
          <TopPerformance />
        </section>
      )}
      {gameSubCategory?.rows.map?.((data, index) => (
        <GetSliderGames
          games={data?.CategoryGames}
          name={data?.name}
          imageUrl={data?.imageUrl}
          tenantGameSubCategoryId={data?.tenantGameSubCategoryId}
          key={index}
        />
      ))}
    </GameSliderContainer>
  )
}
export default React.memo(CategorisedGamesGrid)
