import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { showFooter } from '../../../store/redux-slices/loader'
import { logoutStart } from '../../../store/redux-slices/login'
import { paymentStatusSocket, walletSocket } from '../../../socket'

const useAccountsPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { section } = useParams()
  const { state } = useLocation()
  const tab = state && state.tab
  const [key, setKey] = useState(tab || section)
  const { selectedLanguageCode } = useSelector((state) => state.language)
  const { languageData } = useSelector((state) => state.language)
  const [bonusDetail, setBonusDetail] = useState()

  useEffect(() => {
    if (tab) {
      setKey(tab)
    } else if (section) {
      setKey(section)
    }
    dispatch(showFooter(true))
  }, [tab, section])

  useEffect(() => {
    if (state && state.bonusDetail) {
      setBonusDetail(state.bonusDetail)
    }
  }, [state])

  const navTabs = [
    { title: languageData?.headerDeposit, icon: 'deposit-funds', key: 'deposit' },
    { title: languageData?.headerWithdrawFunds, icon: 'withdraw-funds', key: 'withdraw' },
    { title: languageData?.pendingWithdrawal, icon: 'withdraw-funds', key: 'pending-withdrawals' },
    // { title: languageData?.headerBonus, icon: 'bonus', key: 'bonus' },
    { title: languageData?.dashboard || 'Dashboard', icon: 'chart-icon', key: 'dashboard' },
    { title: languageData?.headerAccAndInfo, icon: 'account-info', key: 'account-info' },
    { title: languageData?.headerAccVerify, icon: 'account-verify', key: 'account-verify' },
    // { title: languageData?.headerLoyalty, icon: 'space-rocket-launch', key: 'loyalty' },
    { title: languageData?.headerTransactionHistory, icon: 'bet-history', key: 'transaction' },
    { title: languageData?.headerLimits, icon: 'limits', key: 'limits' }
  ]

  const logout = () => {
    dispatch(logoutStart({ navigate }))
    navigate('/')
    walletSocket.disconnect()
    paymentStatusSocket.disconnect()
  }

  return {
    navTabs,
    key,
    bonusDetail,
    languageData,
    selectedLanguageCode,
    navigate,
    setKey,
    logout,
    section
  }
}

export default useAccountsPage
