import React from 'react'
import { Col, Dropdown, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import NoDataAvailable from '../../../components/NoDataAvailable'
import { errorMessages } from '../../../constants/errorMessages'
import { formatDateYMD } from '../../../utils/dateFormatter'
import PaginationComponent from '../../../components/Pagination'
import Loader from '../../../components/Loader'
import { bonusStatus } from '../constants'
import { currencyCodeList } from '../../../constants/currencyCodeList'
import CustomDropdownToggle from '../../../components/custom/CustomDropdownToggle'
import useBonusList from '../hooks/useBonusList'

const BonusList = () => {
  const {
    page,
    limit,
    setPage,
    setLimit,
    navigate,
    userBonus,
    setStatus,
    totalPages,
    userDetails,
    bonusLoading,
    languageData,
    fetchUserBonus,
    selectedFilter,
    setSelectedFilter,
    selectedLanguageCode
  } = useBonusList()

  return (
    <>
      <div className='tab-title-wrapper d-flex justify-content-center align-items-center'>
        <h2>{languageData?.bonusHistory}</h2>
        <button
          className='d-flex justify-content-end bg-transparent border-0 btn-refresh'
          size='sm'
          onClick={fetchUserBonus}
        >
          {/* <OverlayTrigger placement='right' overlay={popover}> */}
          <img loading='lazy' src={`${process.env.REACT_APP_STATIC_IMAGE}/reload.svg`} alt='reload' className='reloading-btn' />
          {/* </OverlayTrigger> */}
        </button>
      </div>

      <div className='history-container user-account-block'>
        <div className='table-layouts'>
          <div className='heading-filter'>
            <Row>
              <Col className='column-search'>
                <div className='form-group'>
                  <Dropdown className='icon-right d-flex align-items-center' style={{ width: '13rem' }}>
                    <div className='dropdown-heading mr-2'>Status:</div>
                    <Dropdown.Toggle id='dropdown-basic' as={CustomDropdownToggle}>
                      <span className='text-capitalize'>{languageData[selectedFilter]}</span>
                      <img src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`} alt='Dropdown' />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item disabled>{languageData?.bonusStatus}</Dropdown.Item>
                      {bonusStatus?.map(
                        ({ label, value }) =>
                          <Dropdown.Item
                            key={label}
                            onClick={() => {
                              setStatus(value === 'FORFEITED' ? ['FORFEITED', 'CANCELLED'] : value?.split(' '))
                              setSelectedFilter(label)
                            }}
                          >{languageData[label]}
                          </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>
          <div className='gaming-transaction-table'>
            {bonusLoading
              ? <Loader top='0' position='unset' />
              : userBonus && userBonus?.count !== 0 && (
                <Table width='100%' striped responsive>
                  <thead>
                    <tr className='text-center'>
                      <th scope='col' className='text-left'> {languageData?.title}</th>
                      <th scope='col'> {languageData?.amount}</th>
                      <th scope='col'> {languageData?.status}</th>
                      <th scope='col'> {languageData?.dateTime}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBonus?.rows.map(({
                      status,
                      bonusType,
                      updatedAt,
                      freeSpinsQty,
                      bonusAmount,
                      bonusId,
                      other,
                      bonus
                    }, i) => {
                      return (
                        <tr key={`transaction-history ${i}`}>
                          <td className='align-middle'>
                            <div
                              className='text-capitalize promotion-title'
                              onClick={() => bonusId && navigate(`/${(selectedLanguageCode?.toLowerCase())}/promotion/${bonusId}`, { state: { userBonus: true } })}
                            >
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    <div className='text-capitalize'>
                                      {other?.bonusTitle
                                        ? (typeof other?.bonusTitle === 'string' ? other?.bonusTitle : `${other?.bonusTitle?.[selectedLanguageCode] || other?.bonusTitle?.EN} (${languageData[bonusType?.toUpperCase()]})`)
                                        : `${bonus?.promotionTitle?.[selectedLanguageCode] || bonus?.promotionTitle?.EN} (${languageData[bonusType?.toUpperCase()]})`}
                                    </div>
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <div
                                  style={{ maxWidth: '210px', cursor: 'pointer' }}
                                >
                                  <p>
                                    {other?.bonusTitle
                                      ? (typeof other?.bonusTitle === 'string' ? other?.bonusTitle : `${other?.bonusTitle?.[selectedLanguageCode] || other?.bonusTitle?.EN} (${languageData[bonusType?.toUpperCase()]})`)
                                      : `${bonus?.promotionTitle?.[selectedLanguageCode] || bonus?.promotionTitle?.EN} (${languageData[bonusType?.toUpperCase()]})`}
                                  </p>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='d-flex justify-content-center'>
                              {bonusType !== 'balance' ? <p>{(!['CANCELLED', 'FORFEITED', 'LAPSED'].includes(status)) ? (bonusType === 'freespins' ? `${freeSpinsQty} freespins` : `${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${bonusAmount}`) : 'NA'}</p> : <p>NA</p>}
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='d-flex justify-content-center'>
                              <p>
                                {languageData[status === 'ZEROED-OUT' ? 'zeroedOut' : status === 'CANCELLED' ? (bonusType !== 'match_1' ? 'forfeited' : 'expired') : status?.toLowerCase()]}
                              </p>
                            </div>
                          </td>

                          <td className='align-middle'>
                            <div className='d-flex justify-content-center'>
                              <OverlayTrigger
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {formatDateYMD(updatedAt)}
                                  </Tooltip>
                                )}
                                placement='top'
                              >
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className='text-truncate transaction-name text-center'
                                >
                                  {formatDateYMD(updatedAt)}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              )}

            {userBonus && userBonus.count === 0 && !bonusLoading &&
              <NoDataAvailable errorMessage={languageData[errorMessages.userBonus]} />}

          </div>
          {userBonus && userBonus.count !== 0 && !bonusLoading && (
            <PaginationComponent
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default BonusList
