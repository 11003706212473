import React, { useContext } from 'react'
import { dashboardContext } from '../../../context'
import withContextProvider from '../../../wrapper/withContextProvider'
import DashboardSectionHeading from '../Common/DashboardSectionHeading'
import './styles/index.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from '../../Loader'
import BonusCard from '../BonusCard'
import DashboardModal from '../DashboardModal'
import NoDataAvailable from '../../NoDataAvailable'

const ClaimableBonuses = () => {
  const dashboardDetail = useContext(dashboardContext)
  const {
    languageData,
    userComingActiveBonusLoading,
    userComingActiveBonus,
    claimableBonusPageNo,
    setClaimableBonusPageNo
  } = dashboardDetail

  return (
    <div className='dashboard-section'>
      <div className='claimable-bonuses-section'>
        <DashboardSectionHeading headingText={languageData?.claimableBonuses} />
        {userComingActiveBonusLoading
          ? (
            <Loader top='2rem' />
            )
          : userComingActiveBonus?.count === 0
            ? (
              <div className='no-data-found'>
                <NoDataAvailable
                  errorMessage={languageData?.noBonuses}
                />
              </div>
              )
            : (
              <div className='claimable-bonus-list'>
                <InfiniteScroll
                  dataLength={userComingActiveBonus?.rows?.length || 0}
                  next={() => setClaimableBonusPageNo((pageNo) => pageNo + 1)}
                  hasMore={
                claimableBonusPageNo <
                Math.ceil(userComingActiveBonus?.count / 8)
              }
                  scrollThreshold={0.8}
                  scrollableTarget='your-bonuses-infinit-scroll'
                  loader={<Loader top='6%' />}
                >
                  <div className='bonus-cart-main-section star-section'>
                    {userComingActiveBonus.rows?.length > 0 && (
                      <BonusCard
                        bonusList={userComingActiveBonus}
                        {...dashboardDetail}
                      />
                    )}
                  </div>
                </InfiniteScroll>
              </div>
              )}

        <DashboardModal />
      </div>
    </div>
  )
}

export default withContextProvider(ClaimableBonuses)
