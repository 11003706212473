import React from 'react'
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import NoDataAvailable from '../../components/NoDataAvailable'
import Loader from '../../components/Loader'
import usePromotion from './hooks/usePromotion'
import './styles/index.scss'
import HtmlParser from 'react-html-parser'
import Banner from '../../components/Banner'

const Promotions = () => {
  const {
    allBonus,
    loading,
    limit,
    languageData,
    selectedLanguageCode,
    bannerLoading,
    navigate,
    setLimit,
    capitalize
  } = usePromotion()

  return (
    <>
      <section
        className='wrapper-content view-all-bonuses'
        id={loading ? 'height-70vh' : ''}
      >
        {loading
          ? (
            <Loader top='40vh' />
            )
          : (
            <>
              {!bannerLoading && <Banner pageType='promotions' />}
              <section className='prom-tab-section promotion-wrap promotion-page'>
                <div className='wrapper'>
                  <Row className='promotion-container ml-0 mr-0'>
                    <>
                      {allBonus?.rows?.map((item, i) => (
                        <Col
                          key={`bonus listing ${i}`}
                          lg={4}
                          md={4}
                          sm={6}
                          className='px-2'
                        >
                          <Card className='promotion_card'>
                            <div className='card_img_box'>
                              <Card.Img
                                src={item.imageUrl}
                                alt='Bonus Image'
                                className='card_img img-fluid'
                              />
                            </div>
                            <Card.Body>
                              <Card.Title className='mb-2 d-flex justify-content-start text-capitalize'>
                                <OverlayTrigger
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {
                                      languageData[
                                        capitalize(
                                          item.bonusType
                                        )?.toUpperCase()
                                      ]
                                    }
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <div
                                    style={{
                                      maxWidth: '100%',
                                      cursor: 'pointer'
                                    }}
                                    className='d-inline-block'
                                  >
                                    <i className='fa-solid fa-tag mr-1' />
                                    {
                                    languageData[
                                      capitalize(item.bonusType)?.toUpperCase()
                                    ]
                                  }
                                  </div>
                                </OverlayTrigger>
                              </Card.Title>
                              <Card.Text
                                className='mb-2'
                                style={{ lineHeight: '26px' }}
                              >
                                <OverlayTrigger
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      <span
                                        style={{
                                          maxWidth: '100%',
                                          cursor: 'pointer'
                                        }}
                                        className='d-inline-block'
                                      >
                                        {(
                                          item?.promotionTitle?.[
                                            selectedLanguageCode
                                          ] || item?.promotionTitle?.EN
                                        )?.toLowerCase()}
                                      </span>
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <span
                                    style={{
                                      maxWidth: '100%',
                                      cursor: 'pointer'
                                    }}
                                    className='d-inline-block text-capitalize'
                                  >
                                    {(
                                      item?.promotionTitle?.[
                                        selectedLanguageCode
                                      ] || item?.promotionTitle?.EN
                                    )?.toLowerCase()}
                                  </span>
                                </OverlayTrigger>
                              </Card.Text>
                              <p className='subtext mt-4'>
                                <span className='text-truncate promo-description'>
                                  {item?.promotionalOverview?.[
                                    selectedLanguageCode
                                  ] || item?.promotionalOverview?.EN
                                    ? HtmlParser(
                                      (
                                        item?.promotionalOverview?.[
                                          selectedLanguageCode
                                        ] || item?.promotionalOverview?.EN
                                      )
                                        .replaceAll('&nbsp;', ' ')
                                        .replaceAll('<p><br></p>', '')
                                    )
                                    : (item?.description?.[
                                        selectedLanguageCode
                                      ] ||
                                      item?.description?.EN) &&
                                    HtmlParser(
                                      (
                                        item?.description?.[
                                          selectedLanguageCode
                                        ] || item?.description?.EN
                                      )
                                        .replaceAll('&nbsp;', ' ')
                                        .replaceAll('<p><br></p>', '')
                                    )}
                                </span>
                              </p>
                              <button
                                className='btn btn-primary-promotion'
                                onClick={() => {
                                  navigate(
                                  `/${selectedLanguageCode?.toLowerCase()}/promotion/${
                                    item.bonusId
                                  }`
                                  )
                                  window?.scrollTo(0, 0)
                                }}
                              >
                                {languageData?.promReadMore}
                              </button>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                      {allBonus?.count === 0 && (
                        <Col>
                          <NoDataAvailable
                            errorMessage={languageData?.noPromotions}
                          />
                        </Col>
                      )}
                    </>
                  </Row>
                  {allBonus?.count > limit && (
                    <button
                      className='view-more'
                      onClick={() => setLimit((prev) => prev * 2)}
                    >
                      {languageData?.viewMore}
                    </button>
                  )}
                </div>
              </section>
            </>
            )}
      </section>
    </>
  )
}

export default Promotions
