import { takeLatest, put, select } from 'redux-saga/effects'
import { toast } from '../../components/Toast'

import {
  claimBonus,
  cancelBonus,
  getAllBonus, getBonusDetail, getUserBonus, availBonus, getLoyaltyLevel, getLoyaltyDetails, getCashbackTable
} from '../../utils/apiCalls'

import {
  getAllBonusStart,
  getAllBonusSuccess,
  getAllBonusFailure,
  getBonusDetailStart,
  getBonusDetailSuccess,
  getBonusDetailFailure,
  claimBonusStart,
  claimBonusComplete,
  getUserBonusStart,
  getUserBonusSuccess,
  getUserBonusFailure,
  cancelBonusStart,
  cancelBonusComplete,
  availBonusStart,
  availBonusComplete,
  getLoyaltyLevelStart,
  getLoyaltyLevelSuccess,
  getLoyaltyLevelFailure,
  getLoyaltyDetailsStart,
  getLoyaltyDetailsSuccess,
  getLoyaltyDetailsFailure,
  getWageringBarStart,
  getWageringBarSuccess,
  getWageringBarFailure,
  getDepositUserBonusSuccess,
  getCashbackTableStart,
  getCashbackTableSuccess,
  getCashbackTableFailure,
  getUserActiveBonusStart,
  getUserActiveBonusSuccess,
  getUserActiveBonusFailure,
  getUserBonusReset,
  getUserActiveBonusReset,
  forfeitBonusStart,
  forfeitBonusSuccess,
  forfeitBonusFailure,
  getUserComingBonusSuccess,
  getUserComingActiveBonusReset,
  getUserComingActiveBonusStart
} from '../redux-slices/bonus'
import { showFooter } from '../redux-slices/loader'
import { killAllFrames } from '../../utils/helpers'

export default function * bonusWatcher () {
  yield takeLatest(getAllBonusStart.type, getAllBonusWorker)
  yield takeLatest(getBonusDetailStart.type, getBonusDetailWorker)
  yield takeLatest(claimBonusStart.type, claimBonusWorker)
  yield takeLatest(getUserBonusStart.type, getUserBonusWorker)
  yield takeLatest(getUserActiveBonusStart.type, getUserActiveBonusWorker)
  yield takeLatest(getUserComingActiveBonusStart.type, getUserActiveBonusWorker)
  yield takeLatest(cancelBonusStart.type, cancelBonusWorker)
  yield takeLatest(availBonusStart.type, availBonusWorker)
  yield takeLatest(getLoyaltyLevelStart.type, getLoyaltyLevelWorker)
  yield takeLatest(getLoyaltyDetailsStart.type, getLoyaltyDetailsWorker)
  yield takeLatest(getWageringBarStart.type, getWageringBarWorker)
  yield takeLatest(getCashbackTableStart.type, getCashbackTableWorker)
  yield takeLatest(forfeitBonusStart.type, forfeitBonusWorker)
}

const showLoaderValues = (state) => state?.loader
const selectedLangCode = (state) => state?.language

function * getAllBonusWorker (action) {
  try {
    const { showHeader, showChildren } = yield select(showLoaderValues)
    const { limit, pageNo, bonusType } = action && action.payload
    const { data } = yield getAllBonus({ limit, pageNo, bonusType })

    yield put(getAllBonusSuccess(data?.data?.bonus))

    showHeader && showChildren && (yield put(showFooter(true)))
  } catch (e) {
    yield put(getAllBonusFailure())
  }
}

function * getBonusDetailWorker (action) {
  try {
    const { showHeader, showChildren } = yield select(showLoaderValues)
    const { bonusId, userBonusId = '' } = action && action.payload
    const { data } = yield getBonusDetail({ bonusId, userBonusId })

    yield put(getBonusDetailSuccess(data?.data?.bonusDetails))

    showHeader && showChildren && (yield put(showFooter(true)))
  } catch (e) {
    yield put(getBonusDetailFailure())
  }
}

function * claimBonusWorker (action) {
  try {
    const { selectedLanguageCode } = yield select(selectedLangCode)
    const { data, bonusDetail, navigate } = action && action.payload

    const res = yield claimBonus({ data })

    if (!['freespins', 'balance'].includes(bonusDetail.bonusType)) {
      const bonusData = res?.data?.data?.createBonus

      bonusData.bonus = {
        currency: bonusDetail?.currency,
        promotionTitle: bonusDetail?.promotionTitle,
        depositBonusPercent: bonusDetail?.depositBonusPercent
      }
      navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/deposit`, { state: { tab: 'deposit', bonusDetail: bonusData } })
    }

    yield put(claimBonusComplete())

    yield put(getBonusDetailStart({ bonusId: data.bonusId }))
  } catch (e) {
    const { selectedLanguageCode } = yield select(selectedLangCode)
    const { bonusDetail, navigate } = action && action.payload

    yield put(claimBonusComplete())

    if (e?.response?.data?.errors[0]?.errorCode !== 3066) {
      navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/dashboard`, { state: { tab: 'dashboard', bonusDetail } })
    }

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getUserBonusWorker (action) {
  try {
    const { limit, pageNo, status, inDeposit = '' } = action && action.payload

    const { data } = yield getUserBonus({ limit, pageNo, status, inDeposit })
    if (inDeposit) {
      yield put(getDepositUserBonusSuccess(data?.data?.userBonus))
    } else {
      if (pageNo === 1) {
        yield put(getUserBonusReset())
      }
      yield put(getUserBonusSuccess(data?.data?.userBonus))
    }
  } catch (e) {
    yield put(getUserBonusFailure())
  }
}

function * cancelBonusWorker (action) {
  try {
    const { data, setBonusPageNo, status, activeBonuses, duplicateHandler = false, deposit = false } = action && action.payload

    yield cancelBonus({ data })

    yield put(cancelBonusComplete())
    if (duplicateHandler) {
      duplicateHandler()
      killAllFrames()
    } else {
      if (deposit) {
        yield put(getUserBonusStart({
          limit: '',
          pageNo: '',
          status: JSON.stringify(status),
          inDeposit: true
        }))
      } else if (data?.bonusStatus) {
        if (['ACTIVE', 'IN-PROCESS'].includes(data?.bonusStatus)) {
          yield put(getUserActiveBonusStart({
            limit: 8,
            pageNo: 1,
            status: JSON.stringify(['ACTIVE', 'IN-PROCESS'])
          }))
        } else yield put(getUserComingActiveBonusStart({ limit: 8, pageNo: 1, status: JSON.stringify(['PENDING', 'CLAIMING']), upComingBonus: true }))
      } else {
        setBonusPageNo(1)
        yield put(getUserBonusStart({ limit: 6, pageNo: 1, status }))
        yield put(getUserActiveBonusReset())
        yield put(getUserActiveBonusSuccess(activeBonuses))
      }
    }
  } catch (e) {
    yield put(cancelBonusComplete())
  }
}

function * availBonusWorker (action) {
  try {
    const { selectedLanguageCode } = yield select(selectedLangCode)
    const { data, bonusType, bonusAmount, item, navigate, deposit = false, setShowFreeSpinGameModal, currencyCode, identifier } = action && action.payload
    yield availBonus({ data })
    const isZeroDeposit = item?.bonusType === 'match' && ((item?.currency && item?.currency[currencyCode]?.minDeposit === '0') || (item?.bonus?.currency && item?.bonus?.currency[currencyCode]?.minDeposit === '0'))
    if (!['deposit', 'wagering', 'balance'].includes(bonusType) && !bonusAmount && !data?.inDeposit) {
      if (item?.bonusType === 'freespins') {
        if ((item?.currency && item?.currency[currencyCode]?.minDeposit !== '0') || (item?.bonus?.currency && item?.bonus?.currency[currencyCode]?.minDeposit !== '0')) {
          navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/deposit`, { state: { tab: 'deposit', bonusDetail: item, identifier } })
        }
      } else {
        // If Zero Deposit bonus don't navigate
        !isZeroDeposit && navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/deposit`, { state: { tab: 'deposit', bonusDetail: item } })
      }
    }
    if (setShowFreeSpinGameModal) {
      setShowFreeSpinGameModal(false)
    }
    if (!deposit) {
      yield put(getUserActiveBonusStart({ limit: 8, pageNo: 1, status: JSON.stringify(['ACTIVE', 'IN-PROCESS']) }))
      yield put(getUserComingActiveBonusStart({ limit: 8, pageNo: 1, status: JSON.stringify(['PENDING', 'CLAIMING']), upComingBonus: true }))
    }

    yield put(availBonusComplete())
  } catch (e) {
    const { selectedLanguageCode } = yield select(selectedLangCode)
    const { data, setShowLapsedModal, navigate } = action && action.payload

    yield put(availBonusComplete())

    if (e?.response?.data?.errors[0]?.errorCode === 3072) {
      setShowLapsedModal(true)
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')

      data?.inDeposit && navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/dashboard`, { state: { tab: 'dashboard' } })
    }
  }
}

function * getLoyaltyLevelWorker () {
  try {
    const { data } = yield getLoyaltyLevel()

    yield put(getLoyaltyLevelSuccess(data?.data?.loyaltyLevel))

    yield put(showFooter(true))
  } catch (e) {
    yield put(getLoyaltyLevelFailure())
  }
}

function * getLoyaltyDetailsWorker () {
  try {
    const { data } = yield getLoyaltyDetails()

    yield put(getLoyaltyDetailsSuccess(data?.data?.loyaltyDetails))
  } catch (e) {
    yield put(getLoyaltyDetailsFailure())
  }
}

function * getWageringBarWorker (action) {
  try {
    const { limit, pageNo, status, inDeposit = '' } = action && action.payload

    const { data } = yield getUserBonus({ limit, pageNo, status, inDeposit })

    yield put(getWageringBarSuccess(data?.data?.userBonus?.rows?.[0] || {}))
  } catch (e) {
    yield put(getWageringBarFailure())
  }
}

function * getCashbackTableWorker () {
  try {
    const { data } = yield getCashbackTable()

    yield put(getCashbackTableSuccess(data?.data?.cashbackDetails))

    yield put(showFooter(true))
  } catch (e) {
    yield put(getCashbackTableFailure())
  }
}

function * getUserActiveBonusWorker (action) {
  try {
    const { limit, pageNo, status, inDeposit = '', upComingBonus = false } = action && action.payload
    const { data } = yield getUserBonus({ limit, pageNo, status, inDeposit })

    if (pageNo === 1) {
      !upComingBonus
        ? yield put(getUserActiveBonusReset())
        : yield put(getUserComingActiveBonusReset())
    }

    !upComingBonus
      ? yield put(getUserActiveBonusSuccess(data?.data?.userBonus))
      : yield put(getUserComingBonusSuccess(data?.data?.userBonus))
  } catch (e) {
    yield put(getUserActiveBonusFailure())
  }
}

function * forfeitBonusWorker (action) {
  try {
    const { data } = action && action.payload

    yield cancelBonus({ data })

    yield put(forfeitBonusSuccess())
  } catch (e) {
    yield put(forfeitBonusFailure())
  }
}
