import React from 'react'
import RightSection from '../../components/SignupPage/RightSection'
import LeftSection from '../../components/SignupPage/LeftSection'
import { SignUpContextProvider } from './useContext'
import { isMobileOrTablet } from '../../utils/helpers'
import './styles/index.scss'

const NewSignup = () => {
  const mobileOrTablet = isMobileOrTablet()
  return (
    <SignUpContextProvider>
      <section className='signup-section'>
        {!mobileOrTablet && <LeftSection />}
        <RightSection />
      </section>
    </SignUpContextProvider>
  )
}

export default NewSignup
