import React from 'react'

function CustomError ({ children }) {
  return (
    <div className='sign-error-container'>
      <div className='error-message'>{children}</div>
    </div>
  )
}

export default CustomError
