import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserBonusStart } from '../../../store/redux-slices/bonus'

const useBonusList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(10)
  const [bonusPageNo, setBonusPageNo] = useState(1)
  const { userDetails } = useSelector(state => state.user)
  const [selectedFilter, setSelectedFilter] = useState('all')
  const { languageData } = useSelector((state) => state.language)
  const { userBonus, bonusLoading } = useSelector(state => state.bonus)
  const { selectedLanguageCode } = useSelector((state) => state.language)
  const [status, setStatus] = useState(['CANCELLED', 'FORFEITED', 'EXPIRED', 'COMPLETED', 'ZEROED-OUT', 'LAPSED'])

  const totalPages = Math.ceil(userBonus?.count / limit)

  const fetchUserBonus = () => {
    dispatch(getUserBonusStart({
      limit,
      pageNo: bonusPageNo,
      status: JSON.stringify(status)
    }))
  }

  // API call when History Bonus Infinite scroll runs
  useEffect(() => {
    fetchUserBonus()
  }, [limit, bonusPageNo])

  // API Call When status changes
  useEffect(() => {
    setBonusPageNo(1)
    bonusPageNo === 1 && fetchUserBonus()
  }, [status])

  return {
    limit,
    setLimit,
    navigate,
    setStatus,
    userBonus,
    totalPages,
    userDetails,
    languageData,
    bonusLoading,
    fetchUserBonus,
    selectedFilter,
    setSelectedFilter,
    page: bonusPageNo,
    selectedLanguageCode,
    setPage: setBonusPageNo
  }
}

export default useBonusList
