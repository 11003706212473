import React, { useContext } from 'react'
import { UserDetailContext } from '../../../../../pages/NewAccountInfoPage/useContext'
import HtmlParser from 'react-html-parser'

const Subscriptions = ({ formik }) => {
  const { regFields, languageData } = useContext(UserDetailContext)
  const { values, setFieldValue } = formik
  return (
    <>
      {regFields && (
        <div>
          <div className='form terms-n-condition'>
            {!!regFields?.newsletterAndSms && (
              <div className='d-flex my-4 align-items-center'>
                <span
                  className={`${
                    values?.newsletterAndSms === 'true' ? 'tick-mark' : 'circle-check'
                  }`}
                  onClick={() => {
                    if (values?.newsletterAndSms === 'true') {
                      setFieldValue('newsletterAndSms', 'false')
                    } else {
                      setFieldValue('newsletterAndSms', 'true')
                    }
                  }}
                />
                <p className='p-color'>
                  {languageData?.signupNewsLetterAndSms &&
                    HtmlParser(languageData?.signupNewsLetterAndSms)}
                  {regFields?.newsletterAndSms === 2 && (
                    <span className='text-danger'>*</span>
                  )}
                </p>
              </div>
            )}
            {!regFields?.newsletterAndSms && !!regFields?.newsLetter && (
              <div className='d-flex my-4 align-items-center'>
                <span
                  className={`${
                    values?.newsLetter === 'true' ? 'tick-mark' : 'circle-check'
                  }`}
                  onClick={() => {
                    if (values?.newsLetter === 'true') {
                      setFieldValue('newsLetter', 'false')
                    } else {
                      setFieldValue('newsLetter', 'true')
                    }
                  }}
                />
                <p className='p-color'>
                  {languageData?.signupNewsLetter &&
                    HtmlParser(languageData?.signupNewsLetter)}
                  {regFields?.newsLetter === 2 && (
                    <span className='text-danger'>*</span>
                  )}
                </p>
              </div>
            )}
            {!regFields?.newsletterAndSms && !!regFields?.sms && (
              <div className='d-flex my-4 align-items-center'>
                <span
                  className={`${
                    values?.sms === 'true' ? 'tick-mark' : 'circle-check'
                  }`}
                  onClick={() => {
                    if (values?.sms === 'true') {
                      setFieldValue('sms', 'false')
                    } else {
                      setFieldValue('sms', 'true')
                    }
                  }}
                />
                <p className='p-color'>
                  {languageData?.signupSms &&
                    HtmlParser(languageData?.signupSms)}
                  {regFields?.sms === 2 && (
                    <span className='text-danger'>*</span>
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Subscriptions
