import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getGameCategoryStart,
    getGameCategorySuccess,
    getGameCategoryFailure,
    getGameSubCategoryStart,
    getGameSubCategorySuccess,
    getGameSubCategoryFailure,
    getGameStart,
    getGameSearch,
    getGameSuccess,
    getGameFailure,
    getFavGameStart,
    getFavGameSuccess,
    getFavGameFailure,
    getHomeDataStart,
    getHomeDataSuccess,
    getHomeDataFailure,
    getProvidersStart,
    getProvidersSuccess,
    getProvidersFailure,
    getProvidersByOrderStart,
    getProvidersByOrderSuccess,
    getProvidersByOrderFailure,
    toggleFavoriteStart,
    toggleFavoriteSuccess,
    toggleFavoriteFailure,
    getFilteredGamesStart,
    getFilteredGamesSuccess,
    getFilteredGamesFailure,
    removeFavoriteStart,
    removeFavoriteSuccess,
    removeFavoriteFailure,
    getTopGamesStart,
    getTopGamesSuccess,
    getTopGamesFailure,
    getTopWinnersStart,
    getTopWinnersSuccess,
    getTopWinnersFailure,
    getCurrentWinnersStart,
    getCurrentWinnersSuccess,
    getCurrentWinnersFailure,
    resetCasinoData,
    resetGameSearch,
    updateGameSubCategorySuccess,
    updateRowsFetched,
    getCategoryProviderListStart,
    getCategoryProviderListSuccess,
    getCategoryProviderListFailure,
    getRecentGamesStart,
    getRecentGamesComplete
  },
  reducer
} = createSlice({
  name: 'casinoMenus',
  initialState: {
    loading: false,
    homeLoading: false,
    gameCategory: {},
    gameSubCategory: {},
    games: {},
    providers: [],
    providersByOrder: [],
    gameSearch: {},
    favoriteGames: {},
    favoriteGamesList: {},
    filteredGames: null,
    filteredLoading: true,
    favLoading: false,
    subCatLoading: false,
    rowsFetchedCount: 0,
    categoryProviderList: null,
    providerListLoading: false,
    recentGames: null
  },
  reducers: {
    getGameCategoryStart: (state) => ({
      ...state,
      loading: true
    }),
    getGameCategorySuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      gameCategory: payload
    }),
    getGameSearch: (state, { payload }) => ({
      ...state,
      filteredLoading: false,
      gameSearch: payload
    }),
    getGameCategoryFailure: (state) => ({
      ...state,
      loading: false
    }),
    getGameSubCategoryStart: (state) => ({
      ...state,
      subCatLoading: true
    }),
    getGameSubCategorySuccess: (state, { payload }) => ({
      ...state,
      subCatLoading: false,
      gameSubCategory: payload.subCategories,
      favoriteGames: payload.favoriteGames || {}
    }),
    getGameSubCategoryFailure: (state) => ({
      ...state,
      subCatLoading: false
    }),
    getGameStart: (state) => ({
      ...state,
      loading: true
    }),
    getGameSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      games: payload
    }),
    getGameFailure: (state) => ({
      ...state,
      loading: false
    }),
    resetCasinoData: (state) => ({
      ...state,
      games: {}
    }),
    getFavGameStart: (state) => ({
      ...state,
      favLoading: true
    }),
    getFavGameSuccess: (state, { payload }) => ({
      ...state,
      favLoading: false,
      favoriteGamesList: payload
    }),
    getFavGameFailure: (state) => ({
      ...state,
      favLoading: false
    }),
    getHomeDataStart: (state) => ({
      ...state,
      homeLoading: true
    }),
    getHomeDataSuccess: (state, { payload }) => ({
      ...state,
      homeLoading: false,
      gameCategory: payload.gameCategory
    }),
    getHomeDataFailure: (state) => ({
      ...state,
      homeLoading: false
    }),
    getProvidersStart: (state) => ({
      ...state,
      homeLoading: true
    }),
    getProvidersSuccess: (state, { payload }) => ({
      ...state,
      homeLoading: false,
      providers: payload
    }),
    getProvidersFailure: (state) => ({
      ...state,
      homeLoading: false
    }),
    getProvidersByOrderStart: (state) => ({
      ...state,
      homeLoading: true
    }),
    getProvidersByOrderSuccess: (state, { payload }) => ({
      ...state,
      homeLoading: false,
      providersByOrder: payload
    }),
    getProvidersByOrderFailure: (state) => ({
      ...state,
      homeLoading: false
    }),
    toggleFavoriteStart: (state) => ({
      ...state
    }),
    toggleFavoriteSuccess: (state, { payload }) => ({
      ...state,
      games: payload
    }),
    toggleFavoriteFailure: (state) => ({
      ...state
    }),
    getFilteredGamesStart: (state) => ({
      ...state,
      filteredLoading: true
    }),
    getFilteredGamesSuccess: (state, { payload }) => ({
      ...state,
      filteredLoading: false,
      filteredGames: payload
    }),
    getFilteredGamesFailure: (state) => ({
      ...state,
      filteredLoading: false
    }),
    removeFavoriteStart: (state) => ({
      ...state
    }),
    removeFavoriteSuccess: (state, { payload }) => ({
      ...state,
      favoriteGamesList: payload
    }),
    removeFavoriteFailure: (state) => ({
      ...state
    }),
    resetGameSearch: (state) => ({
      ...state,
      gameSearch: {}
    }),
    getTopGamesStart: (state) => ({
      ...state,
      loading: false
    }),
    getTopGamesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      topGames: payload
    }),
    getTopGamesFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTopWinnersStart: (state) => ({
      ...state,
      loading: false
    }),
    getTopWinnersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      topWinners: payload
    }),
    getTopWinnersFailure: (state) => ({
      ...state,
      loading: false
    }),
    getCurrentWinnersStart: (state) => ({
      ...state,
      loading: false
    }),
    getCurrentWinnersSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      currentWinners: payload
    }),
    getCurrentWinnersFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateGameSubCategorySuccess: (state, { payload }) => ({
      ...state,
      gameSubCategory: payload
    }),
    updateRowsFetched: (state, { payload }) => ({
      ...state,
      rowsFetchedCount: payload
    }),
    getCategoryProviderListStart: (state) => ({
      ...state,
      providerListLoading: true
    }),
    getCategoryProviderListSuccess: (state, { payload }) => ({
      ...state,
      providerListLoading: false,
      categoryProviderList: payload
    }),
    getCategoryProviderListFailure: (state, { payload }) => ({
      ...state,
      providerListLoading: false,
      categoryProviderList: payload
    }),
    getRecentGamesStart: (state) => ({
      ...state
    }),
    getRecentGamesComplete: (state, { payload }) => ({
      ...state,
      recentGames: payload
    })
  }
})

export default reducer

export {
  getGameSubCategoryStart,
  getGameSubCategorySuccess,
  getGameSubCategoryFailure,
  getGameSearch,
  getGameStart,
  getGameSuccess,
  getGameFailure,
  getFavGameStart,
  getFavGameSuccess,
  getFavGameFailure,
  getGameCategoryStart,
  getGameCategorySuccess,
  getGameCategoryFailure,
  getHomeDataStart,
  getHomeDataSuccess,
  getHomeDataFailure,
  getProvidersStart,
  getProvidersSuccess,
  getProvidersFailure,
  getProvidersByOrderStart,
  getProvidersByOrderSuccess,
  getProvidersByOrderFailure,
  toggleFavoriteStart,
  toggleFavoriteSuccess,
  toggleFavoriteFailure,
  getFilteredGamesStart,
  getFilteredGamesSuccess,
  getFilteredGamesFailure,
  removeFavoriteStart,
  removeFavoriteSuccess,
  removeFavoriteFailure,
  getTopGamesStart,
  getTopGamesSuccess,
  getTopGamesFailure,
  getTopWinnersStart,
  getTopWinnersSuccess,
  getTopWinnersFailure,
  getCurrentWinnersStart,
  getCurrentWinnersSuccess,
  getCurrentWinnersFailure,
  resetCasinoData,
  resetGameSearch,
  updateGameSubCategorySuccess,
  updateRowsFetched,
  getCategoryProviderListStart,
  getCategoryProviderListSuccess,
  getCategoryProviderListFailure,
  getRecentGamesStart,
  getRecentGamesComplete
}
