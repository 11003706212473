import React, { useContext } from 'react'
import { SignUpContext } from '../../../pages/NewSignup/useContext'
import HtmlParser from 'react-html-parser'
const LeftSection = () => {
  const { languageData } = useContext(SignUpContext)
  return (
    <>
      {languageData &&
        <div className='signup-left-section'  style={{ backgroundImage: `url('${languageData?.signupBackground}')` }}>
          <img
            loading='lazy'
            src={languageData?.signupBanner}
            className='w-100'
            alt='signup-img'
          />
          <div className='signup-text'>
            <div className='signup-welcome-heading'>
              <span className='amazing'>{languageData?.signupGetAnAmazing}</span>
              <div className='signup-welcome-text'>
                <p>{HtmlParser(languageData?.signupBannerDesc)}</p>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default LeftSection
