import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    showHeader,
    showChildren,
    showBanner,
    showLeagueList,
    showRealPlayer,
    showFooter
  },
  reducer
} = createSlice({
  name: 'loader',
  initialState: {
    showHeader: false,
    showBanner: false,
    showChildren: false,
    showLeagueList: false,
    showRealPlayer: false,
    showFooter: false
  },
  reducers: {
    showHeader: (state, { payload }) => ({
      ...state,
      showHeader: payload
    }),
    showChildren: (state, { payload }) => ({
      ...state,
      showChildren: payload
    }),
    showBanner: (state) => ({
      ...state,
      showBanner: true
    }),
    showLeagueList: (state) => ({
      ...state,
      showLeagueList: true
    }),
    showRealPlayer: (state) => ({
      ...state,
      showRealPlayer: true
    }),
    showFooter: (state, { payload }) => ({
      ...state,
      showFooter: payload
    })
  }
})

export default reducer

export {
  showHeader,
  showBanner,
  showLeagueList,
  showRealPlayer,
  showFooter,
  showChildren
}
