
import React, { useContext } from 'react'

const GenderField = ({ formik, contextValue }) => {
  const { languageData } = useContext(contextValue)
  const { values, handleChange, handleBlur } = formik
  return (
    <div className='radio-btn-design'>
      <label htmlFor='male' className='radio-btn'>
        {languageData?.signupMan}
        <input
          type='radio'
          id='male'
          name='gender'
          value='Male'
          onChange={handleChange}
          onBlur={handleBlur}
          defaultChecked={values.gender === 'Male'}
        />
        <span className='checkmark' />
      </label>

      <label htmlFor='female' className='radio-btn'>
        {languageData?.signupWomen}
        <input
          type='radio'
          id='female'
          name='gender'
          value='Female'
          onChange={handleChange}
          onBlur={handleBlur}
          defaultChecked={values.gender === 'Female'}
        />
        <span className='checkmark' />
      </label>
    </div>
  )
}

export default GenderField
