import { takeLatest, put } from 'redux-saga/effects'
import { getHomePageConfig } from '../../utils/apiCalls'
import { getHomePageConfigStart, getHomePageConfigSuccess, getHomePageConfigFailure } from '../redux-slices/config'

export default function * bonusWatcher () {
  yield takeLatest(getHomePageConfigStart.type, getHomePageConfigWorker)
}

function * getHomePageConfigWorker () {
  try {
    const data = yield getHomePageConfig()

    yield put(getHomePageConfigSuccess(data))
  } catch (e) {
    yield put(getHomePageConfigFailure())
  }
}
