import React from 'react'
import Loader from '../../components/Loader'
import useDeposit from './hooks/useDeposit'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import PaymentForm from '../../components/PaymentForm'
import NoDataAvailable from '../../components/NoDataAvailable'
import ForfietModal from './ForfietModal'
import PaymentReceipt from '../../components/Receipts/PaymentReceipt'
import SkipModal from './SkipModal'
import usePIQ from './hooks/usePIQ'
import IframeModal from './IframeModal'
import FreeSpinGamesModal from '../Bonus/components/FreeSpinGamesModal'
import RestrictDeposit from './RestrictDeposit'

const Deposit = () => {
  const {
    amt,
    type,
    data,
    setAmt,
    setType,
    loadPIQ,
    loading,
    navigate,
    sessionId,
    showModal,
    userBonus,
    initValues,
    amtOptions,
    showIFrame,
    paymentData,
    userDetails,
    bonusLoading,
    handleSubmit,
    setShowModal,
    languageData,
    currencyCode,
    setShowIFrame,
    // showBonusNext,
    selectedBonus,
    showSkipModel,
    paymentStatus,
    // cancelDeposit,
    depositFormRef,
    handleRedirect,
    forfeitHandler,
    bonusListingRef,
    myDepositStatus,
    showDepositForm,
    showBonusListing,
    setShowSkipModel,
    paymentProviders,
    setMyDepositStatus,
    fetchLatestChanges,
    // handleDepositAmount,
    handleProviderClick,
    selectedLanguageCode,
    verifyPaymentLoading,
    showPaymentProviders,
    bonusSelectionHandler,
    skipBonusConfirmHandler,
    showFreeSpinGameModal,
    setShowFreeSpinGameModal,
    identifier,
    setIdentifier,
    freeSpinSelectedBonus,
    handleSelections,
    utorgCurrencies,
    restrictDeposit,
    onForfeitBonus,
    activeBonusLoading,
    cancelBonusLoading
  } = useDeposit()

  usePIQ({ loadPIQ, paymentData, userDetails, sessionId })

  return (
    <>
      <h2 className='tab-title accounts-btn'>
        {showIFrame && !loadPIQ && (
          <button className='deposit-btn' size='sm' onClick={handleRedirect}>
            {languageData?.signupBack}
          </button>
        )}
        {languageData?.headerDeposit}
        {(showBonusListing || showPaymentProviders) && !restrictDeposit && (
          <button
            className='align-items-center d-flex justify-content-end bg-transparent border-0 btn-refresh'
            size='sm'
            onClick={fetchLatestChanges}
          >
            <img
              loading='lazy'
              src={`${process.env.REACT_APP_STATIC_IMAGE}/reload.svg`}
              alt='reload'
              className='reloading-btn'
            />
          </button>
        )}
      </h2>

      {
        restrictDeposit
          ? (
            <RestrictDeposit
              loading={cancelBonusLoading || activeBonusLoading}
              onForfeitBonus={onForfeitBonus}
              languageData={languageData}
            />
            )
          : (
            <>
              {userBonus?.count !== 0 && (
                <div
                  ref={bonusListingRef}
                  className={`user-account-block show-bonus-${showBonusListing}`}
                >
                  <div className='deposit-bonus-tab'>
                    <div className='d-flex accounts-btn justify-content-center align-items-center flex-sm-row flex-column w-100'>
                      <p className='info-text-bonus'>
                        <i className='fa-regular fa-award mr-2' />{' '}
                        {languageData?.selectYourBonus}
                      </p>
                    </div>
                    {
                      bonusLoading
                        ? (
                          <Loader top='20px' bottom='20px' />
                          )
                        : (
                          <>
                            <div className='bonus-block-wrapper'>
                              {userBonus?.rows?.map((bonusData, indx) => {
                                return (
                                  <div
                                    key={indx}
                                    className={`bonus-block ${
                                      !selectedBonus ? 'none-selected' : ''
                                    }${
                                      selectedBonus?.userBonusId === bonusData?.userBonusId
                                        ? 'selected'
                                        : ''
                                    }`}
                                  >
                                    <div className='star-section'>
                                      <img src={languageData?.bonusStaticImage} alt='img' />
                                    </div>
                                    <div className='bonus-title'>
                                      {bonusData?.other?.bonusTitle
                                        ? typeof bonusData?.other?.bonusTitle === 'string'
                                          ? bonusData?.other?.bonusTitle
                                          : `${
                                            bonusData?.other?.bonusTitle?.[
                                              selectedLanguageCode
                                            ] || bonusData?.other?.bonusTitle?.EN
                                          }`
                                        : `${
                                          bonusData?.bonus?.promotionTitle?.[
                                            selectedLanguageCode
                                            ] || bonusData?.bonus?.promotionTitle?.EN
                                        }`}
                                    </div>
                                    <button
                                      className={`select-bonus-btn ${
                                        selectedBonus?.userBonusId ===
                                        bonusData?.userBonusId
                                          ? 'active'
                                          : ''
                                      } `}
                                      key={indx}
                                      onClick={() => {
                                        handleSelections(bonusData)
                                      }}
                                    >
                                      <i className='fa-solid fa-xmark' />
                                      {selectedBonus?.userBonusId ===
                                      bonusData?.userBonusId
                                        ? (
                                          <>
                                            <span>{languageData?.selected}</span>
                                            <i className='fa-solid fa-circle-check' />
                                          </>
                                          )
                                        : (
                                          <span>{languageData?.select}</span>
                                          )}
                                    </button>
                                  </div>
                                )
                              })}
                            </div>
                            {/* <div
                          className={`deposit-btn-wrapper text-center show-next-button-${showBonusNext}`}
                        >
                          <button
                            type='button'
                            className='deposit-button'
                            onClick={() => handleDepositAmount()}
                          >
                            <span>
                              {selectedBonus
                                ? languageData?.depositWithBonus
                                : languageData?.depositWithoutBonus}
                            </span>
                            <i className='fa-solid fa-forward fa-beat' />
                          </button>
                        </div> */}
                          </>
                          )
}
                  </div>
                </div>
              )}

              <>
                <div
                  className={`user-account-block default-state-fx show-methods-${(showPaymentProviders || showBonusListing)}`}
                >
                  <div className='payment-methods-tab'>
                    <div className='d-flex accounts-btn justify-content-center align-items-center flex-sm-row flex-column w-100'>
                      <p className='info-text-bonus'>
                        <i className='fa-solid fa-credit-card mr-2' />{languageData?.selectPaymentMethod}
                      </p>
                    </div>
                    <div className='payment-providers-wrapper'>
                      {(showPaymentProviders || showBonusListing) &&
                        (loading && !bonusLoading
                          ? (
                            <Loader top='20px' bottom='20px' />
                            )
                          : (
                              !bonusLoading && (
                                <>
                                  {paymentProviders &&
                                Object.keys(paymentProviders)?.length
                                    ? (
                                      <>
                                        {Object.keys(paymentProviders).map(
                                          (providerType, index) => {
                                            return paymentProviders?.[providerType]?.map(
                                              (provider, index) => (
                                                <div
                                                  className={`payment-provider ${
                                                  !data?.paymentProviderId
                                                    ? 'none-selected'
                                                    : ''
                                                }${
                                                  data?.paymentProviderId ===
                                                  provider?.paymentProviderId
                                                    ? 'selected'
                                                    : ''
                                                }`}
                                                  key={`${provider}-${index}`}
                                                  onClick={() =>
                                                    handleProviderClick(provider)}
                                                >
                                                  {provider?.recommendedDeposit && (
                                                    <div className='recommended-tag-wrapper'>
                                                      <div className='recommended-tag'>
                                                        <i className='fa-solid fa-fire' />
                                                      </div>
                                                    </div>
                                                  )}
                                                  <div className='payment-provider-content'>
                                                    <OverlayTrigger
                                                      overlay={(props) => (
                                                        <Tooltip {...props}>
                                                          {provider?.displayName ||
                                                          provider?.name}
                                                        </Tooltip>
                                                      )}
                                                      placement='bottom'
                                                    >
                                                      <img
                                                        loading='lazy'
                                                        src={
                                                        provider?.settings
                                                          ?.depositImageUrl ||
                                                        `${process.env.REACT_APP_STATIC_IMAGE}/defaultpayment.svg`
                                                      }
                                                        alt={
                                                        provider?.displayName ||
                                                        provider?.name
                                                      }
                                                      />
                                                    </OverlayTrigger>
                                                  </div>
                                                  <span className='category'>
                                                    {languageData?.[provider?.category] || provider?.category || 'Other'}
                                                  </span>
                                                </div>
                                              )
                                            )
                                          }
                                        )}
                                      </>
                                      )
                                    : (
                                      <NoDataAvailable errorMessage={languageData?.noProvidersFound} />
                                      )}
                                </>
                              )
                            ))}
                    </div>
                  </div>
                </div>

                <div
                  ref={depositFormRef}
                  className={`user-account-block default-state-fx show-deposit-form-${showDepositForm}`}
                >
                  <div className='payment-tab '>
                    <div className='d-flex accounts-btn justify-content-center align-items-center flex-sm-row flex-column bonus-border-bottom w-100'>
                      <p className='info-text-bonus'>
                        <i className='fa-solid fa-money-check-pen' />
                        &nbsp;
                        {languageData?.payWith}
                      </p>
                    </div>

                    {showDepositForm && initValues && (
                      <div>
                        <div className='change-btn-with-logo d-flex align-items-center justify-content-start'>
                          {/* <button
                        className='change-btn'
                        onClick={() => cancelDeposit()}
                      >
                        <i className='fa-solid fa-arrows-repeat' />
                        {languageData?.restart}
                      </button> */}
                          <OverlayTrigger
                            overlay={(props) => (
                              <Tooltip {...props}>
                                {data?.displayName || data?.name}
                              </Tooltip>
                            )}
                            placement='bottom'
                          >
                            <a href={() => false} className='btn amount-type-btn'>
                              <img
                                loading='lazy'
                                src={
                                  data?.settings
                                    ?.depositImageUrl ||
                                  `${process.env.REACT_APP_STATIC_IMAGE}/defaultpayment.svg`
                                }
                                alt='logo'
                                className='transaction-type w-100'
                              />
                            </a>
                          </OverlayTrigger>
                        </div>

                        {/* Form for Payment */}
                        <PaymentForm
                          initValues={initValues}
                          data={data}
                          currencyCode={currencyCode}
                          handleSubmit={handleSubmit}
                          amt={amt}
                          setAmt={setAmt}
                          type={type}
                          setType={setType}
                          selectedBonus={selectedBonus}
                          deposit
                          languageData={languageData}
                          amtOptions={amtOptions}
                          identifier={identifier}
                          utorgCurrencies={utorgCurrencies}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* For PaymentIQ iFrame */}
                {(verifyPaymentLoading || (showIFrame && paymentData && !['FAILED'].includes(paymentData?.txState) && ![400, 401].includes(paymentData?.status))) && (
                  <IframeModal
                    deposit
                    showIFrame={showIFrame}
                    setShowIFrame={setShowIFrame}
                    loading={verifyPaymentLoading}
                    url={paymentData?.redirectOutput?.url || paymentData?.redirect_url}
                    data={paymentData?.qrId ? paymentData : null}
                    isPIQ={data?.aggregator === 'paymentiq'}
                    windowOnly={paymentData?.checkout_url || paymentData?.redirectOutput?.container === 'window' || paymentData?.container === 'window'}
                  />
                )}
                {/* FAILED txState in validate-payment */}
                {showIFrame && paymentData && (['FAILED'].includes(paymentData?.txState) || [400, 401].includes(paymentData?.status)) && (
                  <div className='deposit-receipt user-account-block'>
                    <div className='deposit-receipt-width'>
                      <PaymentReceipt
                        paymentStatus={paymentData}
                        navigate={navigate}
                        setShow={setMyDepositStatus}
                        deposit
                      />
                    </div>
                  </div>
                )}

                {/* For Payment Receipt */}
                {myDepositStatus &&
                  (!paymentStatus
                    ? (
                      <Loader top='5%' />
                      )
                    : (
                        !bonusLoading &&
                      !loading &&
                      paymentStatus &&
                      !showPaymentProviders && (
                        <div className='deposit-receipt user-account-block'>
                          <div className='deposit-receipt-width'>
                            <PaymentReceipt
                              paymentStatus={paymentStatus}
                              navigate={navigate}
                              setShow={setMyDepositStatus}
                              deposit
                            />
                          </div>
                        </div>
                        )
                      ))}

                {showModal && (
                  <ForfietModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    forfeitHandler={forfeitHandler}
                    selectedBonus={selectedBonus}
                    selectedLanguageCode={selectedLanguageCode}
                  />
                )}
                {!showModal && (
                  <SkipModal
                    languageData={languageData}
                    showModal={showSkipModel}
                    setShowModal={setShowSkipModel}
                    skipBonusConfirmHandler={skipBonusConfirmHandler}
                    selectedBonus={selectedBonus}
                    selectedLanguageCode={selectedLanguageCode}
                  />
                )}

                {showFreeSpinGameModal &&
                  <FreeSpinGamesModal
                    showFreeSpinGameModal={showFreeSpinGameModal}
                    setShowFreeSpinGameModal={setShowFreeSpinGameModal}
                    selectedBonusDetail={freeSpinSelectedBonus}
                    activateBonus={(bonusDetail, identifier) => {
                      setIdentifier(identifier)
                      bonusSelectionHandler(bonusDetail, identifier)
                      setShowFreeSpinGameModal(false)
                    }}
                  />}
              </>
            </>
            )
      }
    </>
  )
}

export default Deposit
