import React, { useContext, useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { userSigninSchema, userSigninSchemaEN } from './schema.jsx'
import { countryCurrency } from './constant.jsx'
import {
  checkIsEmailUnique,
  checkIsUsernameUnique
} from '../../../../utils/apiCalls.js'
import { toast } from '../../../Toast/index.jsx'
import { getItem } from '../../../../utils/storageUtils.js'
import SignupFooter from '../../../FormFieldControl/SignupFooter/index.jsx'
import { SignUpContext } from '../../../../pages/NewSignup/useContext/index.js'
import MobileSignupImage from './components/MobileSignupImage/index.jsx'
// import ProcessBar from './components/ProcessBar/index.jsx'
import LoginDetail from './components/LoginDetail/index.jsx'
import PersonalDetail from './components/PersonalDetail/Index.jsx'
import { PersistFormikValues } from 'formik-persist-values'
import { isMobileOrTablet } from '../../../../utils/helpers.js'
import LoginHeading from '../../../LoginHeading/LoginHeading.jsx'

const SignupForm = () => {
  const {
    regFields,
    tenantDetails,
    languageData,
    countryCode,
    handleInitialState,
    language,
    setLanguage
  } = useContext(SignUpContext)

  const formik = useRef()

  const isFinalStep = true

  const mobileOrTablet = isMobileOrTablet()

  const [acceptConditions, setAcceptConditions] = useState({
    privacyPolicy: false,
    termsAndConditions: false,
    newsLetter: true,
    sms: true,
    newsletterAndSms: true
  })

  useEffect(() => {
    document.body.classList.add('login-signup')
    return function cleanup () {
      window.localStorage.removeItem('signup-form')
      document.body.classList.remove('login-signup')
    }
  }, [])

  const initialValues = {
    email: '',
    password: '',
    username: '',
    firstName: '',
    lastName: '',
    phone: null,
    phoneCode: '',
    dateOfBirth: `01/01/${new Date().getFullYear() - 18}`,
    address: '',
    confirmPassword: '',
    countryCode: countryCode?.toUpperCase() || '',
    currencyCode: tenantDetails?.tenantConfig?.allowedCurrencies?.includes(
      countryCurrency?.[countryCode?.toUpperCase()]
    )
      ? countryCurrency?.[countryCode?.toUpperCase()]
      : '',
    gender: '',
    nationality: '',
    preferredLanguage: '',
    newsLetter: true,
    sms: true,
    newsletterAndSms: true,
    zipCode: '',
    city: ''
  }

  const validationSchema = () => {
    return [' ', '', 'EN'].includes(getItem('language')?.toUpperCase())
      ? userSigninSchemaEN(regFields, languageData)
      : userSigninSchema(regFields, languageData)
  }

  const onSubmit = async (formValues) => {
    try {
      await checkIsEmailUnique(formik?.current?.values?.email)
    } catch (error) {
      await formik?.current?.setFieldTouched('email', true)
      formik?.current?.setFieldError('email', languageData?.emailAlreadyRegistered)
      return
    }
    try {
      await checkIsUsernameUnique(formik?.current?.values?.username)
    } catch (error) {
      await formik?.current?.setFieldTouched('username', true)
      formik?.current?.setFieldError('username', languageData?.userNameAlreadyTaken)
      return
    }

    let toastMessage = `${languageData?.pleaseAccept}`

    // if (!acceptConditions.privacyPolicy) {
    //   toastMessage += ` ${languageData?.acceptPrivacyPolicy}`
    // }
    if (!acceptConditions.termsAndConditions) {
      toastMessage += ` ${languageData?.acceptTerms}`
    }

    let modeOfCommShouldValidate = false

    if (regFields?.sms === 2 || regFields?.newsLetter === 2 || regFields?.newsletterAndSms === 2) {
      modeOfCommShouldValidate = true
    }

    if (
      modeOfCommShouldValidate &&
      (
        (regFields?.newsLetter === 2 && !acceptConditions?.newsLetter) ||
        (regFields?.sms === 2 && !acceptConditions?.sms) ||
        (regFields?.newsletterAndSms === 2 && !acceptConditions?.newsletterAndSms)
      )
    ) {
      if (
        acceptConditions.termsAndConditions /* && acceptConditions.privacyPolicy */
      ) {
        toastMessage += `${languageData?.modeOfComm}`.replace('and', '')
      } else {
        toastMessage += ` ${languageData?.modeOfComm}`
      }
    } else {
      modeOfCommShouldValidate = false
    }

    if (
      // !acceptConditions.privacyPolicy ||
      !acceptConditions.termsAndConditions ||
      modeOfCommShouldValidate
    ) {
      toast(`${toastMessage} ${languageData?.beforeNextStep}`, 'error')
    } else {
      handleInitialState(formValues)
    }
  }

  return (
    <Formik
      enableReinitialize
      innerRef={formik}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      validateOnBlur
      validateOnChange
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <>
            <Form className='signup-form'>
              <LoginHeading
                language={language}
                setLanguage={setLanguage}
                langKey='signupStartWithEmail'
              />
              {mobileOrTablet && <MobileSignupImage />}

              {regFields && (
                <div className='signup-right-section'>
                  <div className='form'>
                    <div>
                      {/* <ProcessBar isFinalStep={isFinalStep} /> */}

                      <LoginDetail
                        formik={formik}
                      />
                      <PersonalDetail
                        formik={formik}
                        acceptConditions={acceptConditions}
                        setAcceptConditions={setAcceptConditions}
                      />
                      {/* )} */}
                    </div>
                    <SignupFooter
                      isFinalStep={isFinalStep}
                      formik={formik}
                    />
                  </div>
                </div>
              )}
            </Form>
            <PersistFormikValues
              ignoreValues={[
                'address',
                'city',
                'countryCode',
                'currencyCode',
                'dateOfBirth',
                'firstName',
                'gender',
                'lastName',
                'nationality',
                'newsLetter',
                'password',
                'phone',
                'phoneCode',
                'preferredLanguage',
                'sms',
                'confirmPassword',
                'zipCode'
              ]}
              persistInvalid
              name='signup-form'
            />
          </>
        )
      }}
    </Formik>
  )
}

export default SignupForm
