import React, { useContext } from 'react'
import './styles/index.scss'
import BalanceCard from './BalanceCard'
import withContextProvider from '../../../wrapper/withContextProvider'
import { dashboardContext } from '../../../context'

const BalanceSection = () => {
  const { userDetails, languageData } = useContext(dashboardContext)

  const balanceConstant = [
    {
      balanceIcon: 'dashboard-total-balance.svg',
      balanceText: `${languageData?.totalBalance}`,
      balanceType: 'totalAmount'
    },
    {
      balanceIcon: 'dashboard-bonus-balance.svg',
      balanceText: `${languageData?.bonusBalance}`,
      balanceType: 'nonCashAmount'
    },
    {
      balanceIcon: 'dashboard-cash-balance.svg',
      balanceText: `${languageData?.cashBalance}`,
      balanceType: 'amount'
    },
    {
      balanceIcon: 'dashboard-cashback.svg',
      balanceText: languageData?.headerCashback,
      balanceType: 'cashback'
    }
  ]

  return (
    <div className='dashboard-section'>
      <div className='balance-section'>
        {userDetails &&
          balanceConstant.map((balanceCardsDetail) => (
            <BalanceCard
              key={balanceCardsDetail.balanceType}
              balanceCardsDetail={balanceCardsDetail}
              userDetails={userDetails}
            />
          ))}
      </div>
    </div>
  )
}

export default withContextProvider(BalanceSection)
