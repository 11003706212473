import React, { useState } from 'react'
import useDetailPopover from '../../../../pages/Bonus/hooks/useDetailPopover'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-bootstrap'

const useBonusCard = (props) => {
  const {
    selectedLanguageCode,
    activateBonus,
    setSelectedBonus,
    setForfeitModal
  } = props
  const [selectedBonusDetail, setSelectedBonusDetail] = useState(null)

  const [showFreeSpinGameModal, setShowFreeSpinGameModal] = useState(false)

  const { keys, dataToShow } = useDetailPopover()

  const navigate = useNavigate()

  const bonusPopover = () => {
    return (
      keys?.length > 0 &&
      keys?.map(({ label, value }) => {
        return (
          value && (
            <div key={`${label}-${value}`} className='text-left'>
              <span>{label} : </span>
              <span>{value} </span>
            </div>
          )
        )
      })
    )
  }

  const renderTooltip = (props) => (
    <Tooltip {...props}>{bonusPopover()}</Tooltip>
  )

  const overlayContent = (bonusDetail) => {
    return (
      <div className=''>
        {bonusDetail?.other?.bonusTitle
          ? typeof bonusDetail?.other?.bonusTitle === 'string'
            ? bonusDetail?.other?.bonusTitle
            : `${
                bonusDetail?.other?.bonusTitle?.[selectedLanguageCode] ||
                bonusDetail?.other?.bonusTitle?.EN
              }`
          : `${
              bonusDetail?.bonus?.promotionTitle?.[selectedLanguageCode] ||
              bonusDetail?.bonus?.promotionTitle?.EN
            }`}
      </div>
    )
  }

  const handleBonusActivation = (bonusList, bonusDetail, identifier = '') => {
    if (
      bonusList.rows.some(
        (bonusItem) =>
          bonusItem.status === 'ACTIVE' || bonusItem?.status === 'IN-PROCESS'
      )
    ) {
      setForfeitModal(true)
      setSelectedBonus(
        bonusList.rows.find(
          (bonusItem) =>
            bonusItem.status === 'ACTIVE' || bonusItem?.status === 'IN-PROCESS'
        )
      )
    } else {
      activateBonus(bonusDetail, identifier)
      setTimeout(() => {
        setShowFreeSpinGameModal(false)
      }, 1000)
    }
  }

  return {
    handleBonusActivation,
    overlayContent,
    renderTooltip,
    dataToShow,
    navigate,
    selectedBonusDetail,
    setSelectedBonusDetail,
    showFreeSpinGameModal,
    setShowFreeSpinGameModal,
    ...props
  }
}

export default useBonusCard
