import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import './styles/index.scss'

// CustomOverlayTrigger component
const CustomOverlayTrigger = (props) => {
  // Destructure props
  const {
    trigger,
    placement,
    overlayType,
    overlayId,
    overlayClassName,
    overlayContent,
    children
  } = props

  // Render Tooltip component
  const renderTooltip = (props) => (
    <Tooltip id={overlayId} className={overlayClassName} {...props}>
      {overlayContent}
    </Tooltip>
  )

  // Render Popover component
  const renderPopup = () => (
    <Popover id={overlayId} className={overlayClassName} {...props}>
      <Popover.Body>{overlayContent}</Popover.Body>
    </Popover>
  )

  // Return OverlayTrigger component with either Tooltip or Popover as overlay
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={overlayType === 'tooltip' ? renderTooltip : renderPopup}
    >
      {children}
    </OverlayTrigger>
  )
}

export default CustomOverlayTrigger
