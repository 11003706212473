import React, { useContext } from 'react'
import PhoneInput from 'react-phone-input-2'
import { SignUpContext } from '../../../pages/NewSignup/useContext'
import 'react-phone-input-2/lib/style.css'

const PhoneInputField = ({ formik, disabled = false }) => {
  const { countryCode, setIsPassValid, languageData } = useContext(SignUpContext)
  const { setFieldValue, setFieldTouched } = formik
  return (
    <PhoneInput
      name='phone'
      type='text'
      disabled={disabled}
      containerClass='phoneInputContainer'
      inputClass='phoneInputClass'
      aria-describedby='emailHelp'
      alwaysDefaultMask={false}
      enableSearch
      placeholder={languageData?.phonePlaceholder}
      enableLongNumbers
      country={countryCode?.toLowerCase() || 'us'}
      onChange={(phone, code) => {
        code = String(code?.dialCode)
        const newPhone = phone.substring(code.length)
        let isPhoneNotValid = true
        const newCode = '+' + code
        setFieldValue('phone', newPhone)
        setFieldValue('phoneCode', newCode)
        if (!(newPhone?.length > 15 || newPhone?.length < 5)) {
          isPhoneNotValid = false
        }
        if (newPhone?.charAt(0) === '0' || isPhoneNotValid) {
          setIsPassValid(false)
        } else {
          setIsPassValid(true)
        }
      }}
      onBlur={() => setFieldTouched('phone', true)}
      buttonStyle={{ backgroundColor: '#22214b' }}
      inputStyle={{ width: '100%' }}
    />
  )
}

export default PhoneInputField
