import { getRequest, postRequest, putRequest, deleteRequest } from './axios'
import { getDeviceType } from './getDeviceType'
import { getCookie } from './storageUtils'

const { REACT_APP_API_URL } = process.env

const userLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/login`, data)

const getRegistrationFields = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-registration-fields`)

const userSignUp = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/sign-up`, data)

const checkIsEmailUnique = (email) =>
  getRequest(`${REACT_APP_API_URL}/api/user/check-unique-constraints?email=${email}`)

const checkIsUsernameUnique = (username) =>
  getRequest(`${REACT_APP_API_URL}/api/user/check-unique-constraints?username=${username}`)

const getGameCategory = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-game-category`)

const getGameSubCategory = ({ categoryId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-game-sub-category?categoryId=${categoryId}&deviceType=${getDeviceType()}`)

const getCasinoGames = ({ subCategoryId = '', page, limit, search, provider }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-category-games?limit=${limit}&pageNo=${page}&subCategoryId=${subCategoryId}${search ? `&search=${search}` : ''}${provider && provider.length > 0 ? `&providerId=${JSON.stringify(provider)}` : ''}&deviceType=${getDeviceType()}`)

const getCasinoMenus = ({ limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-casino-menus?pageNo=${pageNo}&limit=${limit}`)

const getCasinoMenuItems = ({ limit, pageNo, casinoMenuId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-menu-items?limit=${limit}&pageNo=${pageNo}&casinoMenuId=${casinoMenuId}`)
const getLobbyData = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-menus-item`)

const getTenantDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-detail`)

const getAllCountries = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-all-country`)

const playGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/init-game`, data)

const demoGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/demo-game`, data)
const getProviders = (orderBy) => {
  const queryParam = orderBy ? `?orderBy=${orderBy}` : ''
  return getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-game-provider${queryParam}`)
}

const getUserDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-details`)

const updateUserDetails = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/update-user-details`, data)

const addFavoriteGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/add-favorite-game`, data)

const removeFavoriteGame = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/user/remove-favorite-game`, data)

const getFavoriteGame = ({ limit, page }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-favorite-games?limit=${limit}&pageNo=${page}`)

const verifyEmail = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/verify-email`, data)

const resendEmail = (email) =>
  postRequest(`${REACT_APP_API_URL}/api/user/refresh-email-token`, { email })

const getUserBetHistory = ({ limit, pageNo, search, startDate, endDate, actionType }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-casino-transactions?limit=${limit}&pageNo=${pageNo}&search=${search}&startDate=${startDate}&endDate=${endDate}&actionType=${actionType}`)

const uploadProfileImage = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/upload-profile-image`, data)

const removeProfileImage = () =>
  putRequest(`${REACT_APP_API_URL}/api/user/remove-profile-image`)

const forgetPassword = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/forget-password`, data)

const resetPassword = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/verify-forget-password`, data)

const getUserCredential = (type) =>
  getRequest(`${REACT_APP_API_URL}/api/user/init-pay?type=${type}`)

const getWithdrawalHistory = ({ limit, pageNo, status, startDate, endDate, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-withdraw-requests?limit=${limit}&pageNo=${pageNo}&status=${status}&startDate=${startDate}&endDate=${endDate}&search=${search}`)

const cancelWithdrawRequest = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/cancel-withdraw-request`, data)

const getDocumentsLabel = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/get-document-label`)

const getDocuments = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/get-documents`)

const updateDocuments = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/document/update-user-document`, data)

const addBrowserId = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/insert-unique-key`, data)

const getAllBonus = ({ limit, pageNo, bonusType }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-all-bonus?limit=${limit}&pageNo=${pageNo}&bonusType=${bonusType}`)

const getBonusDetail = ({ bonusId, userBonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-bonus-detail?bonusId=${bonusId}&userBonusId=${userBonusId}`)

const claimBonus = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/avail-bonus`, data)

const getUserBonus = ({ limit, pageNo, status, inDeposit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-bonus?limit=${limit}&pageNo=${pageNo}&status=${status}&inDeposit=${inDeposit}`)

const cancelBonus = ({ data }) =>
  deleteRequest(`${REACT_APP_API_URL}/api/user/cancel-bonus`, data)

const getUserTransactions = ({ limit, pageNo, startDate, endDate, transactionType, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&transactionType=${transactionType}&search=${search}`)

const availBonus = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/user/avail-bonus`, data)

const getLoyaltyLevel = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-loyalty-level`)

const getLoyaltyDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-loyalty-details`)

const setDailyLimit = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-daily-limit`, data)

const setLossLimit = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-loss-limit`, data)

const setDepositLimit = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-deposit-limit`, data)

const setSessionTime = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-session-time`, data)

const setDisableUntil = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/set-disable-until`, data)

const getCmsPage = ({ cmsPageId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-cms-page?cmsPageId=${cmsPageId}`)

const getAllGames = ({ limit, pageNo, category, search, rating, themes, provider }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-games?limit=${limit}&pageNo=${pageNo}&category=${category}&search=${search}&rating=${rating}&themes=${themes}&provider=${provider}&deviceType=${getDeviceType()}`)

const getLimitTable = ({ userId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-limit-table?userId=${userId}`)

const getLanguages = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-languages`)

const getLanguageData = ({ manual }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-language-support-keys?manual=${manual}`)

const getTopGames = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-top-games?limit=${limit}`)

const getTopWinners = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-top-winners?limit=${limit}`)

const getCurrentWinners = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-current-winners?limit=${limit}`)

const logout = () =>
  postRequest(`${REACT_APP_API_URL}/api/user/logout`)

const getPaymentProviders = ({ paymentType, bonusId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-payment-providers?paymentType=${paymentType}&bonusId=${bonusId}`)

const verifyPayment = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/validate-payment`, data)

const getPaymentStatus = ({ transactionId, sessionId, aggregator, orderId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/payment-status?transactionId=${transactionId}&sessionId=${sessionId}&aggregator=${aggregator}&orderId=${orderId}`)
const getSumSubAccessToken = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/init-access-token`)

const getAllReviews = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-reviews`)

const getThemes = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/themes`)

const getCashbackBalance = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/cashback-balance`)

const getCashbackTable = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-cashback-bonus`)

const getWalletAmount = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/wallet-amount`)

const playTournamentGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/init-tournament-game`, data)

const getCasinoProvider = ({ category = '', search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-category-providers?category=${category}${search ? `&search=${search}` : ''}`)

const getTournament = ({ limit, status, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/tournament?limit=${limit}&pageNo=${pageNo}&status=${status}`)

const getTournamentDetails = ({ tournamentId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tournament/details?tournamentId=${tournamentId}`)

const getRecentGames = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/recent-games?limit=${limit}`)

const setGamePlayLimit = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/game-play-limit`, data)

const stopGamePlayTimer = () =>
  postRequest(`${REACT_APP_API_URL}/api/user/update-game-limit`, {})

const submitOTP = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/phone-otp`, data)

const changePhoneNumber = (data) =>
  getRequest(`${REACT_APP_API_URL}/api/user/phone-otp?phone=${data?.phone}&phoneCode=${data?.phoneCode}`)

const resendPhoneOTP = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/phone-otp`)

const getUtorgCurrencies = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/utorg-currencies`)

const getHomePageConfig = async () => {
  return new Promise((resolve) => {
    setTimeout(() => resolve({
      'top-performance': [{
        priority: 1,
        component: 'current-winners',
        isVisible: true
      },
      {
        priority: 2,
        component: 'top-winners',
        isVisible: true
      },
      {
        priority: 3,
        component: 'top-games',
        isVisible: true
      }]
    }), 1000)
  })
}

// apical.js

export const fetchRedirectionUrl = async ({ accessToken, countryCode }) => {
  try {
    const { REACT_APP_API_URL } = process.env
    const headers = accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
    // Set countryCode as a header if it exists in the cookie
    if (getCookie('countryCode')) {
      headers['Country-Code-Id'] = getCookie('countryCode') || ''
    }
    // Pass countryCode as a query parameter in the URL
    const response = await fetch(`${REACT_APP_API_URL}/api/tenant/get-redirection-url` + (countryCode ? `?countryCodeId=${countryCode}` : ''), {
      method: 'GET',
      headers
    })

    if (response.ok) {
      const data = await response.json()
      return data?.data?.redirectionUrl || null
    } else {
      throw new Error('Failed to fetch redirection URL')
    }
  } catch (error) {
    console.error('Error fetching redirection URL:', error)
    return null
  }
}

export {
  userLogin,
  getAllReviews,
  getRegistrationFields,
  userSignUp,
  getCasinoMenus,
  getCasinoMenuItems,
  getTenantDetails,
  getAllCountries,
  getLobbyData,
  getGameCategory,
  getGameSubCategory,
  getCasinoGames,
  playGame,
  getProviders,
  getUserDetails,
  updateUserDetails,
  demoGame,
  addFavoriteGame,
  removeFavoriteGame,
  getFavoriteGame,
  verifyEmail,
  resendEmail,
  getUserBetHistory,
  uploadProfileImage,
  removeProfileImage,
  forgetPassword,
  resetPassword,
  getUserCredential,
  getWithdrawalHistory,
  cancelWithdrawRequest,
  getDocumentsLabel,
  getDocuments,
  updateDocuments,
  addBrowserId,
  getAllBonus,
  getBonusDetail,
  claimBonus,
  getUserBonus,
  cancelBonus,
  getUserTransactions,
  availBonus,
  getLoyaltyLevel,
  getLoyaltyDetails,
  getCmsPage,
  getAllGames,
  setDailyLimit,
  setLossLimit,
  setDepositLimit,
  setSessionTime,
  setDisableUntil,
  getLimitTable,
  getLanguages,
  getLanguageData,
  getTopGames,
  getTopWinners,
  getCurrentWinners,
  checkIsEmailUnique,
  checkIsUsernameUnique,
  logout,
  getPaymentProviders,
  verifyPayment,
  getPaymentStatus,
  getSumSubAccessToken,
  getThemes,
  getCashbackBalance,
  getCashbackTable,
  getWalletAmount,
  playTournamentGame,
  getCasinoProvider,
  getTournament,
  getTournamentDetails,
  getRecentGames,
  setGamePlayLimit,
  stopGamePlayTimer,
  submitOTP,
  resendPhoneOTP,
  changePhoneNumber,
  getUtorgCurrencies,
  getHomePageConfig
}
