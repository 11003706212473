import React from 'react'
import AllGamesLobby from '../../components/Lobby/AllGamesLobby'
import './styles/index.scss'
import useCasinoMenuList from './hooks/useCasinoMenuList'
import Banner from '../../components/Banner'

const CasinoPage = ({ selectedFilter, setSelectedFilter }) => {
  const {
    showBanner,
    showLeagueList
  } = useCasinoMenuList()

  return (
    <>
      <div className='casino-page-section'>
        <section className='wrapper-content'>
          {showBanner && <Banner pageType='casino' anchorTagFilterType='casinoBannerJoinNow' />}
        </section>
        <section className='wrapper-content casino-page'>
          <div className='games-content'>
            {showLeagueList && <AllGamesLobby selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />}
          </div>
        </section>

      </div>
    </>
  )
}

export default CasinoPage
