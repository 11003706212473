import React from 'react'

const withContextProvider = (WrappedComponent) => {
  const Provider = ({ Context, ...props }) => {
    return (
      <Context.Provider value={{ ...props }}>
        <WrappedComponent {...props} />
      </Context.Provider>
    )
  }

  return Provider
}

export default withContextProvider
