import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllBonusStart } from '../../../store/redux-slices/bonus'

const usePromotion = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { selectedLanguageCode } = useSelector(state => state.language)
  const { allBonus, loading } = useSelector(state => state.bonus)
  const { languageData } = useSelector(state => state.language)
  const { bannerUrl } = useSelector(state => state?.casinoMenu?.gameCategory)
  const { loading: bannerLoading } = useSelector(state => state?.casinoMenu)
  const [limit, setLimit] = useState(8)

  useEffect(() => {
    if (document.getElementById('promotionPage')) {
      document.getElementById('promotionPage').onclick = function (e) {
        e?.preventDefault()
        const href = document.getElementById('promotionPage')?.getAttribute('href')
        navigate(href?.split(`${window?.location?.origin}`)?.[1])
      }
    }
  }, [loading])

  useEffect(() => {
    dispatch(getAllBonusStart({ limit, pageNo: 1, bonusType: 'all' }))
  }, [limit])

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  return {
    allBonus,
    loading,
    limit,
    languageData,
    bannerUrl,
    selectedLanguageCode,
    bannerLoading,
    setLimit,
    navigate,
    capitalize
  }
}

export default usePromotion
