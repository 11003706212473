import React, { useContext } from 'react'
import { UserDetailContext } from '../../../../pages/NewAccountInfoPage/useContext'
import { Tab, Tabs } from 'react-bootstrap'
import ChangePassword from './ChangePassword'
import Subscriptions from './Subscriptions'
import PrivateData from './PrivateData'
import Address from './Address'
import { useSelector } from 'react-redux'

const UserInfoTabs = ({ formik }) => {
  const formDetail = useContext(UserDetailContext)
  const { regFields, languageData, setSelectedTab, selectedTab } =
    formDetail
  const { userDetails } = useSelector(state => state.user)

  const getSubscriptionTabVisibility = () => {
    if (regFields && (!!regFields?.newsLetter || !!regFields?.sms || !!regFields?.newsletterAndSms)) {
      if (process.env.REACT_APP_SHOW_SUBSCRIPTION_TAB === 'true') {
        return true
      } else if (userDetails?.sms === 'true' || userDetails?.newsLetter === 'true' || userDetails?.newsletterAndSms === 'true') {
        return false
      } else {
        return true
      }
    }

    return false
  }

  return (
    <Tabs
      activeKey={selectedTab}
      onSelect={(e) => e !== selectedTab && setSelectedTab(e)}
    >
      <Tab
        eventKey='private-data'
        title={languageData?.accountsInfoFirstTab || 'Private Data'}
      >
        <PrivateData formik={formik} />
      </Tab>

      <Tab eventKey='address' title={languageData?.signupAddress}>
        <Address formik={formik} />
      </Tab>

      <Tab
        eventKey='change-password'
        title={languageData?.changePassword}
      >
        <ChangePassword formik={formik} />
      </Tab>

      {getSubscriptionTabVisibility() && (
        <Tab
          eventKey='subscription'
          title={languageData?.accountsInfoFourthTab || 'Subscription'}
        >
          <Subscriptions formik={formik} />
        </Tab>
      )}
    </Tabs>
  )
}

export default UserInfoTabs
