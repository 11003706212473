import { useSelector } from 'react-redux'

const useCasinoMenuList = () => {
  const { showBanner, showLeagueList } = useSelector(state => state?.loader)

  return {
    showBanner,
    showLeagueList
  }
}

export default useCasinoMenuList
