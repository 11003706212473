import { Dropdown } from 'react-bootstrap'
import React from 'react'
import CustomDropdownToggle from '../../custom/CustomDropdownToggle'
const DateSelector = ({ label, values, onChange, options, getValue, disabled = false }) => {
  return (
    <Dropdown className={`icon-left ${disabled ? 'pe-none' : ''}`}>
      <Dropdown.Toggle
        as={CustomDropdownToggle} // Use the custom button component
        id='dropdown-basic'
        style={{ background: 'rgba(0, 0, 0, 0)' }}
      >
        {' '}
        <img
          src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`}
          alt='Dropdown'
        />
        <div className='text-capitalize date-text'>{getValue(values)}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className='scrollDropdown'>
          {options.map((option) => (
            <Dropdown.Item
              value={option}
              key={option}
              className={option === getValue(values) ? 'active-item' : ''}
              onClick={(e) => onChange(e.target.innerText, label)}
            >
              {option}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DateSelector
