import React, { useContext } from 'react'
import { ModalComponent } from '../../CustomModal'
import {
  LapsedModalOption,
  deleteBonusModalOption,
  forfeitModalOption
} from '../../../constants/modalOption'
import { dashboardContext } from '../../../context'

const DashboardModal = () => {
  const dashboardDetail = useContext(dashboardContext)
  const {
    cancelBonus,
    forfeitModal,
    setForfeitModal,
    selectedBonus,
    selectedLanguageCode,
    showModal,
    setShowModal,
    showLapsedModal,
    setShowLapsedModal,
    selectedForfeitBonus,
    languageData
  } = dashboardDetail

  return (
    <>
      {/* Forfeit modal */}
      {showModal &&
        <ModalComponent
          showModal={showModal}
          handleSubmit={() => {
            cancelBonus(selectedForfeitBonus?.userBonusId, selectedForfeitBonus?.status)
            setShowModal(false)
          }}
          handleClose={() => setShowModal(false)}
          {...deleteBonusModalOption}
        >
          {languageData?.notUsableBonus}
        </ModalComponent>}

      {
      forfeitModal &&
        <ModalComponent
          showModal={forfeitModal}
          handleSubmit={() =>
            cancelBonus(selectedBonus?.userBonusId, selectedBonus?.status)}
          handleClose={() => setForfeitModal(false)}
          {...forfeitModalOption}
        >
          <p>{languageData?.forfeitMessageFirst}</p>
          <span className='text-capitalize highlighted-content'>
            {` ${
            selectedBonus?.bonus?.promotionTitle?.[selectedLanguageCode] ||
            selectedBonus?.bonus?.promotionTitle?.EN
          } `}
          </span>
          <p>{languageData?.forfeitMessageSecond}</p>
        </ModalComponent>
    }

      {/* Lapsed modal */}
      {
        showLapsedModal &&
          <ModalComponent
            showModal={showLapsedModal}
            handleClose={() => setShowLapsedModal(false)}
            {...LapsedModalOption}
          >
            {languageData?.bonusLapsed}
          </ModalComponent>
      }

    </>
  )
}

export default DashboardModal
