
export const deleteBonusModalOption = {
  mainBodyClass: 'bonus-modal generic-modal-layout',
  headerClass: 'd-flex flex-column justify-content-center',
  modalTitle: 'areYouSure',
  submitBtnClass: 'btn-modal w-100  mt-3 btn-grn',
  submitBtnText: 'signupConfirm',
  closeBtnClass: 'btn-modal w-100 btn-cancel',
  closeBtnText: 'cancel',
  footerClass: 'd-flex justify-content-center mt-4',
  showTitle: false
}

export const forfeitModalOption = {
  mainBodyClass: 'generic-modal-layout',
  headerClass: 'd-flex flex-column justify-content-center',
  modalTitle: 'areYouSure',
  bodyClass: 'main-content',
  submitBtnClass: 'btn-modal btn-cancel',
  submitBtnText: 'forfeitButton',
  closeBtnClass: 'btn-modal',
  closeBtnText: 'cancel',
  footerClass: 'd-flex justify-content-around',
  showTitle: false
}

export const LapsedModalOption = {
  mainBodyClass: 'generic-modal-layout',
  bodyClass: 'main-content',
  closeBtnClass: 'btn-modal w-100',
  closeBtnText: 'ok',
  showSubmitBtn: false,
  showHeader: false,
  showTitle: false
}

export const gamePlayLimitDataModalConstants = {
  mainBodyClass: 'generic-modal-layout',
  headerClass: 'd-flex flex-column justify-content-center',
  modalTitle: 'areYouSure',
  bodyClass: 'main-content',
  submitBtnClass: 'btn-modal',
  submitBtnText: 'continue',
  closeBtnClass: 'btn-modal btn-cancel',
  closeBtnText: 'stop',
  footerClass: 'd-flex justify-content-around',
  showTitle: false
}

export const exceedLimitModalOption = {
  mainBodyClass: 'exceed-limit-modal generic-modal-layout',
  bodyClass: 'main-content',
  submitBtnClass: 'btn-modal font-16',
  submitBtnTextKey: 'seeLimits',
  closeBtnClass: 'btn-modal font-16',
  closeBtnTextKey: 'backToHome',
  showSubmitBtn: true,
  showHeader: false,
  showTitle: false,
  backdrop: 'static'
}

export const blockedCountryModalOption = {
  mainBodyClass: 'block-country-container',
  size: 'lg',
  showSubmitBtn: false,
  showHeader: false,
  showTitle: false,
  showFooter: false,
  backdrop: 'static'
}
