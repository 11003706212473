import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserBetHistoryStart } from '../../../store/redux-slices/transactions'
import { formatDateYMD, getDateDaysAgo } from '../../../utils/dateFormatter'
import { useDidMountEffect } from '../../../utils/useDidMountEffect'

const useBetHistory = () => {
  const isInitialRender = useDidMountEffect()
  const dispatch = useDispatch()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [selectedAction, setSelectedAction] = useState('all')

  const { betHistory, betLoading } = useSelector(state => state.transactions)
  const { languageData } = useSelector((state) => state.language)
  const { userDetails } = useSelector(state => state.user)
  const totalPages = Math.ceil(betHistory?.count / limit)
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  useEffect(() => {
    dispatch(getUserBetHistoryStart({
      limit,
      pageNo: page,
      search,
      startDate: formatDateYMD(state?.[0]?.startDate),
      endDate: formatDateYMD(state?.[0]?.endDate),
      actionType: selectedAction
    }))
  }, [limit, page, selectedAction, state])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getUserBetHistoryStart({
            limit,
            pageNo: page,
            search,
            startDate: formatDateYMD(state?.[0]?.startDate),
            endDate: formatDateYMD(state?.[0]?.endDate),
            actionType: selectedAction
          }))
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const fetchLatestChanges = () => {
    if (limit === 10 && page === 1 && state?.[0]?.startDate === new Date().setDate(new Date().getDate() - 10) &&
   state?.[0]?.endDate === new Date() && selectedAction === 'all' && search === '') {
      dispatch(dispatch(getUserBetHistoryStart({
        limit,
        page,
        startDate: formatDateYMD(state?.[0]?.startDate),
        endDate: formatDateYMD(state?.[0]?.endDate),
        transactionType: selectedAction,
        search
      })))
    } else {
      setLimit(10)
      setPage(1)
      setState([
        {
          startDate: getDateDaysAgo(10),
          endDate: new Date(),
          key: 'selection'
        }
      ])
      setSelectedAction('all')
      setSearch('')
    }
  }

  return {
    loading: betLoading,
    betHistory,
    limit,
    page,
    search,
    selectedAction,
    totalPages,
    userDetails,
    languageData,
    setSelectedAction,
    setSearch,
    setLimit,
    setPage,
    fetchLatestChanges,
    state,
    setState
  }
}

export default useBetHistory
