import { Buffer } from 'buffer'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from '../../../components/Toast'
import {
  getAllCountriesStart,
  getRegistrationFieldsStart
} from '../../../store/redux-slices/login'
import { getUserDetailsStart, removeProfileImageStart, uploadProfileImageStart, userUpdateInfoStart } from '../../../store/redux-slices/user'
import { formatDateMDY } from '../../../utils/dateFormatter'

const useUserDetailsInfo = () => {
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState('private-data')
  const [phoneError, setPhoneError] = useState(false)
  const { regFields, allCountries } = useSelector((state) => state.login)
  const { languages, selectedLanguageCode } = useSelector((state) => state.language)
  const { loading, userDetails } = useSelector((state) => state.user)
  const { languageData } = useSelector((state) => state.language)
  const dispatch = useDispatch()
  const [completePhone, setCompletePhone] = useState('')
  const [search, setSearch] = useState('')
  const [toggleViewPassword, setToggleViewPassword] = useState({
    password: false,
    confirmPassword: false
  })
  const [isPassValid, setIsPassValid] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const formRef = useRef()

  // Taking only the true registration fields
  const arr =
        regFields &&
        Object.keys(regFields)
          .map((keyName, i) => regFields[keyName] !== false && keyName)
          .filter((ele) => ele !== false && ele)

  // Converting registration fields array into object with empty values
  const initialState =
    arr &&
    arr.reduce((accumulator, currentValue) => {
      accumulator[currentValue] = ''
      if (currentValue === 'address') {
        accumulator.zipCode = ''
        accumulator.city = ''
      }
      if (currentValue === 'phone') {
        accumulator.phoneCode = ''
      }
      if (currentValue === 'preferredLanguage') {
        accumulator.preferredLanguage = ''
      }
      if (currentValue === 'newsLetter') {
        accumulator.newsLetter = 'false'
      }
      if (currentValue === 'sms') {
        accumulator.sms = 'false'
      }
      return accumulator
    }, {})

  if (userDetails && initialState) {
    for (const k in initialState) initialState[k] = userDetails?.[k] || ''
    initialState.newsLetter = userDetails?.newsLetter || 'false'
    initialState.sms = userDetails?.sms || 'false'
  }

  const handleInitialState = (formValues) => {
    for (const k in initialState) initialState[k] = formValues[k]
    initialState.newsLetter = formValues?.newsLetter || 'false'
    initialState.sms = formValues?.sms || 'false'
    initialState.newsletterAndSms = formValues?.newsletterAndSms || 'false'
    for (const k in initialState) {
      if (regFields[k] === 0 ||
                initialState[k] === '' || initialState[k] === null || initialState[k] === undefined) {
        delete initialState[k] // Deleting the fields which are empty/null
        if (!isChecked) {
          delete initialState.password
        }
      }
    }
    let encryptedPass = null
    if (formValues.password) {
      encryptedPass = Buffer.from(formValues.password).toString('base64')
      initialState.password = encryptedPass
      initialState.confirmPassword && (initialState.confirmPassword = encryptedPass)
    }
    initialState.dateOfBirth && (initialState.dateOfBirth = formatDateMDY(initialState.dateOfBirth))
    if (regFields?.phone === 2 && !isPassValid) {
      toast(languageData?.enterValidPhone, 'error')
    } else if (
      (regFields?.newsLetter === 2 && initialState.newsLetter === 'false') ||
      (regFields?.sms === 2 && initialState.sms === 'false') ||
      (regFields?.newsletterAndSms === 2 && initialState.newsletterAndSms === 'false')
    ) {
      toast(`${languageData?.pleaseAccept || 'Please accept'} ${languageData?.modeOfComm?.replace('and', '') || 'subscriptions'}`)
    } else {
      userUpdateInfo(initialState)
    }
  }

  useEffect(() => {
    dispatch(getRegistrationFieldsStart())
    dispatch(getUserDetailsStart())
  }, [])

  useEffect(() => {
    dispatch(getAllCountriesStart())
  }, [selectedLanguageCode])

  useEffect(() => {
    if (userDetails?.phone) {
      setCompletePhone('' + userDetails?.phoneCode?.substring(1) + userDetails.phone)
    }
  }, [userDetails])

  const userUpdateInfo = (initialState) =>
    dispatch(userUpdateInfoStart({ initialState, navigate }))

  const handleFileChange = (profileImage) => {
    if (profileImage?.type !== 'image/png') {
      if (profileImage?.type !== 'image/jpeg') {
        return toast(languageData?.formatRequired, 'error')
      }
    }
    if (profileImage === undefined) {
      return toast(languageData?.chooseImage, 'error')
    }
    if (profileImage?.size > 3 * 1024 * 1024) {
      return toast(languageData?.imageSize3MB, 'error')
    }
    if (profileImage) {
      dispatch(uploadProfileImageStart({ image: profileImage }))
    }
  }

  const removeImage = () => {
    if (userDetails?.profileImage) {
      dispatch(removeProfileImageStart())
    } else {
      toast(languageData?.noProfileImage, 'error')
    }
  }

  const showStar = (fieldName) =>
    fieldName === 2 &&
      <span className='text-danger'>&nbsp;*&nbsp;</span>
  return {
    userDetails,
    regFields,
    toggleViewPassword,
    loading,
    allCountries,
    completePhone,
    initialState,
    isChecked,
    languages,
    languageData,
    setIsChecked,
    setCompletePhone,
    setIsPassValid,
    setToggleViewPassword,
    handleInitialState,
    removeImage,
    handleFileChange,
    search,
    setSearch,
    selectedTab,
    setSelectedTab,
    setPhoneError,
    phoneError,
    showStar,
    formRef
  }
}
export default useUserDetailsInfo
