import { Form, Formik } from 'formik'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getFullDateTime } from '../../utils/dateFormatter'
import { getItem } from '../../utils/storageUtils'
import './styles/index.scss'
import { userLoginSchema } from './schema'
import useUserLogin from './useUserLogin'
import HtmlParser from 'react-html-parser'
import PasswordFieldC from '../../components/FormFieldControl/PasswordFieldC'
import FormFieldControl from '../../components/FormFieldControl'
import LoginHeading from '../../components/LoginHeading/LoginHeading'
import { UserRegisterButton } from '../../components/Buttons'

const Login = () => {
  const {
    loading,
    showModal,
    showResendEmail,
    languageData,
    language,
    setLanguage,
    setShowModal,
    handleSignIn
  } = useUserLogin()

  return (
    <>
      <section className='login-section'>
        <div className='login-left-section'  style={{ backgroundImage: `url('${languageData?.signupBackground}')` }}>
          <img
            loading='lazy'
            src={languageData?.loginBanner}
            className='desktop-login-banner m-auto h-auto'
            alt='login-img'
          />
          <div className='login-text'>
            <h1 className='text-center'>
              {/* {languageData?.loginKey}<br /> */}
              <span>{HtmlParser(languageData?.loginToYourAccount)}</span>
            </h1>
          </div>
        </div>

        <Formik
          enableReinitialize
          initialValues={{ email: '', password: '' }}
          validationSchema={userLoginSchema(languageData)}
          onSubmit={({ email, password }) => handleSignIn({ email, password })}
        >
          {(formik) => {
            return (
              <Form className='login-form'>
                <LoginHeading
                  language={language}
                  setLanguage={setLanguage}
                  langKey='loginKey'
                />
                <div className='mobile-login-img-box'>
                  <img
                    loading='lazy'
                    src={languageData?.loginBannerMobile}
                    className='w-100'
                    alt='login-img'
                  />

                  <div className='login-text-mobile'>
                    <div className='login-welcome-heading'>
                      {/* <span className='amazing-login'>{languageData?.loginKey}</span> */}
                      <br className='login-line ' />
                      <span className='amazing'>
                        {HtmlParser(languageData?.loginToYourAccount)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='login-right-section'>
                  <div className='form'>
                    <div>
                      <div className='custom-form-group'>
                        <FormFieldControl
                          type='email'
                          name='email'
                          control='input'
                          label='loginEmail'
                          extraLabel='loginUsername'
                          placeholder={languageData?.emailPlaceholder}
                          containerClassName='custom-form-group'
                          fieldClassName='form-control custom-input'
                        />
                      </div>

                      <div className='custom-form-group icon_include'>
                        <PasswordFieldC
                          formik={formik}
                          name='password'
                          label='loginPassword'
                          placeholder={languageData?.passwordPlaceholder}
                          fieldClassName='form-control custom-input'
                          isAccountInfo={false}
                          containerClassName='custom-form-group'
                        />

                        <div className='d-flex'>
                          {showResendEmail && (
                            <Link
                              to={`/${getItem(
                                'language'
                              )?.toLowerCase()}/resend-email`}
                              className='forget-link text-left'
                            >
                              {languageData?.resendEmail}
                            </Link>
                          )}
                          <Link
                            to={`/${getItem(
                              'language'
                            )?.toLowerCase()}/forgot-password`}
                            className='forget-link'
                          >
                            {languageData?.forgetPassword}?
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className='mt-mobile-15'>
                      <div>
                        <UserRegisterButton
                          formik={formik}
                          loading={loading}
                          spinner
                          text={languageData?.loginKey}
                        />
                      </div>

                      <div className='dont-have-ac'>
                        {languageData?.loginDoNotHaveAccount}?{' '}
                        <Link
                          to={`/${getItem('language')?.toLowerCase()}/signup`}
                        >
                          {languageData?.loginSignUp}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </section>

      <Modal
        show={showModal?.show}
        onHide={() =>
          setShowModal((showModal) => ({ ...showModal, show: false }))}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='generic-modal-layout'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabindex='-1'
        aria-hidden='true'
      >
        <div className='topdiv mx-auto'>
          <Modal.Body className='text-center'>
            {languageData?.accountFrozen}{' '}
            {getFullDateTime(showModal?.expiration)}.
          </Modal.Body>
          <Modal.Footer>
            <button
              className='btn-modal w-100'
              onClick={() =>
                setShowModal((showModal) => ({ ...showModal, show: false }))}
            >
              {languageData?.ok}
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export default Login
