import React from 'react'
import TournamentList from './components/TournamentList'
import useTournamentPage from './hooks/useTournamentPage'

const Tournaments = () => {
  const {
    limit,
    pageNo,
    status,
    setLimit,
    setStatus,
    setPageNo,
    fetchMore,
    selectedLanguageCode,
    languageData,
    tournaments,
    navigate,
    dispatch,
    bannerUrl,
    loading
  } = useTournamentPage()

  return (
    <TournamentList
      limit={limit}
      pageNo={pageNo}
      status={status}
      setLimit={setLimit}
      setStatus={setStatus}
      setPageNo={setPageNo}
      fetchMore={fetchMore}
      selectedLanguageCode={selectedLanguageCode}
      languageData={languageData}
      tournaments={tournaments}
      navigate={navigate}
      dispatch={dispatch}
      bannerUrl={bannerUrl}
      loading={loading}
    />
  )
}

export default Tournaments
