import React, { useEffect } from 'react'
import { showFooter } from '../../../store/redux-slices/loader'
import '../styles/index.scss'
import { tournamentStatus } from './constants'
import NoDataAvailable from '../../../components/NoDataAvailable'
import Loader from '../../../components/Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import TournamentListCard from './TournamentListCard'
import Banner from '../../../components/Banner'

const TournamentList = ({
  status,
  setStatus,
  fetchMore,
  pageNo,
  limit,
  selectedLanguageCode,
  languageData,
  tournaments,
  loading,
  navigate,
  dispatch,
  bannerUrl
}) => {
  useEffect(() => {
    dispatch(showFooter(true))
  }, [])

  return (
    <section className='wrapper-content'>
      <Banner pageType='tournament' />
      {/* Start From Here */}
      <div className='container tournament-section'>
        <div className='tournament-tabs'>
          {Object.keys(tournamentStatus).map((key, index) =>
            <p className={status === key ? 'active-tab' : ''} key={index} onClick={() => setStatus(key)}>
              {tournamentStatus[key]}
            </p>)}
        </div>

        <InfiniteScroll
          dataLength={tournaments?.rows?.length || 0}
          style={{ overflow: 'hidden' }}
          hasMore={pageNo <= (Math.ceil(tournaments?.count / limit) || 1)}
          loader={loading && <Loader top='2rem' bottom='4rem' />}
        >
          <div className='row mx-0'>
            {tournaments?.rows?.map((tournament, index) => {
              return (
                <TournamentListCard
                  tournament={tournament}
                  selectedLanguageCode={selectedLanguageCode}
                  navigate={navigate}
                  key={index}
                  languageData={languageData}
                />
              )
            })}
          </div>
        </InfiniteScroll>

        {!tournaments?.count && !loading &&
          <NoDataAvailable />}

        {limit * pageNo < tournaments?.count && !loading &&
          <div className='text-center m-4'>
            <a className='view-all-btn text-center' href={() => false} onClick={() => fetchMore()}>MORE TOURNAMENTS</a>
          </div>}
      </div>
    </section>
  )
}

export default React.memo(TournamentList)
