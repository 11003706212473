import React, { useContext } from 'react'
import { SignUpContext } from '../../../pages/NewSignup/useContext'
import SignupForm from './SignupForm'

const RightSection = () => {
  const { languageData } = useContext(SignUpContext)

  return (
    <> {languageData && <SignupForm />} </>
  )
}

export default RightSection
