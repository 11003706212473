import React from 'react'
import './styles/index.scss'
import Slider from 'react-slick'
import useLoyaltyBonus from './hooks/useLoyaltyBonus'
import HtmlParser from 'react-html-parser'
import Banner from '../../components/Banner'
import LoyaltyCard from './LoyaltyCard'

const LoyaltyBonus = () => {
  const {
    loyaltyLevel,
    languageData,
    selectedLanguageCode,
    navigate
  } = useLoyaltyBonus()

  const settings = {
    initialSlide: 0,
    rows: 1,
    dots: false,
    infinite: true,
    slidesToShow: 3.1,
    slidesToScroll: 3,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 500000,
    responsive: [
      {
        breakpoint: 1324,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1200, // Medium laptops/tablets
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 900, // New breakpoint
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 850, // New breakpoint
        settings: {
          slidesToShow: 2.01,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700, // New breakpoint
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 650, // New breakpoint
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <>
      <section className='wrapper-content'>
        <Banner pageType='loyalty' anchorTagFilterType='loyaltyBannerBtn' />
        <section className='reward-section container'>
          <div className='inner-heading'>
            <h2>
              {languageData?.loyaltyHeadingOne}:
            </h2>
            <h3>
              {languageData?.loyaltyHeadingTwo}
            </h3>
          </div>

          <div>
            <div className='reward-card text-start'>
              <p>{languageData?.loyaltyHeadingOneDesc} </p>
            </div>
          </div>
        </section>

      </section>
      <section className='loyalty-level-card-container'>
        {
          loyaltyLevel?.map((loyaltyLevelDetail, index) => {
            return (
              <LoyaltyCard key={index} loyaltyLevelDetail={loyaltyLevelDetail} languageData={languageData} />
            )
          })
}
      </section>

      <section className='testimonial-wrapper'>
        <div className='testomonal-section'>
          <div className='container-fluid' style={{ maxWidth: '1400px' }}>
            <div className='testimonial-container'>
              <div className='testimonial-image-wrapper'>
                <img className='testimonial-image' loading='lazy' src={languageData?.loyaltyPageSideImg} alt='Heart' />
              </div>
              <div className='testimonial-content'>
                <span className='testimonial-subheading'>{languageData?.loyaltyTestimonialHeadOne}…</span>
                <h2 className='testimonial-heading'>{languageData?.loyaltyTestimonialHeadTwo}!</h2>
              </div>
              <p className='testimonial-description'>{languageData?.loyaltyTestimonialDesc}!</p>
            </div>
          </div>
        </div>
      </section>

      <section className='wrapper-content bonuspage'>
        <section className='progression-section bonuspage'>
          <div className='wrapper'>
            <div className='inner-heading'>
              <h2>
                {languageData?.loyaltyHeadingFour}
              </h2>
            </div>
            <div className='progression-card-wrap'>
              <Slider {...settings}>
                {loyaltyLevel?.map((item, i) => {
                  return (
                    <div className='progression-card loyalty-slick-slide' key={`loyalty-bonus-listing ${i}`}>
                      <div className='progression-top'>
                        <span>{item?.endPoint} {languageData?.loyaltyPoints}</span>
                        <h3>{languageData?.headerLevel} {item?.level}</h3>
                      </div>
                      <div className='progression-bottom'>
                        <span>{languageData?.loyaltyCashback}:</span>
                        <h3>{Math.floor(item?.cashback_multiplier * 100)}%</h3>
                        <div className='progress'>
                          <div
                            className='progress-bar'
                            style={{ width: `${Math.floor(item?.cashback_multiplier * 100)}%` }}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
              <div className='progress-btn-wrap text-center'>
                <button
                  type='button' className='btn btn-primary'
                  onClick={() => navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/loyalty`, { state: { tab: 'loyalty' } })}
                >{HtmlParser(languageData?.checkProgress)}
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}

export default LoyaltyBonus
