import * as Yup from 'yup'
import { currencyCodeList } from '../../constants/currencyCodeList'

export const validateDepositSchema = ({ initValues, data, minDeposit, maxDeposit, currencyCode, type, languageData, walletAmount }) =>
  Yup.object().shape(schemaValidate(initValues, data, minDeposit, maxDeposit, currencyCode, type, languageData, walletAmount))

const schemaValidate = (initValues, data, minDeposit, maxDeposit, currencyCode, type, languageData, walletAmount) => {
  const validationObject = {}

  for (const row in data?.settings?.requiredFields) {
    const field = data.settings.requiredFields[row]
    const label = field.name
    const dataType = field.dataType

    if (label === 'amount') {
      validationObject[label] = Yup.number().typeError(languageData?.onlyNumbers)
        .min(minDeposit,
          `${type === 'deposit' ? languageData?.minDepositOf : languageData?.minWithdrawalOf} ${currencyCodeList[currencyCode || 'EUR']} ${minDeposit} ${languageData?.isRequired}`)
        .max(maxDeposit,
          `${type === 'deposit' ? languageData?.maxDeposit : languageData?.maxWithdrawal} ${currencyCodeList[currencyCode || 'EUR']} ${maxDeposit} ${languageData?.isAllowed}`)
        .test('is-withdrawable', languageData?.notEnoughBalance, function () {
          // Define your custom validation logic here
          return type === 'deposit' ? true : parseFloat(minDeposit) <= parseFloat(walletAmount)
        })
        .required(`${type === 'deposit' ? languageData?.depositAmountRequired : languageData?.withdrawAmountRequired} !`)
    } else if (initValues[label] !== undefined) {
      switch (label) {
        case 'encCreditcardNumber':
          validationObject[label] = Yup.string()
            .min(13, languageData?.min13Numbers)
            .max(19, languageData?.max19Numbers)
            .required(`${languageData?.capCreditCardRequired} !`)
          break

        case 'encCvv':
          validationObject[label] = Yup.string()
            .min(3, languageData?.min3Numbers)
            .max(4, languageData?.max4Numbers)
            .required(`${languageData?.capCvvRequired} !`)
          break

        case 'expirymmyyyy':
          validationObject[label] = Yup.string()
            .matches(/^((0[1-9])|(1[0-2]))\/(\d{4})$/, languageData?.expiryIncorrectFormat)
            .required(`${languageData?.capExpiryRequired} !`)
          break

        default:
          if (dataType === 'number') {
            validationObject[label] = Yup.number()
              .required(languageData?.[`${label}Required`] || `${label.split(/(?=[A-Z])/).filter(str => str !== 'enc').map(str => str.toUpperCase()).join(' ')} ${languageData?.required} !`)
          } else if (label === 'email') {
            validationObject[label] = Yup.string().email(languageData?.invalidEmail)
              .required(`${languageData?.capEmailRequired} !`)
          } else {
            validationObject[label] = Yup.string()
              .required(languageData?.[`${label}Required`] || `${label.split(/(?=[A-Z]_)/).filter(str => str !== 'enc').map(str => str.toUpperCase()).join(' ')} ${languageData?.required} !`)
          }
          break
      }
    }
  }

  return validationObject
}
