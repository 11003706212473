import React from 'react'
import {
  Col,
  Dropdown,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap'
import NoDataAvailable from '../../components/NoDataAvailable'
import PaginationComponent from '../../components/Pagination'
import { getDateTime } from '../../utils/dateFormatter'
import {
  afterBalanceConst,
  internalTransactionType,
  statusType,
  transactionType
} from './constants'
import UseTransactionHistory from './hooks/useTransactionHistory'
import './styles/index.scss'
import Loader from '../../components/Loader'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import CustomDateRange from '../../components/CustomDateRange'
import { currencyCodeList } from '../../constants/currencyCodeList'
import { getItem } from '../../utils/storageUtils'
import { CancelModal } from '../../components/ConfirmationModal'
import CustomDropdownToggle from '../../components/custom/CustomDropdownToggle'

const TransactionHistory = () => {
  const {
    pageNo,
    limit,
    search,
    loading,
    selectedAction,
    userTransactions,
    totalPages,
    userDetails,
    languageData,
    setLimit,
    setSearch,
    setPageNo,
    setSelectedAction,
    fetchLatestChanges,
    state,
    setState,
    navigate,
    selectedLanguageCode,
    cancelTransactionModalOpen,
    setCancelTransactionModalOpen,
    cancelTransaction,
    setPaymentProviderID,
    getTransactionType
  } = UseTransactionHistory()

  return (
    <>
      <div className='tab-title-wrapper d-flex justify-content-center align-items-center'>
        <h2>{languageData?.headerTransactionHistory}</h2>
        <button
          className='d-flex justify-content-end bg-transparent border-0 btn-refresh'
          size='sm'
          onClick={fetchLatestChanges}
        >
          {/* <OverlayTrigger placement='right' overlay={popover}> */}
          <img
            loading='lazy'
            src={`${process.env.REACT_APP_STATIC_IMAGE}/reload.svg`}
            alt='reload'
            className='reloading-btn'
          />
          {/* </OverlayTrigger> */}
        </button>
      </div>
      <div className='history-container user-account-block'>
        <div className='table-layouts'>
          <div className='heading-filter'>
            <Row>
              <Col className='column-search'>
                <div className='transaction-history'>
                  <div className='form-group icon_include search-icon'>
                    <input
                      name='search'
                      className='custom-input form-control'
                      type='text'
                      value={search}
                      onChange={(e) => setSearch(e.target.value, '')}
                      placeholder={languageData?.quickSearch}
                      aria-describedby='emailHelp'
                    />
                    <span className='icon'>
                      <i
                        className='fa-solid fa-magnifying-glass mt-1'
                        style={{ color: '#fff' }}
                      />
                    </span>
                  </div>
                </div>
              </Col>

              <Col className='date-range column-date'>
                <CustomDateRange
                  state={state}
                  setState={setState}
                  onChange={(item) => setState([item.selection])}
                />
              </Col>

              <Col className='column-dropdown'>
                <div className='form-group w-100'>
                  <Dropdown className='icon-right'>
                    <Dropdown.Toggle
                      id='dropdown-basic'
                      as={CustomDropdownToggle}
                    >
                      <span>{languageData[selectedAction]}</span>
                      <img
                        src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`}
                        alt='Dropdown'
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item disabled>
                        {languageData?.actionType}
                      </Dropdown.Item>

                      {transactionType?.map(({ label, value }) => (
                        <Dropdown.Item
                          key={label}
                          onClick={() => setSelectedAction(value)}
                        >
                          {label === 'deposit'
                            ? languageData?.dropdownDeposit
                            : label === 'bonus'
                              ? languageData?.headerBonus
                              : languageData[label]}
                        </Dropdown.Item>
                      ))}
                      {userDetails?.isInternalUser &&
                        internalTransactionType?.map(({ label, value }) => (
                          <Dropdown.Item
                            key={label}
                            onClick={() => setSelectedAction(value)}
                          >
                            {languageData[label]}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>

          <div className='gaming-transaction-table'>
            {loading
              ? (
                <Loader top='2rem' position='unset' />
                )
              : (
                  userTransactions &&
              userTransactions?.count !== 0 && (
                <Table width='100%' responsive striped>
                  <thead>
                    <tr className='text-center'>
                      <th scope='col'>{languageData?.dateTime}</th>
                      <th scope='col'>{languageData?.transactionId}</th>
                      <th scope='col'>{languageData?.casinoProviders}</th>
                      <th scope='col'>{languageData?.amount}</th>
                      <th scope='col'>{languageData?.status}</th>
                      <th scope='col'>{`${languageData?.beforeBalance}`}</th>
                      <th scope='col'>{`${languageData?.afterBalance}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userTransactions?.rows.map(
                      (
                        {
                          transactionType,
                          createdAt,
                          amount,
                          beforeBalance,
                          status,
                          currencyCode,
                          paymentTransactionId,
                          paymentProvider,
                          moreDetails
                        },
                        i
                      ) => {
                        let afterBalanceConstructor = 0

                        const afterBalanceCompare =
                          moreDetails && moreDetails?.transactionHistory
                            ? moreDetails?.transactionHistory?.[
                              moreDetails?.transactionHistory.length - 1
                            ]
                            : { from: 0, to: 0 }

                        if (status === 1) {
                          afterBalanceConstructor = afterBalanceConst.includes(
                            transactionType
                          )
                            ? Math.abs(
                              Math.max(
                                parseFloat(
                                  beforeBalance -
                                      (amount > 0 ? amount : amount * -1)
                                ),
                                0
                              )
                            )?.toFixed(2)
                            : (beforeBalance + amount)?.toFixed(2)
                        } else if (
                          status === 0 ||
                          status === 2 ||
                          status === 3
                        ) {
                          if (
                            afterBalanceCompare?.actioneeName !== 'merchant' &&
                            afterBalanceCompare.to === 2 &&
                            [1, 8, 9].includes(afterBalanceCompare.from)
                          ) {
                            afterBalanceConstructor = (
                              beforeBalance + amount
                            )?.toFixed(2)
                          } else { afterBalanceConstructor = beforeBalance?.toFixed(2) }
                        } else if (status === 8) {
                          if (
                            afterBalanceCompare.to === 8 &&
                            [1, 9, 2, 8].includes(afterBalanceCompare.from)
                          ) {
                            if (
                              afterBalanceCompare?.actioneeName !== 'merchant'
                            ) {
                              afterBalanceConstructor = (
                                beforeBalance + amount
                              )?.toFixed(2)
                            } else {
                              afterBalanceConstructor =
                                beforeBalance?.toFixed(2)
                            }
                          } else {
                            afterBalanceConstructor = (
                              beforeBalance - amount
                            )?.toFixed(2)
                          }
                        } else if (status === 7 || status === 9) {
                          if (
                            [1, 9, 2, 8].includes(afterBalanceCompare.from) &&
                            afterBalanceCompare.to === 9
                          ) {
                            if (
                              afterBalanceCompare?.actioneeName !== 'merchant'
                            ) {
                              afterBalanceConstructor = (
                                beforeBalance + amount
                              )?.toFixed(2)
                            } else {
                              afterBalanceConstructor =
                                beforeBalance?.toFixed(2)
                            }
                          } else {
                            afterBalanceConstructor = Math.abs(
                              Math.max(
                                parseFloat(
                                  beforeBalance -
                                    (amount > 0 ? amount : amount * -1)
                                ),
                                0
                              )
                            )?.toFixed(2)
                          }
                        } else {
                          afterBalanceConstructor = beforeBalance?.toFixed(2)
                        }

                        return (
                          <tr key={`transaction-history ${i}`}>
                            <td className='align-middle'>
                              <div className='transaction-section'>
                                <OverlayTrigger
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {getDateTime(createdAt)}
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <p
                                    style={{ cursor: 'pointer' }}
                                    className='text-truncate transaction-name text-center'
                                  >
                                    {getDateTime(createdAt)}
                                  </p>
                                </OverlayTrigger>
                              </div>
                            </td>

                            <td className='align-middle'>
                              <div className='d-flex justify-content-center'>
                                <p>
                                  {moreDetails?.withdrawId ||
                                    paymentTransactionId ||
                                    '-'}
                                </p>
                              </div>
                            </td>

                            <td className='align-middle'>
                              <div className='d-flex justify-content-center'>
                                <p>
                                  {moreDetails?.providerName ||
                                    paymentProvider ||
                                    '-'}
                                </p>
                              </div>
                            </td>

                            <td className='align-middle'>
                              <div className='cell-values text-center'>
                                <p
                                  style={{ cursor: 'pointer' }}
                                  className='text-truncate'
                                >
                                  {currencyCodeList[currencyCode || 'EUR']}{' '}
                                  {Math.abs(amount)?.toFixed(2)}
                                  <br />
                                  <span className='text-white d-flex justify-content-center mt-1'>
                                    {getTransactionType(transactionType)}
                                    {transactionType === 'bonus'
                                      ? (
                                        <OverlayTrigger
                                          trigger={['click', 'focus']}
                                          overlay={(props) => (
                                            <Tooltip {...props}>
                                              <div
                                                className='text-capitalize cursor-pointer'
                                                onClick={() =>
                                                  navigate(
                                                  `/${
                                                    selectedLanguageCode?.toLowerCase() ||
                                                    getItem(
                                                      'language'
                                                    )?.toLowerCase()
                                                  }/promotion/${
                                                    moreDetails?.bonusId
                                                  }/${moreDetails?.userBonusId}`
                                                  )}
                                              >
                                                {languageData?.promotionTitle}:{' '}
                                                {moreDetails?.promotionTitle?.[
                                                  userDetails?.locale?.toUpperCase()
                                                ] ||
                                                moreDetails?.promotionTitle?.EN}
                                              </div>
                                            </Tooltip>
                                          )}
                                          placement='top'
                                        >
                                          <div
                                            style={{
                                              maxWidth: '210px',
                                              cursor: 'pointer',
                                              marginTop: '-2px'
                                            }}
                                            className='text-truncate'
                                          >
                                          &nbsp;
                                            <img
                                              src='/icon/i-icon.svg'
                                              alt='i-icon'
                                              width='16px'
                                            />
                                          </div>
                                        </OverlayTrigger>
                                        )
                                      : (
                                          ''
                                        )}
                                  </span>
                                </p>
                              </div>
                            </td>

                            <td className='align-middle'>
                              <div
                                className={`transactions ${
                                  status === 1
                                    ? 'status-complete'
                                    : status === 7 || status === 0
                                    ? 'status-requested cancel-transation'
                                    : 'status-failed'
                                } text-center ${statusType?.[status]}`}
                              >
                                <p>
                                  {languageData[statusType?.[status]]} &nbsp;
                                  {status === 7 && (
                                    <img
                                      onClick={() => {
                                        setCancelTransactionModalOpen(true)
                                        setPaymentProviderID(
                                          Number(paymentTransactionId)
                                        )
                                      }}
                                      title='Cancel Transaction'
                                      src={`${process.env.REACT_APP_STATIC_IMAGE}/icon-pending.svg`}
                                      alt='Pending'
                                    />
                                  )}
                                </p>
                              </div>
                            </td>

                            <td className='align-middle'>
                              <div className='cell-values text-center'>
                                <OverlayTrigger
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {currencyCodeList[currencyCode || 'EUR']}{' '}
                                      {beforeBalance?.toFixed(2)}
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <p
                                    style={{ cursor: 'pointer' }}
                                    className='text-truncate'
                                  >
                                    {currencyCodeList[currencyCode || 'EUR']}{' '}
                                    {beforeBalance?.toFixed(2)}
                                  </p>
                                </OverlayTrigger>
                              </div>
                            </td>

                            <td className='align-middle text-center'>
                              <div className='cell-values text-center'>
                                <OverlayTrigger
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {currencyCodeList[currencyCode || 'EUR']}{' '}
                                      {afterBalanceConstructor}
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <p
                                    style={{ cursor: 'pointer' }}
                                    className='text-truncate'
                                  >
                                    {currencyCodeList[currencyCode || 'EUR']}{' '}
                                    {afterBalanceConstructor}
                                  </p>
                                </OverlayTrigger>
                              </div>
                            </td>
                          </tr>
                        )
                      }
                    )}
                  </tbody>
                </Table>
                  )
                )}

            {userTransactions && userTransactions.count === 0 && !loading && (
              <NoDataAvailable />
            )}
          </div>
          {userTransactions && userTransactions.count !== 0 && !loading && (
            <PaginationComponent
              page={pageNo}
              setPage={setPageNo}
              limit={limit}
              setLimit={setLimit}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>

      <CancelModal
        setShow={setCancelTransactionModalOpen}
        show={cancelTransactionModalOpen}
        handleYes={cancelTransaction}
      />
    </>
  )
}

export default TransactionHistory
