import React from 'react'
import './styles/index.scss'

const CustomProgress = ({
  containerClass,
  progressClass,
  percentage,
  labelClass,
  label,
  levelCountIconClass,
  levelCount,
  iconWidth = 0,
  centerLabel = false // Add a prop to determine whether to center the label
}) => {
  // Clamp the percentage value between 0 and 100
  const clampedPercentage = Math.min(percentage, 100) || 0

  // Determine the position of the level count based on the percentage
  const getLevelCountPosition = (percentage) => {
    if (percentage > 90) return '50%' // Center position if percentage is greater than 90
    if (percentage > 80) return '40%'
    if (percentage < 10) return '50%' // Center position if percentage is less than 10
    return percentage > 50 ? '20%' : '60%' // Left or right position based on percentage
  }

  // Determine the label position
  const labelPosition = centerLabel ? '50%' : `calc(${getLevelCountPosition(clampedPercentage)} - ${iconWidth}px)`

  return (
    <div className={containerClass}>
      {/* Render progress bar */}
      <div className={progressClass} style={{ width: `${clampedPercentage}%` }} />

      {/* Render label with dynamic position */}
      <div className={labelClass} style={{ left: labelPosition }}>{label}</div>

      {/* Render level count button */}
      {levelCountIconClass && <button className={levelCountIconClass} style={{ left: `calc(${clampedPercentage}% - ${iconWidth}px)` }}>{levelCount}</button>}
    </div>
  )
}

export default CustomProgress
