import { takeLatest, put, select } from 'redux-saga/effects'
import { getTournament, getTournamentDetails } from '../../utils/apiCalls'
import {
  getTournamentsStart,
  getTournamentsSuccess,
  getTournamentsFailure,
  getTournamentDetailsStart,
  getTournamentDetailsSuccess,
  getTournamentDetailsFailure
} from '../redux-slices/tournament'
import { showFooter } from '../redux-slices/loader'

export default function * cmsWatcher () {
  yield takeLatest(getTournamentsStart, getTournamentsWorker)
  yield takeLatest(getTournamentDetailsStart, getTournamentDetailsWorker)
}

const getTourney = (state) => state?.tournament?.tournaments

function * getTournamentsWorker (action) {
  try {
    const { pageNo, limit, status } = action && action.payload
    const { data } = yield getTournament({ pageNo, limit, status })

    if (pageNo === 1) {
      yield put(getTournamentsSuccess(data?.data?.getAllTournamentDetail))
    } else {
      const tourney = yield select(getTourney)
      yield put(getTournamentsSuccess({ count: tourney?.count, rows: [...tourney?.rows, ...data?.data?.getAllTournamentDetail?.rows] }))
    }

    yield put(showFooter(true))
  } catch (e) {
    yield put(getTournamentsFailure())

    yield put(showFooter(true))
  }
}

function * getTournamentDetailsWorker (action) {
  try {
    const { tournamentId } = action && action.payload
    const { data } = yield getTournamentDetails({ tournamentId })

    yield put(getTournamentDetailsSuccess(data?.data?.getTournamentDetail))

    yield put(showFooter(true))
  } catch (e) {
    yield put(getTournamentDetailsFailure())

    yield put(showFooter(true))
  }
}
