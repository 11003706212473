import React from 'react'

/**
 * A functional component that renders a search input field.
 *
 * The `GameSearch` component is designed to filter games based on user input.
 * It provides a search box that updates the filter state with the entered search term.
 * The placeholder is multilingual and dynamically populated from the provided `languageData`.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setSelectedFilter - A callback function to update the selected filter state.
 * @param {Object} props.languageData - An object containing language-specific data, including the placeholder text for the search input.
 * @returns {JSX.Element} A search input element for filtering.
 */
const GameSearch = ({
  setSelectedFilter,
  languageData
}) => {
  return (
    <input
      type='search'
      className='search-input w-100'
      placeholder={languageData?.quickSearch}
      aria-label='Search'
      onChange={(e) => {
        setSelectedFilter(selectedFilter => ({ ...selectedFilter, search: e?.target?.value }))
      }}
    />
  )
}

export default GameSearch
