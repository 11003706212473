import React from 'react'
import './styles/index.scss'
import { ModalComponent } from '../../components/CustomModal'
import { blockedCountryModalOption } from '../../constants/modalOption'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const BlockedCountry = ({ showModal }) => {
  const { languageData } = useSelector((state) => state.language)
  const navigate = useNavigate()
  return (
    <ModalComponent
      showModal={showModal}
      handleClose={() => {
        navigate('/')
      }}
      {...blockedCountryModalOption}
    >
      <div className='text-center mb-3'>
        <i className='fa-solid fa-ban icon-ban' />
      </div>
      <div className='page-content-403'>
        <p>{languageData?.countryBlocked}!</p>
        <p>
          {languageData?.notAcceptingVisitors}
          {languageData?.contactUsAt}
        </p>
        <p>
          {process.env.REACT_APP_SUPPORT_MAIL}
        </p>
      </div>
    </ModalComponent>
  )
}

export default BlockedCountry
