import React from 'react'
import { useSelector } from 'react-redux'
import { getGameUrl } from 'utils/helpers'
import { Link } from 'react-router-dom'
import './styles/index.scss'

const GameCardSearch = ({
  game,
  index,
  setShowSearchModal
}) => {
  const { selectedLanguageCode } = useSelector(state => state.language)

  return (
    <div key={`list-menu-item-${index}`} className='search-game-card'>
      <Link
        className='real-play'
        to={getGameUrl(selectedLanguageCode?.toLowerCase(), game?.identifier)}
        onClick={() => {
          setShowSearchModal(false)
        }}
      >
        <div className='game-container'>
          <div className='image-area'>
            <img
              loading='lazy' src={game?.thumbnailUrl} className='w-100 game-img' alt='game'
              onError={(e) => {
                e.target.onerror = null
                e.target.src = '/images/placeholder.svg'
              }}
            />
          </div>
          <div className='lobby-game-info'>
            <div className='lobby-game-name'>{game?.name}</div>
            <div className='lobby-game-provider'>
              <img src={game?.providerLogo} className='lobby-game-provider-logo' alt='provider' />
              <span className='lobby-game-provider-name'>{game?.providerName}</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default GameCardSearch
