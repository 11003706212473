import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBonusDetailStart } from '../../../store/redux-slices/bonus'

const useFreeSpinGameCard = (selectedBonusDetail) => {
  const { loading, bonusDetail } = useSelector(state => state.bonus)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBonusDetailStart({
      bonusId: selectedBonusDetail?.bonusId,
      userBonusId: selectedBonusDetail?.userBonusId
    }))
  }, [])

  return {
    loading,
    bonusDetail
  }
}

export default useFreeSpinGameCard
