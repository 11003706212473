import React from 'react'
// import ReactStars from 'react-stars'
import './styles/index.scss'
import Banner from '../../components/Banner'
import useCasinoMenuList from './hooks/useCasinoMenuList'
import CategorisedGamesGrid from '../../components/Lobby/CategorisedGamesGrid'
import PartnersLogos from '../../components/Partners'
import { useSelector } from 'react-redux'
import BlockedCountry from '../BlockedCountry'

const HomePage = ({ selectedFilter, setSelectedFilter }) => {
  const {
    showBanner,
    languageData,
    showLeagueList
  } = useCasinoMenuList()
  const isUserBlock = useSelector((state) => state.user?.isUserBlock)

  return (
    <>
      <section className='wrapper-content banner'>
        {showBanner && <Banner pageType='home' anchorTagFilterType='homeBannerJoinNow' />}
      </section>
      <section className='partners-section fade-background'>
        <PartnersLogos
          showTitle
          partnerType='payments'
          applyFilter={false}
          showAll={false}
        />
      </section>

      <section className='wrapper-content home-page'>
        {showLeagueList &&
          <CategorisedGamesGrid isHomePage selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />}
      </section>

      <section className='wrapper-content aboutus'>
        <section className='tabs-section home-tabs'>
          <div className='wrapper'>
            <div className='container'>
              <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
                <li className='nav-item'>
                  <a className='nav-link active home-aboutus' id='pills-about-tab' data-toggle='pill' href='#pills-about' role='tab' aria-controls='pills-about' aria-selected='true'>{languageData?.homeAbout}</a>
                </li>
              </ul>
              <div className='tab-content about-section' id='pills-tabContent'>
                <div className='tab-pane fade show active' id='pills-about' role='tabpanel' aria-labelledby='pills-about-tab'>
                  <p>
                    {languageData?.homeAboutContent}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      {isUserBlock && <BlockedCountry showModal={isUserBlock} />}
    </>
  )
}

export default React.memo(HomePage)
