import React, { useContext } from 'react'
import PasswordFieldC from '../../../../FormFieldControl/PasswordFieldC'
import { UserDetailContext } from '../../../../../pages/NewAccountInfoPage/useContext'
const ChangePassword = ({ formik }) => {
  const { regFields, languageData, showStar } = useContext(UserDetailContext)
  const commonProps = {
    containerClassName: 'custom-form-group',
    fieldClassName: 'form-control custom-input',
    isAccountInfo: true,
    showStar,
    regFields,
    showInfoIcon: true
  }
  return (
    <div>
      {' '}
      {regFields?.password !== 0 && (
        <div
          id='collapseThree'
          className='collapse show'
          aria-labelledby='headingThree'
          data-parent='#accordionExample'
        >
          <div className=''>
            <div className='changepwd-form'>
              {regFields && (
                <div className='pt-3'>
                  <div className='signup-form-detail'>
                    {regFields?.password !== 0 && (
                      <PasswordFieldC
                        name='password'
                        label='loginPassword'
                        placeholder={languageData?.passwordPlaceholder}
                        formik={formik}
                        {...commonProps}
                      />
                    )}
                    {regFields?.password !== 0 && (
                      <PasswordFieldC
                        name='confirmPassword'
                        placeholder={languageData?.confirmPasswordPlaceholder}
                        label='signupConfirm'
                        extraLabel='loginPassword'
                        formik={formik}
                        {...commonProps}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChangePassword
