import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Loader from '../../components/Loader'
import { useSelector } from 'react-redux'
import CopyAddress from '../../components/CopyAddress'

const IframeModal = ({
  url,
  loading,
  deposit,
  showIFrame,
  setShowIFrame,
  data,
  isPIQ = false,
  windowOnly = false
}) => {
  const { languageData } = useSelector((state) => state.language)

  return (
    <>
      <Modal
        show={showIFrame}
        onHide={() => {
          setShowIFrame(false)
        }}
        size={data?.qrId ? 'lg' : ''}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='generic-modal-layout'
        data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-hidden='true'
      >
        <div className='deposit-iframe-wrapper'>
          <Modal.Header>
            <h6>{deposit ? languageData?.capDeposit : languageData?.capWithdraw}</h6>

            <Button
              className='close-btn modal-close' variant='danger' onClick={() => {
                setShowIFrame(false)
              }}
            >
              <i className='fa-solid fa-xmark' />
            </Button>
          </Modal.Header>
          <Modal.Body>
            {loading
              ? <Loader top='5%' />
              : url
                ? <iframe
                    src={deposit && isPIQ && windowOnly ? '' : url}
                    id='myFrame'
                    name='myFrame'
                    title={window.location.host}
                    height='70vh'
                    width='100%'
                    sandbox='allow-scripts allow-forms allow-same-origin'
                  />
                : data
                  ? (
                    <div>
                      <div className='align-items-center bg-white p-3 d-flex' id='crypto-currency-div'>
                        <a href={`${data?.address}`} target='_blank' rel='noreferrer' className='me-4'>
                          <img src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${data?.address}`} />
                        </a>
                        <div>
                          <h5 className='text-black'>{languageData?.walletAddressCopy}</h5>
                          <CopyAddress languageData={languageData} address={data?.address} />
                          <h5 className='text-black'>{languageData?.depositWarning}</h5>
                        </div>
                      </div>
                      <h5 className='deposit-note'>
                        {languageData?.blockchainMistakeWarning}
                      </h5>
                    </div>
                    )
                  : <div id='cashier' />}

          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}

export default IframeModal
