export const transactionType = [
  { label: 'all', value: 'all' },
  { label: 'bonus', value: 'bonus' },
  { label: 'deposit', value: 'deposit' },
  { label: 'withdrawal', value: 'withdraw' },
  { label: 'addMoney', value: 'addMoney' },
  { label: 'removeMoney', value: 'removeMoney' },
  { label: 'refunded', value: 'refund' }
]

export const internalTransactionType = [
  { label: 'addMoneyInternal', value: 'addMoneyInternal' },
  { label: 'removeMoneyInternal', value: 'removeMoneyInternal' },
  { label: 'depositInternal', value: 'depositInternal' },
  { label: 'withdrawInternal', value: 'withdrawInternal' }
]

export const statusType = ['pending', 'success', 'cancelled', 'failed', 'failed', '', '', 'requested', 'refunded', 'chargeback']

export const afterBalanceConst = ['bonusForfeit', 'bonusExpired', 'bonusZeroedOut', 'withdraw', 'withdrawInternal', 'removeMoney', 'removeMoneyInternal', 'refund', 'refundInternal', 'manualWithdraw', 'manualWithdrawInternal']
