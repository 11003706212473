import React, { useState } from 'react'
import { ErrorMessage, Field } from 'formik'
import CustomError from '../../CustomError'
import PassIcons from './PassIcons'

const PassField = ({ name, fieldClassName, placeholder, formik, t, isAccountInfo, showInfoIcon }) => {
  const [toggleViewPassword, setToggleViewPassword] = useState(false)

  return (
    <div className='position-relative'>
      <Field
        id={name}
        name={name}
        type={toggleViewPassword ? 'text' : 'password'}
        className={fieldClassName}
        placeholder={placeholder}
      />
      <ErrorMessage name={name} component={CustomError} />
      <PassIcons toggleViewPassword={toggleViewPassword} setToggleViewPassword={setToggleViewPassword} />
    </div>
  )
}

export default PassField
