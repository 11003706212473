import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getHomePageConfigStart,
    getHomePageConfigSuccess,
    getHomePageConfigFailure
  },
  reducer
} = createSlice({
  name: 'config',
  initialState: {
    loading: false,
    config: null
  },
  reducers: {
    getHomePageConfigStart: (state) => ({
      ...state,
      loading: true
    }),
    getHomePageConfigSuccess: ({ config }, { payload }) => ({
      loading: false,
      config: { ...config, homepageConfig: payload }
    }),
    getHomePageConfigFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getHomePageConfigStart,
  getHomePageConfigSuccess,
  getHomePageConfigFailure
}
