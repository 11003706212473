import React, { useContext } from 'react'
import FormFieldControl from '../../../../../FormFieldControl'
import PasswordFieldC from '../../../../../FormFieldControl/PasswordFieldC'
import { SignUpContext } from '../../../../../../pages/NewSignup/useContext'

const LoginDetail = ({ formik }) => {
  const { showStar, regFields, languageData } = useContext(SignUpContext)
  const commonProps = {
    containerClassName: 'custom-form-group',
    fieldClassName: 'form-control custom-input',
    showStar,
    regFields,
    showInfoIcon: true
  }

  return (
    <>
      <div className='signup-form-detail'>
        <FormFieldControl
          type='email'
          name='email'
          control='input'
          label='signupEmailAddress'
          placeholder={languageData?.emailPlaceholder}
          {...commonProps}
        />

        <FormFieldControl
          type='text'
          name='username'
          control='input'
          label='signupUserName'
          placeholder={languageData?.userNamePlaceholder}
          {...commonProps}
        />

        <PasswordFieldC
          name='password'
          label='loginPassword'
          placeholder={languageData?.passwordPlaceholder}
          formik={formik}
          {...commonProps}
        />

        <PasswordFieldC
          name='confirmPassword'
          placeholder={languageData?.confirmPasswordPlaceholder}
          label='signupConfirm'
          extraLabel='loginPassword'
          formik={formik}
          {...commonProps}
        />

      </div>
    </>
  )
}

export default LoginDetail
