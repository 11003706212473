import './styles/index.scss'
import React from 'react'
import { Buffer } from 'buffer'
import useLimit from './hooks/useLimits'
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import {
  setDailyLimitStart,
  setDepositLimitStart,
  setDisableUntilStart,
  setGamePlayLimitStart,
  setLossLimitStart,
  setSessionTimeStart,
  updatePasswordStatus
} from '../../store/redux-slices/limits'
import Loader from '../../components/Loader'
// import WagerLimit from './components/WagerLimit'
// import LossLimit from './components/LossLimit'
import DepositLimit from './components/DepositLimit'
// import TakeABreak from './components/TakeABreak'
// import SessionLimit from './components/SessionLimit'
// import SelfExclusion from './components/SelfExclusion'
import { Form, Formik } from 'formik'
import { UserRegisterButton } from '../../components/Buttons'
import PassField from '../../components/FormFieldControl/PasswordFieldC/components/PassField'
// import GamePlayLimit from './components/GamePlayLimit'

const Limits = () => {
  const {
    loading,
    dispatch,
    password,
    showModal,
    showInput,
    confirmMsg,
    setPassword,
    userDetails,
    setShowInput,
    showCheckbox,
    setShowModal,
    languageData,
    setShowCheckbox,
    // handleLossLimit,
    // handleWagerLimit,
    // handleTakeABreak,
    isPasswordCorrect,
    userDetailsLoading,
    // handleSessionLimit,
    handleDepositLimit,
    fetchLatestChanges,
    // handleSelfExclusion,
    // handleGamePlayLimit,
    navigate
  } = useLimit()

  const limitsPopover = (
    <Popover className='loyalty-popover'>
      <Popover.Body>
        <ol>
          <li>{languageData?.limit24Reset}</li>
        </ol>
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      <>
        <div className='tab-title-wrapper d-flex align-items-center justify-content-center'>
          <h2>{languageData?.headerLimits}</h2>

          <OverlayTrigger
            trigger={['focus', 'hover']}
            placement='right'
            overlay={limitsPopover}
          >
            <button className='loyalty-info ml-2'>i</button>
          </OverlayTrigger>

          <button
            className='d-flex justify-content-end bg-transparent border-0 btn-refresh px-0 ml-3'
            size='sm'
            onClick={fetchLatestChanges}
          >
            {/* <OverlayTrigger placement='right' overlay={popoverRefresh}> */}
            <img
              loading='lazy'
              src={`${process.env.REACT_APP_STATIC_IMAGE}/reload.svg`}
              alt='reload'
              className='reloading-btn'
            />
            {/* </OverlayTrigger> */}
          </button>
        </div>

        {!userDetailsLoading && (
          <div className='limits' key={userDetails?.userLimit?.updatedAt}>
            {userDetails?.userLimit && (
              <div className='user-account-block'>
                <DepositLimit
                  userDetails={userDetails}
                  handleDepositLimit={handleDepositLimit}
                  showCheckbox={showCheckbox}
                  showInput={showInput}
                  setShowCheckbox={setShowCheckbox}
                  setShowInput={setShowInput}
                />
              </div>
            )}
          </div>
        )}

        {userDetailsLoading && <Loader />}
      </>

      <Modal
        show={
          showModal?.wagerLimit ||
          showModal?.takeABreak ||
          showModal?.lossLimit ||
          showModal?.depositLimit ||
          showModal?.sessionLimit ||
          showModal?.selfExclusion ||
          showModal?.gamePlayLimit
        }
        onHide={() => {
          setShowModal({
            ...showModal,
            wagerLimit: false,
            lossLimit: false,
            depositLimit: false,
            takeABreak: false,
            sessionLimit: false,
            selfExclusion: false,
            gamePlayLimit: false
          })
          setPassword('')
          dispatch(updatePasswordStatus(null))
        }}
        size='md'
        aria-labelledby='contained-modal-title-vcenter'
        className='generic-modal-layout limits-modal'
      >
        <Modal.Header className='d-flex'>
          <h5>{confirmMsg?.type}</h5>
          <button
            onClick={() =>
              setShowModal({
                ...showModal,
                wagerLimit: false,
                lossLimit: false,
                depositLimit: false,
                takeABreak: false,
                sessionLimit: false,
                selfExclusion: false,
                gamePlayLimit: false
              })}
            className='limit-modal-close-button'
          >
            <i className='fa-solid fa-circle-xmark' />
          </button>
        </Modal.Header>
        <Modal.Body>
          {confirmMsg?.value?.split(',')?.map((item, i) => (
            <div className='limit-value' key={`limit-modal ${i}`}>
              {item && item.includes(':')
                ? (
                  <>
                    <span>{item.split(':')?.[0]}:</span>{' '}
                    <span>{item.split(':')?.[1]}</span>
                  </>
                  )
                : (
                    item
                  )}
            </div>
          ))}
          <span>{confirmMsg?.info}</span>
        </Modal.Body>
        <Modal.Footer className='border-0 align-items-baseline'>
          <Formik
            initialValues={{ password: '' }}
            onSubmit={({ password }) => {
              if (showModal?.wagerLimit) {
                dispatch(
                  setDailyLimitStart({
                    data: {
                      dailyBetLimit: confirmMsg?.dailyBetLimit,
                      weeklyBetLimit: confirmMsg?.weeklyBetLimit,
                      monthlyBetLimit: confirmMsg?.monthlyBetLimit,
                      reset: confirmMsg?.reset,
                      password: Buffer.from(password).toString('base64')
                    }
                  })
                )
              }

              if (showModal?.lossLimit) {
                dispatch(
                  setLossLimitStart({
                    data: {
                      dailyLossLimit: confirmMsg?.dailyLossLimit,
                      weeklyLossLimit: confirmMsg?.weeklyLossLimit,
                      monthlyLossLimit: confirmMsg?.monthlyLossLimit,
                      reset: confirmMsg?.reset,
                      password: Buffer.from(password).toString('base64')
                    }
                  })
                )
              }

              if (showModal?.depositLimit) {
                dispatch(
                  setDepositLimitStart({
                    data: {
                      dailyDepositLimit: confirmMsg?.dailyDepositLimit,
                      weeklyDepositLimit: confirmMsg?.weeklyDepositLimit,
                      monthlyDepositLimit: confirmMsg?.monthlyDepositLimit,
                      reset: confirmMsg?.reset,
                      password: Buffer.from(password).toString('base64')
                    },
                    navigate
                  })
                )
              }

              if (showModal?.sessionLimit) {
                dispatch(
                  setSessionTimeStart({
                    data: {
                      timeLimit: parseInt((confirmMsg?.value).split(' ')?.[0]),
                      reset: confirmMsg?.reset,
                      password: Buffer.from(password).toString('base64')
                    }
                  })
                )
              }

              if (showModal?.takeABreak) {
                dispatch(
                  setDisableUntilStart({
                    data: {
                      type: 'TAKE_A_BREAK',
                      days: parseFloat((confirmMsg?.value).split(' ')?.[0]),
                      portal: 'current',
                      password: Buffer.from(password).toString('base64')
                    }
                  })
                )
              }

              if (showModal?.selfExclusion) {
                dispatch(
                  setDisableUntilStart({
                    data: {
                      type: 'SELF_EXCLUSION',
                      days: parseInt(confirmMsg?.timePeriod),
                      portal: confirmMsg?.portal,
                      password: Buffer.from(password).toString('base64')
                    }
                  })
                )
              }

              if (showModal?.gamePlayLimit) {
                dispatch(
                  setGamePlayLimitStart({
                    data: {
                      timePeriod: parseInt(confirmMsg?.timePeriod),
                      password: Buffer.from(password).toString('base64')
                    }
                  })
                )
              }
            }}
          >
            {(formik) => (
              <Form className='w-100'>
                {confirmMsg?.showFooter && (
                  <div className='custom-form-group'>
                    <PassField
                      name='password'
                      containerClassName='custom-form-group'
                      fieldClassName='form-control custom-input'
                      placeholder={languageData?.passwordPlaceholder}
                      formik={formik}
                      isAccountInfo={false}
                      showInfoIcon={false}
                    />

                    {isPasswordCorrect === false && password !== '' && (
                      <span className='text-danger mt-1 br-1'>
                        {languageData?.incorrectPassword}
                      </span>
                    )}
                  </div>
                )}

                {confirmMsg?.showFooter && (
                  <div>
                    <UserRegisterButton
                      formik={formik}
                      loading={loading}
                      spinner
                      text={languageData?.signupConfirm}
                      disabled={formik.values?.password === ''}
                    />
                  </div>
                )}

                {!confirmMsg?.showFooter && (
                  <button
                    className='btn-modal btn-success'
                    onClick={() => {
                      setShowModal({
                        ...showModal,
                        wagerLimit: false,
                        lossLimit: false,
                        depositLimit: false,
                        takeABreak: false,
                        sessionLimit: false,
                        selfExclusion: false,
                        gamePlayLimit: false
                      })
                      setPassword('')
                      dispatch(updatePasswordStatus(null))
                    }}
                  >
                    {languageData?.ok}
                  </button>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Limits
