import React, { useEffect, useState } from 'react'

const BonusDescription = ({ content, bonusDetail }) => {
  const [showAll, setShowAll] = useState(false)
  useEffect(() => {
    const contentElement = document.getElementById(`bonus-des ${bonusDetail.bonusId}`)
    const contentHeight = contentElement.offsetHeight
    const maxHeight = 80
    setShowAll(contentHeight > maxHeight)
  }, [])

  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  return (
    <>
      <div
        id={`bonus-des ${bonusDetail.bonusId}`}
        className={`bonus-description ${
          showAll ? 'truncate-large-content' : ''
        }`}
      >
        {content}
      </div>
      {showAll && (
        <div
          className='d-flex justify-content-center align-item-center view-all-btn-box'
          onClick={toggleShowAll}
        >
          ...
        </div>
      )}
    </>
  )
}

export default BonusDescription
