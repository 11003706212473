import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import Loader from 'components/Loader'
import NoDataAvailable from 'components/NoDataAvailable'
import InfiniteScrollData from '../InfiniteScrollData'
import './styles/index.scss'

const AllGamesGrid = ({
  loading,
  loadAllData,
  filteredGames,
  fetchMoreGames,
  toggleFavorite,
  setLoadAllData,
  selectedFilter,
  rowsFetchedCount,
  favoriteGamesList,
  selectedLanguageCode
}) => {
  const { languageData } = useSelector((state) => state.language)

  return (
    <>
      <div className='games-container'>
        {loading && !filteredGames
          ? <Loader />
          : filteredGames?.count > 0
            ? (
              <div className='games-grid-wrapper'>
                <div className='games-grid' id='games-grid-id'>
                  <InfiniteScroll
                    dataLength={rowsFetchedCount || 1}
                    next={() => loadAllData && fetchMoreGames(filteredGames.tenantGameSubCategoryId)}
                    hasMore={selectedFilter?.pageNo < Math.ceil(filteredGames?.count / selectedFilter?.limit)}
                    style={{ overflow: 'hidden' }}
                    loader={(loadAllData || loading) && <Loader top='5%' />}
                    scrollableTarget={undefined}
                  >
                    <InfiniteScrollData
                      games={filteredGames}
                      toggleFavorite={toggleFavorite}
                      selectedLanguageCode={selectedLanguageCode}
                      favoriteGamesList={favoriteGamesList}
                    />
                  </InfiniteScroll>

                  {!(loadAllData || loading || selectedFilter?.pageNo > Math.floor(filteredGames.count / selectedFilter?.limit)) &&
                    <div className='text-center mt-5 mb-5'>
                      <a
                        className='view-all-btn'
                        href={() => false}
                        onClick={() => {
                          setLoadAllData(false)
                          fetchMoreGames(filteredGames.tenantGameSubCategoryId)
                        }}
                      >{`${languageData?.loadMore}`.toUpperCase()}
                      </a>
                      <a
                        href={() => false}
                        className='view-all-btn mx-0'
                        onClick={() => {
                          setLoadAllData(true)
                          fetchMoreGames(filteredGames.tenantGameSubCategoryId)
                        }}
                      >{`${languageData?.loadAll}`.toUpperCase()}
                      </a>
                    </div>}
                </div>
              </div>)
            : (
              <div className='no-data-found' dir='ltr'>
                <NoDataAvailable errorMessage={languageData?.casinoNoGamesFound} />
              </div>
              )}
      </div>

    </>
  )
}

export default React.memo(AllGamesGrid)
