import React from 'react'
import Slider from 'react-slick'
import Loader from '../Loader'
import NoDataAvailable from '../NoDataAvailable'
import GameCard from './GameCard'
import { getDeviceType } from '../../utils/getDeviceType'

const FavoriteList = ({
  toggleFavorite,
  favoriteGamesList,
  selectedLanguageCode,
  favLoading,
  languageData
}) => {
  const settings = {
    initialSlide: 0,
    slidesToShow: 6.3,
    slidesToScroll: 4,
    speed: 500,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 2920,
        settings: {
          slidesToShow: 6.3,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5.1,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4.1,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 2.1,
          slidesToScroll: 2
        }
      }
    ]
  }

  if (favoriteGamesList?.count < 1 || !favoriteGamesList?.count || favoriteGamesList?.rows?.length < 1) {
    return (
      <div className='text-center mt-4 mb-3'>
        <NoDataAvailable errorMessage={languageData?.casinoNoFavGamesFound} />
      </div>
    )
  }

  return (
    <>

      {favLoading
        ? <div className='fav-loader'><Loader top='7%' /></div>
        : (
          <div className='game-slider-container mt-5'>
            <div className='game-slider-container-heading-content  mb-2'>
              <div className='d-flex align-items-center'>
                <i className='fa-solid fa-heart fa-beat type-icon' />
                <p className='heading-text ml-2'>{languageData?.favourite}</p>
              </div>
            </div>
            {getDeviceType() === 'mobile'
              ? (
                <div className='game-slider-container-grid'>
                  {favoriteGamesList?.rows && favoriteGamesList?.rows?.map((game, index) => (
                    <GameCard
                      game={game}
                      index={index}
                      toggleFavorite={toggleFavorite}
                      selectedLanguageCode={selectedLanguageCode}
                      lobby={false}
                      key={index}
                      favoriteGamesList={favoriteGamesList}
                    />
                  ))}
                </div>)
              : (
                <Slider {...settings}>
                  {favoriteGamesList?.rows && favoriteGamesList?.rows?.map((game, index) => (
                    <GameCard
                      game={game}
                      index={index}
                      toggleFavorite={toggleFavorite}
                      selectedLanguageCode={selectedLanguageCode}
                      lobby={false}
                      key={index}
                      favoriteGamesList={favoriteGamesList}
                    />
                  ))}
                </Slider>)}
          </div>
          )}
    </>
  )
}
export default React.memo(FavoriteList)
