export const Routes = {
  homepage: '/',
  casinoSubCategory: '/:languageCode/:casinoCategory/:casinoSubCategory',
  promotionpage: '/:languageCode/promotion',
  promotionpageId: '/:languageCode/promotion/:bonusId',
  userpromotionpageId: '/:languageCode/promotion/:bonusId/:userBonusId',
  loyaltyBonusPage: '/:languageCode/loyalty-bonus',
  playGame: '/:languageCode/play/:gameIdentifier',
  mobilePlayGame: '/:languageCode/play-mobile/:gameIdentifier',
  demoGame: '/:languageCode/demo/:gameIdentifier',
  login: '/:languageCode/login',
  signup: '/:languageCode/signup',
  account: '/:languageCode/account/:section',
  forgotPassword: '/:languageCode/forgot-password',
  notFound: '/:languageCode/404',
  forbidden: '/:languageCode/403',
  siteMaintenance: '/:languageCode/site-maintenance',
  siteMaintenanceStatic: '/:languageCode/maintenance',
  blockedCountry: '/:languageCode/600',
  emailTokenLink: '/:languageCode/verify-email',
  resetPassword: '/:languageCode/reset-password',
  resendEmail: '/:languageCode/resend-email',
  tenantInactive: '/:languageCode/tenant-inactive',
  cms: '/:languageCode/:slug',
  paymentStatus: '/:languageCode/payment-status/:type',
  CashbackBonus: '/:languageCode/cashback-bonus',
  paymentSuccess: '/:languageCode/account/:section/status',
  tournaments: '/:languageCode/tournaments',
  tournamentDetails: '/:languageCode/tournaments/:tournamentId',
  VIP: '/:languageCode/vip',
  home: ':languageCode/home'
}
