import React, { useContext } from 'react'
import { UserDetailContext } from '../../pages/NewAccountInfoPage/useContext'
import Loader from '../Loader'
import UserInfoForm from './UserInfoForm'

const AccountInfo = () => {
  const { regFields, loading, userDetails, languageData } =
    useContext(UserDetailContext)

  return (
    <>
      <h2 className='tab-title'>{languageData?.headerAccAndInfo}</h2>
      {loading ? <Loader /> : userDetails && regFields && <UserInfoForm />}
    </>
  )
}

export default AccountInfo
