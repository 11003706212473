import useAccountVerification from './hooks/useAccountVerification'
import Loader from '../../components/Loader'
import './styles/index.scss'
import SystemKYC from './SystemKYC'

const AccountVerification = () => {
  const {
    docs,
    list,
    loading,
    kycStatus,
    userDocument,
    hideButton,
    languageData,
    status,
    fetchLatestChanges,
    update,
    setDocs,
    selectedTab,
    setSelectedTab,
    setLabelId,
    userDetails
  } = useAccountVerification()

  // const CountryInfoModal = ({
  //   show,
  //   setShow
  // }) =>
  //   <Modal
  //     show={show}
  //     onHide={() => setShow(false)}
  //     centered
  //     size='lg'
  //     backdrop='static'
  //     className='generic-modal-layout'
  //     data-bs-backdrop='static' data-bs-keyboard='false' tabindex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'
  //   >
  //     <div className='topdiv mx-auto'>
  //       <Modal.Header>
  //         <Modal.Title>{languageData?.areYouSure}?</Modal.Title>
  //       </Modal.Header>

  //       <Modal.Body>{languageData?.countryCantChange}</Modal.Body>

  //       <Modal.Footer>
  //         <button
  //           className='btn-modal w-100'
  //           onClick={() => {
  //             setCountryChangeModal(false)
  //             navigate(`/${getItem('language')?.toLowerCase() || languageData?.language?.toLowerCase()}/account/account-info`)
  //           }}
  //         >{languageData?.update} {languageData?.signupCountry}
  //         </button>
  //         <button
  //           className='btn-modal w-100 mt-3 btn-grn' onClick={() => {
  //             dispatch(setCountryModal(false))
  //             setCountryChangeModal(false)
  //           }}
  //         >{languageData?.proceed}
  //         </button>
  //       </Modal.Footer>

  //     </div>
  //   </Modal>

  const selectedTabData = userDocument && userDocument?.rows.find(u => u.documentName === selectedTab)

  let selectedTabDocURL
  if (selectedTabData?.documentUrl?.length) {
    selectedTabDocURL = selectedTabData?.documentUrl[selectedTabData?.documentUrl?.length - 1]
  }

  return (
    <>
      <h2 className='tab-title d-flex justify-content-center'>
        {languageData?.headerAccVerify}
        {(userDetails?.kycMethod === 0 || (userDetails?.kycMethod === null && userDetails?.countryKycMethod === 0)) &&
          <button
            className='bg-transparent border-0 btn-refresh'
            size='sm'
            onClick={fetchLatestChanges}
          >
            <img loading='lazy' src={`${process.env.REACT_APP_STATIC_IMAGE}/reload.svg`} alt='reload' className='reloading-btn' />
          </button>}
      </h2>
      {loading
        ? <Loader />
        : !loading && (
          <>
            {(userDetails?.kycMethod === 0 || (userDetails?.kycMethod === null && userDetails?.countryKycMethod === 0)) &&
            list?.length > 0 &&
              <>
                <div className='user-account-block'>
                  {kycStatus === 'APPROVED'
                    ? (
                      <div className='user-account acc-verify-info'>
                        <h5 className='text-center p-0'>{languageData?.accountStatus}:&nbsp;<span className='kyc-status'>{kycStatus}</span></h5>
                        <small className='text-center'>{languageData?.kycVerified}</small>
                      </div>)
                    : (
                      <div className='user-account acc-verify-info'>
                        <h5 className='text-center p-0'>{languageData?.accountStatus}:&nbsp;<span className='kyc-status'>{kycStatus}</span></h5>
                        <small className='text-center'>{languageData?.kycProtocolDetails}</small>
                      </div>)}
                </div>

                <SystemKYC
                  docs={docs}
                  update={update}
                  list={list}
                  userDocument={userDocument}
                  kycStatus={kycStatus}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  selectedTabDocURL={selectedTabDocURL}
                  status={status}
                  selectedTabData={selectedTabData}
                  setDocs={setDocs}
                  setLabelId={setLabelId}
                  hideButton={hideButton}
                  languageData={languageData}
                />
              </>}
            <>

              <div id='sumsub-websdk' />
            </>
          </>)}

      {/* {countryModal && userDetails && !userLoading && countryChangeModal && <CountryInfoModal show={countryChangeModal} setShow={setCountryChangeModal} />} */}
    </>
  )
}

export default AccountVerification
