import React from "react"

/**
 * @typedef TopPerformanceCategoryType
 * @type {'current-winners' | 'top-winners' | 'top-games'}
 */

/**
 * @typedef TopPerformanceCardProps
 *
 * @property {Number}                     index
 * @property {String}                     imageUrl
 * @property {String}                     title
 * @property {String}                     subtitle
 * @property {String}                     infoText
 * @property {Function}                   onClick
 * @property {TopPerformanceCategoryType} type
 */

/**
 * @param   {TopPerformanceCardProps} props
 * @returns {React.JSX}
 */
const TopPerformanceCard = ({ index, imageUrl = '', title = '', subtitle = '', infoText = '', onClick, type }) => {
  return (
    <div
      key={`top-performance__${type}__${index}`}
      className={`top-performance-card ${`card-${type}`} ${`card-${type}-${index+1}`}`}
      onClick={type === 'top-games' && typeof onClick === 'function' ? onClick : () => {}}
    >
      <div
        className={`top-performance-card-image ${type !== 'top-winners' ? 'cursor-pointer' : ''}`}
        onClick={typeof onClick === 'function' ? onClick : () => {}}
      >
        {imageUrl && <img loading="lazy" alt={type === 'top-winners' ? 'Winner Rank' : 'Game Image'} src={imageUrl} />}
      </div>
      <div className="top-performance-card-body">
        <div className="top-performance-card-body-primary">
          <p className="top-performance-card-body-primary-title" title={title}>
            {title}
          </p>
          <p
            className="top-performance-card-body-primary-subtitle"
            onClick={typeof onClick === 'function' ? onClick : () => {}}
          >
            {subtitle}
          </p>
        </div>
        <div className="top-performance-card-body-secondary">
          <p className="top-performance-card-body-secondary-info-text">{infoText}</p>
        </div>
      </div>
    </div>
  )
}

export default TopPerformanceCard
