import React, { useContext } from 'react'
import PhoneInput from 'react-phone-input-2'
const UserInfoPhoneField = ({ contextValue, formik, disabled = false }) => {
  const { completePhone, setCompletePhone, setIsPassValid, languageData } = useContext(contextValue)
  const { values, setFieldTouched, setFieldValue } = formik
  return (
    <div>
      <PhoneInput
        name='phone'
        type='text'
        disabled={disabled}
        aria-describedby='emailHelp'
        alwaysDefaultMask={false}
        enableSearch
        placeholder={languageData?.phonePlaceholder}
        enableLongNumbers
        country='us'
        value={completePhone || `${values?.phoneCode}${values?.phone}`}
        onChange={(phone, code) => {
          setCompletePhone(null)
          code = String(code?.dialCode)
          const newPhone = phone.substring(code.length)
          let isPhoneNotValid = true
          const newCode = '+' + code
          setFieldValue('phone', newPhone)
          setFieldValue('phoneCode', newCode)
          if (!(newPhone?.length > 15 || newPhone?.length < 5)) {
            isPhoneNotValid = false
          }
          if (newPhone?.charAt(0) === '0' || isPhoneNotValid) {
            setIsPassValid(false)
          } else {
            setIsPassValid(true)
          }
        }}
        onBlur={() => setFieldTouched('phone', true)}
        buttonStyle={{ backgroundColor: '#22214b' }}
        inputStyle={{ width: '100%' }}
      />

    </div>
  )
}

export default UserInfoPhoneField
