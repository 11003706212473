import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { userLoginStart } from '../../store/redux-slices/login'
import { Routes } from '../../utils/routes'
import { getItem, getLoginToken } from '../../utils/storageUtils'
import { setSelectedLanguagesStart } from '../../store/redux-slices/language'
//
const useUserLogin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { languageCode } = useParams()
  const { loading } = useSelector(state => state.login)
  const { languages, languageData, selectedLanguageCode } = useSelector((state) => state.language)
  const { state } = useLocation()
  const [showModal, setShowModal] = useState({
    show: false,
    expiration: ''
  })
  const [showResendEmail, setShowResendEmail] = useState(false)
  const [language, setLanguage] = useState(languageCode || selectedLanguageCode || getItem('language'))

  useEffect(() => {
    if (languages?.allowedLanguages?.includes(languageCode?.toUpperCase())) {
      dispatch(setSelectedLanguagesStart({
        selectedLanguageCode: languageCode
      }))
    } else {
      dispatch(setSelectedLanguagesStart({
        selectedLanguageCode: languages?.allowedLanguages?.[0]
      }))
    }
  }, [languages])

  useEffect(() => {
    if (getLoginToken()) {
      navigate(Routes.homepage)
    }
  }, [])

  const handleSignIn = ({ email, password }) =>
    dispatch(userLoginStart({ email, password, setShowModal, navigate, returnTo: window.locationPath || state?.navigate, setShowResendEmail }))

  return {
    loading,
    showModal,
    showResendEmail,
    languageData,
    languages,
    language,
    setLanguage,
    setShowModal,
    handleSignIn
  }
}

export default useUserLogin
