import React from 'react'
import { getTimeLeft } from '../../../utils/helpers'
import '../styles/index.scss'

const TournamentListCard = ({ tournament, selectedLanguageCode, navigate, languageData }) => {
  return (
    <div className='col-lg-4 col-sm-6 game-card-wrap'>
      <div className='card tournament-card'>
        {new Date() > new Date(tournament?.endAt)
          ? <a className='btn btn-primary status ended-status' href={() => false}> <div className='dot' /> {languageData?.ended}</a>
          : new Date() > new Date(tournament?.startAt)
            ? <a className='btn btn-primary status active-status' href={() => false}> <div className='dot' /> {languageData?.active}</a>
            : <a className='btn btn-primary status coming-soon-status' href={() => false}> <div className='dot' />{languageData?.comingSoon}</a>}
        <img src={tournament?.bannerUrl} width='362px' height='205px' className='card-img-top' alt='...' />
        <div className='card-body'>
          <h5 className='card-title tournament-name'>{tournament?.title}</h5>
          <div className='details-wrap'>
            <span className='info-left'> {languageData?.timeLeft}</span>
            <span className='info-right'>
              {new Date() > new Date(tournament?.startAt) ? getTimeLeft(tournament.endAt, true) : getTimeLeft(tournament.startAt, true)}
            </span>
          </div>
          <div className='details-wrap'>
            <span className='info-left'> {languageData?.prizePool}  </span>
            <span className='info-right'>{JSON.parse(tournament?.prizePool)?.amount} EUR</span>
          </div>
          <div className='details-wrap'>
            <span className='info-left'> {languageData?.prizePlaces}</span>
            <span className='info-right'>{tournament?.prizePlaces}</span>
          </div>
          <button href='#' className='btn btn-primary tournament-btn' onClick={() => navigate(`/${selectedLanguageCode?.toLowerCase()}/tournaments/${tournament.tournamentId}`)}>
            {languageData?.showMore}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TournamentListCard
