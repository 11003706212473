import React, { useEffect, useState } from 'react'
import { Button, Modal, Row, Tab, Tabs } from 'react-bootstrap'

import { capitalizeMyText } from 'utils/helpers'
import useSearchGames from '../hooks/useSearchGames'
import GameSearch from '../GameSearch'
import GameCardSearch from '../GameCardSearch'
import GameFiltersSimpleList from '../GameFiltersSimpleList'
import GameCard from '../GameCard'
import { PrimaryButton } from '../../Common/Button'
import Loader from '../../Loader'
import NoDataAvailable from 'components/NoDataAvailable'
import './styles/index.scss'

/**
 * Component representing a modal used for searching and filtering games or providers.
 *
 * @component
 * @param {Object} props - The input properties for the component.
 * @param {boolean} props.showSearchModal - A flag indicating whether the modal should be visible.
 * @param {Function} props.setShowSearchModal - A function to toggle the modal's visibility.
 * @param {string} props.dataType - A string representing the type of data being searched or filtered.
 * @param {Array} props.providers - A list of provider data to display or filter in the modal.
 * @param {Object} props.selectedFilter - An object representing the currently applied search or filter criteria.
 * @param {Function} props.setSelectedFilter - A function to update the selected filter criteria.
 * @description
 * ModalSearch displays a modal dialog that allows users to search for games or providers
 * and apply various filters. It uses multiple tabs for separating search options, and provides
 * infinite scroll functionality for game search results. Supports mobile and tablet views,
 * displaying responsive content accordingly.
 */
const ModalSearch = ({
  showSearchModal,
  setShowSearchModal,
  dataType,
  providers,
  selectedFilter,
  setSelectedFilter
}) => {
  const {
    gameSearch,
    setSelectedSearchGameFilter,
    languageData,
    toggleFavorite,
    favoriteGamesList,
    mobileOrTablet,
    loadMore,
    loadAll,
    hasMore,
    isLoadAllActive,
    bottomRef,
    loading,
    selectedLanguageCode
  } = useSearchGames()

  const [providerSelected, setProviderSelected] = useState(false)

  useEffect(() => {
    if (selectedFilter?.provider.length) {
      setProviderSelected(true)
    }
  })

  return (
    <Modal
      show={showSearchModal}
      aria-labelledby='contained-modal-title-vcenter'
      className='game-search-modal'
      centered
      scrollable
      style={{ overflow: 'auto' }}
    >
      <Modal.Header>
        <div className='modal-title'>
          {languageData?.casinoSearch}
        </div>
        <Button
          className={`close-btn modal-close filters-selected-${providerSelected}`}
          variant='danger'
          onClick={() => {
            setShowSearchModal(false)
          }}
        >
          <i className={`fa-solid ${providerSelected ? 'fa-check' : 'fa-xmark'}`} />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey='gamesearch' id='justify-tab-example' className='mb-3' justify>
          <Tab eventKey='gamesearch' title='Game Search'>
            <Row className='filter-search-input w-100'>
              <GameSearch
                setSelectedFilter={setSelectedSearchGameFilter}
                languageData={languageData}
              />
            </Row>
            <Row className='filter-search-list'>
              <div className='search-game-listing'>
                {gameSearch?.rows &&
                  gameSearch?.rows?.map((game, index) =>
                    mobileOrTablet
                      ? (
                        <GameCardSearch
                          game={game}
                          index={index}
                          key={index}
                          setShowSearchModal={setShowSearchModal}
                        />
                      )
                      : (
                        <GameCard
                          game={game}
                          index={index}
                          key={index}
                          toggleFavorite={toggleFavorite}
                          setShowSearchModal={setShowSearchModal}
                          favoriteGamesList={favoriteGamesList}
                          selectedLanguageCode={selectedLanguageCode}
                          playGameCallback={() => setShowSearchModal(false)}
                        />
                      )
                  )}
                {/* Bottom reference for infinite scroll */}
                {isLoadAllActive && hasMore && (
                  <div ref={bottomRef} style={{ height: '20px' }} />
                )}
                {loading && <Loader top='5%' />}
                {!loading && !Boolean(gameSearch?.rows?.length) && <NoDataAvailable errorMessage={languageData?.casinoNoGamesFound} />}
                {!loading && hasMore && (
                  <div className='search-game-listing__buttons d-flex justify-content-center align-items-center gap-4'>
                    <PrimaryButton
                      className='mt-4 button'
                      onClick={() => loadMore()}
                      isDisabled={!hasMore}
                    >
                      {`${languageData?.loadMore}`.toUpperCase()}
                    </PrimaryButton>
                    <PrimaryButton
                      className='mt-4 button'
                      onClick={() => loadAll()}
                      isDisabled={!hasMore}
                    >
                      {`${languageData?.loadAll}`.toUpperCase()}
                    </PrimaryButton>
                  </div>
                )}
              </div>
            </Row>
          </Tab>
          <Tab eventKey='providersearch' title='Providers'>
            <GameFiltersSimpleList
              dataSet={providers}
              selectedFilterData={selectedFilter?.provider}
              setSelectedFilter={setSelectedFilter}
              capitalizeMyText={capitalizeMyText}
              dataType={dataType}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  )
}

export default ModalSearch
