import React, { useContext, useRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import CustomDropdownToggle from '../../custom/CustomDropdownToggle'

const CountryField = ({ formik, contextValue, disabled = false }) => {
  const { allCountries, setSearch, search, userDetails, languageData } = useContext(contextValue)
  const { values, setFieldValue, setFieldTouched, validateField } = formik
  const dropdownRef = useRef(null)

  return (
    <Dropdown
      className={`icon-left ${disabled ? 'pe-none' : ''}`}
      onClick={() => setFieldTouched('countryCode', true)}
      id='countryCode'
      ref={dropdownRef}
    >
      <Dropdown.Toggle
        id='dropdown-basic'
        as={CustomDropdownToggle}
        disabled={userDetails ? [0, 1, '0', '1'].includes(userDetails?.kycMethod) || userDetails?.claimedBonus : false}
        style={{ textAlign: 'left !important' }}
        onKeyDown={(e) => {
          if (dropdownRef?.current?.querySelector('.show') && e.key !== 'Backspace') {
            setSearch(search + e.key)
          } else if (e.key === 'Backspace' || e.key === 'Delete') {
            setSearch('')
          }
        }}
        onClickCapture={(e) => {
          if (dropdownRef?.current?.querySelector('.show')) {
            setSearch('')
          }
        }}
      >
        <span>{allCountries?.rows?.map(({ name, code }) => {
          if (code === values?.countryCode) return name
          return null
        }
        )}
        </span>
        <img src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`} alt='Dropdown' />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className='scrollDropdown'>
          <Dropdown.Item disabled>{languageData?.selectCountryCode}</Dropdown.Item>
          {allCountries?.rows?.map(({ name, code }) =>
            name.toLowerCase().startsWith(search?.toLowerCase()) &&
              <Dropdown.Item
                key={name}
                onClick={async () => {
                  await setFieldValue('countryCode', code)
                  validateField('countryCode')
                }}
              >{name}
              </Dropdown.Item>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CountryField
