import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const Overlay = ({ message, children }) => {
  return (
    <OverlayTrigger
      placement='bottom'
      delay={{ show: 100, hide: 200 }}
      overlay={<Tooltip>{message}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

const Trigger = ({ val, width = '107px' }) => {
  return (
    <Overlay message={val}>
      <span
        style={{
          width,
          cursor: 'pointer'
        }}
        className='d-inline-block text-link'
      >
        {val}
      </span>
    </Overlay>
  )
}

export default Trigger
