import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    setDailyLimitStart,
    setDailyLimitFailure,
    setLossLimitStart,
    setDepositLimitStart,
    setLossLimitFailure,
    setDepositLimitFailure,
    setSessionTimeStart,
    setSessionTimeFailure,
    setDisableUntilStart,
    setDisableUntilComplete,
    updatePasswordStatus,
    setGamePlayLimitStart,
    setGamePlayLimitComplete
  },
  reducer
} = createSlice({
  name: 'limits',
  initialState: {
    loading: false,
    isPasswordCorrect: null
  },
  reducers: {
    setDailyLimitStart: (state) => ({
      ...state,
      loading: true,
      isPasswordCorrect: null
    }),
    setDailyLimitFailure: (state) => ({
      ...state,
      loading: false,
      isPasswordCorrect: null
    }),
    setLossLimitStart: (state) => ({
      ...state,
      loading: true,
      isPasswordCorrect: null
    }),
    setLossLimitFailure: (state) => ({
      ...state,
      loading: false,
      isPasswordCorrect: null
    }),
    setDepositLimitStart: (state) => ({
      ...state,
      loading: true,
      isPasswordCorrect: null
    }),
    setDepositLimitFailure: (state) => ({
      ...state,
      loading: false,
      isPasswordCorrect: null
    }),
    setSessionTimeStart: (state) => ({
      ...state,
      loading: true,
      isPasswordCorrect: null
    }),
    setSessionTimeFailure: (state) => ({
      ...state,
      loading: false,
      isPasswordCorrect: null
    }),
    setDisableUntilStart: (state) => ({
      ...state,
      loading: true,
      isPasswordCorrect: null
    }),
    setDisableUntilComplete: (state) => ({
      ...state,
      loading: false
    }),
    updatePasswordStatus: (state, { payload }) => ({
      ...state,
      loading: false,
      isPasswordCorrect: payload
    }),
    setGamePlayLimitStart: (state) => ({
      ...state,
      loading: true,
      isPasswordCorrect: null
    }),
    setGamePlayLimitComplete: (state) => ({
      ...state,
      loading: false,
      isPasswordCorrect: null
    })
  }
})

export default reducer

export {
  setDailyLimitStart,
  setDailyLimitFailure,
  setLossLimitStart,
  setLossLimitFailure,
  setDepositLimitStart,
  setDepositLimitFailure,
  setSessionTimeStart,
  setSessionTimeFailure,
  setDisableUntilStart,
  setDisableUntilComplete,
  updatePasswordStatus,
  setGamePlayLimitStart,
  setGamePlayLimitComplete
}
