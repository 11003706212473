import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFilteredGamesStart, toggleFavoriteStart } from '../../../store/redux-slices/casinoMenu'
import { isMobileOrTablet } from '../../../utils/helpers'
import { getItem } from 'utils/storageUtils'
import { toast } from 'components/Toast'

const useSearchGames = () => {
  const dispatch = useDispatch()
  const { gameSearch, filteredLoading, favoriteGamesList } = useSelector(
    (state) => state.casinoMenu
  )

  const loggedIn = JSON.parse(getItem('loggedIn'))

  const totalCount = gameSearch?.totalCount
  const { languageData, selectedLanguageCode } = useSelector((state) => state?.language)

  const [selectedSearchGameFilter, setSelectedSearchGameFilter] = useState({
    search: ''
  })
  const [pageNo, setPageNo] = useState(1)
  const limit = 20
  const [isLoadAllActive, setIsLoadAllActive] = useState(false)

  const mobileOrTablet = isMobileOrTablet()

  const loadMore = () => {
    if (gameSearch?.rows?.length < totalCount) {
      setPageNo((prev) => prev + 1)
    }
  }

  const loadAll = () => {
    setIsLoadAllActive(true)
    if (gameSearch?.rows?.length < totalCount) {
      setPageNo((prev) => prev + 1)
    }
  }

  const bottomRef = useRef(null)

  const hasMore = pageNo * limit < totalCount

  useEffect(() => {
    setPageNo(1)
    setIsLoadAllActive(false)
  }, [selectedSearchGameFilter?.search])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        getFilteredGamesStart({
          search: selectedSearchGameFilter?.search || '',
          gameInputSearch: true,
          pageNo,
          limit,
          fetchMore: pageNo > 1
        })
      )
    }, 1000)

    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [selectedSearchGameFilter?.search, pageNo])

  // Infinite scrolling logic
  useEffect(() => {
    if (!isLoadAllActive || !hasMore) return

    const observer = new window.IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore()
        }
      },
      { threshold: 1.0 }
    )

    if (bottomRef.current) {
      observer.observe(bottomRef.current)
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current)
      }
    }
  }, [isLoadAllActive, hasMore])

  const toggleFavorite = ({ isFavorite, tenantGameSubCategoryId, categoryGameId }) => {
    if (loggedIn) {
      dispatch(toggleFavoriteStart({
        isFavorite,
        tenantGameSubCategoryId,
        categoryGameId,
      }))
    } else {
      toast(languageData?.loginFirst, 'error')
    }
  }

  return {
    languageData,
    loading: filteredLoading,
    gameSearch,
    selectedSearchGameFilter,
    setSelectedSearchGameFilter,
    favoriteGamesList,
    mobileOrTablet,
    loadMore,
    loadAll,
    hasMore,
    isLoadAllActive,
    bottomRef,
    toggleFavorite,
    selectedLanguageCode
  }
}

export default useSearchGames
