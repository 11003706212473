import React from 'react'
import { currencyCodeList } from '../../../constants/currencyCodeList'
import { useSelector } from 'react-redux'
import CustomOverlayTrigger from '../../CustomOverlayTrigger'
import { dashboardOverlayTrigger } from '../../../constants/overlayTrigger'
const BalanceCard = (props) => {
  const { balanceCardsDetail, userDetails } = props
  const { cashbackBalance } = useSelector((state) => state.user)
  const { languageData } = useSelector((state) => state.language)

  /** This variable show balance based upon balance type */
  const balance =
    balanceCardsDetail.balanceType === 'cashback'
      ? cashbackBalance
      : userDetails?.userWallet?.[balanceCardsDetail.balanceType]

  return (
    <div className='balance-sub-section'>
      <div className='balance-icon'>
        <img src={`${process.env.REACT_APP_STATIC_IMAGE}/${balanceCardsDetail.balanceIcon}`} alt='img' />
      </div>
      <div className='balance-details'>
        <div className='balance-type'>
          <span>{balanceCardsDetail.balanceText}</span>
        </div>
        <div className='balance'>
          {`${
            currencyCodeList[userDetails?.userWallet?.currencyCode]
          } ${balance}`}
        </div>
      </div>
      <div className='cashback-toggle'>
        {balanceCardsDetail.balanceType === 'cashback' && (
          <CustomOverlayTrigger overlayContent={languageData?.cashbackInfo} {...dashboardOverlayTrigger}>
            <button className='loyalty-info-i'>i</button>
          </CustomOverlayTrigger>
        )}
      </div>
    </div>
  )
}

export default BalanceCard
