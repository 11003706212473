import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    userLoginStart,
    userLoginSuccess,
    userLoginFailure,
    getRegistrationFieldsStart,
    getRegistrationFieldsSuccess,
    getRegistrationFieldsFailure,
    userSignUpStart,
    userSignUpComplete,
    getTenantDetailsStart,
    getTenantDetailsSuccess,
    getTenantDetailsFailure,
    getAllCountriesStart,
    getAllCountriesSuccess,
    getAllCountriesFailure,
    getUserDetailsSuccess,
    getUserDetailsFailure,
    verifyEmailStart,
    verifyEmailComplete,
    resendEmailStart,
    resendEmailComplete,
    forgetPasswordStart,
    forgetPasswordComplete,
    resetPasswordStart,
    resetPasswordComplete,
    setSocketClient,
    logoutStart,
    logoutComplete,
    getThemesStart,
    getThemesSuccess,
    getThemesFailure
  },
  reducer
} = createSlice({
  name: 'login',
  initialState: {
    loading: false,
    isLoggedIn: false,
    success: false,
    regFields: {},
    tenantDetails: {},
    getAllCountries: {},
    verifyStatus: false,
    isConnected: false,
    themes: []
  },
  reducers: {
    userLoginStart: (state) => ({
      ...state,
      loading: true
    }),
    userLoginSuccess: (state) => ({
      ...state,
      loading: false,
      isLoggedIn: true,
      success: true
    }),
    userLoginFailure: (state) => ({
      ...state,
      loading: false,
      isLoggedIn: false,
      success: false
    }),
    getRegistrationFieldsStart: (state) => ({
      ...state,
      loading: true
    }),
    getRegistrationFieldsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      regFields: payload
    }),
    getRegistrationFieldsFailure: (state) => ({
      ...state,
      loading: false
    }),
    userSignUpStart: (state) => ({
      ...state,
      loading: true
    }),
    userSignUpComplete: (state) => ({
      ...state,
      loading: false
    }),
    getTenantDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getTenantDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tenantDetails: payload
    }),
    getTenantDetailsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllCountriesStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllCountriesSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allCountries: payload
    }),
    getAllCountriesFailure: (state) => ({
      ...state,
      loading: false
    }),
    verifyEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    verifyEmailComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      verifyStatus: payload
    }),
    resendEmailStart: (state) => ({
      ...state,
      loading: true
    }),
    resendEmailComplete: (state) => ({
      ...state,
      loading: false
    }),
    forgetPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    forgetPasswordComplete: (state, { payload }) => ({
      ...state,
      loading: false,
      forgetPass: payload
    }),
    resetPasswordStart: (state) => ({
      ...state,
      loading: true
    }),
    resetPasswordComplete: (state, { payload }) => ({
      ...state,
      loading: false
    }),
    setSocketClient: (state, { payload }) => ({
      ...state,
      client: payload
    }),
    logoutStart: (state) => ({
      ...state,
      logoutLoading: true
    }),
    logoutComplete: (state) => ({
      ...state,
      logoutLoading: false
    }),
    getThemesStart: (state) => ({
      ...state
    }),
    getThemesSuccess: (state, { payload }) => ({
      ...state,
      themes: payload
    }),
    getThemesFailure: (state) => ({
      ...state
    })
  }
})

export default reducer

export {
  userLoginStart,
  userLoginSuccess,
  userLoginFailure,
  getRegistrationFieldsStart,
  getRegistrationFieldsSuccess,
  getRegistrationFieldsFailure,
  userSignUpStart,
  userSignUpComplete,
  getTenantDetailsStart,
  getTenantDetailsSuccess,
  getTenantDetailsFailure,
  getAllCountriesStart,
  getAllCountriesSuccess,
  getAllCountriesFailure,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  verifyEmailStart,
  verifyEmailComplete,
  resendEmailStart,
  resendEmailComplete,
  forgetPasswordStart,
  forgetPasswordComplete,
  resetPasswordStart,
  resetPasswordComplete,
  setSocketClient,
  logoutStart,
  logoutComplete,
  getThemesStart,
  getThemesSuccess,
  getThemesFailure
}
