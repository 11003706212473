import React from 'react'

const GameFiltersCheckbox = ({
  dataSet,
  selectedFilterData,
  setSelectedFilter,
  capitalizeMyText,
  dataType,
  title,
  icon
}) => {
  return (
    <div className='filter-list-block'>
      <div className='filter-list-wrapper-header'>
        <i className={`fa-solid mr-2 fa-${icon}`} />
        <span className='text'>{title}</span>
      </div>
      <div className='filter-list-wrapper-body'>
        <div className='checklist-wrapper'>
          {dataSet?.length > 0 && dataSet?.map((data, index) => (
            <a
              className={selectedFilterData?.includes((data?.name ? data.name : data))
                ? 'checklist-item selected'
                : 'checklist-item'}
              href={() => false}
              key={index}
              onClick={() => {
                if (dataType === 'providers') {
                  if (selectedFilterData?.includes((data?.name ? data.name : data))) {
                    setSelectedFilter(selectedFilter => ({ ...selectedFilter, provider: selectedFilterData?.filter(ele => ele !== (data?.name ? data.name : data)) }))
                  } else {
                    setSelectedFilter(selectedFilter => ({ ...selectedFilter, provider: [...selectedFilterData, (data?.name ? data.name : data)] }))
                  }
                } else if (dataType === 'rating') {
                  if (selectedFilterData?.includes((data?.name ? data.name : data))) {
                    setSelectedFilter(selectedFilter => ({ ...selectedFilter, rating: selectedFilterData?.filter(ele => ele !== (data?.name ? data.name : data)) }))
                  } else {
                    setSelectedFilter(selectedFilter => ({ ...selectedFilter, rating: [...selectedFilterData, (data?.name ? data.name : data)] }))
                  }
                } else if (dataType === 'themes') {
                  if (selectedFilterData?.includes((data?.name ? data.name : data))) {
                    setSelectedFilter(selectedFilter => ({ ...selectedFilter, themes: selectedFilterData?.filter(ele => ele !== (data?.name ? data.name : data)) }))
                  } else {
                    setSelectedFilter(selectedFilter => ({ ...selectedFilter, themes: [...selectedFilterData, (data?.name ? data.name : data)] }))
                  }
                }
              }}
            >
              <div className='checklist-item-checkbox'>
                {selectedFilterData?.includes((data?.name ? data.name : data)) && <img className='check-icon' src='/icon/checkicon.svg' />}
              </div>
              {capitalizeMyText((data?.name ? data.name : data?.includes('[') ? JSON.parse(data)?.join(' / ') : data))}
            </a>))}
        </div>
      </div>
    </div>
  )
}

export default GameFiltersCheckbox
