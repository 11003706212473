import React from 'react'
import ReactTooltip from 'react-tooltip'
import CustomLabel from '../CustomLabel'
import PassField from './components/PassField'
import { useSelector } from 'react-redux'

const PasswordFieldC = ({
  formik,
  name,
  label,
  placeholder,
  fieldClassName,
  extraLabel,
  isAccountInfo,
  containerClassName,
  regFields = '',
  showStar,
  showInfoIcon = false
}) => {
  const { languageData } = useSelector((state) => state.language)
  return (
    <>
      {(regFields ? regFields?.password !== 0 : true) && (
        <div className={`${containerClassName} icon_include password-info`}>
          <CustomLabel
            name={name}
            label={label}
            extraLabel={extraLabel}
            regFields={regFields}
            showStar={showStar}
            className='custom-label'
          />

          {name === 'password' && showInfoIcon && (
            <>
              &nbsp;
              <label
                className='custom-tooltip-icon'
                data-for='tip'
                data-tip={languageData?.passwordTip}
              >
                i
              </label>
              <ReactTooltip
                id='tip'
                place='top'
                type='info'
                effect='float'
                multiline
              />
            </>
          )}
          <PassField
            name={name}
            fieldClassName={fieldClassName}
            placeholder={placeholder}
            formik={formik}
            isAccountInfo={isAccountInfo}
            showInfoIcon={showInfoIcon}
          />

        </div>
      )}
    </>
  )
}

export default PasswordFieldC
