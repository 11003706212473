export const EVENT_LISTENERS = {
  DISCONNECT: 'disconnect',
  CONNECT: 'connect',
  ERROR: 'error',
  WALLET_UPDATE: '/wallet',
  PAYMENT_STATUS: '/paymentStatus'
}

export const SOCKET_ROOM_NAME = {
  WALLET: 'private'
}

export const cmsReorder = {
  'general-terms': 1,
  'bonus-terms': 2,
  'cookie-policy': 3,
  'privacy-policy': 4,
  affiliates: 5
}
