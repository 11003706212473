import React, { useEffect } from 'react'
import { DateRange } from 'react-date-range'
import { formatDateYMD } from '../../utils/dateFormatter'
import useOutsideClick from '../../utils/useOutsideClick'
import './styles/index.scss'

const CustomDateRange = ({ state, setState }) => {
  const { ref, isVisible, setIsVisible } = useOutsideClick(false)

  useEffect(() => {
    const container = document.getElementsByClassName('rdrDateDisplay')?.[0]

    // Create a new element Cross Button
    const newElement = document.createElement('button')
    newElement.textContent = 'X'
    newElement.style = 'border: 0px;background: unset;font-size: 21px;margin: 5px 0px 5px 5px;'
    newElement.onclick = () => setIsVisible(false)

    // Append the new element to the container
    container?.appendChild(newElement)

    return () => container?.removeChild(newElement)
  }, [isVisible])

  return (
    <div className='form-group custom-container'>
      <input
        readOnly
        className='custom-input form-control cursor-pointer'
        onClick={() => setIsVisible(!isVisible)}
        value={`${formatDateYMD(state[0]?.startDate)} to ${formatDateYMD(
          state[0]?.endDate
        )}`}
      />

      {isVisible && (
        <div ref={ref} style={{ zIndex: '9999' }}>
          <DateRange
            editableDateInputs
            onChange={(item) => {
              setState([item.selection])
            }}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </div>
      )}
    </div>
  )
}

export default CustomDateRange
