import React from 'react'
import './styles/index.scss'
import CustomOverlayTrigger from '../../CustomOverlayTrigger'
import { bonusOverlayTrigger } from '../../../constants/overlayTrigger'
import { OverlayTrigger } from 'react-bootstrap'
import useBonusCard from './hooks/useBonusCard'
import { deviceType } from '../Constant'
import HtmlParser from 'react-html-parser'
import BonusDescription from './BonusDescription'
import FreeSpinGamesModal from '../../../pages/Bonus/components/FreeSpinGamesModal'
const BonusCard = (props) => {
  const {
    bonusList,
    selectedLanguageCode,
    cardStatus,
    setShowModal,
    handleBonusActivation,
    setSelectedForfeitBonus,
    overlayContent,
    renderTooltip,
    dataToShow,
    navigate,
    languageData,
    showFreeSpinGameModal,
    setShowFreeSpinGameModal,
    selectedBonusDetail,
    setSelectedBonusDetail
  } = useBonusCard(props)

  return (
    <>
      {
        bonusList.rows.map((bonusDetail, index) => {
          return (
            <div className='bonus-cart-section' key={index}>
              <div className='bonus-img-section star-section'>
                {cardStatus === 'active' && (
                  <div className='bonus-status'>
                    <div className='circle' />
                    <div>
                      {
                        bonusDetail?.status === 'ZEROED OUT'
                          ? languageData?.zeroedOut
                          : bonusDetail?.status === 'IN-PROCESS'
                            ? languageData?.inProcess
                            : languageData?.[bonusDetail?.status?.toLowerCase()]
                      }
                    </div>
                  </div>
                )}
                <img src={languageData?.bonusStaticImage} alt='img' />
              </div>

              <div className='bonus-detail-section'>
                <div className='d-flex flex-row'>
                  <div
                    className='bonus-title'
                    onClick={() => {
                      bonusDetail?.bonusId &&
                        navigate(
                          `/${selectedLanguageCode?.toLowerCase()}/promotion/${bonusDetail?.bonusId
                          }/${bonusDetail?.userBonusId}`
                        )
                    }}
                  >
                    <CustomOverlayTrigger
                      overlayContent={overlayContent(bonusDetail)}
                      {...bonusOverlayTrigger}
                    >
                      {overlayContent(bonusDetail)}
                    </CustomOverlayTrigger>
                  </div>

                  <div className='d-flex justify-content-start align-items-center bonus-popover'>
                    <OverlayTrigger
                      trigger={['focus', 'hover']}
                      overlay={renderTooltip}
                      placement={deviceType === 'mobile' ? 'left' : 'top'}
                    >
                      <div
                        style={{ maxWidth: '210px', cursor: 'pointer' }}
                        className='text-truncate'
                      >
                        <button
                          onClick={() => {
                            dataToShow(bonusDetail)
                          }}
                          onMouseOver={() => {
                            dataToShow(bonusDetail)
                          }}
                          className='loyalty-info'
                        >
                          i
                        </button>
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>

                {bonusDetail.bonus?.description && <BonusDescription bonusDetail={bonusDetail} content={HtmlParser(bonusDetail.bonus?.description?.[selectedLanguageCode]?.replace(/&nbsp;/g, ' '))} />}

              </div>

              <div className='bonus-button-controls'>
                <button
                  className='forfeitBtn'
                  onClick={() => {
                    setSelectedForfeitBonus(bonusDetail)
                    setShowModal(true)
                  }}
                >
                  {languageData?.forfeit}
                </button>
                {bonusDetail?.status !== 'ACTIVE' && bonusDetail?.status !== 'IN-PROCESS' && (
                  <button
                    className='activeBtn'
                    onClick={() => {
                      if (bonusDetail?.bonusType === 'freespins' && ['2', '3', 3].includes(bonusDetail?.bonus?.other?.aggregatorId) && bonusDetail?.status === 'PENDING') {
                        setSelectedBonusDetail(bonusDetail)
                        setShowFreeSpinGameModal(true)
                      } else {
                        handleBonusActivation(bonusList, bonusDetail)
                      }
                    }}
                  >
                    {languageData?.bonusActivate}
                  </button>
                )}
              </div>

            </div>
          )
        })
      }
      {
        showFreeSpinGameModal &&
          <FreeSpinGamesModal
            showFreeSpinGameModal={showFreeSpinGameModal}
            setShowFreeSpinGameModal={setShowFreeSpinGameModal}
            selectedBonusDetail={selectedBonusDetail}
            activateBonus={(bonusDetail, identifier) => handleBonusActivation(bonusList, bonusDetail, identifier)}
          />
      }
    </>
  )
}

export default BonusCard
