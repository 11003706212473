import { React } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const GameFiltersSimpleList = ({
  dataSet,
  selectedFilterData,
  setSelectedFilter,
  capitalizeMyText,
  dataType
}) => {
  const navigate = useNavigate()
  const { selectedLanguageCode, brandLogo } = useSelector(state => state.language)
  const { gameCategory } = useSelector(state => state.casinoMenu)

  return (
    <div className='list-items-wrapper'>
      <div className='list-items-wrapper-content'>
        {dataSet?.length > 0 && dataSet?.map((data, index) => (
          <a
            className={selectedFilterData?.includes((data?.name ? data.name : data))
              ? 'list-item selected'
              : 'list-item'}
            href={() => false}
            key={index}
            onClick={() => {
              if (dataType === 'providers') {
                // redirect from homepage to the category
                if (window.location.pathname === '/') {
                  navigate(`/${selectedLanguageCode?.toLowerCase()}/${gameCategory?.casinoCategories?.rows?.[0]?.name?.EN?.toLowerCase()}/all`)
                }
                if (selectedFilterData?.includes((data?.name ? data.name : data))) {
                  setSelectedFilter(selectedFilter => ({ ...selectedFilter, provider: selectedFilterData?.filter(ele => ele !== (data?.name ? data.name : data)) }))
                } else {
                  setSelectedFilter(selectedFilter => ({ ...selectedFilter, provider: [...selectedFilterData, (data?.name ? data.name : data)] }))
                }
              } else if (dataType === 'rating') {
                if (selectedFilterData?.includes((data?.name ? data.name : data))) {
                  setSelectedFilter(selectedFilter => ({ ...selectedFilter, rating: selectedFilterData?.filter(ele => ele !== (data?.name ? data.name : data)) }))
                } else {
                  setSelectedFilter(selectedFilter => ({ ...selectedFilter, rating: [...selectedFilterData, (data?.name ? data.name : data)] }))
                }
              } else if (dataType === 'themes') {
                if (selectedFilterData?.includes((data?.name ? data.name : data))) {
                  setSelectedFilter(selectedFilter => ({ ...selectedFilter, themes: selectedFilterData?.filter(ele => ele !== (data?.name ? data.name : data)) }))
                } else {
                  setSelectedFilter(selectedFilter => ({ ...selectedFilter, themes: [...selectedFilterData, (data?.name ? data.name : data)] }))
                }
              }
            }}
          >
            {dataType === 'providers' &&
              <img
                alt='provider'
                className='item-icon'
                src={data?.thumbnail_url?.replace('providers/', 'providers_small/') || brandLogo || '/images/brand-logo.svg'}
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = brandLogo || '/images/brand-logo.svg'
                }}
              />}
            {capitalizeMyText((data?.name ? data.name : data))} {selectedFilterData?.includes((data?.name ? data.name : data)) && <i className='fa-solid fa-circle-check' />}
          </a>))}
      </div>
    </div>
  )
}

export default GameFiltersSimpleList
