import React, { useContext } from 'react'
import { Dropdown } from 'react-bootstrap'
import CustomDropdownToggle from '../../custom/CustomDropdownToggle'
const CurrencyField = ({ formik, contextValue }) => {
  const { tenantDetails } = useContext(contextValue)
  const { values, setFieldValue, validateField } = formik
  return (
    <Dropdown
      className='icon-right small-height'
      id='currencyCode'
    >
      <Dropdown.Toggle id='dropdown-basic2' as={CustomDropdownToggle}>
        <span>
          {tenantDetails?.tenantConfig?.allowedCurrencies?.find(
            (curr) => curr === values?.currencyCode
          ) || 'Select Currency Code'}
        </span>
        <img
          src={`${process.env.REACT_APP_STATIC_IMAGE}/select-drop-arrow.svg`}
          alt='Dropdown'
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className='scrollDropdown'>
          <Dropdown.Item disabled>Select Currency Code</Dropdown.Item>
          {tenantDetails?.tenantConfig?.allowedCurrencies?.map((curr) => (
            <Dropdown.Item
              key={curr}
              onClick={async () => {
                await setFieldValue('currencyCode', curr)
                validateField('currencyCode')
              }}
            >
              {curr}
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CurrencyField
