import { useState } from 'react'
import { formatDateYMD } from '../../../utils/dateFormatter'
import { useSelector } from 'react-redux'

const useDetailPopover = () => {
  const [keys, setKeys] = useState([])
  const { languageData } = useSelector(state => state.language)
  const wageringRequirementType = [
    { label: 'BONUS', value: 'bonus', id: 1 },
    { label: 'BONUS+DEPOSIT', value: 'bonusdeposit', id: 2 }
  ]
  const { userDetails } = useSelector(state => state.user)

  const currencyCode = userDetails?.currencyCode
  const validClaim = (bonusDetail) => [
    { label: languageData?.claimedAt, value: bonusDetail?.claimedAt ? formatDateYMD(bonusDetail?.claimedAt) : false },
    { label: languageData?.validTill, value: bonusDetail?.expireAt ? formatDateYMD(bonusDetail?.expireAt) : false }
  ]

  const wageringData = (bonusDetail) => [
    { label: languageData?.wageringType, value: wageringRequirementType?.find(val => val.value === bonusDetail?.bonus?.wageringRequirementType)?.label },
    { label: languageData?.wageringMultiplier, value: bonusDetail?.other?.wageringRequirement || bonusDetail?.bonus?.wageringMultiplier }
  ]

  const getFreeSpinsQty = (selectedBonus, currencyCode) => {
    if (selectedBonus?.bonusType === 'freespins' && selectedBonus?.bonus?.other?.aggregatorId === '2') {
      const selectedBonusDetail = selectedBonus?.bonus?.other?.hub88PrepaidDetails?.filter(
        (bonusDetail) => bonusDetail.currency === currencyCode
      )

      // Check if selectedBonusDetail exists and has at least one element
      return selectedBonusDetail?.[0]?.bet_count ?? 0
    }

    // If conditions are not met, return freeSpinsQty
    return selectedBonus?.freeSpinsQty ?? 0
  }

  const dataToShow = (bonusDetail) => {
    switch (bonusDetail?.bonusType === 'match_1' ? 'MATCH' : bonusDetail?.bonusType?.toUpperCase()) {
      case 'MATCH':
        bonusDetail?.other?.wageringRequirement
          ? setKeys([
            ...validClaim(bonusDetail),
            ...wageringData(bonusDetail)
          ])
          : setKeys([
            ...validClaim(bonusDetail),
            ...wageringData(bonusDetail),
            { label: languageData?.bonusPercentage, value: bonusDetail?.bonus?.depositBonusPercent + '%' }
          ])
        break

      case 'BALANCE':
        bonusDetail?.other?.wageringRequirement
          ? setKeys([
            ...validClaim(bonusDetail)
          ])
          : setKeys([
            ...validClaim(bonusDetail),
            { label: languageData?.minBalancePercentage, value: bonusDetail?.bonus?.depositBonusPercent + '%' }
          ])
        break

      case 'DEPOSIT':
        setKeys([
          ...validClaim(bonusDetail),
          ...wageringData(bonusDetail)
        ])
        break

      case 'WAGERING':
        setKeys([
          ...validClaim(bonusDetail),
          ...wageringData(bonusDetail)
        ])
        break

      case 'FREESPINS':
        bonusDetail?.isSticky
          ? setKeys([
            ...validClaim(bonusDetail),
            { label: languageData?.FREESPINS, value: getFreeSpinsQty(bonusDetail, currencyCode) },
            ...wageringData(bonusDetail)
          ])
          : setKeys([
            ...validClaim(bonusDetail),
            { label: languageData?.FREESPINS, value: getFreeSpinsQty(bonusDetail, currencyCode) }
          ])
        break

      default:
        break
    }
    return keys
  }

  return {
    keys,
    dataToShow
  }
}

export default useDetailPopover
