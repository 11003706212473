import React from 'react'

const GameFiltersSelection = ({
  selectedFilter,
  setSelectedFilter,
  languageData
}) => {
  return (
    <div className='filter-blocks-wrapper'>
      {selectedFilter?.provider?.length > 0 &&
        selectedFilter?.provider?.map((provider, index) => {
          return (
            <a href={() => false} className='filter-block' key={index}>
              <span className='text text-uppercase'>{provider}</span>
              <img
                src={`${process.env.REACT_APP_STATIC_IMAGE}/chip-close.svg`}
                className='close-icon'
                alt='Close'
                onClick={() => {
                  setSelectedFilter(selectedFilter => (
                    {
                      ...selectedFilter,
                      provider: selectedFilter?.provider?.filter(ele => ele !== provider)
                    }))
                }}
              />
            </a>
          )
        })}
      {selectedFilter?.rating?.length > 0 &&
        selectedFilter?.rating?.map((rating, index) => {
          return (
            <a href={() => false} className='filter-block' key={index}>
              <span className='text text-uppercase'>{rating}</span>
              <img
                src={`${process.env.REACT_APP_STATIC_IMAGE}/chip-close.svg`}
                className='close-icon'
                alt='Close'
                onClick={() => {
                  setSelectedFilter(selectedFilter => (
                    {
                      ...selectedFilter,
                      rating: selectedFilter?.rating?.filter(ele => ele !== rating)
                    }))
                }}
              />
            </a>
          )
        })}
      {selectedFilter?.themes?.length > 0 &&
        selectedFilter?.themes?.map((theme, index) => {
          return (
            <a href={() => false} className='filter-block' key={index}>
              <span className='text text-uppercase'>{theme}</span>
              <img
                src={`${process.env.REACT_APP_STATIC_IMAGE}/chip-close.svg`}
                className='close-icon'
                alt='Close'
                onClick={() => {
                  setSelectedFilter(selectedFilter => (
                    {
                      ...selectedFilter,
                      themes: selectedFilter?.themes?.filter(ele => ele !== theme)
                    }))
                }}
              />
            </a>
          )
        })}
      {(selectedFilter?.provider?.length > 0 || selectedFilter?.rating?.length > 0 || selectedFilter?.themes?.length > 0) &&
        <a
          onClick={() => {
            setSelectedFilter(selectedFilter => (
              {
                ...selectedFilter,
                themes: [],
                provider: [],
                rating: []
              }))
          }} href={() => false} className='filter-block clear-filter'
        >
          <span className='text text-uppercase'>{languageData?.reset}</span>
        </a>}
    </div>
  )
}

export default GameFiltersSelection
