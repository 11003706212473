export const sumsubCountry = {
  Afghanistan: 'AFG',
  'Aland Islands': 'ALA',
  Algeria: 'DZA',
  Andorra: 'AND',
  Angola: 'AGO',
  Anguilla: 'AIA',
  Antarctica: 'ATA',
  'Antigua and Barbuda': 'ATG',
  Argentina: 'ARG',
  Armenia: 'ARM',
  Aruba: 'ABW',
  Australia: 'AUS',
  Austria: 'AUT',
  Azerbaijan: 'AZE',
  Bahamas: 'BHS',
  Bahrain: 'BHR',
  Bangladesh: 'BGD',
  Barbados: 'BRB',
  Belarus: 'BLR',
  Belgium: 'BEL',
  Belize: 'BLZ',
  Benin: 'BEN',
  Bermuda: 'BMU',
  Bhutan: 'BTN',
  Bolivia: 'BOL',
  'Bonaire, Saint Eustatius and Saba ': 'BES',
  'Bosnia and Herzegovina': 'BIH',
  Botswana: 'BWA',
  'Bouvet Island': 'BVT',
  Brazil: 'BRA',
  'British Indian Ocean Territory': 'IOT',
  'British Virgin Islands': 'VGB',
  Brunei: 'BRN',
  Bulgaria: 'BGR',
  'Burkina Faso': 'BFA',
  Burundi: 'BDI',
  Cambodia: 'KHM',
  Cameroon: 'CMR',
  Canada: 'CAN',
  'Cape Verde': 'CPV',
  'Cayman Islands': 'CYM',
  'Central African Republic': 'CAF',
  Chad: 'TCD',
  Chile: 'CHL',
  China: 'CHN',
  'Christmas Island': 'CXR',
  'Cocos Islands': 'CCK',
  Colombia: 'COL',
  Comoros: 'COM',
  'Cook Islands': 'COK',
  'Costa Rica': 'CRI',
  Croatia: 'HRV',
  Cuba: 'CUB',
  Curacao: 'CUW',
  Cyprus: 'CYP',
  'Czech Republic': 'CZE',
  'Democratic Republic of the Congo': 'COD',
  Denmark: 'DNK',
  Djibouti: 'DJI',
  Dominica: 'DMA',
  'Dominican Republic': 'DOM',
  'East Timor': 'TLS',
  Ecuador: 'ECU',
  Egypt: 'EGY',
  'El Salvador': 'SLV',
  'Equatorial Guinea': 'GNQ',
  Eritrea: 'ERI',
  Estonia: 'EST',
  Ethiopia: 'ETH',
  'Falkland Islands': 'FLK',
  'Faroe Islands': 'FRO',
  Fiji: 'FJI',
  Finland: 'FIN',
  France: 'FRA',
  'French Guiana': 'GUF',
  'French Polynesia': 'PYF',
  'French Southern Territories': 'ATF',
  Gabon: 'GAB',
  Gambia: 'GMB',
  Georgia: 'GEO',
  Ghana: 'GHA',
  Gibraltar: 'GIB',
  Greece: 'GRC',
  Greenland: 'GRL',
  Grenada: 'GRD',
  Guadeloupe: 'GLP',
  Guam: 'GUM',
  Guatemala: 'GTM',
  Guernsey: 'GGY',
  Guinea: 'GIN',
  'Guinea-Bissau': 'GNB',
  Guyana: 'GUY',
  Haiti: 'HTI',
  'Heard Island and McDonald Islands': 'HMD',
  Honduras: 'HND',
  'Hong Kong': 'HKG',
  Hungary: 'HUN',
  Iceland: 'ISL',
  India: 'IND',
  Indonesia: 'IDN',
  Iran: 'IRN',
  Iraq: 'IRQ',
  Ireland: 'IRL',
  'Isle of Man': 'IMN',
  Israel: 'ISR',
  Italy: 'ITA',
  'Ivory Coast': 'CIV',
  Jamaica: 'JAM',
  Japan: 'JPN',
  Jersey: 'JEY',
  Jordan: 'JOR',
  Kazakhstan: 'KAZ',
  Kenya: 'KEN',
  Kiribati: 'KIR',
  Kuwait: 'KWT',
  Kyrgyzstan: 'KGZ',
  Laos: 'LAO',
  Latvia: 'LVA',
  Lebanon: 'LBN',
  Lesotho: 'LSO',
  Liberia: 'LBR',
  Libya: 'LBY',
  Liechtenstein: 'LIE',
  Lithuania: 'LTU',
  Luxembourg: 'LUX',
  Macao: 'MAC',
  Macedonia: 'MKD',
  Madagascar: 'MDG',
  Malawi: 'MWI',
  Malaysia: 'MYS',
  Maldives: 'MDV',
  Mali: 'MLI',
  Malta: 'MLT',
  'Marshall Islands': 'MHL',
  Martinique: 'MTQ',
  Mauritania: 'MRT',
  Mauritius: 'MUS',
  Mayotte: 'MYT',
  Mexico: 'MEX',
  Micronesia: 'FSM',
  Moldova: 'MDA',
  Monaco: 'MCO',
  Mongolia: 'MNG',
  Montenegro: 'MNE',
  Montserrat: 'MSR',
  Morocco: 'MAR',
  Mozambique: 'MOZ',
  Myanmar: 'MMR',
  Namibia: 'NAM',
  Nauru: 'NRU',
  Nepal: 'NPL',
  Netherlands: 'NLD',
  'New Caledonia': 'NCL',
  'New Zealand': 'NZL',
  Nicaragua: 'NIC',
  Niger: 'NER',
  Nigeria: 'NGA',
  Niue: 'NIU',
  'Norfolk Island': 'NFK',
  'Northern Mariana Islands': 'MNP',
  'North Korea': 'PRK',
  Norway: 'NOR',
  Oman: 'OMN',
  Pakistan: 'PAK',
  Palau: 'PLW',
  'Palestinian Territory': 'PSE',
  Panama: 'PAN',
  'Papua New Guinea': 'PNG',
  Paraguay: 'PRY',
  Peru: 'PER',
  Philippines: 'PHL',
  Pitcairn: 'PCN',
  Poland: 'POL',
  Portugal: 'PRT',
  'Puerto Rico': 'PRI',
  Qatar: 'QAT',
  'Republic of the Congo': 'COG',
  Reunion: 'REU',
  Romania: 'ROU',
  Russia: 'RUS',
  Rwanda: 'RWA',
  'Saint Barthelemy': 'BLM',
  'Saint Helena': 'SHN',
  'Saint Kitts and Nevis': 'KNA',
  'Saint Lucia': 'LCA',
  'Saint Martin': 'MAF',
  'Saint Pierre and Miquelon': 'SPM',
  'Saint Vincent and the Grenadines': 'VCT',
  Samoa: 'WSM',
  'San Marino': 'SMR',
  'Sao Tome and Principe': 'STP',
  'Saudi Arabia': 'SAU',
  Senegal: 'SEN',
  Serbia: 'SRB',
  Seychelles: 'SYC',
  'Sierra Leone': 'SLE',
  Singapore: 'SGP',
  'Sint Maarten': 'SXM',
  Slovakia: 'SVK',
  Slovenia: 'SVN',
  'Solomon Islands': 'SLB',
  Somalia: 'SOM',
  'South Africa': 'ZAF',
  'South Georgia and the South Sandwich Islands': 'SGS',
  'South Korea': 'KOR',
  'South Sudan': 'SSD',
  Spain: 'ESP',
  'Sri Lanka': 'LKA',
  Sudan: 'SDN',
  Suriname: 'SUR',
  'Svalbard and Jan Mayen': 'SJM',
  Swaziland: 'SWZ',
  Sweden: 'SWE',
  Switzerland: 'CHE',
  Syria: 'SYR',
  Taiwan: 'TWN',
  Tajikistan: 'TJK',
  Tanzania: 'TZA',
  Thailand: 'THA',
  Togo: 'TGO',
  Tokelau: 'TKL',
  Tonga: 'TON',
  'Trinidad and Tobago': 'TTO',
  Tunisia: 'TUN',
  Turkey: 'TUR',
  Turkmenistan: 'TKM',
  'Turks and Caicos Islands': 'TCA',
  Tuvalu: 'TUV',
  Uganda: 'UGA',
  Ukraine: 'UKR',
  'United Arab Emirates': 'ARE',
  'United Kingdom': 'GBR',
  'United States': 'USA',
  'United States Minor Outlying Islands': 'UMI',
  Uruguay: 'URY',
  'U.S. Virgin Islands': 'VIR',
  Uzbekistan: 'UZB',
  Vanuatu: 'VUT',
  Vatican: 'VAT',
  Venezuela: 'VEN',
  Vietnam: 'VNM',
  'Wallis and Futuna': 'WLF',
  'Western Sahara': 'ESH',
  Yemen: 'YEM',
  Zambia: 'ZMB',
  Zimbabwe: 'ZWE'
}

export const sumsubLanguageCode = {
  RU: 'ru',
  PS: 'en',
  AR: 'ar',
  EN: 'en',
  PT: 'pt',
  DE: 'de',
  DU: 'nl',
  FR: 'fr',
  ES: 'es',
  SR: 'en',
  BS: 'en',
  BG: 'bg',
  CN: 'zh',
  JA: 'ja',
  MS: 'ms',
  GR: 'el',
  TR: 'tr',
  CZ: 'cs',
  SK: 'sk',
  DA: 'en',
  HI: 'hi',
  FI: 'fi',
  SW: 'en',
  HU: 'hu',
  ID: 'id',
  FA: 'fa',
  IT: 'it',
  KO: 'ko',
  MT: 'en',
  MU: 'en',
  RO: 'to',
  TH: 'th',
  VI: 'vi',
  PL: 'pl',
  NO: 'no'
}
