import React from 'react'
import useBanner from './hooks/useBanner'
import HtmlParser from 'react-html-parser'
import { BannerButton } from '../Buttons'
import './styles/index.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectCube, Pagination } from 'swiper/modules'

const Banner = ({ pageType }) => {
  const { bannerUrl, selectedLanguageCode } = useBanner()

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop
      pagination={{
        clickable: true
      }}
      cubeEffect={{
        shadow: true,
        slideShadows: true,
        shadowOffset: 20,
        shadowScale: 0.94
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }}
      modules={[Autoplay, EffectCube, Pagination]}
      className='mySwiper'
    >
      {bannerUrl?.[pageType?.toUpperCase()]?.banners.map((banner, index) => {
        const bannerHeading =
          banner?.[selectedLanguageCode]?.bannerHeading ||
          banner?.EN?.bannerHeading ||
          ''
        const bannerloggedInBtn =
          banner?.[selectedLanguageCode]?.bannerloggedInBtn ||
          banner?.EN?.bannerloggedInBtn ||
          ''

        const bannerloggedOutBtn =
          banner?.[selectedLanguageCode]?.bannerloggedOutBtn ||
          banner?.EN?.bannerloggedOutBtn ||
          ''

        const bannerDesc =
          banner?.[selectedLanguageCode]?.bannerDesc ||
          banner?.EN?.bannerDesc ||
          ''
        const bannerUrlImage = banner?.image ? process.env.REACT_APP_S3_IMAGE_HEADER + banner?.image : ''

        const bannerFooter =
          banner?.[selectedLanguageCode]?.bannerFooter ||
          banner?.EN?.bannerFooter ||
          ''

        const allDataAvailable = bannerHeading || bannerloggedInBtn || bannerloggedOutBtn || bannerDesc || bannerFooter || bannerUrlImage

        return allDataAvailable && (
          <SwiperSlide key={index}>
            <section className='wrapper banner'>
              <div className='banner-img'>
                <div className='banner-text'>
                  {/* banner heading code part */}
                  {bannerHeading && (
                    <div className='banner-description'>
                      {HtmlParser(bannerHeading)}
                    </div>
                  )}

                  {/* banner description code part */}
                  {bannerDesc &&
                    (bannerHeading
                      ? (
                        <span className='mb-1'>{HtmlParser(bannerDesc)}</span>
                        )
                      : (
                        <div className='banner-description'>
                          {HtmlParser(bannerDesc)}
                        </div>
                        ))}

                  <BannerButton bannerloggedInBtn={bannerloggedInBtn} bannerloggedOutBtn={bannerloggedOutBtn} pageType={pageType} />

                  {/* banner term and condition code part */}
                  {bannerFooter && <span>{HtmlParser(bannerFooter)}</span>}
                </div>

                {/* banner right side image code part */}
                <div className='banner-right-img'>
                  {bannerUrlImage &&
                    <img
                      src={bannerUrlImage}
                      alt='Banner'
                    />}
                </div>
              </div>
            </section>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default React.memo(Banner)
