import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getLoyaltyLevelStart } from '../../../store/redux-slices/bonus'
import { getTenantDetailsStart } from '../../../store/redux-slices/login'
import { checkDelay, setDelay } from '../../../utils/apiDelayHelper'

const useLoyaltyBonus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { tenantDetails } = useSelector(state => state?.login)
  const { selectedLanguageCode } = useSelector(state => state?.language)
  const { languageData } = useSelector(state => state?.language)
  const { loyaltyLevel, loading } = useSelector(state => state?.bonus)
  const { bannerUrl } = useSelector(state => state?.casinoMenu?.gameCategory)
  const { loading: bannerLoading } = useSelector(state => state?.casinoMenu)

  const [showMoreLevels, setShowMoreLevels] = useState(false)

  if (document.getElementById('loyaltyBannerBtn')) {
    document.getElementById('loyaltyBannerBtn').onclick = function (e) {
      const href = document.getElementById('loyaltyBannerBtn')?.getAttribute('href')
      e?.preventDefault()
      navigate(href?.split(`${window?.location?.origin}`)?.[1])
    }
  }

  useEffect(() => {
    if (checkDelay('getLoyaltyLevelStart')) {
      dispatch(getLoyaltyLevelStart())
      setDelay('getLoyaltyLevelStart', 300000)
    }
    if (checkDelay('getTenantDetailsStart')) {
      dispatch(getTenantDetailsStart())
      setDelay('getTenantDetailsStart', 300000)
    }
  }, [])

  return {
    loading,
    loyaltyLevel,
    languageData,
    tenantDetails,
    bannerUrl,
    bannerLoading,
    selectedLanguageCode,
    showMoreLevels,
    setShowMoreLevels,
    navigate
  }
}

export default useLoyaltyBonus
