import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getUserDetailsStart,
    getUserDetailsSuccess,
    getUserDetailsFailure,
    userUpdateInfoStart,
    userUpdateInfoComplete,
    uploadProfileImageStart,
    uploadProfileImageComplete,
    removeProfileImageStart,
    removeProfileImageComplete,
    getDocumentsLabelStart,
    getDocumentsLabelSuccess,
    getDocumentsLabelFailure,
    getDocumentsStart,
    getDocumentsSuccess,
    getDocumentsFailure,
    updateDocumentsStart,
    updateDocumentsComplete,
    updateWalletBalance,
    updateDepositError,
    setDailyLimitSuccess,
    setLossLimitSuccess,
    setDepositLimitSuccess,
    setSessionTimeSuccess,
    getLimitTableStart,
    getLimitTableSuccess,
    getLimitTableFailure,
    getSumSubAccessTokenStart,
    getSumSubAccessTokenSuccess,
    getSumSubAccessTokenFailure,
    getAllReviewFailure,
    getAllReviewSuccess,
    getAllReviewStart,
    getCashbackBalanceFailure,
    getCashbackBalanceSuccess,
    getCashbackBalanceStart,
    getWalletAmountStart,
    getWalletAmountSuccess,
    getWalletAmountFailure,
    setCountryModal,
    setSidebarStatusTrue,
    setSidebarStatusFalse,
    setPeriodicData,
    submitOTPStart,
    submitOTPComplete,
    changePhoneNumberStart,
    changePhoneNumberComplete,
    resendPhoneOTPStart,
    resendPhoneOTPComplete,
    setIsUserBlock,
    setShowMaxBetModal,
    setShowRestrictedGameModal,
    setShowBannedGameModal,
    setMaxBetAffiliate
  },
  reducer
} = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    userLoading: false,
    userDetails: null,
    sumsubAccessToken: null,
    reviewList: null,
    cashbackBalance: 0,
    cashbackLoading: false,
    walletLoading: false,
    countryModal: true,
    isSidebarVisible: false,
    periodicData: {
      bonusBetAmount: null,
      bonusBinAmount: null,
      cashBetAmount: null,
      cashWinAmount: null
    },
    isUserBlock: false,
    showMaxBetModal: false,
    showRestrictedGameModal: false,
    showBannedGameModal: false,
    maxBetAffiliate: null
  },
  reducers: {
    getUserDetailsStart: (state) => ({
      ...state,
      loading: true,
      userLoading: true
    }),
    getUserDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userLoading: false,
      userDetails: payload
    }),
    getUserDetailsFailure: (state) => ({
      ...state,
      loading: false,
      userLoading: false
    }),
    userUpdateInfoStart: (state) => ({
      ...state,
      loading: true
    }),
    userUpdateInfoComplete: (state) => ({
      ...state,
      loading: false
    }),
    uploadProfileImageStart: (state) => ({
      ...state,
      loading: true
    }),
    uploadProfileImageComplete: (state) => ({
      ...state,
      loading: false
    }),
    removeProfileImageStart: (state) => ({
      ...state,
      loading: true
    }),
    removeProfileImageComplete: (state) => ({
      ...state,
      loading: false
    }),
    getDocumentsLabelStart: (state) => ({
      ...state,
      loading: true
    }),
    getDocumentsLabelSuccess: (state, { payload }) => ({
      ...state,
      labels: payload,
      loading: false
    }),
    getDocumentsLabelFailure: (state) => ({
      ...state,
      loading: false
    }),
    getDocumentsStart: (state) => ({
      ...state,
      loading: true
    }),
    getDocumentsSuccess: (state, { payload }) => ({
      ...state,
      userDocument: payload,
      loading: false
    }),
    getDocumentsFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateDocumentsStart: (state) => ({
      ...state,
      loading: true
    }),
    updateDocumentsComplete: (state) => ({
      ...state,
      loading: false
    }),
    setDailyLimitSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: { ...state?.userDetails, userLimit: payload }
    }),
    setLossLimitSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: { ...state?.userDetails, userLimit: payload }
    }),
    setDepositLimitSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: { ...state?.userDetails, userLimit: payload }
    }),
    setSessionTimeSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      userDetails: { ...state?.userDetails, userLimit: payload }
    }),
    getLimitTableStart: (state) => ({
      ...state
    }),
    getLimitTableSuccess: (state, { payload }) => ({
      ...state,
      userDetails: {
        ...state?.userDetails,
        userLimit: {
          ...state?.userDetails?.userLimit,
          ...(payload?.dailyBetLimit?.usedLimit !== null && { dailyBetUsedLimit: payload?.dailyBetLimit?.usedLimit }),
          ...(payload?.weeklyBetLimit?.usedLimit !== null && { weeklyBetUsedLimit: payload?.weeklyBetLimit?.usedLimit }),
          ...(payload?.monthlyBetLimit?.usedLimit !== null && { monthlyBetUsedLimit: payload?.monthlyBetLimit?.usedLimit }),
          ...(payload?.dailyLossLimit?.usedLimit !== null && { dailyLossUsedLimit: payload?.dailyLossLimit?.usedLimit }),
          ...(payload?.weeklyLossLimit?.usedLimit !== null && { weeklyLossUsedLimit: payload?.weeklyLossLimit?.usedLimit }),
          ...(payload?.monthlyLossLimit?.usedLimit !== null && { monthlyLossUsedLimit: payload?.monthlyLossLimit?.usedLimit }),
          ...(payload?.dailyDepositLimit?.usedLimit !== null && { dailyDepositUsedLimit: payload?.dailyDepositLimit?.usedLimit }),
          ...(payload?.weeklyDepositLimit?.usedLimit !== null && { weeklyDepositUsedLimit: payload?.weeklyDepositLimit?.usedLimit }),
          ...(payload?.monthlyDepositLimit?.usedLimit !== null && { monthlyDepositUsedLimit: payload?.monthlyDepositLimit?.usedLimit }),
          ...(payload?.realityCheck !== null && { realityCheck: payload?.realityCheck })
        }
      }
    }),
    getLimitTableFailure: (state) => ({
      ...state
    }),
    updateWalletBalance: (state, { payload }) => ({
      ...state,
      userDetails: {
        ...state.userDetails,
        ...(payload?.loyaltyExist && { loyaltyPoints: payload.loyaltyPoints }),
        ...(payload?.loyaltyExist && { level: payload.level }),
        userWallet: { ...state.userDetails?.userWallet, amount: payload.cash, totalAmount: payload.cash + payload.nonCash, nonCashAmount: payload.nonCash },
        inSufficientPopup: payload.inSufficientPopup
      }
    }),
    updateDepositError: (state, { payload }) => ({
      ...state,
      userDetails: {
        ...state.userDetails,
        depositError: payload?.depositError,
        errorMsg: payload?.errorMsg
      }
    }),
    getSumSubAccessTokenStart: (state) => ({
      ...state,
      loading: true
    }),
    getSumSubAccessTokenSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      sumsubAccessToken: payload
    }),
    getSumSubAccessTokenFailure: (state) => ({
      ...state,
      loading: false
    }),
    getAllReviewStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllReviewSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      reviewList: payload
    }),
    getAllReviewFailure: (state) => ({
      ...state,
      keysLoading: false
    }),
    getCashbackBalanceStart: (state) => ({
      ...state,
      cashbackLoading: true
    }),
    getCashbackBalanceSuccess: (state, { payload }) => ({
      ...state,
      cashbackLoading: false,
      cashbackBalance: payload
    }),
    getCashbackBalanceFailure: (state) => ({
      ...state,
      cashbackLoading: false
    }),
    getWalletAmountStart: (state) => ({
      ...state,
      walletLoading: true
    }),
    getWalletAmountSuccess: (state, { payload }) => ({
      ...state,
      walletLoading: false,
      userDetails: { ...state.userDetails, userWallet: { ...state.userDetails.userWallet, amount: payload } }
    }),
    getWalletAmountFailure: (state) => ({
      ...state,
      walletLoading: false
    }),
    setCountryModal: (state) => ({
      ...state,
      countryModal: false
    }),
    setSidebarStatusTrue: (state) => ({
      ...state,
      isSidebarVisible: true
    }),
    setSidebarStatusFalse: (state) => ({
      ...state,
      isSidebarVisible: false
    }),
    setPeriodicData: (state, { payload }) => ({
      ...state,
      periodicData: payload
    }),
    submitOTPStart: (state) => ({
      ...state,
      loading: true
    }),
    submitOTPComplete: (state) => ({
      ...state,
      loading: false
    }),
    changePhoneNumberStart: (state) => ({
      ...state,
      loading: true
    }),
    changePhoneNumberComplete: (state) => ({
      ...state,
      loading: false
    }),
    resendPhoneOTPStart: (state) => ({
      ...state,
      loading: true
    }),
    resendPhoneOTPComplete: (state) => ({
      ...state,
      loading: false
    }),
    setIsUserBlock: (state, { payload }) => ({
      ...state,
      isUserBlock: payload
    }),
    setShowMaxBetModal: (state, { payload }) => ({
      ...state,
      showMaxBetModal: payload
    }),
    setShowRestrictedGameModal: (state, { payload }) => ({
      ...state,
      showRestrictedGameModal: payload
    }),
    setShowBannedGameModal: (state, { payload }) => ({
      ...state,
      showBannedGameModal: payload
    }),
    setMaxBetAffiliate: (state, { payload }) => ({
      ...state,
      maxBetAffiliate: payload
    })
  }
})

export default reducer

export {
  getUserDetailsStart,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  userUpdateInfoStart,
  userUpdateInfoComplete,
  uploadProfileImageStart,
  uploadProfileImageComplete,
  removeProfileImageStart,
  removeProfileImageComplete,
  getDocumentsLabelStart,
  getDocumentsLabelSuccess,
  getDocumentsLabelFailure,
  getDocumentsStart,
  getDocumentsSuccess,
  getDocumentsFailure,
  updateDocumentsStart,
  updateDocumentsComplete,
  updateWalletBalance,
  updateDepositError,
  setDailyLimitSuccess,
  setLossLimitSuccess,
  setDepositLimitSuccess,
  setSessionTimeSuccess,
  getLimitTableStart,
  getLimitTableSuccess,
  getLimitTableFailure,
  getSumSubAccessTokenStart,
  getSumSubAccessTokenSuccess,
  getSumSubAccessTokenFailure,
  getAllReviewFailure,
  getAllReviewSuccess,
  getAllReviewStart,
  getCashbackBalanceFailure,
  getCashbackBalanceSuccess,
  getCashbackBalanceStart,
  getWalletAmountStart,
  getWalletAmountSuccess,
  getWalletAmountFailure,
  setCountryModal,
  setSidebarStatusTrue,
  setSidebarStatusFalse,
  setPeriodicData,
  submitOTPStart,
  submitOTPComplete,
  changePhoneNumberStart,
  changePhoneNumberComplete,
  resendPhoneOTPStart,
  resendPhoneOTPComplete,
  setIsUserBlock,
  setShowMaxBetModal,
  setShowRestrictedGameModal,
  setShowBannedGameModal,
  setMaxBetAffiliate
}
