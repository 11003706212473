import React, { useContext } from 'react'
import { UserDetailContext } from '../../../../pages/NewAccountInfoPage/useContext'
import { UserRegisterButton } from '../../../Buttons'

const UserInfoFooter = ({ formik }) => {
  const { errors, handleSubmit } = formik
  const { regFields, loading, setIsPassValid, setSelectedTab, selectedTab, languageData } = useContext(UserDetailContext)
  const handleClick = () => {
    handleSubmit()
    if (
      errors?.firstName ||
      errors?.lastName ||
      errors?.username ||
      (regFields?.phone && (!setIsPassValid || errors?.phone))
    ) {
      selectedTab !== 'private-data' &&
        setSelectedTab('private-data')
    } else if (
      errors?.countryCode ||
      errors?.currencyCode ||
      errors?.address ||
      errors?.city ||
      errors?.zipCode ||
      errors?.preferredLanguage
    ) {
      selectedTab !== 'address' && setSelectedTab('address')
    } else if (selectedTab === 'subscription') {
      setSelectedTab('private-data')
    }
  }

  return (
    <div>
      <UserRegisterButton
        loading={loading}
        spinner
        hidden={['private-data', 'address'].includes(selectedTab)}
        type='button'
        clickEvent={handleClick}
        text={languageData?.submit}
      />
    </div>
  )
}

export default UserInfoFooter
