import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLoyaltyDetailsStart } from '../../../store/redux-slices/bonus'

const useLoyalty = () => {
  const dispatch = useDispatch()
  const { selectedLanguageCode } = useSelector(state => state?.language)
  const { languageData } = useSelector(state => state.language)
  const { loyaltyDetails, loyaltyLoading } = useSelector(state => state.bonus)

  useEffect(() => {
    dispatch(getLoyaltyDetailsStart())
    window.scrollTo(0, 0)
  }, [])

  return {
    loading: loyaltyLoading,
    languageData,
    loyaltyDetails,
    selectedLanguageCode
  }
}

export default useLoyalty
