import { ErrorMessage, Form, Formik } from 'formik'
import React from 'react'
import { Col, Nav, OverlayTrigger, Row, Tab, Tooltip } from 'react-bootstrap'
import Icon from '../../components/Icon'

const SystemKYC = ({
  docs,
  update,
  list,
  userDocument,
  kycStatus,
  selectedTab,
  setSelectedTab,
  selectedTabDocURL,
  status,
  selectedTabData,
  setDocs,
  setLabelId,
  hideButton,
  languageData
}) => {
  return (
    <>
      <div className='user-account-block acc-verify-info accounts-btn'>
        <Formik
          enableReinitialize
          initialValues={{
            ...docs
          }}
          onSubmit={(formValues) => {
            function check () {
              for (const key in formValues) {
                if (formValues[key] !== null) {
                  return true
                }
              }
            }
            check() && update(formValues)
          }}
        >
          {({ handleSubmit }) => (
            <Form>
              <Tab.Container className='mb-3' id='left-tabs-example' defaultActiveKey={list?.length > 0 && list?.[0]?.label}>
                <Row className='align-items-center'>
                  <Col sm={12}>
                    <Nav variant='tabs' className='verification-menu'>
                      {list.map(({ label }, i) => {
                        const selectedDocument = userDocument && userDocument.rows.find(u => u.documentName === label)
                        return (
                          (!(kycStatus === 'APPROVED' && selectedDocument === undefined)) &&
                            <Nav.Item
                              key={`account-verification-nav ${i}`}
                              onClick={() => {
                                URL.revokeObjectURL(docs?.[selectedTab])
                                setSelectedTab(() => label)
                              }}
                            >
                              <Nav.Link eventKey={label} className='position-relative'>
                                <OverlayTrigger
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {label}
                                    </Tooltip>
                                  )}
                                  placement='top'
                                >
                                  <span className='kyc-status-wrapper'>
                                    <span className='kyc-status-icon'>
                                      {status[selectedDocument?.status] === 'APPROVED' && <i className='fa-solid fa-shield-check' style={{ color: '#2eb52e' }} />}
                                      {status[selectedDocument?.status] === 'REJECTED' && <i className='fa-solid fa-xmark-to-slot' style={{ color: '#dd0000' }} />}
                                      {(status[selectedDocument?.status] === 'PENDING' || status[selectedDocument?.status] === 'RE-REQUESTED') && <i className='fa-solid fa-timer' style={{ color: '#e78c0e' }} />}
                                    </span>
                                    <span>{label}</span>
                                  </span>
                                </OverlayTrigger>
                              </Nav.Link>
                            </Nav.Item>
                        )
                      })}
                    </Nav>
                  </Col>

                  <Col sm={12}>
                    <div className='d-flex align-items-center justify-content-center'>
                      <div className='document-img'>
                        {(() => {
                          if (docs?.[selectedTab] && docs?.[selectedTab] !== null) {
                            return (docs?.[selectedTab]
                              ? (
                                <>
                                  {docs?.[selectedTab]?.type?.match('pdf')
                                    ? <iframe
                                        src={URL?.createObjectURL(docs?.[selectedTab])}
                                        className='kyc-prev-img'
                                      />
                                    : <img src={URL?.createObjectURL(docs?.[selectedTab])} className='kyc-prev-img' alt='document-verify' />}
                                </>)
                              : <img src={`${process.env.REACT_APP_STATIC_IMAGE}/document-verify.png`} alt='document-verify' />
                            )
                          } else {
                            return (
                              <>
                                {selectedTabDocURL
                                  ? (
                                    <>
                                      {selectedTabDocURL?.match('.pdf')
                                        ? <iframe
                                            src={selectedTabDocURL}
                                            className='kyc-prev-img'
                                          />
                                        : <img
                                            src={selectedTabDocURL}
                                            className='kyc-prev-img'
                                            onClick={() => window.open(selectedTabDocURL)} alt='document-verify'
                                          />}
                                    </>)
                                  : <img src={`${process.env.REACT_APP_STATIC_IMAGE}/document-verify.png`} alt='document-verify' />}
                              </>
                            )
                          }
                        })()}
                      </div>
                    </div>

                    {selectedTab
                      ? (
                        <div className='approved-denied-text'>
                          {status[selectedTabData?.status] === 'APPROVED' &&
                            <p>
                              <span className='approved-text'>
                                {languageData?.approved}
                              </span>

                              <span>
                                <img src={`${process.env.REACT_APP_STATIC_IMAGE}/green-tick.svg`} alt='green-tick' />
                              </span>
                            </p>}
                          {status[selectedTabData?.status] === 'REJECTED' &&
                            <p>
                              <span className='denied-text'>
                                {languageData?.denied}
                              </span>

                              <span>
                                <img src={`${process.env.REACT_APP_STATIC_IMAGE}/denied-icon.svg`} alt='denied-icon' />
                              </span>
                            </p>}
                        </div>)
                      : (
                        <div className='approved-denied-text'>
                          {kycStatus === 'APPROVED' &&
                            <p>
                              <span className='approved-text'>
                                {languageData?.approved}
                              </span>

                              <span>
                                <img src={`${process.env.REACT_APP_STATIC_IMAGE}/green-tick.svg`} alt='green-tick' />
                              </span>
                            </p>}
                          {kycStatus === 'REJECTED' &&
                            <p>
                              <span className='denied-text'>
                                {languageData?.approved}
                              </span>

                              <span>
                                <img src={`${process.env.REACT_APP_STATIC_IMAGE}/denied-icon.svg`} alt='denied-icon' />
                              </span>
                            </p>}
                        </div>)}

                    <Tab.Content style={{ minHeight: '50px' }}>
                      {list.map(({ label, documentLabelId }, i) => {
                        const selectedDocument = userDocument && userDocument.rows.find(u => u.documentName === label)
                        return (
                          (!(kycStatus === 'APPROVED' && selectedDocument === undefined)) &&
                            <Tab.Pane eventKey={label} key={`account-verification-content ${i}`}>
                              <Row className='align-items-center flex-column'>

                                <Col className='d-flex justify-content-center'>
                                  <div>
                                    {(selectedDocument?.status === 2 || selectedDocument?.status === 4) &&
                                      <OverlayTrigger
                                        overlay={(props) => (
                                          <Tooltip {...props}>
                                            {selectedDocument.reason}
                                          </Tooltip>
                                        )}
                                        placement='top'
                                      >
                                        <div
                                          style={{ width: '230px', cursor: 'pointer', color: 'red', textAlign: 'center', marginTop: '10px' }}
                                          className='d-inline-block text-truncate'
                                        >
                                          {selectedDocument.reason}
                                        </div>
                                      </OverlayTrigger>}
                                  </div>
                                </Col>

                                <Col>
                                  <div className='d-flex justify-content-center'>
                                    <div>
                                      <Col className='d-flex justify-content-end p-0'>

                                        {((selectedDocument &&
                                                selectedDocument.status !== 1) ||
                                                (selectedDocument === undefined) || (selectedDocument.status === 4)) &&
                                                (
                                                  <div className='d-flex justify-content-end'>
                                                    <label
                                                      htmlFor={label}
                                                      style={{ margin: '0', minWidth: '0', cursor: 'pointer' }}
                                                      className='deposit-btn'
                                                    >
                                                      {languageData?.chooseFile}
                                                    </label>

                                                    <input
                                                      name={label}
                                                      type='file'
                                                      id={label}
                                                      style={{ display: 'none' }}
                                                      onClick={(e) => {
                                                        e.currentTarget.value = null
                                                        setDocs({})
                                                      }}
                                                      onChange={(event) => {
                                                        setDocs({
                                                          ...docs,
                                                          [label]: event.target.files[0]
                                                        })
                                                        setLabelId(documentLabelId)
                                                      }}
                                                    />
                                                  </div>
                                                )}
                                      </Col>
                                      <ErrorMessage
                                        component='div'
                                        name={label}
                                        className='text-danger d-flex justify-content-end align-items-center'
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} className='mt-2'>
                                  <div className='d-flex justify-content-center align-items-center'>
                                    {(((selectedDocument && (selectedDocument?.status === 2 ||
                                            selectedDocument?.status === 0 || selectedDocument?.status === 4) &&
                                            docs?.[label]) || (selectedDocument === undefined && docs?.[label] && docs?.[label] !== null)) &&
                                            (
                                              <>
                                                {docs?.[label]?.name}
                                                <button
                                                  type='button'
                                                  className='btn p-0 ml-1'
                                                  onClick={() => {
                                                    setDocs({ ...docs, [label]: null })
                                                  }}
                                                >
                                                  <Icon name='trash' />
                                                </button>
                                              </>
                                            ))}

                                  </div>
                                </Col>
                              </Row>
                            </Tab.Pane>
                        )
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>

              {kycStatus !== 'APPROVED' &&
                <div className='text-center'>
                  <button
                    type='submit'
                    hidden={hideButton}
                    className='mt-4 btn btn-success figma btn-sm submitButton save-button'
                    onClick={handleSubmit}
                  >
                    {languageData?.upload}
                  </button>
                </div>}
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default SystemKYC
