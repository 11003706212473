import { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getPaymentProvidersStart, getPaymentStatusStart, getUtorgCurrenciesStart, verifyPaymentStart } from '../../../store/redux-slices/payment'
import { clearUserIdAndSessionId, getUserIdAndSessionIdStart } from '../../../store/redux-slices/transactions'
import { decryptSocketData, encryptSocketData, getItem, getLoginTokenForSocket, removeItem, setItem } from '../../../utils/storageUtils'
import {
  availBonusStart,
  cancelBonusStart,
  getUserActiveBonusStart,
  getUserBonusStart
} from '../../../store/redux-slices/bonus'
import { setSocketClient } from '../../../store/redux-slices/login'
import { getDeviceType } from '../../../utils/getDeviceType'
import { killAllFrames } from '../../../utils/helpers'
import { paymentStatusSocket } from '../../../socket'

const useDeposit = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  const depositFormRef = useRef(null)
  const [data, setData] = useState({})
  const bonusListingRef = useRef(null)
  const depositIframeRef = useRef(null)
  const token = getItem('access-token')
  const [amt, setAmt] = useState('Other')
  const [type, setType] = useState('password')
  const [loadPIQ, setLoadPIQ] = useState(false)
  const loggedIn = JSON.parse(getItem('loggedIn'))
  const [initValues, setInitValues] = useState({})
  const [amtOptions, setAmtOptions] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [showIFrame, setShowIFrame] = useState(false)
  const [bonusChange, setBonusChange] = useState(false)
  const [selectedBonus, setSelectedBonus] = useState(state?.bonusDetail || null)
  const [showBonusNext, setShowBonusNext] = useState(true)
  const { userDetails } = useSelector(state => state.user)
  const [showBonusInfo, setShowBonusInfo] = useState(false)
  const [showSkipModel, setShowSkipModel] = useState(false)
  const [showDepositForm, setShowDepositForm] = useState(false)
  const depositStatus = window.location.href?.includes('status')
  const { languageData } = useSelector((state) => state.language)
  const [myDepositStatus, setMyDepositStatus] = useState(depositStatus)
  const { selectedLanguageCode } = useSelector(state => state.language)
  const [showPaymentProviders, setShowPaymentProviders] = useState(false)
  const [showBonusListing, setShowBonusListing] = useState(!depositStatus)
  const [status, setStatus] = useState(['PENDING', 'CLAIMING', 'IN-PROCESS'])
  const {
    activeBonusLoading,
    bonusDetail: bonusDetailInfo,
    depositUserBonus,
    bonusLoading,
    loading: cancelBonusLoading,
    userActiveBonus
  } = useSelector(state => state.bonus)
  const { sessionId } = useSelector(state => state.transactions.userPaymentCredential || '')
  const { paymentProviders, loading, paymentData, paymentStatus, merchantId, verifyPaymentLoading, utorgCurrencies } = useSelector((state) => state.payment)
  const activeInprocess = depositUserBonus?.rows?.filter(item => item.status === 'ACTIVE' || item.status === 'IN-PROCESS')?.[0]?.userBonusId
  const [showFreeSpinGameModal, setShowFreeSpinGameModal] = useState(false)
  const [identifier, setIdentifier] = useState('')
  const [freeSpinSelectedBonus, setFreeSpinSelectedBonus] = useState('')
  const [fetchUtorgCurrencies, setFetchUtorgCurrencies] = useState(false)

  const restrictDeposit = useMemo(() => {
    return Boolean(userActiveBonus?.rows
      ?.find((bonus) =>
        ['match', 'match_1'].includes(bonus.bonusType) && bonus.status === 'ACTIVE' && bonus?.bonus?.isSticky ))
  }, [userActiveBonus])

  useEffect(() => {
    showDepositForm && dispatch(getUserIdAndSessionIdStart({ type: 'deposit', navigate }))
  }, [showDepositForm])

  useEffect(() => {
    if (fetchUtorgCurrencies) {
      dispatch(getUtorgCurrenciesStart())
    }
  }, [fetchUtorgCurrencies])

  useEffect(() => {
    killAllFrames('payment')
    if (!depositStatus) {
      const script = document.createElement('script')
      script.src = process.env.REACT_APP_PAYMENTIQ_URL + merchantId
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
        setBonusChange(false)
      }
    } else {
      handleDepositStatus()
    }
    return () => dispatch(clearUserIdAndSessionId())
  }, [merchantId])

  useEffect(() => {
    showBonusListing && !loadPIQ && dispatch(getUserBonusStart({
      limit: '',
      pageNo: '',
      status: JSON.stringify(status),
      inDeposit: true
    }))
  }, [showBonusListing])

  useEffect(() => {
    dispatch(getUserActiveBonusStart({ pageNo: 1, status: JSON.stringify(['ACTIVE', 'IN-PROCESS']) }))
  }, [])

  /**
   * Called when the socket connects.
   * @param {Object} client - The connected socket client.
   */
  function handleConnect () {
    dispatch(setSocketClient(paymentStatusSocket))
    // Send a 'login' event to the server with encrypted data
    paymentStatusSocket.emit('login', encryptSocketData({ deviceType: getDeviceType() }))
  }

  /**
     * Handles updates to the wallet received from the server.
     * @param {Object} data - The data received from the server.
     */
  const handlePaymentStatus = (data) => {
    if (paymentStatusSocket.connected) {
      // Decrypt and process the received data
      const statusData = decryptSocketData(data)
      if (statusData && getItem('txId')) {
        // Remove transaction ID if it exists
        removeItem('txId')
      }
      if (statusData?.transactionId) {
        // Store the new transaction ID
        setItem('txId', statusData?.transactionId + '')
      }
      // Common logic to handle wallet updates
      if (!myDepositStatus && showIFrame) {
        handleDepositStatus()
      }
    }
  }

  useEffect(() => {
    if (loggedIn && !paymentStatusSocket?.connected && token) {
      paymentStatusSocket.auth.token = getLoginTokenForSocket()
      paymentStatusSocket.disconnect().connect()
    }
  }, [loggedIn, token])

  useEffect(() => {
    if (paymentStatusSocket?.connected) {
      paymentStatusSocket.on('connect', handleConnect)
      paymentStatusSocket.on('payment-status', handlePaymentStatus)
    }
    return () => {
      paymentStatusSocket.off('payment-status')
    }
  }, [paymentStatusSocket, showIFrame])

  useEffect(() => {
    if (!depositStatus && !bonusChange && !loading && !loadPIQ) {
      if (showModal) {
        setShowModal(false)
      }
      if (depositUserBonus?.count) {
        !showBonusListing && !myDepositStatus && setShowBonusListing(true)
        setShowPaymentProviders(true)
        const inProcess = depositUserBonus?.rows?.filter(({ userBonusId, status }) =>
          (userBonusId === selectedBonus?.userBonusId || ['IN-PROCESS'].includes(status)))?.[0]

        const claiming = depositUserBonus?.rows?.filter(({ userBonusId, status }) =>
          (userBonusId === selectedBonus?.userBonusId || ['CLAIMING'].includes(status)))?.[0]

        // if none of them is present then make bonus selected which user clicked on activate button
        if (state?.identifier) {
          if (state?.item || state?.bonusDetail) {
            const bonus = state?.item || state?.bonusDetail
            window.history.replaceState({}, document.title)
            setSelectedBonus(bonus)
            setIdentifier(state?.identifier)
          } else if ((inProcess || claiming)) {
            setSelectedBonus(inProcess || claiming)
          } else {
            setSelectedBonus(null)
          }
        }
      } else if (!bonusLoading) {
        setShowBonusListing(true)
        !loadPIQ && setShowPaymentProviders(true)
      }
    }
  }, [selectedBonus?.userBonusId, depositUserBonus, userDetails, state, depositStatus, bonusLoading, bonusChange, loadPIQ])

  const skipBonusHandler = () => {
    setShowDepositForm(false)
    if (!selectedBonus?.userBonusId) {
      setSelectedBonus(null)
      !showPaymentProviders && !loadPIQ && setShowPaymentProviders(true)
    } else {
      setShowBonusNext(true)
      setBonusChange(true)
      setSelectedBonus(null)
      setShowPaymentProviders(true)
    }
  }

  const skipBonusConfirmHandler = () => {
    setShowPaymentProviders(true)
    setShowBonusListing(false)
  }

  const bonusSelectionHandler = (item, id) => {
    if (!['ACTIVE'].includes(item?.status)) {
      // User can't select other bonus if active bonus is auto-selected
      if (!['ACTIVE'].includes(selectedBonus?.status)) {
        setBonusChange(true)
        setSelectedBonus(item)

        // If Bonus is Selected then avail that bonus

        const availBonusData = {
          userBonusId: parseInt(item?.userBonusId) || '',
          fromCustomerIo: false,
          inDeposit: true
        }
        if (item?.bonus?.other?.aggregatorId === '2' || item?.bonus?.other?.aggregatorId === 2) {
          availBonusData.hub88GameCode = identifier || id
        }
        if (item?.bonus?.other?.aggregatorId === '3' || item?.bonus?.other?.aggregatorId === 3) {
          availBonusData.iGamingDeckGameCode = identifier || id
        }

        if (item?.userBonusId) {
          dispatch(availBonusStart({
            data: availBonusData,
            navigate,
            deposit: true,
            bonusType: item?.bonusType
          }))
          !loadPIQ && setShowPaymentProviders(true)
          setBonusChange(true)
        } else {
          skipBonusHandler()
        }
        setShowBonusNext(false)
      } else {
        setShowModal(true)
      }
    }
    setShowPaymentProviders(true)
    setShowDepositForm(false)
    setData(null)
    setShowBonusNext(true)
    killAllFrames('payment')
    setLoadPIQ(false)
    setShowIFrame(false)
  }

  useEffect(() => {
    if (!bonusLoading && showPaymentProviders) {
      dispatch(getPaymentProvidersStart({ paymentType: 'deposit', bonusId: depositUserBonus?.count ? selectedBonus?.bonusId || '' : '' }))
    }
  }, [showPaymentProviders, bonusLoading, selectedBonus, depositUserBonus])

  useEffect(() => {
    if (myDepositStatus) {
      const agg = getItem('aggregator') || data?.aggregator || ''
      const txId = getItem('txId') || String(paymentData?.txId || paymentData?.id || '') || ''
      const orderId = getItem('orderId') || String(paymentData?.session?.order_id) || ''
      setShowBonusListing(false)
      if (getItem('sessionId') && agg && (txId || orderId)) {
        !loading && !paymentStatus && dispatch(getPaymentStatusStart({
          transactionId: txId,
          sessionId: getItem('sessionId'),
          aggregator: agg,
          orderId
        }))
      } else if (!paymentStatus && !loading) {
        navigate(`/${selectedLanguageCode?.toLowerCase() || getItem('language').toLowerCase() || 'en'}/account/deposit`)
      }
    }
  }, [myDepositStatus, loading])

  useEffect(() => {
    // For Pre-filling of Payment Form
    if (data?.settings?.requiredFields?.length) {
      const isUtorgSkrill = data.aggregator === 'utorg' && ['skrill', 'cv skrl'].includes(data.name.toLowerCase())
      const formValues = {}
      data?.settings?.requiredFields?.forEach(({ name }) => {
        if (['beneficiaryName', 'cardHolder', 'name'].includes(name)) {
          formValues[name] = userDetails?.firstName + ' ' + userDetails?.lastName
        } else if (['first_name', 'firstName'].includes(name)) {
          formValues[name] = userDetails?.firstName
        } else if (['last_name', 'lastName'].includes(name)) {
          formValues[name] = userDetails?.lastName
        } else if (['phoneNumber', 'phone', 'mobile'].includes(name)) {
          formValues[name] = userDetails?.phoneCode ? userDetails?.phoneCode + ' ' + userDetails?.phone : ''
        } else if (name === 'email' || name === 'destinationAccount') {
          if (!isUtorgSkrill) {
            formValues[name] = userDetails?.email
          }
        } else if (name === 'address') {
          formValues[name] = userDetails?.address
        } else if (['country'].includes(name)) {
          formValues[name] = userDetails?.countryCode
        } else {
          formValues[name] = ''
        }
      })
      setInitValues(formValues)
    }
  }, [data])

  const cancelDeposit = () => {
    // Show Providers List
    if (depositUserBonus?.count) {
      setShowBonusListing(true)
      setShowBonusNext(true)
      setShowPaymentProviders(false)
    } else {
      setShowBonusListing(false)
      setShowBonusNext(false)
      setShowPaymentProviders(true)
    }
    setShowDepositForm(false)
    killAllFrames('payment')
    setLoadPIQ(false)
    setShowIFrame(false)
    window?.scrollTo(0, (bonusListingRef?.current?.offsetTop + 10))
  }

  const handleDepositStatus = () => {
    // Show Payment Receipt
    setShowDepositForm(false)
    setShowPaymentProviders(false)
    setShowIFrame(false)
    setShowBonusListing(false)
    setMyDepositStatus(true)
  }

  const handleProviderClick = async (data) => {
    if (data?.settings?.requiredFields.some(item => item.name === 'currency') && data?.aggregator?.toLowerCase() === 'utorg') {
      setFetchUtorgCurrencies(true)
    }

    data?.group?.toLowerCase() === 'cryptocurrency' && data?.name?.toLowerCase()?.includes('invoice') && data?.aggregator?.toLowerCase() === 'coinspaid'
      ? setData({
        ...data,
        settings: {
          ...data?.settings,
          requiredFields: [{ name: 'amount', dataType: 'number' }]
        }
      })
      : data?.group === 'mifinity' && data?.name === 'mifinity'
        ? setData({
          ...data,
          settings: {
            ...data?.settings,
            requiredFields: data?.settings?.requiredFields?.[0]
          }
        })
        : setData(data)

    setShowDepositForm(true)
    killAllFrames('payment')
    setLoadPIQ(false)
    setShowIFrame(false)
    window?.scrollTo(0, (depositFormRef?.current?.offsetTop + 10))
    setAmtOptions(typeof data?.amountKeys === 'string' ? JSON.parse(data?.amountKeys) : data?.amountKeys)
  }

  const handleTrue = () => {
    // Show iframe
    setShowDepositForm(false)
    setShowPaymentProviders(false)
    setShowIFrame(true)
  }

  const handleRedirect = () => {
    // Show bonus
    setShowBonusListing(true)
    setShowDepositForm(false)
    setShowPaymentProviders(false)
    setShowIFrame(false)
  }

  const paymentFormLink = (window = false) => {
    const form = document.createElement('form')
    form.id = 'paymentForm'
    form.action = paymentData?.redirectOutput?.url || paymentData?.redirect_url
    form.method = paymentData?.redirectOutput?.method || 'GET'

    // Create a hidden input field and set its value
    for (const i in paymentData?.redirectOutput?.parameters) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = i
      input.value = paymentData?.redirectOutput?.parameters[i]

      // Append the input field to the form
      form.appendChild(input)
    }
    // Set the target of the form to the iframe
    if (!window) {
      form.target = 'myFrame'
      document.getElementById('myFrame').src = paymentData?.redirectOutput?.url || paymentData?.redirect_url
    }
    document.body.appendChild(form)
    form.submit()
    document.body.removeChild(form)
  }

  useEffect(() => {
    if (showIFrame && data?.settings?.redirection === 'cashier_iframe' && data?.aggregator === 'paymentiq') {
      setLoadPIQ(true)
    } else if (showIFrame && (paymentData?.redirectOutput?.url || paymentData?.redirect_url || paymentData?.checkout_url)) {
      if (paymentData?.checkout_url || paymentData?.redirectOutput?.container === 'window' || paymentData?.container === 'window') {
        const url = paymentData?.redirectOutput?.url || paymentData?.redirect_url || paymentData?.checkout_url

        // If POST method then open via form submit else direct open the url
        paymentData?.redirectOutput?.method?.toLowerCase() === 'post'
          ? paymentFormLink(data?.aggregatorId === 'paymentiq' ? ['redirect', 'window'].includes(paymentData?.redirectOutput?.container) : true)
          : openPopup(url)
      } else if (!['SUCCESSFUL'].includes(paymentData?.txState) && !['', undefined, null, 'get']?.includes(paymentData?.redirectOutput?.method?.toLowerCase())) {
        // To open in iframe
        paymentFormLink()
      }
    } else if (['WAITING_INPUT', 'SUCCESSFUL'].includes(paymentData?.txState) && !paymentData?.redirectOutput?.url) {
      handleDepositStatus()
    }
  }, [showIFrame, paymentData])

  const resetPay = () => {
    handleRedirect()
  }

  const openPopup = (url) => {
    window.location.href = url
  }

  const handleSubmit = (formData) => {
    const { amount, ...rest } = formData
    if (rest && rest?.encCreditcardNumber) {
      // Encryption for Credit Card
      rest.encCreditcardNumber = window.encryptData(String(rest.encCreditcardNumber?.replace(/ /g, '')))
      rest.encCvv = window.encryptData(String(rest.encCvv))
      rest.expiryMonth = rest.expirymmyyyy.substr(0, 2)
      rest.expiryYear = rest.expirymmyyyy.substr(3, 5)
      delete rest.expirymmyyyy
    }

    dispatch(verifyPaymentStart({
      data: {
        amount,
        aggregator: data.aggregator,
        paymentProviderId: data.paymentProviderId,
        provider: data?.name,
        group: data.group,
        paymentType: 'deposit',
        other: rest ? { ...rest } : {},
        confirm: false,
        gateway: data?.settings?.gateway || null,
        sessionId
      },
      resetPay,
      navigate,
      setShowBonusNext,
      setData,
      setAmt
    }))
    handleTrue()
    setType('password')
    setShowDepositForm(true)
    setShowPaymentProviders(true)
    window?.scrollTo(0, (depositIframeRef?.current?.offsetTop + 10))
  }

  const fetchLatestChanges = () => {
    if (depositUserBonus?.count) {
      dispatch(getUserBonusStart({
        limit: '',
        pageNo: '',
        status: JSON.stringify(status),
        inDeposit: true
      }))
      setShowBonusListing(true)
      setShowBonusNext(true)
      setShowPaymentProviders(false)
      setShowDepositForm(false)
    } else if (!depositUserBonus?.count) {
      dispatch(getPaymentProvidersStart({ paymentType: 'deposit', bonusId: selectedBonus?.bonusId || '' }))
    }
  }

  const forfeitHandler = () => {
    setShowModal(false)
    setSelectedBonus({})
    setBonusChange(false)
    if (state?.item || state?.bonusDetail) {
      window.history.replaceState({}, document.title)
    }
    dispatch(cancelBonusStart({ data: { userBonusId: activeInprocess }, deposit: true, status }))
  }

  const handleSelections = (bonusData) => {
    if (selectedBonus?.userBonusId === bonusData?.userBonusId) {
      skipBonusHandler()
    } else {
      if (bonusData.bonusType === 'freespins' && ['2', '3', 3].includes(bonusData?.bonus?.other?.aggregatorId)) {
        setFreeSpinSelectedBonus(bonusData)
        setShowFreeSpinGameModal(true)
      } else {
        bonusSelectionHandler(bonusData)
      }
    }
  }

  const onForfeitBonus = () => {
    dispatch(cancelBonusStart({
      data: {
        userBonusId: userActiveBonus?.rows[0]?.userBonusId,
        bonusStatus: userActiveBonus?.rows[0]?.status
      }
    }))
  }

  return {
    amt,
    type,
    data,
    setAmt,
    status,
    setType,
    loadPIQ,
    loading,
    navigate,
    sessionId,
    showModal,
    setStatus,
    initValues,
    setLoadPIQ,
    amtOptions,
    showIFrame,
    paymentData,
    userDetails,
    bonusLoading,
    handleSubmit,
    setShowModal,
    languageData,
    setShowIFrame,
    showBonusNext,
    showBonusInfo,
    selectedBonus,
    showSkipModel,
    paymentStatus,
    cancelDeposit,
    depositFormRef,
    handleRedirect,
    forfeitHandler,
    bonusListingRef,
    myDepositStatus,
    bonusDetailInfo,
    showDepositForm,
    setSelectedBonus,
    skipBonusHandler,
    showBonusListing,
    setShowBonusNext,
    setShowBonusInfo,
    setShowSkipModel,
    paymentProviders,
    depositIframeRef,
    setMyDepositStatus,
    fetchLatestChanges,
    // handleDepositAmount,
    handleProviderClick,
    setShowBonusListing,
    handleDepositStatus,
    verifyPaymentLoading,
    selectedLanguageCode,
    showPaymentProviders,
    bonusSelectionHandler,
    setShowPaymentProviders,
    skipBonusConfirmHandler,
    userBonus: depositUserBonus,
    currencyCode: userDetails?.currencyCode,
    showFreeSpinGameModal,
    setShowFreeSpinGameModal,
    identifier,
    setIdentifier,
    freeSpinSelectedBonus,
    setFreeSpinSelectedBonus,
    handleSelections,
    utorgCurrencies,
    onForfeitBonus,
    restrictDeposit,
    activeBonusLoading,
    cancelBonusLoading
  }
}

export default useDeposit
