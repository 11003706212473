import React from 'react'
import './styles/index.scss'
import { useSelector } from 'react-redux'

const NoDataAvailable = ({ errorMessage }) => {
  const { languageData } = useSelector((state) => state.language)

  return (
    <>
      <div className='not-found-box'>
        <i className='fa-solid fa-siren-on fa-shake' />
        <div className='text-box'>
          <h5 className='title-text'>{errorMessage || languageData?.noDataFound} !</h5>
        </div>
      </div>
    </>
  )
}

export default NoDataAvailable
