import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import Login from './pages/Login'
import Account from './pages/Account'
import store from './store'
import { Routes as AppRoutes } from './utils/routes'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.generated.scss'
import 'react-toastify/dist/ReactToastify.css'
import { setupInterceptors } from './utils/axios'
import Loader from './components/Loader'
import Promotions from './pages/Promotions'
import HomePage from './pages/HomePage'
import CasinoPage from './pages/CasinoPage'
import LoyaltyBonus from './pages/LoyaltyBonus'
import CashbackBonus from './pages/CashbackBonus'
// import { getItem } from './utils/storageUtils'
import { ScrollToTop } from './utils/helpers'
import BlockedCountry from './pages/BlockedCountry'
import Tournaments from './pages/Tournament'
// import TemporaryPause from './components/TemporaryPause'
// import TournamentIndex from './pages/Tournament/components/index'
import NewSignup from './pages/NewSignup'
// import Signup from './pages/Signup'
import PrivateRoute from './components/PrivateRoute'
import PreAppLoader from './components/PreAppLoader'

// const currentURL = window.location.host
// if (currentURL) {
//   scssConstants(currentURL)
// }

const Layout = React.lazy(() => import('./components/Layout'))
const PlayGame = React.lazy(() => import('./pages/PlayGame'))
const MobilePlayGame = React.lazy(() => import('./pages/PlayGame/MobileGameScreen'))
const DemoGame = React.lazy(() => import('./pages/PlayGame/demo'))
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'))
const NotFound = React.lazy(() => import('./pages/NotFound'))
const Forbidden = React.lazy(() => import('./pages/Forbidden'))
const SiteMaintenance = React.lazy(() => import('./pages/SiteMaintenance'))
const SiteMaintenanceStatic = React.lazy(() => import('./pages/SiteMaintenanceStatic'))
const VerifyEmail = React.lazy(() => import('./pages/VerifyEmail'))
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'))
const TenantInactive = React.lazy(() => import('./pages/TenantInactive'))
const PromotionsPage = React.lazy(() => import('./pages/Promotions/components/PromotionsPage'))
const Cms = React.lazy(() => import('./pages/Cms'))
const ResendEmail = React.lazy(() => import('./pages/ResendEmail'))
const SuccessReceipt = React.lazy(() => import('./components/Receipts/SuccessReceipt'))
const CookieModal = React.lazy(() => import('./components/CookieModal'))
const VIPPage = React.lazy(() => import('./pages/VIP'))

const root = ReactDOM.createRoot(document.getElementById('root'))

// const pathName = Object.keys(AppRoutes).flatMap((path) => AppRoutes[path].replace('/:languageCode/', '/'))

setupInterceptors()

// Checks if the Zendesk API key is available in the environment variables.
// If present, it calls the `hideRubico` function, which hides the Zendesk web widget.
if (process.env.REACT_APP_ZENDESK_KEY) {
  window.hideRubico()
}

if (process.env.REACT_APP_ON_MAINTENANCE === 'true') {
  root.render(
    <Provider store={store}>
      <Suspense fallback={<Loader top='40vh' />}>
        <SiteMaintenanceStatic />
      </Suspense>
    </Provider>)
} else {
  root.render(
    <Provider store={store}>
      <PreAppLoader>
        <ToastContainer
          theme='colored'
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <ScrollToTop />
          <CookieModal />
          <Suspense fallback={<Loader top='40vh' />}>
            <Routes>
              <Route path={AppRoutes.login} element={<Login />} />
              <Route path={AppRoutes.signup} element={<NewSignup />} />
              <Route path={AppRoutes.forgotPassword} element={<ForgotPassword />} />
              <Route path={AppRoutes.tenantInactive} element={<TenantInactive />} />
              <Route
                path={AppRoutes.notFound}
                element={
                  <NotFound />
                }
              />
              <Route
                path={AppRoutes.forbidden}
                element={
                  <Forbidden />
                }
              />
              <Route
                path={AppRoutes.blockedCountry}
                element={
                  <BlockedCountry />
                }
              />
              <Route
                path={AppRoutes.siteMaintenance}
                element={
                  <SiteMaintenance />
                }
              />
              <Route
                path={AppRoutes.siteMaintenanceStatic}
                element={
                  <SiteMaintenanceStatic />
                }
              />
              <Route path={AppRoutes.resetPassword} element={<ResetPassword />} />
              <Route path={AppRoutes.resendEmail} element={<ResendEmail />} />
              <Route
                path={AppRoutes.homepage}
                element={
                  <Layout>
                    <HomePage />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.casinoSubCategory}
                element={
                  <Layout>
                    <CasinoPage />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.promotionpage}
                element={
                  <Layout>
                    <Promotions />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.CashbackBonus}
                element={
                  <Layout>
                    <CashbackBonus />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.loyaltyBonusPage}
                element={
                  <Layout>
                    <LoyaltyBonus />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.promotionpageId}
                element={
                  <Layout>
                    <PromotionsPage />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.cms}
                element={
                  <Layout>
                    <Cms />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.account}
                element={
                  <Layout isPrivateRoute>
                    <Account />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.playGame}
                element={
                  <Layout isPrivateRoute>
                    <PlayGame />
                  </Layout>
                }
              />
              {/* New Route added for only mobile devices to maintain separate UI */}
              <Route
                path={AppRoutes.mobilePlayGame}
                element={
                  <PrivateRoute>
                    <MobilePlayGame />
                  </PrivateRoute>
                }
              />
              <Route
                path={AppRoutes.demoGame}
                element={
                  <Layout>
                    <DemoGame />
                  </Layout>
                }
              />
              <Route
                path={AppRoutes.emailTokenLink}
                element={
                  <VerifyEmail />
                }
              />

              <Route
                path={AppRoutes.paymentStatus}
                element={
                  <Layout isPrivateRoute>
                    <SuccessReceipt />
                  </Layout>
                }
              />

              <Route
                path={AppRoutes.paymentSuccess}
                element={
                  <Layout isPrivateRoute>
                    <Account />
                  </Layout>
                }
              />

              <Route
                path={AppRoutes.userpromotionpageId}
                element={
                  <Layout isPrivateRoute>
                    <PromotionsPage />
                  </Layout>
                }
              />

              {/* <Route
              path='*'
              element={
                <Navigate
                  to={pathName?.includes(window?.location?.pathname) && `/${getItem('language')?.toLowerCase() || 'en'}${window?.location?.pathname}`}
                  replace
                />
              }
            /> */}

              <Route
                path={AppRoutes.tournaments} element={
                  <Layout>
                    <Tournaments />
                  </Layout>
              }
              />

              {/* <Route
              path={AppRoutes.tournamentDetails} element={
                <Layout>
                  <TournamentIndex />
                </Layout>
              }
            /> */}

              <Route
                path={AppRoutes.VIP} element={
                  <Layout>
                    <VIPPage />
                  </Layout>
              }
              />

              <Route
                path={AppRoutes.home}
                element={
                  <Layout>
                    <HomePage />
                  </Layout>
                }
              />

            </Routes>
          </Suspense>
        </Router>
      </PreAppLoader>
    </Provider>)
}
