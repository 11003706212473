import { takeLatest, put, select } from 'redux-saga/effects'
import { getCmsPage } from '../../utils/apiCalls'
import { getCmsPageFailure, getCmsPageStart, getCmsPageSuccess } from '../redux-slices/cms'
import { showFooter } from '../redux-slices/loader'

export default function * cmsWatcher () {
  yield takeLatest(getCmsPageStart, getCmsPageWorker)
}

const getCmsList = state => state?.casinoMenu?.gameCategory

function * getCmsPageWorker (action) {
  try {
    const { cmsList } = yield select(getCmsList)
    const { cmsPageId, slug } = action && action.payload
    let cmsId = null
    if (!cmsPageId) {
      cmsId = cmsList?.rows?.find((item) => (slug === item?.slug))
    }
    const res = yield getCmsPage({ cmsPageId: (cmsPageId || cmsId?.cmsPageId) })

    yield put(getCmsPageSuccess(res?.data?.data?.cmsDetails))

    yield put(showFooter(true))
  } catch (e) {
    yield put(getCmsPageFailure())

    yield put(showFooter(true))
  }
}
