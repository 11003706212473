import React, { useContext } from 'react'
import HtmlParser from 'react-html-parser'
import { SignUpContext } from '../../../pages/NewSignup/useContext'

const CheckboxWithLabel = ({ checked, onChange, label, required = false }) => {
  const onChangeCheckbox = () => {
    onChange(!checked)
  }

  return (
    <div className='d-flex my-4 align-items-center'>
      <span
        className={`${checked ? 'tick-mark' : 'circle-check'}`}
        onClick={onChangeCheckbox}
      />
      <p>
        {label}
        {required && <span className='text-danger h4'>*</span>}
      </p>
    </div>
  )
}

const TermAndCondition = ({ formik, acceptConditions, setAcceptConditions }) => {
  const { languageData, regFields } = useContext(SignUpContext)
  const { setFieldValue } = formik
  // const handleAcceptAll = (value) => {
  //   setAcceptConditions((prev) => {
  //     const updatedState = {
  //       ...prev,
  //       privacyPolicy: value,
  //       termsAndConditions: value
  //     }

  //     if (regFields?.newsletterAndSms) {
  //       updatedState.newsletterAndSms = value
  //       setFieldValue('newsletterAndSms', value)
  //     } else {
  //       updatedState.newsLetter = value
  //       updatedState.sms = value
  //       setFieldValue('newsLetter', value)
  //       setFieldValue('sms', value)
  //     }

  //     return updatedState
  //   })
  // }

  return (
    <div className='terms-n-condition'>
      {/* <CheckboxWithLabel
        checked={
          // acceptConditions.privacyPolicy &&
          acceptConditions.termsAndConditions &&
          (acceptConditions.newsletterAndSms || regFields?.newsletterAndSms === 0) &&
          (acceptConditions.newsLetter || regFields?.newsLetter === 0) &&
          (acceptConditions.sms || regFields?.sms === 0)
        }
        onChange={handleAcceptAll}
        label={languageData?.acceptAll}
      /> */}

      {/* <CheckboxWithLabel
        checked={acceptConditions.privacyPolicy}
        onChange={() =>
          setAcceptConditions((prev) => ({
            ...prev,
            privacyPolicy: !prev.privacyPolicy
          }))}
        label={
          languageData?.signupPrivacyPolicy &&
          HtmlParser(languageData?.signupPrivacyPolicy)
        }
        required
      /> */}

      <CheckboxWithLabel
        checked={acceptConditions.termsAndConditions}
        onChange={() =>
          setAcceptConditions((prev) => ({
            ...prev,
            termsAndConditions: !prev.termsAndConditions
          }))}
        label={
          languageData?.signupTermAndConditions &&
          HtmlParser(languageData?.signupTermAndConditions)
        }
        required
      />

      {Boolean(regFields?.newsletterAndSms) && (
        <CheckboxWithLabel
          checked={acceptConditions.newsletterAndSms}
          onChange={() => {
            setFieldValue('newsletterAndSms', !acceptConditions.newsLetter)
            setAcceptConditions((prev) => ({
              ...prev,
              newsletterAndSms: !prev.newsletterAndSms
            }))
          }}
          label={
            languageData?.signupNewsLetterAndSms &&
            HtmlParser(languageData?.signupNewsLetterAndSms)
          }
          required={regFields?.newsletterAndSms === 2}
        />
      )}

      {Boolean(!regFields?.newsletterAndSms) && (
        <>
          {!!regFields?.newsLetter && (
            <CheckboxWithLabel
              checked={acceptConditions.newsLetter}
              onChange={() => {
                setFieldValue('newsLetter', !acceptConditions.newsLetter)
                setAcceptConditions((prev) => ({
                  ...prev,
                  newsLetter: !prev.newsLetter
                }))
              }}
              label={
                languageData?.signupNewsLetter &&
                HtmlParser(languageData?.signupNewsLetter)
              }
              required={regFields?.newsLetter === 2}
            />
          )}

          {!!regFields?.sms && (
            <CheckboxWithLabel
              checked={acceptConditions.sms}
              onChange={() => {
                setFieldValue('sms', !acceptConditions.sms)
                setAcceptConditions((prev) => ({
                  ...prev,
                  sms: !prev.sms
                }))
              }}
              label={languageData?.signupSms && HtmlParser(languageData?.signupSms)}
              required={regFields?.sms === 2}
            />
          )}
        </>
      )}
    </div>
  )
}
export default TermAndCondition
