import React, { useId } from 'react'
import { Tab, Nav } from 'react-bootstrap'

import Icon from '../../components/Icon'
import Deposit from '../Deposit'
import Withdraw from '../Withdraw'
import BetHistory from '../BetHistory'
import './styles/index.scss'
import useAccountsPage from './hooks/useAccountsPage'
import AccountVerification from '../AccountVerification'
import TransactionHistory from '../TransactionHistory'
import Loyalty from '../Loyalty'
import Limits from '../Limits'
import NewAccountInfoPage from '../NewAccountInfoPage'
import Dashboard from '../Dashboard'
import WithdrawList from '../Withdraw/WithdrawList'
import RedirectToHome from '../../components/RedirectToHome'
import BonusList from '../Bonus/components/BonusList'

const Account = ({ selectedFilter, setSelectedFilter }) => {
  const {
    navTabs,
    key,
    bonusDetail,
    languageData,
    selectedLanguageCode,
    navigate,
    setKey,
    logout,
    section
  } = useAccountsPage()

  const id = useId()

  return (
    <section className='wrapper-content'>
      <section className='account-section container-fluid'>
        <div className='wrapper'>
          <Tab.Container
            id='account-tabs' activeKey={key} className='mb-3' mountOnEnter unmountOnExit
            onSelect={(k) => {
              navigate(`/${selectedLanguageCode?.toLowerCase()}/account/${k}`)
              setKey(k)
              window.scrollTo(0, 0)
            }}
          >
            <div className='account-tabs'>
              <div className='account-menu-option'>
                <ul className='nav nav-pills' id='pills-tab' role='tablist'>
                  {navTabs?.map((n, index) => (
                    <li className='nav-item' role='presentation' key={index}>
                      <Nav.Link eventKey={n.key} className={`nav-link ${key === n?.key ? 'active' : ''}`}>
                        <Icon name={n?.icon} /> {n?.title}
                      </Nav.Link>
                    </li>
                  ))}

                  <li className='nav-item' role='presentation' onClick={() => logout()}>
                    <Nav.Link className='nav-link' id='pills-logout-tab' data-toggle='pill' href='#logout-tab' role='tab' aria-controls='logout-tab' aria-selected='false'>
                      <Icon name='logout' />
                      {languageData?.headerLogout}
                    </Nav.Link>
                  </li>
                </ul>
              </div>

              <div className='account-detail-section'>
                <div className='tab-content tab-detail' id='pills-tabContent'>
                  <Tab.Content>
                    <Tab.Pane eventKey='dashboard'>
                      {key === 'dashboard' && <Dashboard selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />}
                    </Tab.Pane>

                    <Tab.Pane eventKey='pending-withdrawals'>
                      <WithdrawList />
                    </Tab.Pane>

                    <Tab.Pane eventKey='account-info'>
                      {(section === 'account-info' || (section === 'account-info' && key === 'account-info')) && <NewAccountInfoPage />}
                    </Tab.Pane>

                    <Tab.Pane eventKey='account-verify'>
                      <AccountVerification key={id} />
                    </Tab.Pane>

                    <Tab.Pane eventKey='loyalty'>
                      <Loyalty />
                    </Tab.Pane>

                    <Tab.Pane eventKey='limits'>
                      <Limits />
                    </Tab.Pane>

                    <Tab.Pane eventKey='deposit' className='deposit-withdraw-section'>
                      {(section === 'deposit' || (section === 'account-info' && key === 'deposit')) && <Deposit bonusDetail={bonusDetail} key={Math.random()} />}
                    </Tab.Pane>

                    <Tab.Pane eventKey='withdraw' className='deposit-withdraw-section'>
                      {(section === 'withdraw' || (section === 'account-info' && key === 'withdraw')) && <Withdraw key={Math.random()} />}
                    </Tab.Pane>

                    <Tab.Pane eventKey='transaction'>
                      <TransactionHistory />
                      <BetHistory />
                      <BonusList />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
                <RedirectToHome />
              </div>
            </div>
          </Tab.Container>
        </div>
      </section>
    </section>
  )
}

export default React.memo(Account)
