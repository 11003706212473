import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import GameCard from '../../Lobby/GameCard'
import { dashboardContext } from '../../../context'
import DashboardSectionHeading from '../Common/DashboardSectionHeading'
import Loader from '../../Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { deviceType } from '../Constant'

const AvailableGames = () => {
  // Retrieve bonusDetail from Redux store
  const { bonusDetail } = useSelector((state) => state.bonus)

  // Retrieve dashboard context values
  const {
    selectedLanguageCode,
    showAllGames,
    setShowAllGames,
    toggleFavorite,
    favoriteGamesList,
    languageData
  } = useContext(dashboardContext)

  const settings = {
    initialSlide: 0,
    slidesToShow: 1.3,
    slidesToScroll: 1,
    speed: 500,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 2920,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1.3,
          slidesToScroll: 1
        }
      }
    ]
  }

  // Render individual game cards
  const renderGameCards = () => (
    bonusDetail?.games.map((game, index) => (
      <GameCard
        key={index}
        game={game}
        index={index}
        toggleFavorite={toggleFavorite}
        selectedLanguageCode={selectedLanguageCode}
        lobby={false}
        favoriteGamesList={favoriteGamesList}
      />
    ))
  )

  // Render all games in an InfiniteScroll component
  const renderAllGames = () => (
    <InfiniteScroll
      dataLength={bonusDetail?.games.length}
      scrollThreshold={0.8}
      height={438}
      scrollableTarget='your-bonuses-infinit-scroll'
      loader={<Loader top='6%' />}
    >
      <div className='all-available-game-list'>{renderGameCards()}</div>
    </InfiniteScroll>
  )

  // Render games in a slider component
  const renderGameSlider = () => (
    <div className='game-slider-container'>
      <Slider {...settings}>{renderGameCards()}</Slider>
    </div>
  )

  // Render the "Show All" button
  const renderShowAllButton = () => (
    <button className='bonus-btn' onClick={() => setShowAllGames(true)}>
      {languageData?.sellAll}
    </button>
  )

  // Render the "Show All" button for mobile devices
  const renderShowAllButtonMobile = () => (
    <div className='d-flex justify-content-center align-item-center'>
      {renderShowAllButton()}
    </div>
  )

  return (
    <div className='available-game-section'>
      <div className='top-section'>
        {/* Render heading for the available games section */}
        <DashboardSectionHeading headingText={languageData?.availableGames} />

        {/* Render "Show All" button if conditions are met */}
        {!showAllGames && deviceType !== 'mobile' && bonusDetail?.games.length > 10 && renderShowAllButton()}
      </div>

      {/* Render all games or game slider based on showAllGames state */}
      {bonusDetail?.games?.length > 0 && showAllGames ? renderAllGames() : renderGameSlider()}

      {/* Render "Show All" button for mobile devices if conditions are met */}
      {!showAllGames && deviceType === 'mobile' && bonusDetail?.games.length > 5 && renderShowAllButtonMobile()}
    </div>
  )
}

export default AvailableGames
