import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFavGameStart, toggleFavoriteStart, getFilteredGamesStart, getGameSubCategoryStart, getCategoryProviderListStart, getRecentGamesStart, getProvidersByOrderStart } from '../../../store/redux-slices/casinoMenu'
import { getItem } from '../../../utils/storageUtils'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from '../../Toast'
import { showFooter } from '../../../store/redux-slices/loader'
import { getThemesStart } from '../../../store/redux-slices/login'

const useFilterGames = ({ category = false, selectedFilter, setSelectedFilter }) => {
  const { state } = useLocation()
  const casinoCategoryId = state?.from
  const navTabSubCategory = useRef()
  const { casinoCategory } = useParams()
  const { casinoSubCategory } = useParams()

  const { gameCategory, gameSubCategory, providersByOrder, filteredGames, filteredLoading, rowsFetchedCount, games, favoriteGames, favoriteGamesList, recentGames } = useSelector((state) => state.casinoMenu)
  const loggedIn = JSON.parse(getItem('loggedIn'))
  const [page, setPage] = useState(1)
  const limit = 20
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { selectedLanguageCode, languageData } = useSelector((state) => state.language)
  const { showLeagueList } = useSelector(state => state?.loader)
  const { themes } = useSelector((state) => state.login)
  const { userDetails } = useSelector((state) => state.user)
  const [selectedMenu, setSelectedMenu] = useState(null)
  const [loadAllData, setLoadAllData] = useState(false)

  useEffect(() => {
    if (loggedIn) {
      dispatch(
        getFavGameStart({
          page: 1,
          limit: 100
        })
      )
    }
  }, [])

  // For Sub-Category change from url
  useEffect(() => {
    if (!window.location.pathname.includes('promotion')) {
      navTabSubCategory?.current?.parentElement?.offsetTop &&
        window?.scrollTo({
          top: ((navTabSubCategory?.current?.parentElement?.offsetTop / 100) - 1.9) * 100
        })
      if (!gameCategory?.casinoCategories?.rows?.some(cat => cat?.name?.EN?.toLowerCase() === casinoCategory)) {
        navigate('/')
      } else {
        const casinoMenuId = gameSubCategory?.rows?.find(({ name }) => name?.EN?.toLowerCase() === casinoSubCategory)?.tenantGameSubCategoryId
        casinoMenuId && changeMenuTab(casinoMenuId)
      }
      setLoadAllData(false)
    }
  }, [casinoSubCategory, state?.reload])

  useEffect(() => {
    if (gameSubCategory && Object.keys(gameSubCategory).length === 0) {
      dispatch(getGameSubCategoryStart({ categoryId: gameCategory?.casinoCategories?.rows?.[0]?.tenantGameCategoryId }))
    }
  }, [gameSubCategory])

  // GET THEMES AND PROVIDERS
  useEffect(() => {
    themes?.length === 0 && !category && dispatch(getThemesStart())
    if (providersByOrder?.length === 0) {
      dispatch(getProvidersByOrderStart({
        orderBy: 'name'
      }))
    }
  }, [])

  useEffect(() => {
    if (showLeagueList) {
      dispatch(showFooter(true))
    }
  }, [showLeagueList])

  useEffect(() => {
    if (selectedMenu && typeof selectedMenu === 'number') {
      dispatch(getCategoryProviderListStart({ category: selectedMenu }))
    }
  }, [])

  // API call for Games
  const getFilterParams = (selectedFilter, myPageNo, fetchMore) => ({
    limit: selectedFilter?.limit,
    pageNo: myPageNo,
    category: JSON.stringify(selectedFilter?.category),
    search: selectedFilter?.search,
    rating: JSON.stringify(selectedFilter?.rating),
    themes: JSON.stringify(selectedFilter?.themes),
    provider: JSON.stringify(selectedFilter?.provider.map(provider => window.encodeURIComponent(provider))),
    fetchMore,
    gameInputSearch: false
  })

  const shouldFetchGames = (selectedMenu, selectedFilter) => {
    if (selectedMenu === 0 && selectedFilter.search) {
      return true
    }
    return (
      selectedFilter?.category?.length ||
      selectedFilter?.rating?.length ||
      selectedFilter?.themes?.length ||
      selectedFilter?.provider?.length
    )
  }

  const fetchGames = ({ myPageNo = 1, fetchMore = false }) => {
    if (shouldFetchGames(selectedMenu, selectedFilter)) {
      dispatch(getFilteredGamesStart(getFilterParams(selectedFilter, myPageNo, fetchMore)))
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (selectedFilter?.search?.length > 0) {
        dispatch(getFilteredGamesStart(getFilterParams(selectedFilter, 1, false)))
      }
    }, 1000)

    return () => {
      clearTimeout(delayDebounceFn)
    }
  }, [selectedFilter?.search])

  useEffect(() => {
    fetchGames({ myPageNo: 1, fetchMore: false })
  }, [selectedFilter?.category,
    selectedFilter?.rating,
    selectedFilter?.themes,
    selectedFilter?.provider])

  const changeMenuTab = (casinoMenuId) => {
    if (casinoMenuId && casinoMenuId !== selectedMenu) {
      setSelectedMenu(casinoMenuId)
      setSelectedFilter(selectedFilter => ({ ...selectedFilter, pageNo: 1, category: [casinoMenuId] }))
    }
  }

  useEffect(() => {
    if (category && userDetails) {
      dispatch(getRecentGamesStart({ limit: 10 }))
    }
  }, [category, userDetails])

  const fetchMoreGames = () => {
    fetchGames({ myPageNo: selectedFilter?.pageNo + 1, fetchMore: true })
    setSelectedFilter(selectedFilter => ({ ...selectedFilter, pageNo: selectedFilter?.pageNo + 1 }))
  }

  const toggleFavorite = ({ isFavorite, tenantGameSubCategoryId, categoryGameId }) => {
    if (loggedIn) {
      dispatch(toggleFavoriteStart({
        isFavorite,
        tenantGameSubCategoryId,
        categoryGameId,
        categoryId: (casinoCategoryId || gameCategory?.casinoCategories?.rows?.[0]?.tenantGameCategoryId),
        searchGamesFilter: true
      }))
    } else {
      toast(languageData?.loginFirst, 'error')
    }
  }

  return {
    page,
    limit,
    games,
    themes,
    setPage,
    navigate,
    loggedIn,
    dispatch,
    providers: providersByOrder,
    recentGames,
    loadAllData,
    userDetails,
    gameCategory,
    selectedMenu,
    languageData,
    favoriteGames,
    changeMenuTab,
    filteredGames,
    fetchMoreGames,
    toggleFavorite,
    casinoCategory,
    setLoadAllData,
    setSelectedMenu,
    gameSubCategory,
    rowsFetchedCount,
    navTabSubCategory,
    favoriteGamesList,
    selectedLanguageCode,
    loading: filteredLoading
  }
}

export default useFilterGames
