export const errorMessages = {
  betHistory: 'noBetHistory',
  promotionPage: 'noPromotions',
  bonusPage: 'noBonuses',
  bonusPageHistory: 'noHistory',
  bonusPageYourBonuses: 'noBonuses',
  withdrawalHistory: 'noWithdrawalHistory',
  favoriteGames: 'noFavouriteGames',
  cms: 'noCMS'
}
