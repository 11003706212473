export const countryFlag = [
  { languageCode: 'RU', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg' },
  { languageCode: 'PS', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg' },
  { languageCode: 'AR', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg' },
  { languageCode: 'EN', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-EN.svg` },
  { languageCode: 'PT', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg' },
  { languageCode: 'DE', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-DE.svg` },
  { languageCode: 'DU', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg' },
  { languageCode: 'FR', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-FRCA.svg` },
  { languageCode: 'ES', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-ES.png` },
  { languageCode: 'SR', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg' },
  { languageCode: 'BS', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg' },
  { languageCode: 'BG', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg' },
  { languageCode: 'CN', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg' },
  { languageCode: 'JA', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg' },
  { languageCode: 'MS', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg' },
  { languageCode: 'GR', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg' },
  { languageCode: 'TR', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-TR.svg` },
  { languageCode: 'CZ', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg' },
  { languageCode: 'SK', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg' },
  { languageCode: 'DA', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg' },
  { languageCode: 'HI', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg' },
  { languageCode: 'FI', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-FI.svg` },
  { languageCode: 'SW', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-SE.svg` },
  { languageCode: 'HU', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg' },
  { languageCode: 'ID', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg' },
  { languageCode: 'FA', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg' },
  { languageCode: 'IT', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-IT.svg` },
  { languageCode: 'KO', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg' },
  { languageCode: 'MT', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg' },
  { languageCode: 'MU', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg' },
  { languageCode: 'RO', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg' },
  { languageCode: 'TH', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg' },
  { languageCode: 'VI', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg' },
  { languageCode: 'PL', flag: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg' },
  { languageCode: 'NO', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-NO.svg` },
  { languageCode: 'AU', flag: `${process.env.REACT_APP_STATIC_IMAGE}/flag-AU.svg` }
]
