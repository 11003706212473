export const transactionType = [
  { label: 'all', value: 'all' },
  { label: 'win', value: 'win' },
  { label: 'bet', value: 'bet' },
  { label: 'rollback', value: 'rollback' },
  { label: 'rollbackbeforebetwin', value: 'rollbackbeforebetwin' },
  { label: 'freeSpins', value: 'freespins' }
]

export const internalTransactionType = [
  { label: 'betInternal', value: 'betInternal' },
  { label: 'winInternal', value: 'winInternal' }
]
