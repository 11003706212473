import { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import snsWebSdk from '@sumsub/websdk'
import { getDocumentsLabelStart, getDocumentsStart, getSumSubAccessTokenStart, getUserDetailsStart, updateDocumentsStart } from '../../../store/redux-slices/user'
import { sumsubCountry, sumsubLanguageCode } from '../../../constants/sumsubCountryCode'
import { getAllCountriesStart } from '../../../store/redux-slices/login'
import { getItem } from '../../../utils/storageUtils'
import { useNavigate } from 'react-router-dom'
// import { debounce } from 'lodash'

const useAccountVerification = () => {
  const { userDetails, loading, userLoading, sumsubAccessToken } = useSelector(state => state.user || '')
  const { userDocument } = useSelector(state => state.user)
  const { allCountries } = useSelector(state => state.login)
  const { selectedLanguageCode } = useSelector(state => state.language)
  const labels = useSelector(state => state.user?.labels?.data?.documentLabel)
  const { languageData } = useSelector((state) => state.language)
  const [hideButton, setHideButton] = useState(false)
  const [selectedTab, setSelectedTab] = useState('')
  const [labelId, setLabelId] = useState('')
  const [docs, setDocs] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const documents = {}
  const list = []
  const countryCode = allCountries ? sumsubCountry[allCountries.rows.find(({ code }) => userDetails?.countryCode === code)?.name] : ''
  const status = ['PENDING', 'APPROVED', 'REJECTED', '', 'RE-REQUESTED']
  // PENDING: 0,
  // APPROVED: 1,
  // REJECTED: 2,
  // CANCELLED: 3,
  // REREQUESTED: 4
  useEffect(() => {
    if ((userDetails?.kycMethod === 1 || (!userDetails?.kycMethod && userDetails?.countryKycMethod === 1))) {
      dispatch(getAllCountriesStart())
      dispatch(dispatch(getSumSubAccessTokenStart()))
      const script = document.createElement('script')
      script.src = 'https://static.sumsub.com/idensic/static/sns-websdk-builder.js'
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  // const updateCountryModal = () => {
  //   if ([0, 1, '0', '1'].includes(userDetails?.kycMethod) || userDetails?.claimedBonus) {
  //     countryChangeModal && setCountryChangeModal(false)
  //     dispatch(setCountryModal(false))
  //   } else !countryChangeModal && setCountryChangeModal(true)
  // }

  // useEffect(() => {
  //   duplicateHandler()
  // }, [userDetails])

  // const duplicateHandler = useCallback(debounce(updateCountryModal, 1000), [])

  useEffect(() => {
    sumsubAccessToken &&
    launchWebSdk({
      sumsubAccessToken,
      country: countryCode,
      email: userDetails?.email,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      phone: userDetails?.phoneCode ? userDetails?.phoneCode + userDetails?.phone : '',
      language: getItem('language')?.toUpperCase() || 'EN'
    })
  }, [sumsubAccessToken])

  function launchWebSdk ({ sumsubAccessToken: accessToken, country, email, firstName, lastName, phone, language }) {
    const snsWebSdkInstance = snsWebSdk.init(
      accessToken,
      // token update callback, must return Promise
      // Access token expired
      // get a new one and pass it to the callback to re-initiate the WebSDK
      () => getNewAccessToken()
    )
      .withConf({
        lang: sumsubLanguageCode[language] || 'en', // language of WebSDK texts and comments (ISO 639-1 format)
        email,
        country,
        fixedInfo: {
          firstName,
          lastName,
          phone
        }
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
    // see below what kind of messages WebSDK generates
      .on('idCheck.stepCompleted', (payload) => {
        // console.log('stepCompleted', payload)
      })
      .on('idCheck.onError', (error) => {
        console.log('onError', error)
      })
      .build()

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk')
  }

  async function getNewAccessToken () {
    await dispatch(getSumSubAccessTokenStart())
    return Promise.resolve(sumsubAccessToken)
  }

  labels && labels.map(({ name, documentLabelId }) => {
    list.push({ label: (name?.[selectedLanguageCode] || name?.EN), documentLabelId })
    return null
  })
  for (const item in list) documents[list[item].label] = null

  userDocument && userDocument.rows.map(({ documentName, status }) => {
    if (status !== 2 && status !== 0 && status !== 4) delete documents[documentName]
    return null
  })

  useEffect(() => {
    userDocument && docs && documents &&
            Object.keys(docs).length !== Object.keys(documents).length &&
            setDocs(() => documents)
  }, [documents])

  useEffect(() => {
    if ((userDetails?.kycMethod === 0 || (userDetails?.kycMethod === null && userDetails?.countryKycMethod === 0))) {
      dispatch(getDocumentsLabelStart())
      dispatch(getDocumentsStart())
    }
  }, [])

  const update = (docs) => {
    const data = {}
    for (const item in docs) {
      const selectedDocument = userDocument && userDocument.rows.find(u => u.documentName === item)
      if (selectedDocument) {
        data[`${selectedDocument.documentName}_${selectedDocument.userDocumentId}`] = docs[item]
        data.labelId = labelId
      } else {
        data[item] = docs[item]
        data.labelId = labelId
      }
    }
    dispatch(updateDocumentsStart({ data }))
    setDocs({})
  }

  useEffect(() => {
    if (userDocument?.rows?.length > 0) {
      const approvedDocs = userDocument?.rows?.filter(item => item?.status === 1 && (selectedTab === item?.documentName))
      if (approvedDocs?.length > 0) {
        setHideButton(true)
      } else {
        setHideButton(false)
      }
    }
  }, [selectedTab])

  useLayoutEffect(() => {
    if (userDocument && list?.length > 0) {
      // Makes 1st label by default selected
      setSelectedTab(() => userDocument?.rows?.filter(item => (item?.documentName === list?.[0]?.label))?.[0]?.documentName)
    }
  }, [userDocument, labels])

  const fetchLatestChanges = () => {
    // window?.location.reload()
    setDocs(() => {})
    setSelectedTab(() => '')
    dispatch(getUserDetailsStart())
    dispatch(getDocumentsStart())
    dispatch(getDocumentsLabelStart())
  }

  return {
    docs,
    list,
    loading,
    kycStatus: userDetails?.kycStatus,
    userDocument,
    hideButton,
    languageData,
    status,
    fetchLatestChanges,
    selectedLanguageCode,
    update,
    setDocs,
    selectedTab,
    setSelectedTab,
    setLabelId,
    userDetails,
    navigate,
    dispatch,
    userLoading
  }
}

export default useAccountVerification
