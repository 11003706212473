import React, { useState } from 'react'
import './styles/index.scss'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { useSelector } from 'react-redux'
const PartnersLogos = ({
  showTitle,
  partnerType,
  applyFilter,
  showAll,
  data,
  providersRef,
  toggleProviders,
  setToggleProviders,
  showViewMore,
  viewMore
}) => {
  const { languageData } = useSelector(state => state.language)
  const [settings, setSettings] = useState({
    modules: [Autoplay],
    spaceBetween: 30,
    slidesPerView: 3,
    loop: true,
    autoplay: {
      delay: 100,
      disableOnInteraction: false
    },
    speed: 8500,
    breakpoints: {
      480: {
        slidesPerView: 3,
        spaceBetween: 5
      },
      600: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 20
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 20
      },
      1324: {
        slidesPerView: 6,
        spaceBetween: 30
      },
      1440: {
        slidesPerView: 8,
        spaceBetween: 30
      }
    }
  }
  )

  return (
    <>
      {showTitle &&
        <div className='header-title'>
          <h6 className='white-color'><i className='fa-solid fa-shield-check' />&nbsp;{languageData?.fastAndSecurePayments}</h6>
        </div>}
      {partnerType === 'payments'
        ? !showAll
            ? (
              <>
                <div className={`partners-box ${applyFilter && 'filter-applied'}`}>
                  <img width='80px' height='25px' src={`${process.env.REACT_APP_STATIC_IMAGE}/visa.svg`} className='img-fluid' alt='Partner' />
                  <img width='60px' height='25px' src={`${process.env.REACT_APP_STATIC_IMAGE}/mifinity.svg`} className='img-fluid' alt='Partner' />
                  <img width='80px' height='25px' src={`${process.env.REACT_APP_STATIC_IMAGE}/neteller.svg`} className='img-fluid' alt='Partner' />
                  <img width='70px' height='25px' src={`${process.env.REACT_APP_STATIC_IMAGE}/paysafe.svg`} className='img-fluid' alt='Partner' />
                  <img width='80px' height='25px' src={`${process.env.REACT_APP_STATIC_IMAGE}/jeton.svg`} className='img-fluid' alt='Partner' />
                  <img width='80px' height='25px' src='https://d1urdgvrz10048.cloudfront.net/production/superadmin_gallery_image_1n815r9h4oz-1727937241056.png' className='img-fluid' alt='Partner' />
                  <img width='60px' height='25px' src={`${process.env.REACT_APP_STATIC_IMAGE}/skrill.svg`} className='img-fluid' alt='Partner' />
                  <img width='60px' height='25px' src={`${process.env.REACT_APP_STATIC_IMAGE}/instant-banking.svg`} className='img-fluid' alt='Partner' />
                </div>
                {/* <span className='more'>+ {languageData?.manyMore}!</span> */}
              </>)
            : (
              <div className={`partners-box ${applyFilter && 'filter-applied'}`}>
                <Swiper
                  {...settings}
                >
                  {data && data?.map(({ depositImageUrl, withdrawImageUrl, paymentProviderId, footerImageUrl }) => {
                    const isValidUrl = (url) => url && typeof url === 'string' && !url.includes('undefined')
                    const validDepositImage = isValidUrl(depositImageUrl)
                    const validWithdrawImage = isValidUrl(withdrawImageUrl)
                    const validFooterImage = isValidUrl(footerImageUrl)

                    return (
                      (validFooterImage || validDepositImage || validWithdrawImage) && (
                        <SwiperSlide key={paymentProviderId}>
                          <img
                            width='60px'
                            src={footerImageUrl || (validDepositImage ? depositImageUrl : (validWithdrawImage ? withdrawImageUrl : `${process.env.REACT_APP_STATIC_IMAGE}/defaultpayment.svg`))}
                            alt='img'
                          />
                        </SwiperSlide>
                      )
                    )
                  })}
                </Swiper>
              </div>
              )
        : (
          <div className={`partners-box position-relative viewmore-applied ${applyFilter && 'filter-applied'} ${(toggleProviders || !showViewMore) && 'viewmore-applied-expanded '}`}>
            <Swiper
              {...settings}
            >
              {data?.map((provider) => (
                provider?.thumbnail_url && !provider.thumbnail_url.includes('undefined') && (
                  <SwiperSlide key={provider.master_casino_provider_id}>
                    <img
                      src={provider?.thumbnail_url}
                      alt='img'
                      width='60px'
                    />
                  </SwiperSlide>
                )
              ))}
            </Swiper>
          </div>)}
    </>
  )
}

export default PartnersLogos
