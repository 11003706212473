import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { availBonusStart, cancelBonusStart, getBonusDetailStart, getLoyaltyDetailsStart, getUserActiveBonusStart, getUserComingActiveBonusStart } from '../../../store/redux-slices/bonus'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toggleFavoriteStart } from '../../../store/redux-slices/casinoMenu'
import { getItem } from '../../../utils/storageUtils'

const useDashboard = (props) => {
  const { languageData, selectedLanguageCode } = useSelector((state) => state.language)

  const { favoriteGamesList, gameCategory } = useSelector((state) => state.casinoMenu)

  const { userDetails } = useSelector((state) => state.user)

  const [bonusPageNo, setBonusPageNo] = useState(1)

  const loggedIn = JSON.parse(getItem('loggedIn'))

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const bonusState = useSelector(state => state.bonus)

  // Loyalty section states
  const { loyaltyDetails, loyaltyLoading, userActiveBonus } = useSelector(state => state.bonus)

  // states of Active bonus section
  const [activeBonusPageNo, setActiveBonusPageNo] = useState(1)

  const [showModal, setShowModal] = useState(false)

  // states of claimable bonus section states
  const [claimableBonusPageNo, setClaimableBonusPageNo] = useState(1)

  const [forfeitModal, setForfeitModal] = useState(false)

  const [showLapsedModal, setShowLapsedModal] = useState(false)

  const [status, setStatus] = useState(['CANCELLED', 'FORFEITED', 'EXPIRED', 'COMPLETED', 'ZEROED-OUT', 'LAPSED'])

  const [selectedBonus, setSelectedBonus] = useState({})

  const [selectedForfeitBonus, setSelectedForfeitBonus] = useState({})

  const [showAllGames, setShowAllGames] = useState(false)

  useEffect(() => {
    // Fetching loyalty details on component mount
    fetchLoyaltyDetails()
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // Fetching Active Bonuses on component mount
    fetchActiveBonuses()
  }, [activeBonusPageNo])

  useEffect(() => {
    // Fetching Claimable Bonuses on component mount
    fetchClaimableBonuses()
  }, [claimableBonusPageNo])

  useEffect(() => {
    // Fetching Active Bonus Detail
    if (userActiveBonus && userActiveBonus?.rows?.length > 0) {
      const bonusId = userActiveBonus?.rows[0].bonusId
      const userBonusId = userActiveBonus?.rows[0].userBonusId
      dispatch(getBonusDetailStart({ bonusId, userBonusId }))
    }
  }, [userActiveBonus])

  // This function will fetch loyalty detail
  const fetchLoyaltyDetails = () => {
    dispatch(getLoyaltyDetailsStart())
  }

  // This function will fetch Active Bonuses list
  const fetchActiveBonuses = () => {
    dispatch(getUserActiveBonusStart({
      limit: 8,
      pageNo: activeBonusPageNo,
      status: JSON.stringify(['ACTIVE', 'IN-PROCESS'])
    }))
  }

  // This function will fetch  user Claimable Bonuses list
  const fetchClaimableBonuses = () => {
    dispatch(getUserComingActiveBonusStart({
      limit: 8,
      pageNo: claimableBonusPageNo,
      status: JSON.stringify(['PENDING', 'CLAIMING']),
      upComingBonus: true
    }))
  }

  // This function will activate bonus
  const activateBonus = (item, identifier) => {
    const availBonusData = {
      userBonusId: parseInt(item?.userBonusId) || '',
      fromCustomerIo: false
    }

    if (item?.bonus?.other?.aggregatorId === '2' || item?.bonus?.other?.aggregatorId === 2) {
      availBonusData.hub88GameCode = identifier
    }
    if (item?.bonus?.other?.aggregatorId === '3' || item?.bonus?.other?.aggregatorId === 3) {
      availBonusData.iGamingDeckGameCode = identifier
    }
    if (item.status !== 'CLAIMING') {
      setActiveBonusPageNo(1)
      dispatch(availBonusStart({
        data: availBonusData,
        bonusType: item?.bonusType,
        bonusAmount: item?.bonusAmount,
        item,
        userBonus: null,
        setShowLapsedModal,
        navigate,
        identifier,
        currencyCode: userDetails.currencyCode,
        freeSpinsQty: item?.cashAmount === 0
      }))
    } else {
      const isZeroDeposit = item?.bonusType === 'match' && ((item?.currency && item?.currency[userDetails.currencyCode]?.minDeposit === '0') || (item?.bonus?.currency && item?.bonus?.currency[userDetails.currencyCode]?.minDeposit === '0'))
      setActiveBonusPageNo(1)
      setClaimableBonusPageNo(1)
      if (isZeroDeposit) {
        dispatch(availBonusStart({
          data: availBonusData,
          bonusType: item?.bonusType,
          bonusAmount: item?.bonusAmount,
          item,
          userBonus: null,
          setShowLapsedModal,
          navigate,
          identifier,
          currencyCode: userDetails.currencyCode,
          freeSpinsQty: item?.cashAmount === 0
        }))
      } else {
        navigate(`/${(selectedLanguageCode?.toLowerCase())}/account/deposit`, { state: { tab: 'deposit', bonusDetail: item, identifier } })
      }
    }
  }

  // This function will cancel bonus
  const cancelBonus = (userBonusId, bonusStatus) => {
    dispatch(cancelBonusStart({
      data: bonusStatus ? { userBonusId, bonusStatus } : { userBonusId },
      setBonusPageNo,
      status: JSON.stringify(status),
      activeBonuses: { count: userActiveBonus?.count - 1, rows: userActiveBonus?.rows?.filter(bonus => bonus?.userBonusId !== userBonusId) }
    }))
    setForfeitModal(false)
  }

  // This function will mark game as favorite game
  const toggleFavorite = ({ isFavorite, tenantGameSubCategoryId, categoryGameId }) => {
    if (loggedIn) {
      dispatch(toggleFavoriteStart({
        isFavorite,
        tenantGameSubCategoryId,
        categoryGameId,
        categoryId: (gameCategory?.casinoCategories?.rows?.[0]?.tenantGameCategoryId),
        searchGamesFilter: true
      }))
    } else {
      toast(languageData?.loginFirst, 'error')
    }
  }

  return {
    languageData,
    userDetails,
    loyaltyDetails,
    loyaltyLoading,
    selectedLanguageCode,
    setActiveBonusPageNo,
    setClaimableBonusPageNo,
    activateBonus,
    cancelBonus,
    showModal,
    setShowModal,
    setForfeitModal,
    selectedForfeitBonus,
    setSelectedForfeitBonus,
    selectedBonus,
    setSelectedBonus,
    showAllGames,
    setShowAllGames,
    forfeitModal,
    showLapsedModal,
    favoriteGamesList,
    setShowLapsedModal,
    toggleFavorite,
    navigate,
    ...bonusState,
    ...props
  }
}

export default useDashboard
