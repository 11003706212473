import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getLoginToken } from '../../utils/storageUtils'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetailsStart } from '../../store/redux-slices/user'

const PrivateRoute = ({ children }) => {
  const accessToken = getLoginToken()
  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useDispatch()
  const { userDetails } = useSelector((state) => state.user)
  const { selectedLanguageCode } = useSelector((state) => state.language)

  useEffect(() => {
    if (!accessToken) {
      navigate(`/${(selectedLanguageCode?.toLowerCase())}/login`)
      if (location?.pathname) {
        window.locationPath = location.pathname
      }
    }
    if (!userDetails) {
      dispatch(getUserDetailsStart())
    }
  }, [accessToken, userDetails])

  return accessToken && children
}

export default PrivateRoute
