import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { currencyCodeList } from '../../../constants/currencyCodeList'
import { getLimitTableStart, getUserDetailsStart } from '../../../store/redux-slices/user'
import { getDateTime } from '../../../utils/dateFormatter'
import { getItem } from '../../../utils/storageUtils'
import { useNavigate } from 'react-router-dom'

const useLimit = () => {
  const dispatch = useDispatch()

  const { languageData } = useSelector(state => state.language)
  const { userDetails, loading: userDetailsLoading } = useSelector((state) => state.user)
  const { isPasswordCorrect, loading } = useSelector((state) => state.limits)
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState({
    wagerLimit: false,
    lossLimit: false,
    depositLimit: false,
    takeABreak: false,
    sessionLimit: false,
    selfExclusion: false,
    gamePlayLimit: false
  })
  const [confirmMsg, setConfirmMsg] = useState({
    type: '',
    value: '',
    timePeriod: '',
    reset: '',
    info: '',
    showFooter: false
  })
  const [password, setPassword] = useState('')

  const [showInput, setShowInput] = useState({
    wagerInput: false,
    lossInput: false,
    depositInput: false
  })
  const [showCheckbox, setShowCheckbox] = useState({
    wagerCheckbox: false,
    lossCheckbox: false,
    depositCheckbox: false
  })

  useEffect(() => {
    if (isPasswordCorrect) {
      setShowModal({
        ...showModal,
        wagerLimit: false,
        lossLimit: false,
        depositLimit: false,
        takeABreak: false,
        sessionLimit: false,
        selfExclusion: false,
        gamePlayLimit: false
      })
      setPassword('')
    }
  }, [isPasswordCorrect])

  useEffect(() => {
    if (!userDetailsLoading) dispatch(getLimitTableStart({ userId: getItem('userId') }))
  }, [userDetailsLoading])

  useEffect(() => {
    setShowInput(showInput => ({
      wagerInput: false,
      lossInput: false,
      depositInput: false
    }))

    setShowCheckbox(showCheckbox => ({
      wagerCheckbox: false,
      lossCheckbox: false,
      depositCheckbox: false
    }))
  }, [userDetails?.userLimit?.updatedAt])

  const handleWagerLimit = (formValues) => {
    setShowModal({
      ...showModal,
      wagerLimit: true,
      lossLimit: false,
      depositLimit: false,
      takeABreak: false,
      sessionLimit: false,
      selfExclusion: false,
      gamePlayLimit: false
    })
    setConfirmMsg(confirmMsg => ({
      ...confirmMsg,
      type: `${languageData?.wagerLimit}`,
      value: `${formValues?.dailyBetLimit !== '' && userDetails?.userLimit?.dailyBetLimit !== formValues?.dailyBetLimit ? `${languageData?.daily}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.dailyBetLimit}` : ''} ${formValues?.weeklyBetLimit !== '' && userDetails?.userLimit?.weeklyBetLimit !== formValues?.weeklyBetLimit ? `,${languageData?.weekly}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.weeklyBetLimit}` : ''} ${formValues?.monthlyBetLimit !== '' && userDetails?.userLimit?.monthlyBetLimit !== formValues?.monthlyBetLimit ? `,${languageData?.monthly}: ${formValues?.monthlyBetLimit} ${userDetails?.currencyCode}` : ''}`,
      dailyBetLimit: showCheckbox?.wagerCheckbox ? formValues?.dailyBetReset : userDetails?.userLimit?.dailyBetLimit !== formValues?.dailyBetLimit ? formValues?.dailyBetLimit : '',
      weeklyBetLimit: showCheckbox?.wagerCheckbox ? formValues?.weeklyBetReset : userDetails?.userLimit?.weeklyBetLimit !== formValues?.weeklyBetLimit ? formValues?.weeklyBetLimit : '',
      monthlyBetLimit: showCheckbox?.wagerCheckbox ? formValues?.monthlyBetReset : userDetails?.userLimit?.monthlyBetLimit !== formValues?.monthlyBetLimit ? formValues?.monthlyBetLimit : '',
      reset: showCheckbox?.wagerCheckbox,
      info: handleInfo({ formValues, type: 'Bet', checkboxType: 'wagerCheckbox' }),
      showFooter: true
    }))
  }

  const handleInfo = ({ formValues, type, checkboxType }) => {
    if (showCheckbox?.[checkboxType]) {
      setConfirmMsg(confirmMsg => ({
        ...confirmMsg,
        value: `${formValues?.[`daily${type}Reset`] ? `${languageData?.daily}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.[`daily${type}Limit`]}` : ''} ${formValues?.[`weekly${type}Reset`] ? `,${languageData?.weekly}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.[`weekly${type}Limit`]}` : ''} ${formValues?.[`monthly${type}Reset`] ? `,${languageData?.monthly}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.[`monthly${type}Limit`]}` : ''}`,
        showFooter: true
      }))
      return languageData?.limitRemoved
    } else {
      return languageData?.limitSet24Hrs
    }
  }

  const handleLossLimit = (formValues) => {
    setShowModal({
      ...showModal,
      wagerLimit: false,
      lossLimit: true,
      depositLimit: false,
      takeABreak: false,
      sessionLimit: false,
      selfExclusion: false,
      gamePlayLimit: false
    })
    setConfirmMsg(confirmMsg => ({
      ...confirmMsg,
      type: `${languageData?.lossLimit}`,
      value: `${formValues?.dailyLossLimit !== '' && userDetails?.userLimit?.dailyLossLimit !== formValues?.dailyLossLimit ? `${languageData?.daily}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.dailyLossLimit}` : ''} ${formValues?.weeklyLossLimit !== '' && userDetails?.userLimit?.weeklyLossLimit !== formValues?.weeklyLossLimit ? `,${languageData?.weekly}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.weeklyLossLimit}` : ''} ${formValues?.monthlyLossLimit !== '' && userDetails?.userLimit?.monthlyLossLimit !== formValues?.monthlyLossLimit ? `,${languageData?.monthly}: ${formValues?.monthlyLossLimit} ${userDetails?.currencyCode}` : ''}`,
      dailyLossLimit: showCheckbox?.lossCheckbox ? formValues?.dailyLossReset : userDetails?.userLimit?.dailyLossLimit !== formValues?.dailyLossLimit ? formValues?.dailyLossLimit : '',
      weeklyLossLimit: showCheckbox?.lossCheckbox ? formValues?.weeklyLossReset : userDetails?.userLimit?.weeklyLossLimit !== formValues?.weeklyLossLimit ? formValues?.weeklyLossLimit : '',
      monthlyLossLimit: showCheckbox?.lossCheckbox ? formValues?.monthlyLossReset : userDetails?.userLimit?.monthlyLossLimit !== formValues?.monthlyLossLimit ? formValues?.monthlyLossLimit : '',
      reset: showCheckbox?.lossCheckbox,
      info: handleInfo({ formValues, type: 'Loss', checkboxType: 'lossCheckbox' }),
      showFooter: true
    }))
  }

  const handleDepositLimit = (formValues) => {
    setShowModal({
      ...showModal,
      wagerLimit: false,
      lossLimit: false,
      depositLimit: true,
      takeABreak: false,
      sessionLimit: false,
      selfExclusion: false,
      gamePlayLimit: false
    })
    setConfirmMsg(confirmMsg => ({
      ...confirmMsg,
      type: `${languageData?.depositLimit}`,
      value: `${formValues?.dailyDepositLimit !== '' && userDetails?.userLimit?.dailyDepositLimit !== formValues?.dailyDepositLimit ? `${languageData?.daily}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.dailyDepositLimit}` : ''} ${formValues?.weeklyDepositLimit !== '' && userDetails?.userLimit?.weeklyDepositLimit !== formValues?.weeklyDepositLimit ? `,${languageData?.weekly}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.weeklyDepositLimit}` : ''} ${formValues?.monthlyDepositLimit !== '' && userDetails?.userLimit?.monthlyDepositLimit !== formValues?.monthlyDepositLimit ? `,${languageData?.monthly}: ${currencyCodeList[userDetails?.currencyCode || 'EUR']} ${formValues?.monthlyDepositLimit}` : ''}`,
      dailyDepositLimit: showCheckbox?.depositCheckbox ? formValues?.dailyDepositReset : userDetails?.userLimit?.dailyDepositLimit !== formValues?.dailyDepositLimit ? formValues?.dailyDepositLimit : '',
      weeklyDepositLimit: showCheckbox?.depositCheckbox ? formValues?.weeklyDepositReset : userDetails?.userLimit?.weeklyDepositLimit !== formValues?.weeklyDepositLimit ? formValues?.weeklyDepositLimit : '',
      monthlyDepositLimit: showCheckbox?.depositCheckbox ? formValues?.monthlyDepositReset : userDetails?.userLimit?.monthlyDepositLimit !== formValues?.monthlyDepositLimit ? formValues?.monthlyDepositLimit : '',
      reset: showCheckbox?.depositCheckbox,
      info: handleInfo({ formValues, type: 'Deposit', checkboxType: 'depositCheckbox' }),
      showFooter: true
    }))
  }

  const handleTakeABreak = (formValues) => {
    setShowModal({
      ...showModal,
      wagerLimit: false,
      lossLimit: false,
      depositLimit: false,
      takeABreak: true,
      sessionLimit: false,
      selfExclusion: false,
      gamePlayLimit: false
    })
    setConfirmMsg({
      ...confirmMsg,
      type: `${languageData?.takeABreak}`,
      value: `${formValues?.takeABreak} ${languageData?.days}`,
      info: languageData?.takeABreakInfo,
      showFooter: true
    })
  }

  const handleGamePlayLimit = (formValues) => {
    setShowModal({
      ...showModal,
      wagerLimit: false,
      lossLimit: false,
      depositLimit: false,
      takeABreak: false,
      sessionLimit: false,
      selfExclusion: false,
      gamePlayLimit: true
    })
    setConfirmMsg({
      ...confirmMsg,
      type: `${languageData?.gamePlayLimit || 'Default Game Play Limit'}`,
      value: `${formValues?.timePeriod} ${languageData?.minutes || 'minutes'}`,
      info: (languageData?.limitSet24Hrs?.split('.')?.[0] || 'Default Limit Set for 24 Hours') + '.',
      showFooter: true,
      timePeriod: formValues?.timePeriod
    })
  }

  const handleSessionLimit = (formValues) => {
    setShowModal({
      ...showModal,
      wagerLimit: false,
      lossLimit: false,
      depositLimit: false,
      takeABreak: false,
      sessionLimit: true,
      selfExclusion: false,
      gamePlayLimit: false
    })
    setConfirmMsg({
      ...confirmMsg,
      type: `${languageData?.sessionLimit}`,
      value: `${formValues?.timePeriod} ${languageData?.hours || 'hours'} ${languageData?.daily || 'daily'}`,
      reset: formValues?.reset,
      info: languageData?.sessionLimitInfo || 'Default Session Limit Info',
      showFooter: true
    })
    if (new Date(userDetails?.userLimit?.timeLimitExpiry) > new Date() && formValues?.reset) {
      setConfirmMsg({
        ...confirmMsg,
        type: `${languageData?.sessionLimit}`,
        value: `${formValues?.timePeriod} ${languageData?.hours || 'hours'} ${languageData?.daily || 'daily'}`,
        reset: formValues?.reset,
        info: `Your session limit can not be removed before ${getDateTime(userDetails?.userLimit?.timeLimitExpiry)}.`,
        showFooter: false
      })
    }
    if (new Date(userDetails?.userLimit?.timeLimitExpiry) > new Date() && !formValues?.reset) {
      setConfirmMsg({
        ...confirmMsg,
        type: `${languageData?.sessionLimit}`,
        value: `${formValues?.timePeriod} ${languageData?.hours || 'hours'} ${languageData?.daily || 'daily'}`,
        reset: formValues?.reset,
        info: `${languageData?.limitCantSetBefore || 'Default message for limit cannot be set before'} ${getDateTime(userDetails?.userLimit?.timeLimitExpiry)}.`,
        showFooter: false
      })
    }
  }

  const handleSelfExclusion = (formValues) => {
    setShowModal({
      ...showModal,
      wagerLimit: false,
      lossLimit: false,
      depositLimit: false,
      takeABreak: false,
      sessionLimit: false,
      selfExclusion: true,
      gamePlayLimit: false
    })
    setConfirmMsg({
      ...confirmMsg,
      type: `${languageData?.selfExclusion || 'Default Self Exclusion'}`,
      value: calculateDuration(formValues),
      reset: false,
      portal: formValues?.portal,
      timePeriod: formValues?.timePeriod,
      info: languageData?.selfExclusionInfo || 'Default Self Exclusion Info',
      showFooter: true
    })
  }

  const calculateDuration = (formValues) => {
    if ((parseInt(formValues?.timePeriod) <= 186) && formValues?.timePeriod !== '-1') {
      return `6 ${languageData?.months || 'months'}`
    }
    if (parseInt(formValues?.timePeriod) > 186) {
      return `12 ${languageData?.months || 'months'}`
    } else {
      return `${languageData?.permanent || 'permanent'}`
    }
  }

  const fetchLatestChanges = () => dispatch(getUserDetailsStart())

  return {
    loading,
    dispatch,
    password,
    showModal,
    showInput,
    confirmMsg,
    setPassword,
    userDetails,
    setShowInput,
    showCheckbox,
    setShowModal,
    languageData,
    setShowCheckbox,
    handleLossLimit,
    handleWagerLimit,
    handleTakeABreak,
    isPasswordCorrect,
    userDetailsLoading,
    handleSessionLimit,
    handleDepositLimit,
    fetchLatestChanges,
    handleSelfExclusion,
    handleGamePlayLimit,
    navigate
  }
}

export default useLimit
