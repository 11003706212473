import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { cancelWithdrawRequestStart, getUserTransactionsStart } from '../../../store/redux-slices/transactions'
import { formatDateYMD, getDateDaysAgo } from '../../../utils/dateFormatter'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const UseTransactionHistory = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isInitialRender = useDidMountEffect()
  const { userDetails } = useSelector(state => state?.user)
  const { languageData, selectedLanguageCode } = useSelector(state => state.language)
  const { userTransactions, txLoading } = useSelector(state => state.transactions)
  const [pageNo, setPageNo] = useState(1)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [selectedAction, setSelectedAction] = useState('all')
  const [paymentProviderId, setPaymentProviderID] = useState(null)
  const [cancelTransactionModalOpen, setCancelTransactionModalOpen] = useState(false)
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const totalPages = Math.ceil(userTransactions?.count / limit)

  useEffect(() => {
    dispatch(getUserTransactionsStart({
      limit,
      pageNo,
      startDate: formatDateYMD(state?.[0]?.startDate),
      endDate: formatDateYMD(state?.[0]?.endDate),
      transactionType: selectedAction,
      search
    }))
  }, [limit, pageNo, selectedAction, state])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (pageNo === 1) {
          dispatch(getUserTransactionsStart({
            limit,
            pageNo,
            startDate: formatDateYMD(state?.[0]?.startDate),
            endDate: formatDateYMD(state?.[0]?.endDate),
            transactionType: selectedAction,
            search
          }))
        } else {
          setPageNo(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const fetchLatestChanges = () => {
    if (limit === 10 && pageNo === 1 && state?.[0]?.startDate === new Date().setDate(new Date().getDate() - 10) &&
   state?.[0]?.endDate === new Date() && selectedAction === 'all' && search === '') {
      dispatch(getUserTransactionsStart({
        limit,
        pageNo,
        startDate: formatDateYMD(state?.[0]?.startDate),
        endDate: formatDateYMD(state?.[0]?.endDate),
        transactionType: selectedAction,
        search
      }))
    } else {
      setLimit(10)
      setPageNo(1)
      setState([
        {
          startDate: getDateDaysAgo(10),
          endDate: new Date(),
          key: 'selection'
        }
      ])
      setSelectedAction('all')
      setSearch('')
    }
  }

  const cancelTransaction = async () => {
    await dispatch(cancelWithdrawRequestStart({
      data: {
        paymentProviderId
      },
      isTransactions: true,
      limit,
      pageNo,
      startDate: formatDateYMD(state?.[0]?.startDate),
      endDate: formatDateYMD(state?.[0]?.endDate),
      transactionType: selectedAction,
      search
    }))
    setCancelTransactionModalOpen(false)
  }

  const getTransactionType = (type) => {
    if (type === 'deposit') return languageData?.headerDeposit
    else if (type === 'withdraw') return languageData?.withdraw
    else if (type === 'bonus') return languageData?.headerBonus
    else if (type === 'manualWithdrawInternal') return languageData?.withdrawInternal
    else if (type === 'manualDepositInternal') return languageData?.depositInternal
    else return languageData[type]
  }

  return {
    pageNo,
    limit,
    search,
    loading: txLoading,
    selectedAction,
    userTransactions,
    totalPages,
    userDetails,
    languageData,
    setLimit,
    setSearch,
    setPageNo,
    setSelectedAction,
    fetchLatestChanges,
    state,
    setState,
    navigate,
    selectedLanguageCode,
    cancelTransaction,
    cancelTransactionModalOpen,
    setCancelTransactionModalOpen,
    setPaymentProviderID,
    getTransactionType
  }
}

export default UseTransactionHistory
