import React, { useState } from 'react'

const CopyAddress = ({ languageData, address }) => {
  const [copyMessage, setCopyMessage] = useState('')

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address)
      .then(() => {
        setCopyMessage(languageData?.copied)
        setTimeout(() => setCopyMessage(languageData?.copy), 2000) // Reset after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy address:', err)
        setCopyMessage('Failed to copy.')
      })
  }

  return (
    <div className='d-flex flex-wrap'>
      <p className='text-success mr-2'>{address}</p>
      <button
        onClick={copyToClipboard}
        className='copy-btn'
      >
        {copyMessage || languageData?.copy}
      </button>
    </div>
  )
}

export default CopyAddress
