import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPaymentProvidersStart, getPaymentStatusStart, getUtorgCurrenciesStart, verifyPaymentStart } from '../../../store/redux-slices/payment'
import { clearUserIdAndSessionId, getUserIdAndSessionIdStart } from '../../../store/redux-slices/transactions'
import { getUserDetailsStart, getWalletAmountStart } from '../../../store/redux-slices/user'
import { encryptSocketData, getItem, getLoginTokenForSocket } from '../../../utils/storageUtils'
import { getDeviceType } from '../../../utils/getDeviceType'
import { resendEmailStart, setSocketClient } from '../../../store/redux-slices/login'
import { toast } from '../../../components/Toast'
import { killAllFrames } from '../../../utils/helpers'
import { paymentStatusSocket } from '../../../socket'
import { cancelBonusStart, getUserActiveBonusStart } from 'store/redux-slices/bonus'

const useWithdraw = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = getItem('access-token')
  const [type, setType] = useState('password')
  const { languageData } = useSelector((state) => state.language)
  const { userDetails, walletLoading, loading: usersLoading } = useSelector(state => state.user)
  const { paymentProviders, loading, paymentData, paymentStatus, merchantId, verifyPaymentLoading, utorgCurrencies } = useSelector((state) => state.payment)
  const [showPaymentProviders, setShowPaymentProviders] = useState(true)
  const [showWithdrawal, setShowWithdrawal] = useState(false)
  const [providerData, setProviderData] = useState({})
  const [amt, setAmt] = useState('Other')
  const [initValues, setInitValues] = useState({})
  const [showIFrame, setShowIFrame] = useState(false)
  const { sessionId } = useSelector(state => state.transactions.userPaymentCredential || '')
  const [showWithdrawList, setShowWithdrawList] = useState(false)
  const [amtOptions, setAmtOptions] = useState({})
  const withdrawStatus = window.location.href?.includes('status')
  const [showReceipt, setShowReceipt] = useState(withdrawStatus)
  const [remainingTime, setRemainingTime] = useState({ minutes: '', seconds: '' })
  const [isTimeOver, setIsTimeOver] = useState(true)
  const [loadPIQ, setLoadPIQ] = useState(false)
  const fetchAmount = paymentStatus?.messages?.find(({ label }) =>
    label === 'Amount withdrawn from player account')?.value

  const loggedIn = JSON.parse(getItem('loggedIn'))

  const [fetchUtorgCurrencies, setFetchUtorgCurrencies] = useState(false)

  const { userActiveBonus, activeBonusLoading, loading: cancelBonusLoading } = useSelector(state => state.bonus)

  const restrictWithdraw = useMemo(() => {
    return Boolean(userActiveBonus?.rows?.find((bonus) => bonus.status === 'ACTIVE'))
  }, [userActiveBonus])

  useEffect(() => {
    if (fetchUtorgCurrencies) {
      dispatch(getUtorgCurrenciesStart())
    }
  }, [fetchUtorgCurrencies])

  const fetchActiveBonuses = () => {
    dispatch(getUserActiveBonusStart({
      limit: 8,
      pageNo: 1,
      status: JSON.stringify(['ACTIVE', 'IN-PROCESS'])
    }))
  }

  useEffect(() => {
    fetchActiveBonuses()
  }, [])

  useEffect(() => {
    killAllFrames('payment')
    if (!withdrawStatus) {
      const script = document.createElement('script')

      script.src = process.env.REACT_APP_PAYMENTIQ_URL + merchantId
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    } else {
      handleWithdrawSuccess()
    }
    return () => dispatch(clearUserIdAndSessionId())
  }, [merchantId])

  /**
   * Handles the 'connect' event for the socket.
   * @param {Object} client - The connected socket client.
   */
  const handleConnect = () => {
    // Dispatch the connected client to the Redux store for later use
    dispatch(setSocketClient(paymentStatusSocket))
    // Send a 'login' event to the server with encrypted device type data
    paymentStatusSocket.emit('login', encryptSocketData({ deviceType: getDeviceType() }))
  }

  /**
   * Handles the 'payment-status' event from the server.
   * @param {Object} data - The data received from the server about payment status.
   */
  const handlePaymentStatus = () => {
    // If the receipt is not shown, handle withdrawal success
    if (!showReceipt) {
      handleWithdrawSuccess()
    }
  }

  /**
   * Handles the 'disconnect' event for the socket.
   */
  const handleDisconnect = () => {
    // Log a message when the socket disconnects
    // You can add additional logic here if needed, such as reconnecting or cleanup
    console.log('Socket Connection Disconnect')
  }

  const onForfeitBonus = () => {
    dispatch(cancelBonusStart({
      data: {
        userBonusId: userActiveBonus?.rows[0]?.userBonusId,
        bonusStatus: userActiveBonus?.rows[0]?.status
      }
    }))
  }

  useEffect(() => {
    if (loggedIn && !paymentStatusSocket?.connected && token) {
      paymentStatusSocket.auth.token = getLoginTokenForSocket()
      paymentStatusSocket.disconnect().connect()
    }
  }, [loggedIn, token])

  useEffect(() => {
    if (paymentStatusSocket?.connected) {
      paymentStatusSocket.on('connect', handleConnect)
      paymentStatusSocket.on('payment-status', handlePaymentStatus)
    }

    return () => {
      paymentStatusSocket.on('disconnect', handleDisconnect)
      paymentStatusSocket.off('payment-status')
    }
  }, [paymentStatusSocket])

  const handleWithdrawSuccess = () => {
    if ((getItem('sessionId') && getItem('aggregator') && (getItem('txId') || getItem('orderId')))) {
      setShowIFrame(false)
      setShowPaymentProviders(false)
      setShowWithdrawList(false)
      setShowWithdrawal(false)
      setShowReceipt(true)
    }
  }

  useEffect(() => {
    if (showReceipt) {
      if (getItem('sessionId') && getItem('aggregator') && (getItem('txId') || getItem('orderId'))) {
        !loading && !paymentStatus && dispatch(getPaymentStatusStart({
          transactionId: getItem('txId') || '',
          sessionId: getItem('sessionId'),
          aggregator: getItem('aggregator'),
          orderId: getItem('orderId') || ''
        }))
      } else if (!paymentStatus) {
        navigate(`/${getItem('language').toLowerCase()}/account/withdraw`)
      }
    }
  }, [showReceipt, loading])

  useEffect(() => {
    showPaymentProviders && dispatch(getPaymentProvidersStart({ paymentType: 'withdraw', bonusId: '' }))
  }, [showPaymentProviders])

  useEffect(() => {
    showWithdrawal && dispatch(getUserIdAndSessionIdStart({ type: 'withdraw', navigate }))
  }, [showWithdrawal])

  const handleChangeClick = () => {
    setShowWithdrawal(false)
    setShowPaymentProviders(true)
  }

  const handleProviderClick = (data) => {
    if (data?.settings?.requiredFields.some(item => item.name === 'currency') && data?.aggregator?.toLowerCase() === 'utorg') {
      setFetchUtorgCurrencies(true)
    }

    setProviderData(data)
    // setShowPaymentProviders(false)
    setShowWithdrawal(true)
    setShowIFrame(false)
    setShowWithdrawList(false)
    setAmtOptions(typeof data?.amountKeys === 'string' ? JSON.parse(data?.amountKeys) : data?.amountKeys)
  }

  const handleTrue = () => {
    // setShowPaymentProviders(false)
    setShowWithdrawal(false)
    setShowIFrame(true)
  }

  // For New Window with Payment URL
  useEffect(() => {
    if (showIFrame && (!paymentData?.redirectOutput && !paymentData?.redirect_url && !loading)) {
      handleWithdrawSuccess()
    }
  }, [showIFrame, paymentData])

  useEffect(() => {
    if (providerData?.settings?.requiredFields?.length && providerData?.aggregator !== 'praxis') {
      const formValues = {}
      const isUtorgSkrill = providerData.aggregator === 'utorg' && providerData.name === 'Skrill'
      providerData?.settings?.requiredFields?.forEach(({ name }) => {
        if (['beneficiaryName', 'cardHolder', 'name'].includes(name)) {
          formValues[name] = userDetails?.firstName + ' ' + userDetails?.lastName
        } else if (['first_name', 'firstName'].includes(name)) {
          formValues[name] = userDetails?.firstName
        } else if (['last_name', 'lastName'].includes(name)) {
          formValues[name] = userDetails?.lastName
        } else if (['phoneNumber', 'phone', 'mobile'].includes(name)) {
          formValues[name] = userDetails?.phoneCode ? userDetails?.phoneCode + ' ' + userDetails?.phone : ''
        } else if (name === 'email' || name === 'destinationAccount') {
          formValues[name] = userDetails?.email
        } else if (name === 'address') {
          formValues[name] = userDetails?.address
        } else if (['country'].includes(name)) {
          formValues[name] = userDetails?.countryCode
        } else if (name === 'walletAddress') {
          if (!isUtorgSkrill) {
            formValues[name] = userDetails?.walletAddress || ''
          }
        } else if (name === 'currency') {
          if (!isUtorgSkrill) {
            formValues[name] = userDetails?.currency || ''
          }
        } else {
          formValues[name] = ''
        }
      })
      setInitValues(formValues)
    } else {
      setInitValues({})
    }
  }, [providerData])

  const resetPay = () => {
    setShowIFrame(false)
    setShowPaymentProviders(true)
    setShowWithdrawList(false)
    setShowWithdrawal(false)
    setShowReceipt(false)
  }

  useEffect(() => {
    if (!userDetails && !usersLoading) {
      dispatch(getUserDetailsStart())
    }
  }, [userDetails])

  const handleSubmit = (formData) => {
    if (userDetails && !userDetails.isEmailVerified) {
      toast(languageData?.notVerified, 'error')
      return
    }

    const { amount, ...rest } = formData
    if (rest && rest?.encCreditcardNumber) {
      // Encryption for Credit Card
      rest.encCreditcardNumber = window.encryptData(String(rest.encCreditcardNumber?.replace(/ /g, '')))
      rest.encCvv = window.encryptData(String(rest.encCvv))
      rest.expiryMonth = rest.expirymmyyyy.substr(0, 2)
      rest.expiryYear = rest.expirymmyyyy.substr(3, 5)
      delete rest.expirymmyyyy
    }
    dispatch(verifyPaymentStart({
      data: {
        amount,
        aggregator: providerData.aggregator,
        paymentProviderId: providerData.paymentProviderId,
        provider: providerData.name,
        group: providerData.group,
        paymentType: 'withdraw',
        other: rest ? { ...rest } : {},
        confirm: false,
        gateway: providerData?.settings?.gateway,
        sessionId
      },
      resetPay,
      navigate
    }))
    handleTrue()
    setType('password')
  }

  const getWalletAmount = () => {
    dispatch(getWalletAmountStart())
  }

  const resendEmail = ({ email }) => dispatch(resendEmailStart({ email, navigate, isProfilePage: true }))

  const resendVerificationLink = () => {
    resendEmail(userDetails)
    waitForResendEmail()
  }

  let now = new Date()
  now.setMinutes(now.getMinutes() + 2)
  now = new Date(now)

  const waitForResendEmail = () => {
    setTimeout(() => {
      const countDownDate = now

      const x = setInterval(function () {
        const now = new Date().getTime()
        const distance = countDownDate - now

        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)

        setRemainingTime({ minutes, seconds })
        setIsTimeOver(false)
        if (distance < 0) {
          clearInterval(x)
          setIsTimeOver(true)
        }
      }, 1000)
    }, 100)
  }

  const handleLoadPIQ = () => {
    setLoadPIQ(true)
    // setShowIFrame(false)
  }

  return {
    languageData,
    showPaymentProviders,
    showWithdrawal,
    providerData,
    handleChangeClick,
    handleProviderClick,
    paymentProviders,
    loading,
    amt,
    setAmt,
    currencyCode: userDetails?.currencyCode || 'EUR',
    type,
    setType,
    handleSubmit,
    initValues,
    wallet: userDetails?.userWallet,
    showIFrame,
    paymentData,
    showWithdrawList,
    userDetails,
    showReceipt,
    paymentStatus,
    navigate,
    fetchAmount,
    setShowReceipt,
    getWalletAmount,
    walletLoading,
    amtOptions,
    resendVerificationLink,
    remainingTime,
    isTimeOver,
    data: providerData,
    loadPIQ,
    sessionId,
    setShowIFrame,
    handleLoadPIQ,
    verifyPaymentLoading,
    utorgCurrencies,
    activeBonusLoading,
    restrictWithdraw,
    cancelBonusLoading,
    onForfeitBonus
  }
}

export default useWithdraw
