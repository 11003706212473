import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getAllBonusStart,
    getAllBonusSuccess,
    getAllBonusFailure,
    getBonusDetailStart,
    getBonusDetailSuccess,
    getBonusDetailFailure,
    claimBonusStart,
    claimBonusComplete,
    getUserBonusStart,
    getUserBonusReset,
    getUserBonusSuccess,
    getUserBonusFailure,
    cancelBonusStart,
    cancelBonusComplete,
    availBonusStart,
    availBonusComplete,
    getLoyaltyLevelStart,
    getLoyaltyLevelSuccess,
    getLoyaltyLevelFailure,
    getLoyaltyDetailsStart,
    getLoyaltyDetailsSuccess,
    getLoyaltyDetailsFailure,
    getWageringBarStart,
    getWageringBarSuccess,
    getWageringBarFailure,
    updateWageringBar,
    getDepositUserBonusSuccess,
    getCashbackTableStart,
    getCashbackTableSuccess,
    getCashbackTableFailure,
    getUserActiveBonusStart,
    getUserActiveBonusSuccess,
    getUserComingBonusSuccess,
    getUserActiveBonusFailure,
    getUserActiveBonusReset,
    forfeitBonusStart,
    forfeitBonusSuccess,
    forfeitBonusFailure,
    getUserComingActiveBonusReset,
    getUserComingActiveBonusStart
  },
  reducer
} = createSlice({
  name: 'bonus',
  initialState: {
    loading: false,
    userBonus: null,
    userActiveBonus: [],
    allBonus: {},
    depositUserBonus: null,
    loyaltyBar: { loyaltyExist: false },
    wageringBar: { wagering: false },
    loyaltyLoading: false,
    activeBonusLoading: false,
    bonusLoading: false,
    forfeitLoading: false,
    userComingActiveBonus: [],
    userComingActiveBonusLoading: false
  },
  reducers: {
    getAllBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    getAllBonusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      allBonus: payload
    }),
    getAllBonusFailure: (state) => ({
      ...state,
      loading: false
    }),
    getBonusDetailStart: (state) => ({
      ...state,
      loading: true
    }),
    getBonusDetailSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bonusDetail: payload
    }),
    getBonusDetailFailure: (state) => ({
      ...state,
      loading: false
    }),
    claimBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    claimBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getUserBonusStart: (state) => ({
      ...state,
      bonusLoading: true,
      userBonus: null
    }),
    getUserBonusReset: (state) => ({
      ...state,
      userBonus: null
    }),
    getUserActiveBonusReset: (state) => ({
      ...state,
      userActiveBonus: null
    }),
    getUserComingActiveBonusReset: (state) => ({
      ...state,
      userComingActiveBonus: null
    }),
    getUserBonusSuccess: (state, { payload }) => ({
      ...state,
      bonusLoading: false,
      userBonus: payload
    }),
    getDepositUserBonusSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      bonusLoading: false,
      depositUserBonus: payload
    }),
    getUserBonusFailure: (state) => ({
      ...state,
      bonusLoading: false
    }),
    cancelBonusStart: (state) => ({
      ...state,
      loading: true
    }),
    cancelBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    availBonusStart: (state) => ({
      ...state,
      loading: false
    }),
    availBonusComplete: (state) => ({
      ...state,
      loading: false
    }),
    getLoyaltyLevelStart: (state) => ({
      ...state,
      loading: true
    }),
    getLoyaltyLevelSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      loyaltyLevel: payload
    }),
    getLoyaltyLevelFailure: (state) => ({
      ...state,
      loading: false
    }),
    getLoyaltyDetailsStart: (state) => ({
      ...state,
      loyaltyLoading: true
    }),
    getLoyaltyDetailsSuccess: (state, { payload }) => ({
      ...state,
      loyaltyLoading: false,
      loyaltyDetails: payload
    }),
    getLoyaltyDetailsFailure: (state) => ({
      ...state,
      loyaltyLoading: false
    }),
    getWageringBarStart: (state) => ({
      ...state,
      loading: true,
      loyaltyBar: { ...state.loyaltyBar, loyaltyExist: false },
      wageringBar: { ...state.wageringBar, wagering: false }
    }),
    getWageringBarSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      wageringBar: payload
    }),
    getWageringBarFailure: (state) => ({
      ...state,
      loading: false
    }),
    updateWageringBar: (state, { payload }) => ({
      ...state,
      wageringBar: (payload?.wagering || payload?.wageredAmount || payload?.amountToWager) ? { ...state.wageringBar, wageredAmount: payload.wageredAmount, wagering: payload.wagering, amountToWager: payload.amountToWager, wageringStatus: payload.wageringStatus } : state.wageringBar,
      loyaltyBar: { ...state.loyaltyBar, loyaltyExist: payload.loyaltyExist, loyaltyPoints: payload.loyaltyPoints, level: payload.level }
    }),
    getCashbackTableStart: (state) => ({
      ...state,
      loading: true
    }),
    getCashbackTableSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      cashbackTable: payload
    }),
    getCashbackTableFailure: (state) => ({
      ...state,
      loading: false
    }),
    getUserActiveBonusStart: (state) => ({
      ...state,
      activeBonusLoading: true
    }),
    getUserComingActiveBonusStart: (state) => ({
      ...state,
      userComingActiveBonusLoading: true
    }),
    getUserActiveBonusSuccess: (state, { payload }) => ({
      ...state,
      activeBonusLoading: false,
      userActiveBonus: { count: payload?.count, rows: state?.userActiveBonus ? [...payload?.rows, ...state?.userActiveBonus?.rows] : payload?.rows }
    }),
    getUserComingBonusSuccess: (state, { payload }) => ({
      ...state,
      userComingActiveBonusLoading: false,
      userComingActiveBonus: { count: payload?.count, rows: state?.userComingActiveBonus ? [...payload?.rows, ...state?.userComingActiveBonus?.rows] : payload?.rows }
    }),
    getUserActiveBonusFailure: (state) => ({
      ...state,
      activeBonusLoading: false,
      userComingActiveBonusLoading: false
    }),
    forfeitBonusFailure: (state) => ({
      ...state,
      forfeitLoading: false
    }),
    forfeitBonusStart: (state) => ({
      ...state,
      forfeitLoading: true
    }),
    forfeitBonusSuccess: (state) => ({
      ...state,
      forfeitLoading: false
    })
  }
})

export default reducer

export {
  getAllBonusStart,
  getAllBonusSuccess,
  getAllBonusFailure,
  getBonusDetailStart,
  getBonusDetailSuccess,
  getBonusDetailFailure,
  claimBonusStart,
  claimBonusComplete,
  getUserBonusStart,
  getUserBonusReset,
  getUserBonusSuccess,
  getUserBonusFailure,
  cancelBonusStart,
  cancelBonusComplete,
  availBonusStart,
  availBonusComplete,
  getLoyaltyLevelStart,
  getLoyaltyLevelSuccess,
  getLoyaltyLevelFailure,
  getLoyaltyDetailsStart,
  getLoyaltyDetailsSuccess,
  getLoyaltyDetailsFailure,
  getWageringBarStart,
  getWageringBarSuccess,
  getWageringBarFailure,
  updateWageringBar,
  getDepositUserBonusSuccess,
  getCashbackTableStart,
  getCashbackTableSuccess,
  getCashbackTableFailure,
  getUserActiveBonusStart,
  getUserActiveBonusSuccess,
  getUserActiveBonusFailure,
  getUserActiveBonusReset,
  forfeitBonusStart,
  forfeitBonusSuccess,
  forfeitBonusFailure,
  getUserComingBonusSuccess,
  getUserComingActiveBonusReset,
  getUserComingActiveBonusStart
}
