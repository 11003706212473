import React, { useState, useEffect, useRef } from 'react'
import { Offcanvas, Button } from 'react-bootstrap'
import { toast } from 'components/Toast'
import { getFavGameStart } from 'store/redux-slices/casinoMenu'
import { capitalizeMyText } from 'utils/helpers'

import useFilterGames from '../hooks/useFilterGames'
import AllGamesGrid from '../AllGamesGrid'
import FavoriteList from '../Favorite'
import GameCategories from '../GameCategories'
import GameFiltersCheckbox from '../GameFiltersCheckbox'
import CategorisedGamesGrid from '../CategorisedGamesGrid'
import ModalSearch from '../ModalSearch'
import GameFiltersSelection from '../GameFiltersSelection'
import GameSearch from '../GameSearch'
import './styles/index.scss'

const AllGamesLobby = ({ selectedFilter, setSelectedFilter }) => {
  const {
    loading,
    providers,
    filteredGames,
    gameSubCategory,
    selectedLanguageCode,
    dispatch,
    toggleFavorite,
    fetchMoreGames,
    loggedIn,
    // themes,
    languageData,
    rowsFetchedCount,
    loadAllData,
    setLoadAllData,
    setSelectedMenu,
    selectedMenu,
    navigate,
    casinoCategory,
    navTabSubCategory,
    favoriteGamesList,
    favLoading
  } = useFilterGames({ category: false, selectedFilter, setSelectedFilter })

  const [showSearchModal, setShowSearchModal] = useState(false)
  const [stickyClass, setStickyClass] = useState('')
  const [elementClass, setElementClass] = useState('')
  const [isOpened, setIsOpened] = useState(false)
  const [filtersSelected, setFiltersSelected] = useState(false)
  const element = useRef(null)
  const categorizedList = useRef(null)
  const allList = useRef(null)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShowFilters = () => setShow(true)

  const volatilityRating = [languageData?.low, languageData?.medium, languageData?.high, languageData?.mediumHigh, languageData?.veryHigh, languageData?.lowMedium]

  const stickNavbar = () => {
    if (window !== undefined) {
      if ((window.scrollY - 150) > (element?.current?.getBoundingClientRect()?.top + 150)) {
        setStickyClass('sticky-nav')
        setElementClass('element-sticky')
      } else {
        setStickyClass('')
        setElementClass('')
      }
    }
  }

  const showFavorites = () => {
    if (loggedIn) {
      setIsOpened(isOpened => !isOpened)
      if (!isOpened) {
        dispatch(
          getFavGameStart({
            page: 1,
            limit: 9999
          })
        )
      }
    } else {
      toast(languageData?.loginFirst, 'error')
    }
  }

  useEffect(() => {
    navTabSubCategory?.current?.offsetParent?.offsetTop &&
      window?.scrollTo({
        top: ((navTabSubCategory?.current?.offsetParent?.offsetTop / 100) - 1) * 100
      })
  })

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar)
    return () => window.removeEventListener('scroll', () => stickNavbar)
  }, [stickNavbar])

  useEffect(() => {
    if (selectedFilter?.category?.length === 0 && selectedFilter?.provider?.length === 0 && selectedFilter?.rating?.length === 0 && selectedFilter?.themes?.length === 0 && selectedFilter?.search === '') {
      categorizedList.current.style.display = 'block'
      allList.current.style.display = 'none'
      setFiltersSelected(false)
    } else {
      categorizedList.current.style.display = 'none'
      allList.current.style.display = 'block'
      if (selectedFilter?.provider?.length === 0 && selectedFilter?.rating?.length === 0 && selectedFilter?.themes?.length === 0 && selectedFilter?.search === '') {
        setFiltersSelected(false)
      } else {
        setFiltersSelected(true)
      }
    }
  }, [selectedFilter?.category, selectedFilter?.provider, selectedFilter?.rating, selectedFilter?.themes, selectedFilter?.search])

  return (
    <>
      <div className={`filters-content ${stickyClass}`}>
        <div className='game-type-container'>
          <div className='game-type-slider'>
            <GameCategories
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              setSelectedMenu={setSelectedMenu}
              selectedMenu={selectedMenu}
              selectedLanguageCode={selectedLanguageCode}
              gameSubCategory={gameSubCategory}
              navigate={navigate}
              casinoCategory={casinoCategory}
              navTabSubCategory={navTabSubCategory}
            />
          </div>
          <div class='search-input-container'>
            <GameSearch
              setSelectedFilter={setSelectedFilter}
              languageData={languageData}
            />
          </div>

          <div className='filter-search'>
            <div className='filter-search-others'>
              <button className='filter-btn' onClick={handleShowFilters}>
                <i class='fa-solid fa-gears' />
              </button>
              <button className='filter-btn' onClick={() => { showFavorites() }}>
                <i className={`fa-solid fa-heart fav-open-${isOpened}`} />
              </button>
            </div>
            <Offcanvas className='filter-search-others-wrapper' show={show} onHide={handleClose} placement='end' scroll='true'>
              <Offcanvas.Header>
                <Offcanvas.Title>{languageData?.filtres}</Offcanvas.Title>
                <Button className={`close-btn modal-close filters-selected-${filtersSelected}`} variant='danger' onClick={handleClose}>
                  <i className={`fa-solid ${filtersSelected ? 'fa-check' : 'fa-xmark'}`} />
                </Button>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <GameFiltersSelection
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  languageData={languageData}
                />
                <div className='filter-list-wrapper'>
                  <GameFiltersCheckbox
                    dataSet={volatilityRating}
                    selectedFilterData={selectedFilter?.rating}
                    setSelectedFilter={setSelectedFilter}
                    capitalizeMyText={capitalizeMyText}
                    dataType='rating'
                    title={languageData?.volatility}
                    icon='chart-line-up-down'
                  />
                  <GameFiltersCheckbox
                    dataSet={providers}
                    selectedFilterData={selectedFilter?.provider}
                    setSelectedFilter={setSelectedFilter}
                    capitalizeMyText={capitalizeMyText}
                    dataType='providers'
                    title={languageData?.providers}
                    icon='gamepad'
                  />
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>

        <GameFiltersSelection
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          languageData={languageData}
        />

      </div>
      <div class='mobile-search-input-container'>
        <GameSearch
          setSelectedFilter={setSelectedFilter}
          languageData={languageData}
        />
      </div>
      <div ref={element} className={`all-games-content favorites-open-${isOpened} ${elementClass}`}>
        <div ref={navTabSubCategory} />
        <div className='favorites-wrapper'>
          <FavoriteList
            toggleFavorite={toggleFavorite}
            favoriteGamesList={favoriteGamesList}
            selectedLanguageCode={selectedLanguageCode}
            favLoading={favLoading}
            languageData={languageData}
          />
        </div>

        <div ref={categorizedList}>
          <CategorisedGamesGrid
            isHomePage={false}
            favoriteGamesList={favoriteGamesList}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        </div>
        <div ref={allList}>
          <AllGamesGrid
            filteredGames={filteredGames}
            fetchMoreGames={fetchMoreGames}
            toggleFavorite={toggleFavorite}
            selectedLanguageCode={selectedLanguageCode}
            loadAllData={loadAllData}
            setLoadAllData={setLoadAllData}
            loading={loading}
            rowsFetchedCount={rowsFetchedCount}
            selectedFilter={selectedFilter}
            favoriteGamesList={favoriteGamesList}
          />
        </div>
      </div>

      {showSearchModal &&
        <ModalSearch
          showSearchModal={showSearchModal}
          setShowSearchModal={setShowSearchModal}
          dataType='providers'
          providers={providers}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />}
    </>
  )
}

export default AllGamesLobby
