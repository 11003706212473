import React, { useContext } from 'react'
import { date, month, year } from '../../../constants/dateConstants'
import DateSelector from './DateSelector'

const DateBirth = ({ formik, contextValue, disabled = false }) => {
  const { regFields } = useContext(contextValue)
  const { values, setFieldValue } = formik

  const getDayValue = (values) => parseInt(new Date(values.dateOfBirth)?.getDate())

  const getMonthValue = (values) => parseInt(new Date(values.dateOfBirth)?.getMonth() + 1)

  const getYearValue = (values) => parseInt(new Date(values.dateOfBirth)?.getFullYear())

  const onChange = (value, label) => {
    setFieldValue(
      'dateOfBirth',
      `${
        label === 'year' ? value : new Date(values.dateOfBirth).getFullYear()
      }/${
        label === 'month' ? value : new Date(values.dateOfBirth).getMonth() + 1
      }/${label === 'date' ? value : new Date(values.dateOfBirth).getDate()}`
    )
  }

  return (
    <>
      {regFields?.dateOfBirth !== 0 && (
        <div className='date-of-birth-container'>
          <DateSelector
            label='date'
            values={values}
            onChange={onChange}
            options={date}
            getValue={getDayValue}
            contextValue={contextValue}
            disabled={disabled}
          />
          <DateSelector
            label='month'
            values={values}
            onChange={onChange}
            options={month}
            getValue={getMonthValue}
            contextValue={contextValue}
            disabled={disabled}
          />
          <DateSelector
            label='year'
            values={values}
            onChange={onChange}
            options={year}
            getValue={getYearValue}
            contextValue={contextValue}
            disabled={disabled}
          />
        </div>
      )}
    </>
  )
}

export default DateBirth
