import React, { useContext } from 'react'
import FormFieldControl from '../../../../../FormFieldControl'
import DateBirth from '../../../../../FormFieldControl/DateBirth'
import PhoneInputField from '../../../../../FormFieldControl/PhoneInputField'
import CountryField from '../../../../../FormFieldControl/CountryField'
import CurrencyField from '../../../../../FormFieldControl/CurrencyField'
import LanguageField from '../../../../../FormFieldControl/LanguageField'
import GenderField from '../../../../../FormFieldControl/GenderField'
import { SignUpContext } from '../../../../../../pages/NewSignup/useContext'
import TermAndCondition from '../../../../../FormFieldControl/TermAndCondition'

const PersonalDetail = ({ formik, acceptConditions, setAcceptConditions }) => {
  const { regFields, languageData, showStar } = useContext(SignUpContext)

  const commonProps = {
    containerClassName: 'custom-form-group',
    fieldClassName: 'form-control custom-input',
    contextValue: SignUpContext,
    regFields,
    languageData,
    showStar
  }
  const PreferredLanguageLabel = () => {
    return (
      <label htmlFor='currencyCode' className='custom-label'>
        {languageData?.signupLanguage || 'Preferred Language'}
        {regFields?.preferredLanguage === 2
          ? showStar(regFields.preferredLanguage)
          : ''}
      </label>
    )
  }
  return (
    <div className='signup-form-detail'>
      <FormFieldControl
        type='text'
        name='firstName'
        control='input'
        label='signupFirstName'
        placeholder={languageData?.firstNamePlaceholder}
        {...commonProps}
      />

      <FormFieldControl
        type='text'
        name='lastName'
        control='input'
        label='signupLastName'
        placeholder={languageData?.lastNamePlaceholder}
        {...commonProps}
      />

      <FormFieldControl
        name='dateOfBirth'
        label='signupDob'
        {...commonProps}
      >
        <DateBirth formik={formik} contextValue={SignUpContext} />
      </FormFieldControl>

      <FormFieldControl
        type='text'
        name='address'
        control='input'
        label='signupAddress'
        placeholder={languageData?.addressPlaceholder}
        {...commonProps}
      />

      <FormFieldControl
        name='phone'
        label='signupPhoneNo'
        {...commonProps}
      >
        <PhoneInputField formik={formik} contextValue={SignUpContext} />
      </FormFieldControl>

      <FormFieldControl
        type='text'
        name='city'
        control='input'
        label='signupCity'
        placeholder={languageData?.cityPlaceholder}
        {...commonProps}
      />

      <FormFieldControl
        type='text'
        name='zipCode'
        control='input'
        label='signupPostcode'
        placeholder={languageData?.postCodePlaceholder}
        {...commonProps}
      />

      <FormFieldControl
        name='countryCode'
        label='signupCounty'
        {...commonProps}
      >
        <CountryField formik={formik} contextValue={SignUpContext} />
      </FormFieldControl>

      <FormFieldControl
        name='currencyCode'
        label='signupCurrency'
        {...commonProps}
      >
        <CurrencyField formik={formik} contextValue={SignUpContext} />
      </FormFieldControl>

      <FormFieldControl
        name='preferredLanguage'
        label='signupLanguage'
        {...commonProps}
        defaultLabel={<PreferredLanguageLabel />}
      >
        <LanguageField formik={formik} contextValue={SignUpContext} />
      </FormFieldControl>

      <FormFieldControl
        name='gender'
        label='signupGender'
        {...commonProps}
      >
        <GenderField formik={formik} contextValue={SignUpContext} />
      </FormFieldControl>

      <TermAndCondition
        formik={formik}
        acceptConditions={acceptConditions}
        setAcceptConditions={setAcceptConditions}
      />
    </div>

  )
}

export default PersonalDetail
