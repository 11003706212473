
import io from 'socket.io-client'
import { getLoginTokenForSocket } from '../utils/storageUtils'
const SOCKET_URL = process.env.REACT_APP_BACKEND_SOCKET_URL

export const walletSocket = io(`${SOCKET_URL}/wallet`, {
  auth: {
    token: getLoginTokenForSocket()
  },
  transports: ['websocket', 'polling'],
  path: '/socket.io',
  reconnectionAttempts: 10 // Number of times to retry login
})

export const paymentStatusSocket = io(`${SOCKET_URL}/paymentStatus`, {
  auth: {
    token: getLoginTokenForSocket()
  },
  transports: ['websocket', 'polling'],
  path: '/socket.io',
  reconnectionAttempts: 10 // Number of times to retry login
})
