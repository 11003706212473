import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getTournamentsStart,
    getTournamentsSuccess,
    getTournamentsFailure,
    getTournamentDetailsStart,
    getTournamentDetailsSuccess,
    getTournamentDetailsFailure
  },
  reducer
} = createSlice({
  name: 'tournament',
  initialState: {
    loading: false,
    tournaments: null,
    tournamentDetails: null
  },
  reducers: {
    getTournamentsStart: (state) => ({
      ...state,
      loading: true
    }),
    getTournamentsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tournaments: payload
    }),
    getTournamentsFailure: (state) => ({
      ...state,
      loading: false
    }),
    getTournamentDetailsStart: (state) => ({
      ...state,
      loading: true
    }),
    getTournamentDetailsSuccess: (state, { payload }) => ({
      ...state,
      loading: false,
      tournamentDetails: payload
    }),
    getTournamentDetailsFailure: (state) => ({
      ...state,
      loading: false
    })
  }
})

export default reducer

export {
  getTournamentsStart,
  getTournamentsSuccess,
  getTournamentsFailure,
  getTournamentDetailsStart,
  getTournamentDetailsSuccess,
  getTournamentDetailsFailure
}
