import React, { useEffect, useState } from 'react'
import HtmlParser from 'react-html-parser'

const TermsAndConditions = ({ initialContent, languageData }) => {
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    const contentElement = document.getElementById('t&c')
    const contentHeight = contentElement.offsetHeight
    const maxHeight = 60
    setShowAll(!(contentHeight > maxHeight))
  }, [])

  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  return (
    <div className='bonus-term-condition'>
      <div className='heading'>{languageData?.bonusDetails}</div>
      <div id='t&c' className={`term-and-condition ${showAll ? '' : 'truncate-large-content'}`}>
        {HtmlParser(initialContent?.replace(/&nbsp;/g, ' '))}
      </div>
      {!showAll && (
        <div className='d-flex justify-content-center align-item-center view-all-btn-box'>
          <button onClick={toggleShowAll}>{languageData?.viewAll} T&amp;C</button>
        </div>
      )}
    </div>
  )
}

export default TermsAndConditions
