import React, { useContext } from 'react'
import './styles/index.scss'
import { Link, useNavigate } from 'react-router-dom'
import CustomOverlayTrigger from '../../CustomOverlayTrigger'
import withContextProvider from '../../../wrapper/withContextProvider'
import { dashboardContext } from '../../../context'
import DashboardSectionHeading from '../Common/DashboardSectionHeading'
import { dashboardOverlayTrigger } from '../../../constants/overlayTrigger'
import Loader from '../../Loader'
import CustomProgress from '../../CustomProgress'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
const LoyaltySection = () => {
  const {
    languageData,
    loyaltyLoading,
    loyaltyDetails,
    selectedLanguageCode
  } = useContext(dashboardContext)
  const navigate = useNavigate()

  const overlayContent = (
    <>
      {languageData?.loyaltyPopover} &nbsp;
      <Link to={`/${selectedLanguageCode?.toLowerCase()}/loyalty-bonus`}>
        {languageData?.loyaltyPage}
      </Link>
      &nbsp;
    </>
  )

  const loyaltyPercent = loyaltyDetails
    ? parseFloat(
      ((loyaltyDetails.currentPoint - loyaltyDetails.startPoint) /
          (loyaltyDetails.endPoint - loyaltyDetails.startPoint)) *
          100
    )?.toFixed(2)
    : 0

  return (
    <>
      <div className='dashboard-section'>
        {loyaltyLoading
          ? <Loader />
          : (
            <div className='loyalty-section'>
              {/* level-point-section or first Section */}
              <div className='loyalty-sub-section'>
                <div className=' d-flex flex-column level-point-section'>
                  <div className='level-icon-section'>
                    <img
                      src={`${process.env.REACT_APP_STATIC_IMAGE}/dashboard-level.svg`}
                      alt='img'
                    />
                    <div className='level'>{loyaltyDetails?.level}</div>
                  </div>
                  <div className='loyalty-details'>
                    <div class='level'>
                      {languageData?.headerLevel} {loyaltyDetails?.level}
                    </div>
                    <div class='point-text'>
                      {languageData?.loyaltyTableHeaderTwo} :
                    </div>
                    <div class='points'>
                      {loyaltyDetails?.currentPoint &&
                      (loyaltyDetails?.currentPoint).toFixed(2)}{' '}
                      {languageData?.loyaltyPoints}
                    </div>
                  </div>
                </div>
              </div>

              {/* heading-section or second Section */}
              <div className='loyalty-sub-section'>
                <DashboardSectionHeading headingText={languageData?.Loyalty} />
                {/* <div className='per-currency'>
                  {languageData?.loyaltyPerCurrency} :{' '}
                  <span>x{loyaltyDetails?.pointPerUnit}</span>
                </div> */}
              </div>

              {/* loyalty-progress-bar-container or third Section */}
              <div className='loyalty-sub-section'>
                {loyaltyDetails?.currentPoint !== undefined &&
                loyaltyDetails?.currentPoint !== null &&
                loyaltyDetails?.startPoint !== undefined &&
                loyaltyDetails?.startPoint !== null &&
                loyaltyDetails?.endPoint && (
                  <div className='loyalty-progress-bar-container'>
                    <div className='progress-parent'>
                      <CustomProgress
                        containerClass='custom-progress-container'
                        progressClass='progress-done'
                        percentage={loyaltyPercent}
                        labelClass='progress-label'
                        levelCountIconClass='label-icon'
                        levelCount={loyaltyDetails?.level}
                        label={languageData?.headerLevel}
                        iconWidth={10}
                      />
                      <div className='winner-icon'>
                        <img
                          src={`${process.env.REACT_APP_STATIC_IMAGE}/winner-level.svg`}
                          alt='img'
                        />
                      </div>{' '}
                    </div>
                    <div className='d-flex justify-content-between justify-content-center'>
                      <div>
                        <span className='loyalty-percentage'>
                          {parseFloat(
                            ((loyaltyDetails?.currentPoint -
                              loyaltyDetails?.startPoint) /
                              (loyaltyDetails?.endPoint -
                                loyaltyDetails?.startPoint)) *
                              100
                          )?.toFixed(2)}
                          %
                        </span>{' '}
                        <span className='loyalty-current-point'>
                          ({loyaltyDetails?.currentPoint.toFixed(2)}{' '}
                          {languageData?.loyaltyPoints}){' '}
                        </span>
                      </div>
                      <div>
                        <span className='next-level-text'>
                          {' '}
                          {languageData?.headerLevel}{' '}
                          {loyaltyDetails?.level + 1}
                        </span>{' '}
                        <span className='point-to-reach'>
                          ( {languageData?.pointsToReach}
                          {(
                            loyaltyDetails?.endPoint -
                            loyaltyDetails?.currentPoint
                          )?.toFixed(2)}{' '}
                          )
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* cash-bonus-section or four Section */}
              <div className='loyalty-sub-section'>
                <div className='cash-bonus-section'>
                  <div className=' d-flex flex-row'>
                    <div className='text-center'>
                      <div className='next-level-section'>
                        {' '}
                        {languageData?.nextLevel}{' '}
                        {loyaltyDetails?.level + 1} :
                      </div>
                      <div className='rewards'>{languageData?.rewards}</div>
                    </div>
                    <CustomOverlayTrigger
                      overlayContent={overlayContent}
                      {...dashboardOverlayTrigger}
                    >
                      <button className='loyalty-info-i'>i</button>
                    </CustomOverlayTrigger>
                  </div>

                  {loyaltyDetails?.bonusId &&
                    <div className='reward-icon-section'>
                      <img
                        src={`${process.env.REACT_APP_STATIC_IMAGE}/cash-money.svg`}
                        alt='img'
                      />
                      <div className='cash-bonus-amount'>
                        <OverlayTrigger
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {(loyaltyDetails?.bonusPromotionTitle?.[selectedLanguageCode] || loyaltyDetails?.bonusPromotionTitle?.EN)}
                            </Tooltip>
                          )}
                          placement='top'
                        >
                          <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/${(selectedLanguageCode?.toLowerCase())}/promotion/${loyaltyDetails?.bonusId}`)}
                            className='text-truncate mb-0'
                          >
                            {(loyaltyDetails?.bonusPromotionTitle?.[selectedLanguageCode] || loyaltyDetails?.bonusPromotionTitle?.EN)}
                          </p>
                        </OverlayTrigger>
                      </div>
                    </div>}
                </div>
              </div>
            </div>
            )}
      </div>
    </>
  )
}

export default withContextProvider(LoyaltySection)
