import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from '../../../utils/useDidMountEffect'
import { formatDateYMD, getDateDaysAgo } from '../../../utils/dateFormatter'
import { cancelWithdrawRequestStart, getWithdrawalHistoryStart } from '../../../store/redux-slices/transactions'

const useWithdrawList = () => {
  const dispatch = useDispatch()
  const [id, setId] = useState('')
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [show, setShow] = useState(false)
  const [status, setStatus] = useState('')
  const [search, setSearch] = useState('')
  const isInitialRender = useDidMountEffect()
  const { userDetails } = useSelector(state => state.user)
  const { languageData } = useSelector((state) => state.language)
  const { loading: withdrawLoading, withdrawalHistory } = useSelector(state => state.transactions || '')

  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const fetchWithdrawList = () => {
    dispatch(getWithdrawalHistoryStart({
      limit,
      pageNo: page,
      status,
      startDate: formatDateYMD(state?.[0]?.startDate),
      endDate: formatDateYMD(state?.[0]?.endDate),
      search
    }))
  }

  const fetchLatestChanges = () => {
    if (limit === 10 && page === 1 && status === '') {
      fetchWithdrawList()
    } else {
      setLimit(10)
      setPage(1)
      setStatus('')
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (page === 1) {
        fetchWithdrawList()
      } else {
        setPage(1)
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [limit, status, state])

  useEffect(() => {
    !isInitialRender && fetchWithdrawList()
  }, [page])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          fetchWithdrawList()
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const handleYes = () => {
    dispatch(cancelWithdrawRequestStart({
      data: { withdrawRequestId: id },
      limit,
      pageNo: page,
      status,
      search
    }))
    setShow(false)
  }

  const handleCancel = (withdrawRequestId) => {
    setId(withdrawRequestId)
    setShow(true)
  }

  const totalPages = Math.ceil(withdrawalHistory?.count / limit)

  return {
    withdrawalHistory,
    loading: withdrawLoading,
    userDetails,
    handleCancel,
    page,
    setPage,
    limit,
    setLimit,
    status,
    setStatus,
    search,
    setSearch,
    state,
    setState,
    languageData,
    totalPages,
    show,
    setShow,
    handleYes,
    fetchLatestChanges
  }
}

export default useWithdrawList
