import React from 'react'
import { useSelector } from 'react-redux'

const CustomLabel = ({ name, label, defaultLabel, extraLabel, className, regFields, showStar }) => {
  const { languageData } = useSelector(state => state.language)
  return (
    defaultLabel ||
      <label htmlFor={name} className={className}>
        {languageData?.[label]}{''}
        {extraLabel && '/' + languageData?.[extraLabel]}
        {showStar && regFields && showStar(regFields?.[name])}
      </label>
  )
}

export default CustomLabel
