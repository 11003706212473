import { takeLatest, put, select } from 'redux-saga/effects'
import { Buffer } from 'buffer'
import {
  userLogin,
  getRegistrationFields,
  userSignUp,
  getTenantDetails,
  getAllCountries,
  verifyEmail,
  resendEmail,
  forgetPassword,
  resetPassword,
  logout,
  getThemes
} from '../../utils/apiCalls'

import {
  userLoginStart,
  userLoginSuccess,
  userLoginFailure,
  getRegistrationFieldsStart,
  getRegistrationFieldsSuccess,
  getRegistrationFieldsFailure,
  userSignUpStart,
  userSignUpComplete,
  getTenantDetailsStart,
  getTenantDetailsSuccess,
  getTenantDetailsFailure,
  getAllCountriesStart,
  getAllCountriesSuccess,
  getAllCountriesFailure,
  verifyEmailStart,
  verifyEmailComplete,
  resendEmailStart,
  resendEmailComplete,
  forgetPasswordStart,
  forgetPasswordComplete,
  resetPasswordStart,
  resetPasswordComplete,
  logoutStart,
  logoutComplete,
  getThemesStart,
  getThemesSuccess,
  getThemesFailure
} from '../redux-slices/login'
import {
  getItem,
  getLoginToken,
  removeLoginToken,
  setCookie,
  setItem,
  setLoginToken
} from '../../utils/storageUtils'
import { toast } from '../../components/Toast'
import { Routes } from '../../utils/routes'
import { resetState } from '../redux-slices'
import { setSelectedLanguagesStart } from '../redux-slices/language'
import { getCashbackBalanceStart } from '../redux-slices/user'
import { getHomeDataStart } from '../redux-slices/casinoMenu'

export default function * loginWatcher () {
  yield takeLatest(userLoginStart.type, userLoginWorker)
  yield takeLatest(getRegistrationFieldsStart.type, getRegistrationFieldsWorker)
  yield takeLatest(userSignUpStart.type, userSignUpWorker)
  yield takeLatest(getTenantDetailsStart.type, getTenantDetailsWorker)
  yield takeLatest(getAllCountriesStart.type, getAllCountriesWorker)
  yield takeLatest(verifyEmailStart.type, verifyEmailWorker)
  yield takeLatest(resendEmailStart.type, resendEmailWorker)
  yield takeLatest(forgetPasswordStart.type, forgetPasswordWorker)
  yield takeLatest(resetPasswordStart.type, resetPasswordWorker)
  yield takeLatest(logoutStart.type, logoutWorker)
  yield takeLatest(getThemesStart.type, getThemesWorker)
}

const selectedLangCode = state => state?.language
const getLanguageData = state => state?.language?.languageData

function * userLoginWorker (action) {
  try {
    const { email, password, navigate, returnTo, setShowModal, isSignup } = action && action.payload

    const encryptedPass = Buffer.from(password).toString('base64')
    const { data } = yield userLogin({ email, password: isSignup ? password : encryptedPass })
    const { accessToken, userId, selfExclusion, expiration, locale, countryCode } = data?.data
    const currentUrl = window.location.origin
    if (selfExclusion) {
      setShowModal({ show: true, expiration })
      yield put(userLoginFailure())
    } else if (data?.data?.redirectionUrl && !selfExclusion && currentUrl !== new URL(data?.data?.redirectionUrl).origin) {
      if (countryCode) setCookie('countryCode', countryCode, 365)
      const redirectionUrlWithParams = `${data?.data?.redirectionUrl}?redirectionToken=${encodeURIComponent(accessToken)}&userId=${encodeURIComponent(userId)}&countryCode=${encodeURIComponent(countryCode)}`
      window.location.href = redirectionUrlWithParams
    } else {
      const languageData = yield select(getLanguageData)
      yield setLoginToken(accessToken)
      yield put(userLoginSuccess())

      yield toast(`${languageData?.loginSuccess}`, 'success')
      yield setItem('loggedIn', String(true))
      yield setItem('userId', String(userId))
      if (countryCode) setCookie('countryCode', countryCode, 365)
      yield put(getHomeDataStart({ getLang: true, navigate }))
      if (locale) {
        yield put(setSelectedLanguagesStart({ selectedLanguageCode: locale }))
      }

      yield put(getCashbackBalanceStart())

      navigate(returnTo || Routes.homepage)

      if (returnTo) {
        window.locationPath = null
      }
    }
  } catch (e) {
    const { setShowResendEmail } = action && action.payload
    if (e?.response?.data?.errors[0]?.errorCode === 3043) {
      if (e?.response?.data?.errors[0]?.name === 'Email not verified') {
        setShowResendEmail(true)
      } else {
        setShowResendEmail(false)
      }
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
      yield put(userLoginFailure(e?.response?.data?.errors[0]?.description))
    } else {
      yield toast(e.message, 'error')
      yield put(userLoginFailure(e.message))
    }
  }
}

function * logoutWorker (action) {
  try {
    const { navigate } = action && action.payload
    const languageData = yield select(getLanguageData)
    yield logout()
    yield window.cioLogoutUser()
    yield put(resetState())

    yield put(logoutComplete())
    yield toast(languageData?.userLoggedOut, 'success')
    yield put(getHomeDataStart({ getLang: true, navigate }))
  } catch (e) {
    yield put(logoutComplete())
  }
}
function * getRegistrationFieldsWorker () {
  try {
    const { data } = yield getRegistrationFields()

    yield put(getRegistrationFieldsSuccess(data?.data?.registrationFields))
  } catch (e) {
    yield put(getRegistrationFieldsFailure(e.message))
  }
}

function * forgetPasswordWorker (action) {
  try {
    const email = action && action.payload

    const { data } = yield forgetPassword(email)
    yield put(forgetPasswordComplete(data?.data?.forgetPasswordEmailSent))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(forgetPasswordComplete())
  }
}

function * resetPasswordWorker (action) {
  const { selectedLanguageCode } = yield select(selectedLangCode)
  const languageData = yield select(getLanguageData)
  const { data, navigate } = action && action.payload
  try {
    yield resetPassword({ data })
    yield put(resetPasswordComplete())
    yield toast(`${languageData?.loginPassword} ${languageData?.updatedSuccess}`, 'success')
    yield navigate(`/${(selectedLanguageCode?.toLowerCase()) || languageData?.language?.toLowerCase() || 'en'}/login`)
    yield removeLoginToken()
  } catch (e) {
    yield navigate(`/${(selectedLanguageCode?.toLowerCase()) || languageData?.language?.toLowerCase() || 'en'}/forgot-password`)
    yield put(resetPasswordComplete())
    yield toast(e.response?.data?.message, 'error')
  }
}

function * userSignUpWorker (action) {
  try {
    const { initialState, navigate, returnTo } = action && action.payload
    const languageData = yield select(getLanguageData)
    yield userSignUp(initialState)

    yield put(userSignUpComplete())
    yield toast(`${languageData?.signupSuccess}`, 'success')

    yield put(userLoginStart({ ...initialState, isSignup: true, navigate, returnTo }))

    // navigate('/verify-email')
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(userSignUpComplete())
  }
}

function * getTenantDetailsWorker () {
  try {
    const { data } = yield getTenantDetails()

    yield put(getTenantDetailsSuccess(data?.data?.tenantDetail))
  } catch (e) {
    yield put(getTenantDetailsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllCountriesWorker () {
  try {
    const { data } = yield getAllCountries()

    yield put(getAllCountriesSuccess(data?.data?.countries))
  } catch (e) {
    yield put(getAllCountriesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * verifyEmailWorker (action) {
  const { navigate } = action.payload

  try {
    const { emailToken } = action.payload
    yield verifyEmail({ emailToken: emailToken.substring(12) })
    yield put(verifyEmailComplete(true))
  } catch (e) {
    const languageData = yield select(getLanguageData)
    if (e?.response?.data?.errors[0]?.errorCode === 3044) {
      yield put(verifyEmailComplete(true))
      yield toast(languageData?.[getLoginToken() ? 'accountVerified' : 'accAlreadyVerified'], 'success')
    } else if (e?.response?.status === 422) {
      yield toast(languageData?.linkExpired, 'error')
      yield put(verifyEmailComplete(false))
    } else if (e?.response?.data?.errors[0]?.errorCode === 3009) {
      const languageData = yield select(getLanguageData)
      yield toast(e?.response?.data?.errors[0]?.description, 'Error')
      yield put(resendEmailComplete())
      navigate(`/${getItem('language').toLowerCase() || languageData?.language?.toLowerCase() || 'en'}/login`)
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
      yield put(verifyEmailComplete(false))
    }
  }
}

function * resendEmailWorker (action) {
  const { navigate, isProfilePage } = action.payload

  try {
    const { email } = action.payload
    const languageData = yield select(getLanguageData)
    yield resendEmail(email)
    yield put(resendEmailComplete())
    yield toast(languageData?.linkSent, 'success')
    if (!isProfilePage) navigate('/verify-email')
  } catch (e) {
    if (e?.response?.data?.errors[0]?.errorCode === 3044) {
      const languageData = yield select(getLanguageData)
      yield toast(languageData?.[getLoginToken() ? 'accountVerified' : 'accAlreadyVerified'], 'success')
      yield put(resendEmailComplete())

      if (!isProfilePage) navigate(`/${getItem('language')?.toLowerCase() || languageData?.language?.toLowerCase()}/login`)
    } else {
      yield toast(e?.response?.data?.errors[0]?.description, 'error')
      yield put(resendEmailComplete())
    }
  }
}

function * getThemesWorker () {
  try {
    const { data } = yield getThemes()
    yield put(getThemesSuccess(data?.data?.ThemeList?.map(({ theme }) => theme)))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getThemesFailure())
  }
}
