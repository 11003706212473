export const dashboardOverlayTrigger = {
  trigger: 'focus',
  placement: 'left',
  overlayType: 'tooltip',
  overlayId: 'popover-contained',
  overlayClassName: 'dashboard-loyalty-popover',
}

export const bonusOverlayTrigger = {
  trigger: 'hover',
  placement: 'top',
  overlayType: 'tooltip',
  overlayId: 'popover-contained',
  overlayClassName: 'dashboard-loyalty-popover',
}

export const cashbackOverlayTrigger = {
  trigger: 'hover',
  placement: 'top',
  overlayType: 'popup',
  overlayId: 'popover-contained',
  overlayClassName: 'dashboard-loyalty-popover',
}

export const gameOverlayHover = {
  trigger: 'hover',
  placement: 'top',
  overlayType: 'tooltip',
  overlayId: 'popover-contained',
  overlayClassName: 'dashboard-loyalty-popover',
}
