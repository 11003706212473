import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { getItem } from '../../../utils/storageUtils'
import { SignUpContext } from '../../../pages/NewSignup/useContext'
import { UserRegisterButton } from '../../Buttons'
const SignupFooter = ({ isFinalStep, formik }) => {
  const { languageData, loading } = useContext(SignUpContext)
  return (
    <div>
      <div>
        {isFinalStep && (
          <UserRegisterButton
            formik={formik}
            loading={loading}
            spinner
            text={languageData?.signupCreateAnAccount}
          />
        )}
      </div>

      <div className='dont-have-ac mb-2'>
        {languageData?.signupHaveAccount}?{' '}
        <Link
          to={`/${getItem('language')?.toLowerCase()}/login`}
        >
          {languageData?.loginKey}
        </Link>
      </div>
    </div>
  )
}

export default SignupFooter
