import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getCashbackTableStart } from '../../../store/redux-slices/bonus'
import { getTenantDetailsStart } from '../../../store/redux-slices/login'
import { checkDelay, setDelay } from '../../../utils/apiDelayHelper'

const useCashbackBonus = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { tenantDetails } = useSelector(state => state?.login)
  const { selectedLanguageCode, languageData } = useSelector(state => state?.language)
  const { cashbackTable, loading } = useSelector(state => state?.bonus)
  const { bannerUrl } = useSelector(state => state?.casinoMenu?.gameCategory)
  const { loading: bannerLoading } = useSelector(state => state?.casinoMenu)

  const [showMoreLevels, setShowMoreLevels] = useState(false)

  useEffect(() => {
    if (checkDelay('getCashbackTableStart')) {
      dispatch(getCashbackTableStart())
      setDelay('getCashbackTableStart', 300000)
    }
    if (checkDelay('getTenantDetailsStart')) {
      dispatch(getTenantDetailsStart())
      setDelay('getTenantDetailsStart', 300000)
    }
  }, [])

  const CASHBACK_DATA = [
    { imgSlug: 'cashback-difference.png', headingText: languageData?.cashbackHeadingTwo, descText: languageData?.cashbackHeadingTwoDesc },
    { imgSlug: 'claim-money.png', headingText: languageData?.cashbackHeadingThree, descText: languageData?.cashbackHeadingThreeDesc },
    { imgSlug: 'maximum-cashback.png', headingText: languageData?.cashbackHeadingFour, descText: languageData?.cashbackHeadingFourDesc }
  ]

  return {
    loading,
    cashbackTable,
    languageData,
    tenantDetails,
    bannerUrl,
    bannerLoading,
    selectedLanguageCode,
    showMoreLevels,
    setShowMoreLevels,
    navigate,
    CASHBACK_DATA
  }
}

export default useCashbackBonus
