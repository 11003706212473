import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getGameSubCategoryStart, getHomeDataStart } from '../../../store/redux-slices/casinoMenu'
import { checkDelay, setDelay } from '../../../utils/apiDelayHelper'

const useBanner = () => {
  const { languageChanged, selectedLanguageCode } = useSelector((state) => state?.language)
  const { bannerUrl, casinoCategories } = useSelector((state) => state?.casinoMenu?.gameCategory)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { homeLoading, gameCategory } = useSelector(state => state?.casinoMenu)

  useEffect(() => {
    if (Object.keys(gameCategory)?.length !== 0 && checkDelay('getGameSubCategoryStart')) {
      dispatch(getGameSubCategoryStart({
        categoryId: gameCategory?.casinoCategories?.rows?.[0]?.tenantGameCategoryId
      }))
      setDelay('getGameSubCategoryStart', 300000)
    } else if (checkDelay('getHomeDataStart')) {
      !casinoCategories && !homeLoading && dispatch(getHomeDataStart({ getLang: false, navigate }))
      setDelay('getHomeDataStart', 300000)
    }
  }, [gameCategory, languageChanged])

  return {
    bannerUrl,
    selectedLanguageCode
  }
}

export default useBanner
