import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getTournamentsStart, getTournamentsSuccess } from '../../../store/redux-slices/tournament'
import useDidMountEffect from '../../../utils/useDidMountEffect'

const useTournamentPage = () => {
  const { selectedLanguageCode, languageData } = useSelector((state) => state.language)
  const { tournaments, loading } = useSelector(state => state.tournament)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [limit, setLimit] = useState(6)
  const [pageNo, setPageNo] = useState(1)
  const [status, setStatus] = useState('all')
  const { userDetails } = useSelector((state) => state.user)
  const isInitialRender = useDidMountEffect()
  const { bannerUrl } = useSelector(state => state?.casinoMenu?.gameCategory)

  // useEffect(() => {
  //   const script = document.createElement('script')

  //   script.src = 'https://demo.iframe.a8r.games/leaderboard-widget/latest/index.js?id=00000000-0000-0000-0000-799691377097&url=https://api.demo.lobby.a8r.games'
  //   script.async = true

  //   document.body.appendChild(script)

  //   window.scrollTo(0, 0)
  //   dispatch(showFooter(true))

  //   return () => {
  //     document.body.removeChild(script)
  //   }
  // }, [])

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://cdn.launcher.a8r.games/scripts/glconnector/connector.js'
    script.async = true

    document.body.appendChild(script)

    window.scrollTo(0, 0)
    // dispatch(showFooter(true))

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    dispatch(getTournamentsStart({ limit, pageNo, status }))
  }, [limit])

  const fetchMore = () => {
    setPageNo(pageNo + 1)
    dispatch(getTournamentsStart({ limit, pageNo: pageNo + 1, status }))
  }

  useEffect(() => {
    if (!isInitialRender) {
      setPageNo(1)
      dispatch(getTournamentsSuccess({}))
      dispatch(getTournamentsStart({ limit, pageNo: 1, status }))
    }
  }, [status])

  return {
    selectedLanguageCode,
    navigate,
    userDetails,
    limit,
    pageNo,
    status,
    setLimit,
    setStatus,
    setPageNo,
    fetchMore,
    languageData,
    tournaments,
    loading,
    dispatch,
    bannerUrl
  }
}

export default useTournamentPage
