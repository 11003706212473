import React from 'react'
import './styles/index.scss'
import useFreeSpinGameCard from '../../hooks/useFreeSpinGameCard'
import GameModal from './GameModal'
const FreeSpinGamesModal = ({ showFreeSpinGameModal, setShowFreeSpinGameModal, selectedBonusDetail, activateBonus }) => {
  const { loading, bonusDetail } = useFreeSpinGameCard(selectedBonusDetail)
  return (
    <GameModal
      loading={loading}
      showFreeSpinGameModal={showFreeSpinGameModal}
      setShowFreeSpinGameModal={setShowFreeSpinGameModal}
      games={bonusDetail?.games}
      selectedBonusDetail={selectedBonusDetail}
      activateBonus={activateBonus}
    />
  )
}

export default FreeSpinGamesModal
