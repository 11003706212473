import * as Yup from 'yup'

export const userSigninSchemaEN = (userFields, user, isChecked, languageData) =>
  userFields &&
  Yup.object().shape({
    password:
      userFields.password && user && isChecked && userFields.password === 2
        ? Yup.string().min(6, languageData?.min6Chars).required(languageData?.passwordRequired)
        : userFields.password && user && userFields.password !== 0 && Yup.string()
          .min(6, languageData?.min6Chars)
          .max(50, languageData?.max50Characters)
          .nullable(),
    confirmPassword: userFields.confirmPassword && user && isChecked && userFields.confirmPassword === 2
      ? Yup.string()
        .oneOf([Yup.ref('password'), null], languageData?.matchPassword)
        .required(languageData?.confirmPasswordRequired)
      : userFields.confirmPassword && user && userFields.confirmPassword !== 0 && Yup.string()
        .nullable(),
    firstName:
      userFields.firstName &&
      Yup.string()
        .min(3, languageData?.fName3Chars)
        .matches(/^([^0-9]*)$/, languageData?.notNumbers)
        .max(50, languageData?.max50Characters).required(languageData?.firstNameRequired),
    lastName:
      userFields.lastName === 2
        ? Yup.string()
          .matches(/^([^0-9]*)$/, languageData?.notNumbers)
          .min(3, languageData?.lName3Chars)
          .max(50, languageData?.max50Characters).required(languageData?.lastNameRequired)
        : Yup.string()
          .matches(/^([^0-9]*)$/, languageData?.notNumbers)
          .min(3, languageData?.lName3Chars)
          .max(50, languageData?.max50Characters).nullable(),
    phone: userFields.phone === 2
      ? Yup.string().required(languageData?.phoneRequired).nullable()
      : userFields.phone !== 0 &&
          Yup.string().nullable(),
    dateOfBirth:
          userFields.dateOfBirth === 2
            ? Yup.date()
              .max(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), languageData?.DOBEarlyThanToday)
              .typeError(languageData?.validDOB)
              .required(languageData?.invalidDate)
            : userFields.dateOfBirth !== 0 && Yup.date()
              .max(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), languageData?.DOBEarlyThanToday)
              .typeError(languageData?.validDOB).nullable(),
    username:
      userFields.username === 2
        ? Yup.string()
          .min(3, languageData?.min3Characters)
          .max(20, languageData?.max20Characters)
          .matches(/^[A-Za-z][^\s]*$/, languageData?.userNameAllowed)
          .required(languageData?.userNameRequired).nullable()
        : userFields.username !== 0 && Yup.string()
          .min(3, languageData?.min3Characters)
          .max(20, languageData?.max20Characters)
          .matches(/^[A-Za-z][^\s]*$/, languageData?.userNameAllowed)
          .nullable(),
    address: userFields.address === 2
      ? Yup.string()
        .max(100, languageData?.max100Characters).required(languageData?.addressRequired).nullable()
      : userFields.address !== 0 && Yup.string()
        .max(100, languageData?.max100Characters).nullable(),
    city: userFields.address === 2
      ? Yup.string().max(50, languageData?.max50Characters)
        .required(languageData?.cityRequired).nullable()
      : userFields.address !== 0 && Yup.string()
        .max(50, languageData?.max50Characters).nullable(),
    zipCode: userFields.address === 2
      ? Yup.string()
        .required(languageData?.postCodeRequired).nullable()
      : userFields.address !== 0 && Yup.string()
        .nullable(),
    currencyCode:
      userFields.currencyCode &&
      Yup.string().required(languageData?.currencyCodeRequired),
    countryCode: userFields.countryCode === 2
      ? Yup.string().required(languageData?.countryRequired)
      : userFields.countryCode !== 0 && Yup.string().nullable(),
    preferredLanguage: userFields.preferredLanguage === 2
      ? Yup.string().required(languageData?.preferredLanguageRequired).nullable()
      : userFields.preferredLanguage !== 0 && Yup.string().nullable()
  })

export const userSigninSchema = (userFields, user, isChecked, languageData) =>
  userFields &&
    Yup.object().shape({
      password:
        userFields.password && user && isChecked && userFields.password === 2
          ? Yup.string()
            .required(languageData?.passwordRequired)
          : userFields.password && user && userFields.password !== 0 && Yup.string()
            .max(50, languageData?.max50Characters)
            .nullable(),
      confirmPassword: userFields.confirmPassword && user && isChecked && userFields.confirmPassword === 2
        ? Yup.string()
          .oneOf([Yup.ref('password'), null], languageData?.matchPassword)
          .required(languageData?.confirmPasswordRequired)
        : userFields.confirmPassword && user && userFields.confirmPassword !== 0 && Yup.string()
          .nullable(),
      firstName:
        userFields.firstName &&
        Yup.string()
          .min(3, languageData?.fName3Chars)
          .matches(/^([^0-9]*)$/, languageData?.notNumbers)
          .max(50, languageData?.fName50Chars)
          .required(languageData?.firstNameRequired),
      lastName:
        userFields.lastName === 2
          ? Yup.string()
            .min(3, languageData?.lName3Chars)
            .matches(/^([^0-9]*)$/, languageData?.notNumbers)
            .max(50, languageData?.lName50Chars)
            .required(languageData?.lastNameRequired)
          : Yup.string()
            .min(3, languageData?.lName3Chars)
            .matches(/^([^0-9]*)$/, languageData?.notNumbers)
            .max(50, languageData?.lName50Chars)
            .nullable(),
      phone: userFields.phone === 2
        ? Yup.string().required(languageData?.phoneRequired).nullable()
        : userFields.phone !== 0 && Yup.string().nullable(),
      dateOfBirth:
        userFields.dateOfBirth === 2
          ? Yup.date()
            .max(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), languageData?.DOBEarlyThanToday)
            .typeError(languageData?.validDOB)
            .required(languageData?.invalidDate)
          : userFields.dateOfBirth !== 0 && Yup.date()
            .max(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1), languageData?.DOBEarlyThanToday)
            .typeError(languageData?.validDOB).nullable(),
      username:
        userFields.username === 2
          ? Yup.string()
            .min(3, languageData?.min3Characters)
            .max(20, languageData?.max20Characters)
            .matches(/^[A-Za-z][^\s]*$/, languageData?.userNameAllowed)
            .required(languageData?.userNameRequired).nullable()
          : userFields.username !== 0 && Yup.string()
            .min(3, languageData?.min3Characters)
            .max(20, languageData?.max20Characters)
            .matches(/^[A-Za-z][^\s]*$/, languageData?.userNameAllowed)
            .nullable(),
      address: userFields.address === 2
        ? Yup.string()
          .max(100, languageData?.max100Characters)
          .required(languageData?.addressRequired)
          .nullable()
        : userFields.address !== 0 && Yup.string()
          .max(100, languageData?.max100Characters)
          .nullable(),
      city: userFields.address === 2
        ? Yup.string()
          .max(50, languageData?.max50Characters)
          .required(languageData?.cityRequired)
          .nullable()
        : userFields.address !== 0 && Yup.string()
          .max(50, languageData?.max50Characters)
          .nullable(),
      zipCode: userFields.address === 2
        ? Yup.string()
          .required(languageData?.postCodeRequired)
          .nullable()
        : userFields.address !== 0 && Yup.string()
          .nullable(),
      currencyCode:
        userFields.currencyCode &&
        Yup.string().required(languageData?.currencyCodeRequired),
      countryCode: userFields.countryCode === 2
        ? Yup.string().required(languageData?.countryRequired)
        : userFields.countryCode !== 0 && Yup.string().nullable()
    })
