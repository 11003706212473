import React from 'react'
import { toast as toastify } from 'react-toastify'
import store from '../../store'

export const toast = (message, type) => {
  switch (type) {
    case 'success':
      return toastify.success(Toast({ message, title: 'success' }))

    case 'error':
      return toastify.error(Toast({ message: <p><span>{message?.charAt(0)}</span><span style={{ textTransform: 'lowercase' }}>{message?.slice(1)}</span></p>, title: 'error' }))

    default:
      return toastify.warning(Toast({ message, title: 'warning' }))
  }
}

const Toast = ({ message, title }) => (
  <>
    <span className='toast-title'>{store ? store?.getState('language')?.language?.languageData?.[title] : ''}</span>

    <span>{message}</span>
  </>
)
