import React, { useContext } from 'react'
import { dashboardContext } from '../../../context'
import BonusCard from '../BonusCard'
import DashboardSectionHeading from '../Common/DashboardSectionHeading'
import './styles/index.scss'
import withContextProvider from '../../../wrapper/withContextProvider'
import { useSelector } from 'react-redux'
import AvailableGames from './AvailableGames'
import TermsAndConditions from './TermAndCondition'
import { formatDateMDY } from '../../../utils/dateFormatter'
import Loader from '../../Loader'
import NoDataAvailable from '../../NoDataAvailable'
import LoyaltyProgressBar from '../../LoyaltyProgressBar'

const ActiveBonuses = () => {
  const dashboardDetail = useContext(dashboardContext)
  const { userActiveBonus, activeBonusLoading, languageData } = dashboardDetail
  const { bonusDetail } = useSelector((state) => state.bonus)

  const activeBonus = userActiveBonus.rows?.filter(
    (bonusDetail) => bonusDetail.status === 'ACTIVE'
  )
  const wageredAmount =
    activeBonus?.length > 0 ? activeBonus[0]?.wageredAmount : 0

  const amountToWager =
    activeBonus?.length > 0 ? activeBonus[0]?.amountToWager : 1 // Avoid division by zero

  const loyaltyProgressPercentage = parseFloat(
    (wageredAmount / amountToWager) * 100
  )?.toFixed(2)

  const loyaltyProgressLabel =
    loyaltyProgressPercentage >= 100 ? '100%' : `${loyaltyProgressPercentage}%`

  return (
    <div className='dashboard-section'>
      <div className='active-bonuses-section'>
        <DashboardSectionHeading headingText={languageData?.activeBonuses} />
        {activeBonusLoading
          ? (
            <Loader top='2rem' />
          )
          : userActiveBonus?.count === 0
            ? (
              <div className='no-data-found'>
                <NoDataAvailable
                  errorMessage={languageData?.noBonuses}
                />
              </div>
            )
            : (
              bonusDetail && (
                <>
                  <div className='active-bonuses-list'>
                    {userActiveBonus.rows?.length > 0 && (
                      <BonusCard
                        cardStatus='active'
                        bonusList={userActiveBonus}
                        {...dashboardDetail}
                      />
                    )}
                  </div>
                  <div className='bonus-active-info'>
                    <div className='dates'>
                      <span className='active'>
                        {languageData?.activeFrom}
                        {formatDateMDY(bonusDetail.validFrom)}
                        {' - '}
                      </span>
                      <span className='expires'>
                        {languageData?.expires} {formatDateMDY(bonusDetail.validTo)}
                      </span>
                    </div>
                    {activeBonus?.length > 0 &&
                      activeBonus[0]?.status !== 'PENDING' &&
                      activeBonus[0]?.status === 'ACTIVE' &&
                      activeBonus[0]?.wageringStatus === 'STARTED' &&
                      activeBonus[0]?.bonusType !== 'balance' && (
                        <>
                          <div className='wager-main-progress-container'>
                            <LoyaltyProgressBar
                              now={loyaltyProgressPercentage}
                              label={loyaltyProgressLabel}
                              progressClass='default-percentage'
                            />
                          </div>
                        </>
                      )}
                  </div>
                  {bonusDetail?.games?.length > 0 && <AvailableGames />}
                  {
                    bonusDetail.termCondition &&
                    <TermsAndConditions
                      languageData={languageData}
                      initialContent={bonusDetail.termCondition}
                    />
                  }
                </>
              )
            )}
      </div>
    </div>
  )
}

export default withContextProvider(ActiveBonuses)
